<template>
  <div class="d-flex" v-if="equipment != null">
    <v-menu dense offset-y top left z-index="1000">
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          :icon="icon"
          :disabled="disabled"
          :elevation="icon ? 0 : 2"
          :width="icon ? 'auto' : '32px'"
          :height="icon ? 'auto' : '32px'"
          :min-width="icon ? 'auto' : '32px'"
          :min-height="icon ? 'auto' : '32px'"
          v-bind="attrs"
          v-on="on"
        >
          <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
        </v-btn>
      </template>
      <v-list class="more-options-menu">
        <v-list-item
          @click="update"
          v-if="!hideUpdate && $has(perms.Equipments.Update)"
        >
          <v-list-item-icon class="mr-2 justify-center">
            <v-icon small>fal fa-pen</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Edit</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="cloneEquipment"
          v-if="$has(perms.Equipments.Update)"
        >
          <v-list-item-icon class="mr-2 justify-center">
            <v-icon small>fal fa-clone</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Clone</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="displayStats">
          <v-list-item-icon class="mr-2 justify-center">
            <v-icon small>fal fa-analytics</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Stats</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="displayActivityLogs">
          <v-list-item-icon class="mr-2 justify-center">
            <v-icon small>fal fa-history</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Activity Logs</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider
          class="my-2"
          v-if="$has(perms.Equipments.Delete)"
        ></v-divider>
        <v-list-item
          @click="openDeleteReplaceDialog"
          v-if="$has(perms.Equipments.Delete)"
        >
          <v-list-item-icon class="mr-2 justify-center">
            <v-icon small>fal fa-trash-undo purple--text</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="purple--text"
              >Delete & Replace</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="del" v-if="$has(perms.Equipments.Delete)">
          <v-list-item-icon class="mr-2 justify-center">
            <v-icon small>fal fa-trash-alt red--text</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="red--text">Delete</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <replace-equipment
      nested
      ref="replaceEquipmentComp"
      @Success="replaceEquipmentSuccedded()"
    ></replace-equipment>
    <equipment-activity-log
      nested
      ref="equipmentActivityLog"
    ></equipment-activity-log>
    <equipment-stats
      nested
      ref="statsEquipment"
      :template="equipment"
    ></equipment-stats>
  </div>
</template>

<script>
import perms from "../../../plugins/permissions";
import ApiService from "../services/equipments-service.js";
import ReplaceEquipment from "./ReplaceEquipment.vue";
import EquipmentStats from "./EquipmentStats.vue";
import EquipmentActivityLog from "./activityLogs/EquipmentActivityLog.vue";

export default {
  data() {
    return {
      perms: perms,
      openEquipmentInEditMode: false,
      paramId: null,
      modals: {
        replaceEquipment: {
          active: false,
          sourceEquipment: {}
        }
      }
    };
  },
  props: {
    equipment: {
      type: Object,
      default: null
    },
    hideUpdate: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hasChanges: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean,
      default: false
    }
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    del(id) {
      if (this.hasChanges) {
        this.$dialog.warning({
          title: `Can't Perform This Action!`,
          text: `<h4>This equipment has some changes! so please save changes or discard to proceed!</h4>`,
          color: "warning",
          actions: {
            true: {
              text: "OK",
              color: "warning"
            }
          }
        });
        return;
      }
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this equipment?<br />${this.createEquipmentCardHtml(
            this.equipment
          )}`,
          title: `Delete Equipment?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel"
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return ApiService.delete(this.equipment.id)
                  .then(resp => {
                    this.$emit("deleteEquipmentSuccess", this.equipment.id);
                    this.$dialog.notify.success(
                      "Equipment deleted successfully!",
                      {
                        position: "top-right",
                        timeout: 3000
                      }
                    );
                  })
                  .catch(resp => {
                    this.$dialog.notify.error("Error deleting equipment!", {
                      position: "top-right",
                      timeout: 3000
                    });
                  });
              }
            }
          }
        })
        .then(res => {});
    },
    createEquipmentCardHtml(equipmentObj) {
      var manufacturer = ``;
      var productFamily = ``;
      if (equipmentObj.manufacture != null) {
        if (equipmentObj.productFamily != null) {
          productFamily = `<h5 class="ma-0 fs-12px text--disabled" style="line-height: 1;" v-if="item.productFamily != null">${equipmentObj.productFamily.name}</h5>`;
        }
        var manufacturerImg = `<img src="/img/DNA_Symbol.png" style="width: 100%; height: 100%;" />`;
        if (
          equipmentObj.manufacture.logoUrl != null &&
          equipmentObj.manufacture.logoUrl != ""
        ) {
          manufacturerImg = `<img src="${equipmentObj.manufacture.logoUrl}" style="width: 100%; height: 100%;" />`;
        }
        manufacturer = `
          <div class="d-flex flex-row">
            <div class="company-logo-mini pa-1 mr-1">${manufacturerImg}</div>
            <div class="d-flex flex-column">
              <h5 class="ma-0">${equipmentObj.manufacture.name}</h5>
              ${productFamily}
            </div>
          </div>
        `;
      }
      var equipImg = `<i class="fad fa-plug"></i>`;
      if (equipmentObj.imageUrl != null && equipmentObj.imageUrl != "")
        equipImg = `<img src="${equipmentObj.imageUrl}" height="100%" width="100%">`;
      else if (equipmentObj.category != null)
        equipImg = `<i class="${"fad " + equipmentObj.category.icon}"></i>`;

      return `
        <div class="d-flex align-center flex-row pa-2 mt-2"
          style="border-radius: .5rem; border: 1.5px solid rgba(42, 54, 59, 0.24); gap: .65rem">
          <div class="equipment-img">${equipImg}</div>
          <div>
              ${manufacturer}
              <div class="d-flex align-center mt-1"><b class='px-1'>Tag:</b><b class='mono-font' 
              style="line-height: 1;">${equipmentObj.tag}</b></div>
          </div>
        </div>
      `;
    },
    cloneEquipment() {
      this.$dialog
        .info({
          text: `Are you sure you want to clone this equipment?<br/>${this.createEquipmentCardHtml(
            this.equipment
          )}`,
          title: `Clone Equipment?`,
          color: "info",
          persistent: true,
          actions: {
            false: {
              text: "Cancel"
            },
            true: {
              text: "Confirm",
              color: "info",
              handle: () => {
                return ApiService.clone(this.equipment.id)
                  .then(resp => {
                    this.$emit("cloneEquipmentSuccess", resp.data);
                  })
                  .catch(resp => {
                    this.$dialog.notify.error(
                      "Oops! an error occurred during cloning this equipment!",
                      {
                        position: "top-right",
                        timeout: 3000
                      }
                    );
                  });
              }
            }
          }
        })
        .then(res => {});
    },
    openDeleteReplaceDialog() {
      this.modals.replaceEquipment.sourceEquipment = this.equipment;
      this.$refs.replaceEquipmentComp.open(this.equipment);
    },
    replaceEquipmentSuccess() {
      this.$emit("replaceEquipmentSuccess");
    },
    displayStats() {
      this.$refs.statsEquipment.open(this.equipment);
    },
    displayActivityLogs() {
      this.$refs.equipmentActivityLog.open(this.equipment);
    },
    update() {
      this.$emit("update", this.equipment.id);
    }
  },
  watch: {},
  components: {
    EquipmentStats,
    ReplaceEquipment,
    EquipmentActivityLog
  }
};
</script>

<style lang="scss"></style>
