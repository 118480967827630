import Vue from "vue";
import Footer from "./components/Footer.vue";
import Header from "./components/Header.vue";
import SideMenu from "./components/SideMenu.vue";
import Dater from "./components/Dater.vue";
import PageTitle from "./components/PageTitle.vue";
import UserAvatar from "./components/UserAvatar.vue";
import UserProfileCard from "./components/UserProfileCard.vue";
import VTextFieldAlt from "./components/VTextFieldAlt.vue";
import VTextareaAlt from "./components/VTextareaAlt.vue";
import VAutocompleteAlt from "./components/VAutocompleteAlt.vue";
import VComboboxAlt from "./components/VComboboxAlt.vue";
import VSelectAlt from "./components/VSelectAlt.vue";
import VBreadcrumbsAlt from "./components/VBreadcrumbsAlt.vue";
import SingleImageUploader from "./components/SingleImageUploader.vue";
import SpaceCount from "./components/SpaceCount.vue";
import EquipmentCount from "./components/EquipmentCount.vue";
import CountTag from "./components/CountTag.vue";
import ProjectCard from "./components/ProjectCard.vue";
import ProjectCardEmpty from "./components/ProjectCardEmpty.vue";
import Counter from "./components/Counter.vue";
import Slideout from "@hyjiacan/vue-slideout";
import ColumnsVisibilityControl from "./components/ColumnsVisibilityControl.vue";
import SortByColumn from "./components/SortByColumn.vue";
import EntityId from "./components/EntityId.vue";
import Spotlight from "./components/Spotlight.vue";
import Refresh from "./components/Refresh.vue";
import FilterMenu from "./components/FilterMenu.vue";
import FiltersControl from "./components/FiltersControl.vue";
import StatusItem from "./components/StatusItem.vue";
import PhoneNumber from "./components/PhoneNumber.vue";
import AddressSummary from "./components/AddressSummary.vue";

Vue.component("Footer", Footer);
Vue.component("Header", Header);
Vue.component("SideMenu", SideMenu);
Vue.component("Dater", Dater);
Vue.component("PageTitle", PageTitle);
Vue.component("UserAvatar", UserAvatar);
Vue.component("UserProfileCard", UserProfileCard);
Vue.component("VTextFieldAlt", VTextFieldAlt);
Vue.component("VTextareaAlt", VTextareaAlt);
Vue.component("VAutocompleteAlt", VAutocompleteAlt);
Vue.component("VComboboxAlt", VComboboxAlt);
Vue.component("VSelectAlt", VSelectAlt);
Vue.component("VBreadcrumbsAlt", VBreadcrumbsAlt);
Vue.component("SingleImageUploader", SingleImageUploader);
Vue.component("SpaceCount", SpaceCount);
Vue.component("EquipmentCount", EquipmentCount);
Vue.component("CountTag", CountTag);
Vue.component("ProjectCard", ProjectCard);
Vue.component("ProjectCardEmpty", ProjectCardEmpty);
Vue.component("Counter", Counter);
Vue.component("Slideout", Slideout);
Vue.component("ColumnsVisibilityControl", ColumnsVisibilityControl);
Vue.component("SortByColumn", SortByColumn);
Vue.component("EntityId", EntityId);
Vue.component("Spotlight", Spotlight);
Vue.component("Refresh", Refresh);
Vue.component("FilterMenu", FilterMenu);
Vue.component("FiltersControl", FiltersControl);
Vue.component("StatusItem", StatusItem);
Vue.component("PhoneNumber", PhoneNumber);
Vue.component("AddressSummary", AddressSummary);
