<template>
  <v-container fluid class="px-6 pt-2" style="background: #eceff1">
    <v-row class="my-0" justify="center" align="center">
      <v-col>
        <h3>Accessories that contains this Equipment!</h3>
      </v-col>
      <v-col
        lg="5"
        md="2"
        sm="6"
        class="d-flex flex-row align-center justify-end flex-wrap"
        style="gap: 0.5rem"
      >
        <v-text-field
          v-model="options.search"
          label="Search Accessories"
          ref="mainSearch"
          dense
          solo
          clearable
          hide-details
          prepend-inner-icon="far fa-search"
          :loading="loadingStates.table"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      dense
      class="elevation-2"
      :headers="headers"
      :items="entities"
      :server-items-length="total"
      :items-per-page="15"
      :loading="loadingStates.table"
      :options.sync="options"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'far fa-arrow-to-left',
        lastIcon: 'far fa-arrow-to-right',
        prevIcon: 'far fa-angle-left',
        nextIcon: 'far fa-angle-right',
        itemsPerPageOptions: [15, 30, 50, 100],
      }"
    >
      <template v-slot:[`item.name`]="{ item }">
        <v-tooltip right z-index="999" nudge-right="-4px">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <div class="equipment-img">
                <div
                  style="height: 100%; width: 100%"
                  v-viewer
                  @click.stop
                  v-if="item.imageUrl != null && item.imageUrl != ''"
                >
                  <img :key="item.id + '_img'" :src="item.imageUrl" height="100%" width="100%" />
                </div>
                <i v-else-if="item.category != null" :class="'fad ' + item.category.icon"></i>
                <i v-else :class="'fad fa-plug'"></i>
              </div>
            </div>
          </template>
          <span>
            <v-img
              v-if="(item.imageUrl != null) & (item.imageUrl != '')"
              class="img"
              :src="item.imageUrl"
              width="250px"
              height="250px"
              contain
            ></v-img>
            <i
              v-else-if="item.category != null"
              :class="'fad ' + item.category.icon"
              style="font-size: 32px; padding: 2rem"
            ></i>
            <i v-else :class="'fad fa-plug'" style="font-size: 32px; padding: 2rem"></i>
          </span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.category.name`]="{ item }">
        <v-row no-gutters>
          <v-col sm="auto" class="d-flex justify-content-start align-items-center">
            <i :class="'equipment-icon fad ' + item.category.icon" v-if="item.category"></i>
          </v-col>
          <v-col class="d-flex align-center">
            <span class="category-name" v-if="item.category">{{ item.category.name }}</span>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.tag`]="{ item }">
        <div class="equipment-tag font-weight-bold">{{ item.tag }}</div>
      </template>

      <template v-slot:[`item.model`]="{ item }">
        <div class="equipment-model">{{ item.model }}</div>
      </template>

      <template v-slot:[`item.providerId`]="{ item }">
        <equipment-provider
          v-if="item.provider"
          :provider-name="item.provider.name"
        ></equipment-provider>
        <i v-else :class="'far fa-times pl-2 text--disabled'"></i>
      </template>

      <template v-slot:[`item.installerId`]="{ item }">
        <equipment-installer
          v-if="item.installer"
          :installer-name="item.installer.name"
        ></equipment-installer>
        <i v-else :class="'far fa-times pl-2 text--disabled'"></i>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          icon
          elevation="0"
          color="info"
          target="_blank"
          @click.stop
          :href="`/equipment/${item.id}`"
        >
          <i class="fal fa-external-link"></i>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <img width="300" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>

      <template v-slot:loading>
        <video width="250" muted loop autoplay style="padding: 0.5rem">
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Searching the Cosmos...</p>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import equipmentService from "../services/equipments-service";
import EquipmentProvider from "../components/EquipmentProvider.vue";
import EquipmentInstaller from "../components/EquipmentInstaller.vue";
import equipmentStatsAccessories from "../config/tables/equipmentStatsAccessories.header";

export default {
  name: "equipment-has-accessories",
  props: {
    equipment: {
      type: Object,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    storageKey: {
      type: String,
    },
  },
  data() {
    return {
      entities: [],
      total: 0,
      clientsTimerId: null,
      options: {
        search: null,
        page: 1,
        itemsPerPage: 15,
      },
      loadingStates: {
        table: false,
      },
      headers: equipmentStatsAccessories,
    };
  },
  created() {
    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
  },
  methods: {
    getEquipmentItems() {
      this.$backToTop(
        0,
        document.querySelector(".equipment-stats-tabs.v-window.v-item-group.v-tabs-items")
      );
      this.loadingStates.table = true;
      if (this.equipment) {
        equipmentService
          .getEquipmentItems(this.equipment.id, this.$clean(this.options, true), "accessories")
          .then((resp) => {
            this.entities = resp.data.items;
            this.total = resp.data.total;
            this.loadingStates.table = false;
          })
          .catch(() => {
            this.loadingStates.table = false;
          });
      }
    },
    getAccessoryDebounced() {
      if (this.clientsTimerId == null) {
        this.clientsTimerId = -1;
        this.getEquipmentItems();
        return;
      }
      // cancel pending call
      clearTimeout(this.clientsTimerId);

      // delay new call 400ms
      this.clientsTimerId = setTimeout(() => {
        this.getEquipmentItems();
      }, 400);
    },
  },
  watch: {
    options: {
      handler(val) {
        this.$setToLocal(`${this.storageKey}-itemsPerPage`, val.itemsPerPage);
        this.getAccessoryDebounced();
      },
      deep: true,
    },
    active(val) {
      if (val) {
        this.options.page = 1;
        this.getAccessoryDebounced();
      } else {
        this.entities = [];
        this.total = 0;
      }
    },
  },
  components: { EquipmentProvider, EquipmentInstaller },
};
</script>
