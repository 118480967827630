export default [
  {
    text: "Open",
    value: "actions",
    sortable: false,
    align: "left",
    width: "64px",
    order: 0,
    hidable: true,
    hidden: false,
  },
  {
    text: "Image",
    value: "imageUrl",
    width: "92px",
    order: 1,
    hidable: false,
    hidden: false,
  },
  { text: "Name", value: "name", order: 2, hidable: false, hidden: false },
  {
    text: "Last Update",
    value: "updateDate",
    width: "130px",
    order: 3,
    hidable: true,
    hidden: false,
  },
];
