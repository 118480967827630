<template>
  <div>
    <v-row class="mt-0 mb-1" justify="space-between">
      <v-col class="d-flex justify-start align-center">
        <v-switch
          v-model="selectedOnly"
          label="Show Selected Only"
          class="ma-0"
          style="font-size: 14px !important"
          hide-details
        ></v-switch>
      </v-col>
      <v-col lg="5" md="5" sm="12" class="d-flex justify-end align-center">
        <v-text-field
          v-model="options.search"
          label="Search"
          ref="mainSearch"
          class="table-search-field"
          @focus="mainSearchInFocus = true"
          @blur="mainSearchInFocus = false"
          hide-details
          :style="{
            'max-width':
              mainSearchInFocus || (options.search != '' && options.search != null)
                ? '200px'
                : '110px',
          }"
          dense
          solo
          clearable
          prepend-inner-icon="far fa-search"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      v-if="selectedOnly"
      dense
      :headers="type == 'suggested' ? suggested : headers"
      :items="selectedAccessories"
      :item-class="rowClass"
      :items-per-page="-1"
      class="elevation-2"
      hide-default-footer
    >
      <template v-slot:[`item.info`]="{ item }">
        <v-btn color="secondary" icon elevation="0" @click="viewEquipment(item[type].id)" small>
          <i class="far fa-info"></i>
        </v-btn>
      </template>

      <template v-slot:[`item.${type}.name`]="{ item }">
        <v-tooltip left z-index="999" nudge-left="-4px">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <div class="equipment-img">
                <div
                  style="height: 100%; width: 100%"
                  v-viewer
                  @click.stop
                  v-if="item[type].imageUrl != null && item[type].imageUrl != ''"
                >
                  <img
                    :key="item[type].id + '_img'"
                    :src="item[type].imageUrl"
                    height="100%"
                    width="100%"
                  />
                </div>
                <i
                  v-else-if="item[type].category != null"
                  :class="'fad ' + item[type].category.icon"
                ></i>
                <i v-else :class="'fad fa-plug'"></i>
              </div>
            </div>
          </template>
          <span>
            <v-img
              v-if="(item[type].imageUrl != null) & (item[type].imageUrl != '')"
              class="img"
              :src="item[type].imageUrl"
              width="250px"
              height="250px"
              contain
            ></v-img>
            <i
              v-else
              :class="'fad ' + item[type].category.icon"
              style="font-size: 32px; padding: 2rem"
            ></i>
          </span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.${type}.category.name`]="{ item }">
        <v-row align-content="center" justify="start" no-gutters>
          <v-col sm="auto" class="d-flex align-center justify-start">
            <i
              :class="'equipment-icon fad ' + item[type].category.icon"
              v-if="item[type].category"
            ></i>
          </v-col>
          <v-col class="d-flex align-center justify-start">
            <span class="category-name" v-if="item[type].category">{{
              item[type].category.name
            }}</span>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.${type}.tag`]="{ item }">
        <div class="equipment-tag">{{ item[type].tag }}</div>
      </template>

      <template v-slot:[`item.${type}.model`]="{ item }">
        <div class="equipment-model">{{ item[type].model }}</div>
      </template>

      <template v-slot:[`item.${type}.providerId`]="{ item }">
        <equipment-provider
          v-if="item[type].provider"
          :provider-name="item[type].provider.name"
        ></equipment-provider>
        <i v-else :class="'far fa-times pl-2 text--disabled'"></i>
      </template>

      <template v-slot:[`item.${type}.installerId`]="{ item }">
        <equipment-installer
          v-if="item[type].installer"
          :installer-name="item[type].installer.name"
        ></equipment-installer>
        <i v-else :class="'far fa-times pl-2 text--disabled'"></i>
      </template>

      <template v-slot:[`item.count`]="{ item }">
        <counter
          :key="item[type].id"
          v-model="item.count"
          :min="0"
          :max="1000"
          @input="onCountChangeFromSelectedOnly($event, item[type])"
        />
      </template>

      <template v-slot:no-data>
        <img width="300" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>
    </v-data-table>

    <v-data-table
      v-show="!selectedOnly"
      dense
      :headers="headers"
      :items="entities"
      :item-class="rowClass"
      :options.sync="options"
      :server-items-length="total"
      :items-per-page="15"
      :loading="loadingStates.table"
      class="elevation-2"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'far fa-arrow-to-left',
        lastIcon: 'far fa-arrow-to-right',
        prevIcon: 'far fa-angle-left',
        nextIcon: 'far fa-angle-right',
        itemsPerPageOptions: [15, 30, 50, 100],
      }"
    >
      <template v-slot:[`item.info`]="{ item }">
        <v-btn color="secondary" icon elevation="0" @click="viewEquipment(item.accessory.id)" small>
          <i class="far fa-info"></i>
        </v-btn>
      </template>
      <template v-slot:[`item.accessory.name`]="{ item }">
        <v-tooltip left z-index="999" nudge-left="-4px">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <div class="equipment-img">
                <div
                  style="height: 100%; width: 100%"
                  v-viewer
                  @click.stop
                  v-if="item.accessory.imageUrl != null && item.accessory.imageUrl != ''"
                >
                  <img
                    :key="item.accessory.id + '_img'"
                    :src="item.accessory.imageUrl"
                    height="100%"
                    width="100%"
                  />
                </div>
                <i
                  v-else-if="item.accessory.category != null"
                  :class="'fad ' + item.accessory.category.icon"
                ></i>
                <i v-else :class="'fad fa-plug'"></i>
              </div>
            </div>
          </template>
          <span>
            <v-img
              v-if="(item.accessory.imageUrl != null) & (item.accessory.imageUrl != '')"
              class="img"
              :src="item.accessory.imageUrl"
              width="250px"
              height="250px"
              contain
            ></v-img>
            <i
              v-else
              :class="'fad ' + item.accessory.category.icon"
              style="font-size: 32px; padding: 2rem"
            ></i>
          </span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.accessory.category.name`]="{ item }">
        <v-row align-content="center" justify="start" no-gutters>
          <v-col sm="auto" class="d-flex align-center justify-start">
            <i
              :class="'equipment-icon fad ' + item.accessory.category.icon"
              v-if="item.accessory.category"
            ></i>
          </v-col>
          <v-col class="d-flex align-center justify-start">
            <span class="category-name" v-if="item.accessory.category">{{
              item.accessory.category.name
            }}</span>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.accessory.tag`]="{ item }">
        <div class="equipment-tag">{{ item.accessory.tag }}</div>
      </template>

      <template v-slot:[`item.accessory.model`]="{ item }">
        <div class="equipment-model">{{ item.accessory.model }}</div>
      </template>

      <template v-slot:[`item.accessory.providerId`]="{ item }">
        <equipment-provider
          v-if="item.accessory.provider"
          :provider-name="item.accessory.provider.name"
        ></equipment-provider>
        <i v-else :class="'far fa-times pl-2 text--disabled'"></i>
      </template>

      <template v-slot:[`item.accessory.installerId`]="{ item }">
        <equipment-installer
          v-if="item.accessory.installer"
          :installer-name="item.accessory.installer.name"
        ></equipment-installer>
        <i v-else :class="'far fa-times pl-2 text--disabled'"></i>
      </template>

      <template v-slot:[`item.count`]="{ item }">
        <counter
          v-if="disabledAccessories == null || !disabledAccessories.includes(item.accessory.id)"
          v-model="item.count"
          :min="0"
          :max="1000"
          @input="onCountChange($event, item.accessory)"
        />
      </template>

      <template v-slot:no-data>
        <img width="300" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>
    </v-data-table>

    <edit-equipment ref="editEquipment" nested></edit-equipment>
  </div>
</template>

<script>
import ApiService from "../services/equipments-service.js";
import accessoryHeader from "../config/tables/accessory.header";
import suggestedHeader from "../config/tables/suggested.header";
import EquipmentProvider from "../components/EquipmentProvider.vue";
import EquipmentInstaller from "../components/EquipmentInstaller.vue";

export default {
  name: "add-accessories",
  data() {
    return {
      selectedOnly: false,
      entities: [],
      selectedAccessories: [],
      total: 0,
      search: "",
      valid: false,
      categories: [],
      addAccessoriesTimerId: -1,
      mainSearchInFocus: false,
      options: {
        id: null,
        search: null,
      },
      slideouts: {
        add: {
          active: false,
          isLoading: false,
        },
        update: {
          active: false,
          tab: null,
          isLoading: false,
          categoryLoading: false,
        },
      },
      loadingStates: {
        table: false,
      },
      headers: accessoryHeader,
      suggested: suggestedHeader,
    };
  },
  props: {
    value: {
      type: Array,
      default: null,
    },
    disabledAccessories: {
      type: Array,
      default: null,
    },
    type: {
      type: String,
      default: "accessory",
    },
  },
  mounted() {
    this.entities = [];
    if (this.value) {
      this.selectedAccessories = this.value.slice();
    } else {
      this.selectedAccessories = [];
    }
    this.$log("this.selectedAccessories", this.selectedAccessories);
  },
  methods: {
    viewEquipment(equipmentId) {
      this.$refs.editEquipment.open(equipmentId);
    },
    onCountChange(newVal, accessory) {
      this.$log("onCountChange", newVal, accessory);
      let foundIndex = this.selectedAccessories.findIndex((e) => e[this.type].id === accessory.id);
      let found = foundIndex != -1 ? this.selectedAccessories[foundIndex] : null;
      if (newVal > 0) {
        if (foundIndex != -1) found.count = newVal;
        else
          this.selectedAccessories.push({
            count: newVal,
            [this.type]: accessory,
          });
      } else {
        if (foundIndex != -1) this.selectedAccessories.splice(foundIndex, 1);
      }

      this.announceChange();
    },
    onCountChangeFromSelectedOnly(newVal, accessory) {
      this.$log("onCountChangeFromSelectedOnly", newVal, accessory);
      let foundIndex = this.selectedAccessories.findIndex((e) => e[this.type].id === accessory.id);
      if (newVal <= 0 && foundIndex != -1) {
        this.selectedAccessories.splice(foundIndex, 1);
      }

      //update existing entities
      let foundEntityItem = this.entities.find((e) => e[this.type].id === accessory.id);
      this.$log("foundEntityItem", foundEntityItem);
      if (foundEntityItem) foundEntityItem.count = newVal;

      this.announceChange();
    },
    announceChange() {
      this.$emit("input", this.selectedAccessories);
    },
    getData() {
      this.loadingStates.table = true;
      ApiService.query(this.$clean(this.options))
        .then((resp) => {
          resp.data.items = resp.data.items.map((equip) => {
            return {
              accessory: equip,
              count: this.getAddedQty(equip),
            };
          });
          this.$log("resp.data.items", resp.data.items);
          this.entities = resp.data.items.slice();
          this.$log("entities", this.entities);
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
          this.$handleError(err);
        });
    },
    getDataDebounced() {
      if (this.addAccessoriesTimerId == null) {
        this.addAccessoriesTimerId = -1;
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.addAccessoriesTimerId);

      // delay new call 400ms
      this.addAccessoriesTimerId = setTimeout(() => {
        this.getData();
      }, 400);
    },
    getAddedQty(equip) {
      let found = this.selectedAccessories.find((e) => e[this.type].id == equip.id);
      if (found) return found.count;
      return 0;
    },
    rowClass(item) {
      return item.count > 0 ? "row-not-empty" : "";
    },
  },
  watch: {
    options: {
      handler() {
        this.getDataDebounced();
      },
      deep: true,
    },
  },
  components: { EquipmentProvider, EquipmentInstaller },
};
</script>

<style lang="scss">
.v-list-item__title {
  font-size: 14px !important;
}
</style>
