<template>
  <div
    :class="{
      'equipment-card-no-border': noBorder,
      'py-0 px-0': small, // px-1
      'py-2 px-3': !small,
    }"
    class="equipment-card d-flex align-center flex-row justify-space-between"
    :style="{
      gap: small ? '0.75rem' : '1rem',
      width: fullWidth ? '100%' : 'auto',
    }"
  >
    <div
      class="d-flex align-center"
      :style="{
        gap: small ? '.75rem' : '1rem',
      }"
    >
      <v-tooltip right z-index="99999" nudge-right="-4px">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="d-flex align-center">
            <div
              @click.stop
              class="equipment-img"
              :class="small ? 'equipment-img-small' : 'equipment-img-box'"
            >
              <div
                style="height: 100%; width: 100%"
                v-viewer
                v-if="equipment.imageUrl != null && equipment.imageUrl != ''"
              >
                <img
                  :key="equipment.id + '_img'"
                  :src="equipment.imageUrl"
                  height="100%"
                  width="100%"
                />
              </div>
              <i
                v-else-if="equipment.category != null"
                :class="'fad ' + equipment.category.icon"
              ></i>
              <i v-else :class="'fad fa-plug'"></i>
            </div>
          </div>
        </template>
        <span>
          <div v-viewer v-if="(equipment.imageUrl != null) & (equipment.imageUrl != '')">
            <v-img
              class="img"
              :src="equipment.imageUrl"
              width="250px"
              height="250px"
              contain
            ></v-img>
          </div>
          <i
            v-else-if="equipment.category != null"
            :class="'fad ' + equipment.category.icon"
            style="font-size: 32px; padding: 2rem"
          ></i>
          <i v-else :class="'fad fa-plug'" style="font-size: 32px; padding: 2rem"></i>
        </span>
      </v-tooltip>
      <div class="d-flex flex-column" style="gap: 0.25rem">
        <div class="d-flex align-center fs-12px">
          <i class="fad fa-tag px-1"></i>
          <b class="mono-font">{{ equipment.tag }}</b>
          <i class="fad fa-barcode pr-1 pl-4"></i>
          <b class="mono-font">{{ equipment.model }}</b>
        </div>
        <div class="d-flex flex-row align-center">
          <div
            v-if="showRackUnits"
            class="d-flex flex-row align-center equipment-rack-units pl-1 pr-3"
          >
            <i class="fad fa-layer-group fs-12px" style="line-height: 0"></i>
            <!-- <i class="fad fa-r" style="font-size: 9px; margin-right: 1.5px"></i>
            <i class="fad fa-u" style="font-size: 9px;"></i> -->
            <h5 class="fs-12px ma-0 ml-1">
              {{ equipment.rackUnits }}
            </h5>
            <span class="fs-10px" style="margin-left: 2px">RUs</span>
          </div>
          <i class="fad fa-industry-windows pl-1 pr-1 fs-12px" style="line-height: 0"></i>
          <div class="company-logo-mini mr-1" v-if="!small">
            <div
              v-if="equipment.manufacture != null"
              class="d-flex align-center"
              style="gap: 0.5rem"
            >
              <v-tooltip right z-index="99999" nudge-right="-4px">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <div class="company-logo-mini">
                      <div
                        style="height: 100%; width: 100%"
                        v-viewer
                        @click.stop
                        v-if="
                          equipment.manufacture.logoUrl != null &&
                          equipment.manufacture.logoUrl != ''
                        "
                      >
                        <img
                          :key="equipment.manufacture.id + '_img'"
                          :src="equipment.manufacture.logoUrl"
                          height="100%"
                          width="100%"
                          style="object-fit: contain"
                        />
                      </div>
                      <div style="height: 100%; width: 100%" v-viewer @click.stop v-else>
                        <img
                          :key="equipment.manufacture.id + '_img'"
                          src="/img/DNA_Symbol.png"
                          height="100%"
                          width="100%"
                          style="object-fit: contain"
                        />
                      </div>
                    </div>
                  </div>
                </template>
                <span @click.stop>
                  <v-img
                    v-if="
                      equipment.manufacture.logoUrl != null && equipment.manufacture.logoUrl != ''
                    "
                    :src="equipment.manufacture.logoUrl"
                    height="250px"
                    width="250px"
                    contain
                  ></v-img>
                  <v-img
                    v-else
                    src="/img/DNA_Symbol.png"
                    height="250px"
                    width="250px"
                    contain
                  ></v-img>
                </span>
              </v-tooltip>
            </div>
          </div>
          <div
            class="d-flex"
            style="gap: 0.25rem"
            :class="small ? 'flex-row align-center' : 'flex-column'"
          >
            <h5 v-if="equipment.manufacture != null" class="fs-12px ma-0">
              {{ equipment.manufacture.name }}
            </h5>
            <span
              v-if="
                small &&
                equipment.productFamily != null &&
                equipment.productFamily.name != null &&
                equipment.manufacture != null &&
                equipment.manufacture.name != null
              "
              class="opacity-54 fs-12px"
              >></span
            >
            <div
              v-if="
                equipment.productFamily != null &&
                equipment.productFamily.name != null &&
                equipment.manufacture != null &&
                equipment.manufacture.name != null
              "
              class="opacity-87 fs-12px"
            >
              {{ equipment.productFamily.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-flex flex-column"
      :style="{
        gap: small ? '0.15rem' : '0.25rem',
        lineHeight: small ? '0' : '1',
      }"
      v-if="showInstallerAndProvider"
    >
      <span class="d-flex align-center">
        <span class="opacity-87 fs-12px" :class="small ? ' mr-1' : ' mr-2'">
          <i class="fad fa-cart-shopping text-center" style="width: 16px"></i>
        </span>
        <equipment-provider
          :small="small"
          v-if="equipment.installer"
          :provider-name="equipment.provider.name"
        ></equipment-provider>
      </span>
      <span class="d-flex align-center">
        <span class="opacity-87 fs-12px" :class="small ? ' mr-1' : ' mr-2'">
          <i class="fad fa-wrench-simple text-center" style="width: 16px"></i>
        </span>
        <equipment-installer
          :small="small"
          v-if="equipment.installer"
          :installer-name="equipment.installer.name"
        ></equipment-installer>
      </span>
    </div>
  </div>
</template>

<script>
import EquipmentInstaller from "../components/EquipmentInstaller.vue";
import EquipmentProvider from "../components/EquipmentProvider.vue";
export default {
  name: "equipment-card",
  props: {
    equipment: null,
    noBorder: {
      type: Boolean,
      default: false,
    },
    showRackUnits: {
      type: Boolean,
      default: false,
    },
    showInstallerAndProvider: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  components: { EquipmentInstaller, EquipmentProvider },
};
</script>
<style lang="scss">
.equipment-rack-units {
  color: #009688;
}
.teal .equipment-rack-units {
  color: #fff;
}
</style>
