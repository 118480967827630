import perms from "../../plugins/permissions";
export default [
  {
    path: "/layout-templates",
    name: "layout-templates",
    meta: {
      layout: "dashboard",
      title: "Layout Templates",
      permissions: [perms.LayoutTemplates.View],
    },
    component: () => import("./views/LayoutTemplates.vue"),
  },
  {
    path: "/layout-templates/:id",
    name: "layout-template",
    meta: { layout: "dashboard", permissions: [perms.LayoutTemplates.View] },
    component: () => import("./views/LayoutTemplates.vue"),
  },
  {
    path: "/layout-views",
    name: "layout-views",
    meta: { layout: "dashboard", title: "Layout Views", permissions: [perms.LayoutViews.View] },
    component: () => import("./views/LayoutViews.vue"),
  },
  {
    path: "/layout-views/:id",
    name: "single-layout-view",
    meta: { layout: "dashboard", permissions: [perms.LayoutViews.View] },
    component: () => import("./views/LayoutViews.vue"),
  },
  {
    path: "/naming-templates",
    name: "naming-templates",
    meta: {
      layout: "dashboard",
      title: "Naming Templates",
      permissions: [perms.NamingTemplates.View],
    },
    component: () => import("./views/NamingTemplates.vue"),
  },
  {
    path: "/naming-templates/:id",
    name: "naming-template",
    meta: { layout: "dashboard", permissions: [perms.NamingTemplates.View] },
    component: () => import("./views/NamingTemplates.vue"),
  },
];
