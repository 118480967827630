<template>
  <div
    class="quick-tools-sidebar elevation-4 white"
    @mouseup="($event) => $event.stopPropagation()"
    @mouseenter="open"
    @mouseleave="close"
  >
    <h4
      class="px-2 py-2 white--text grey darken-4 d-flex justify-space-between align-center"
      style="100%;"
    >
      <span>
        <i class="fad fa-wrench-simple"></i>
        <v-scroll-x-transition mode="out-in">
          <span v-if="!mini" class="ml-3" key="quick-tools-btn-text">Quick Tools</span>
        </v-scroll-x-transition>
      </span>
      <v-scroll-x-transition mode="out-in">
        <kbd
          class="light ml-3 fs-12px"
          v-if="!$vuetify.breakpoint.mobile && !mini"
          key="quick-tools-btn-keyboard"
          >CTRL<span class="opacity-54">+</span>Q</kbd
        >
      </v-scroll-x-transition>
      <v-btn dark @click="close" icon>
        <i class="far fa-times"></i>
      </v-btn>
    </h4>
    <!-- <v-divider class="mb-1"></v-divider> -->
    <v-btn
      v-for="tool in tools"
      :key="tool.key"
      height="40px"
      min-height="40px"
      width="220px"
      min-width="220px"
      tile
      class="tool-btn justify-start"
      style="padding-left: 0.5rem !important; padding-right: 0.5rem !important"
      elevation="0"
      :color="tool.isBtnFocused ? 'indigo lighten-4 btn-in-focus indigo--text' : 'transparent'"
      @click="openTool(tool)"
      @focus="($event) => (tool.isBtnFocused = true)"
      @blur="($event) => (tool.isBtnFocused = false)"
      @mouseenter="toolIsHovered(tool)"
      :ref="tool.btnRef"
    >
      <i
        class="fs-16px text-center"
        style="width: 24px"
        :class="[tool.icon, tool.isBtnFocused ? 'fas' : 'fad']"
      ></i>
      <span class="font-weight-bold fs-12px ml-2">{{ tool.title }}</span>
      <span class="enter-icon">
        <i class="fad fa-arrow-right"></i>
      </span>
    </v-btn>
    <!-- <div class="quick-tools-sidebar-handler"></div> -->
    <div class="quick-tools-sidebar-icon grey darken-4 elevation-4">
      <i class="fad fa-wrench-simple"></i>
      <span>Quick Tools</span>
    </div>
    <btu-calculator ref="BtuCalculator"></btu-calculator>
    <power-calculator ref="PowerCalculator"></power-calculator>
    <project-cost-estimator ref="ProjectCostEstimator"></project-cost-estimator>
    <screen-size-calculator ref="ScreenSizeCalculator"></screen-size-calculator>
  </div>
</template>

<script>
import BtuCalculator from "./BtuCalculator.vue";
import PowerCalculator from "./PowerCalculator.vue";
import ProjectCostEstimator from "./ProjectCostEstimator.vue";
import ScreenSizeCalculator from "./ScreenSizeCalculator.vue";

export default {
  components: { BtuCalculator, ProjectCostEstimator, PowerCalculator, ScreenSizeCalculator },
  name: "quick-tools",
  data() {
    return {
      showTooltips: true,
      fab: false,
      ctrlKeyActive: false,
      tools: [
        {
          value: "BTU",
          title: "BTU Calculator",
          icon: "fa-temperature-low",
          key: "BtuCalculator",
          componentRef: "BtuCalculator",
          isBtnFocused: false,
          btnRef: "toolBtnBtuCalculator",
        },
        {
          value: "POWER",
          title: "Power Calculator",
          icon: "fa-bolt",
          key: "PowerCalculator",
          componentRef: "PowerCalculator",
          isBtnFocused: false,
          btnRef: "toolBtnPowerCalculator",
        },
        {
          value: "PCE",
          title: "Project Cost Estimator",
          icon: "fa-circle-dollar",
          key: "ProjectCostEstimator",
          componentRef: "ProjectCostEstimator",
          isBtnFocused: false,
          btnRef: "toolBtnProjectCostEstimator",
        },
        {
          value: "SSC",
          title: "Screen Size Calculator",
          icon: "fa-display",
          key: "ScreenSizeCalculator",
          componentRef: "ScreenSizeCalculator",
          isBtnFocused: false,
          btnRef: "toolBtnScreenSizeCalculator",
        },
      ],
    };
  },
  props: {
    mini: {
      type: Boolean,
      default: null,
    },
  },
  mounted() {
    document.addEventListener("mouseup", (event) => {
      this.close();
    });
    document.addEventListener("keyup", (event) => {
      this.ctrlKeyActive = event.ctrlKey;
    });
    document.addEventListener("keydown", (event) => {
      this.ctrlKeyActive = event.ctrlKey;
      if (event.code == "KeyQ" && event.ctrlKey) {
        event.preventDefault();
        this.open();
      } else if (event.code == "Escape") {
        this.close();
      }
    });
    var parent = document.querySelector(".quick-tools-sidebar");
    parent.addEventListener("focusout", (event) => {
      if (!this.fab) return;
      event.stopPropagation();

      if (parent.contains(event.relatedTarget)) {
        // if focus moved to another parent descend
        return;
      }

      // parent.focus(); // otherwise focus on parent or change to another dom
      if (this.$refs[this.tools[0].btnRef].length > 0) {
        this.$refs[this.tools[0].btnRef][0].$el.focus();
      }
    });
  },
  computed: {},
  methods: {
    toolIsHovered(tool) {
      this.$refs[tool.btnRef][0].$el.focus();
    },
    openTool(tool) {
      this.$refs[tool.componentRef].open();
      this.fab = false;
    },
    open() {
      this.tools.forEach((tool) => {
        this.$refs[tool.componentRef].close();
      });
      this.fab = true;
    },
    close() {
      this.fab = false;
    },
  },
  watch: {
    fab: {
      handler() {
        if (this.fab) {
          // document.getElementsByClassName("app-main-container")[0].classList.add("has-overlay");
          document.getElementsByClassName("quick-tools-sidebar")[0].classList.add("on-hover");
          setTimeout(() => {
            if (this.$refs[this.tools[0].btnRef].length > 0) {
              this.$refs[this.tools[0].btnRef][0].$el.focus();
            }
          }, 50);
        } else {
          // document.getElementsByClassName("app-main-container")[0].classList.remove("has-overlay");
          document.getElementsByClassName("quick-tools-sidebar")[0].classList.remove("on-hover");
        }
      },
    },
  },
};
</script>

<style lang="scss">
.quick-tools-sidebar {
  position: fixed;
  width: 220px;
  height: 100vh;
  top: 0;
  left: -220px;
  z-index: 100000;
  transition: all ease-in-out 0.3s;

  .quick-tools-sidebar-handler {
    position: absolute;
    width: 12px;
    height: 100vh;
    top: 0;
    right: -12px;
    background: linear-gradient(to right, rgba($shades-black, 0.2) 0%, transparent 100%);
    transition: all ease-in-out 0.3s;
  }

  .quick-tools-sidebar-icon {
    position: absolute;
    width: 20px;
    padding: 0.75rem 0 0.75rem 3px;
    bottom: 64px;
    right: -20px;
    color: #fff;
    border-radius: 0rem 0.5rem 0.5rem 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 0.35rem;
    font-size: 12px;
    transition: all ease-in-out 0.3s;
    display: flex;
    flex-direction: column-reverse;
    gap: 0.75rem;
    align-items: center;
    justify-content: center;

    span {
      writing-mode: vertical-lr;
      text-transform: uppercase;
      letter-spacing: 1px;
      transform: rotate(180deg);
    }
  }

  &.on-hover {
    left: 0;

    .quick-tools-sidebar-handler {
      opacity: 0;
    }

    .quick-tools-sidebar-icon {
      position: absolute;
      right: 0;
      opacity: 0;
    }
  }

  .tool-btn {
    .enter-icon {
      position: absolute;
      top: 0px;
      right: 1.5rem;
      opacity: 0;
      transition: all ease-in-out 0.3s;
    }

    &.btn-in-focus {
      &::before {
        display: none !important;
      }
    }

    &.btn-in-focus,
    &:hover {
      .enter-icon {
        opacity: 1;
        right: 0.25rem;
      }
    }
  }
}
</style>
