<template>
  <div class="d-flex ml-1 flex-column" v-if="parsedValue != null">
    <span class="d-flex">
      <v-chip
        v-if="parsedValue.Availability == PowerSpecAvailability.Required.value"
        small
        outlined
        color="orange"
        text-color="orange"
        style="height: 22px"
      >
        <i class="fas fa-circle-check mr-2 secondary--text"></i>
        <span class="font-weight-bold text--orange"> Required </span>
      </v-chip>
      <v-chip v-else small outlined color="orange" text-color="orange" style="height: 22px">
        <i class="fas fa-circle-half-stroke mr-2 secondary--text"></i>
        <span class="font-weight-bold text--orange"> Optional </span>
      </v-chip>
    </span>
    <div v-if="parsedValue.PowerSupplyIncluded">
      <h4 class="font-weight-bold fs-12px mt-1">
        <i class="fas fa-circle-check mr-2 secondary--text"></i>Power Supply Included
      </h4>
      <h4 class="font-weight-bold fs-12px mt-1"><i class="fad fa-caret-right mr-1"></i>Options</h4>
      <ol>
        <li v-for="(option, index) in parsedValue.PowerSupplyPowerOptions" :key="index">
          <div class="d-flex flex-row flex-wrap" style="gap: 0.25rem 0.5rem">
            <span><b>Voltage:</b> {{ option.Voltage }}</span>
            <span><b>Current:</b> {{ option.Current }}</span>
            <span><b>Watt:</b> {{ option.Watt }}</span>
            <span><b>Heatload:</b> {{ option.Heatload }}</span>
            <span><b>Hvac Tonnage:</b> {{ option.HvacTonnage }}</span>
            <span><b>HeatLoad AutoCalculate:</b> {{ option.HeatLoadAutoCalculate }}</span>
          </div>
          <v-divider
            v-if="index != parsedValue.PowerSupplyPowerOptions.length - 1"
            class="my-1"
          ></v-divider>
        </li>
      </ol>
    </div>
    <div v-else>
      <h4 class="font-weight-bold fs-12px mt-1">
        <i class="fas fa-circle-xmark mr-2 secondary--text"></i>Power Supply Not Included
      </h4>
      <h4 class="font-weight-bold fs-12px mt-1"><i class="fad fa-caret-right mr-1"></i>Option</h4>
      <div class="d-flex flex-row flex-wrap ml-3" style="gap: 0.25rem 0.5rem">
        <span><b>Voltage:</b> {{ parsedValue.DCPowerRequirement.Voltage }}</span>
        <span><b>Current:</b> {{ parsedValue.DCPowerRequirement.Current }}</span>
        <span><b>Watt:</b> {{ parsedValue.DCPowerRequirement.Watt }}</span>
        <span><b>Heatload:</b> {{ parsedValue.DCPowerRequirement.Heatload }}</span>
        <span><b>Hvac Tonnage:</b> {{ parsedValue.DCPowerRequirement.HvacTonnage }}</span>
        <span
          ><b>HeatLoad AutoCalculate:</b>
          {{ parsedValue.DCPowerRequirement.HeatLoadAutoCalculate }}</span
        >
      </div>
    </div>
  </div>
  <div v-else>
    <i class="fad fa-empty-set fs-16px ml-2" style="opacity: 0.24"></i>
  </div>
</template>

<script>
import Enums from "../../../../plugins/enums";
export default {
  components: {},
  name: "dc-power-spec-logged-prop",
  inheritAttrs: false,
  data() {
    return {
      PowerSpecAvailability: Enums.PowerSpecAvailability,
    };
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    parsedValue() {
      var val = this.value == "" ? null : this.value;
      return JSON.parse(val) || null;
    },
  },
  methods: {},
};
</script>

<style lang="scss"></style>
