import perms from "../../plugins/permissions";
export default [
  {
    path: "/inventory",
    name: "inventory",
    meta: {
      layout: "dashboard",
      title: "Inventory List",
      permissions: [perms.Equipments.BulkUpdate],
    },
    component: () => import("./views/InventoryListView.vue"),
  },
];
