<template>
  <v-dialog
    v-model="active"
    :persistent="isImageViewerActive"
    width="700"
    retain-focus
    content-class="quick-tools-dialog"
  >
    <v-card rounded="lg">
      <v-card-title
        class="font-weight-medium white--text grey darken-4"
        style="font-size: 16px; padding: 12px 16px 8px 20px; padding: 8px 8px 6px 18px"
      >
        <span class="d-flex align-center">
          <i class="fad fa-display mr-3 fs-20px"></i>Screen Size Calculator
        </span>
        <v-spacer></v-spacer>
        <v-btn dark @click="close" icon>
          <i class="far fa-times"></i>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="secondary--text">
        <v-row class="my-0 mt-4">
          <v-col cols="12" md="12">
            <h3 class="indigo--text">1. Choose Aspect Ratio</h3>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field-alt
              suffix="Pixel"
              :rules="[allRules.number]"
              label="Width"
              ref="widthPixel"
              placeholder="Aspect Ratio Width"
              v-model.number="selected.widthPixel"
              @input="widthPixelChanged"
              hide-details
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field-alt
              suffix="Pixel"
              :rules="[allRules.number]"
              label="Height"
              ref="heightPixel"
              placeholder="Aspect Ratio Height"
              v-model.number="selected.heightPixel"
              @input="heightPixelChanged"
              hide-details
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field-alt
              label="Ratio"
              v-model="aspectRatio"
              hide-details
              readonly
            ></v-text-field-alt>
          </v-col>
          <v-col cols="12" md="3" class="pt-10 pl-0">
            <v-menu dense offset-y bottom min-width="250px">
              <template v-slot:activator="{ attrs, on }">
                <v-btn color="secondary" v-bind="attrs" v-on="on" small>
                  <i class="fad fa-database mr-2"></i>Predefined Ratios
                  <i class="fas fa-caret-down ml-4"></i>
                </v-btn>
              </template>
              <v-list class="more-options-menu">
                <template v-for="(ratio, i) in predefinedAspectRatios">
                  <v-subheader style="height: 32px" :key="i" v-if="ratio.type == 'header'">{{
                    ratio.label
                  }}</v-subheader>
                  <v-list-item @click="onPredefinedRatioSelected(ratio)" :key="i" v-else>
                    <v-list-item-content>
                      <v-list-item-title
                        class="secondary--text font-weight-medium d-flex align-center justify-space-between"
                      >
                        <span>
                          {{ ratio.label }}
                          <v-chip x-small class="py-0 ml-2">
                            {{ calcAspectRatio(ratio.width, ratio.height).message }}
                          </v-chip>
                        </span>
                        <span
                          class="d-flex align-center ml-2 fs-10px opacity-72"
                          style="gap: 0.25rem"
                        >
                          {{ ratio.width }}<i class="fas fa-times fs-8px" style="line-height: 0"></i
                          >{{ ratio.height }}
                        </span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </v-col>
          <v-col cols="12" md="12" class="mt-3">
            <h3 class="indigo--text">2. Calculate</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="auto" class="pt-8 pr-0" style="width: 84px">
            <h4 class="opacity-64"><i class="fas fa-caret-right mr-2"></i>In CM</h4>
          </v-col>
          <v-col class="pt-0">
            <v-text-field-alt
              suffix="cm"
              :rules="[allRules.number]"
              label="Width"
              ref="widthCM"
              placeholder="Width"
              v-model.number="selected.widthCM"
              @input="calcWidthCM"
              hide-details
            >
            </v-text-field-alt>
          </v-col>
          <v-col class="pt-0">
            <v-text-field-alt
              suffix="cm"
              :rules="[allRules.number]"
              label="Height"
              ref="heightCM"
              placeholder="Height"
              v-model.number="selected.heightCM"
              @input="calcHeightCM"
              hide-details
            >
            </v-text-field-alt>
          </v-col>
          <v-col class="pt-0">
            <v-text-field-alt
              suffix="cm"
              :rules="[allRules.number]"
              label="Diagonal"
              ref="diagonalCM"
              placeholder="Diagonal"
              v-model.number="selected.diagonalCM"
              @input="calcDiagonalCM"
              hide-details
            >
            </v-text-field-alt>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col cols="12" md="auto" class="pt-8 pr-0" style="width: 84px">
            <h4 class="opacity-64"><i class="fas fa-caret-right mr-2"></i>In Inches</h4>
          </v-col>
          <v-col class="pt-0">
            <v-text-field-alt
              suffix="inch"
              :rules="[allRules.number]"
              label="Width"
              ref="widthInch"
              placeholder="Width"
              v-model.number="selected.widthInch"
              @input="calcWidthInch"
              hide-details
            >
            </v-text-field-alt>
          </v-col>
          <v-col class="pt-0">
            <v-text-field-alt
              suffix="inch"
              :rules="[allRules.number]"
              label="Height"
              ref="heightInch"
              placeholder="Height"
              v-model.number="selected.heightInch"
              @input="calcHeightInch"
              hide-details
            >
            </v-text-field-alt>
          </v-col>
          <v-col class="pt-0">
            <v-text-field-alt
              suffix="inch"
              :rules="[allRules.number]"
              label="Diagonal"
              ref="diagonalInch"
              placeholder="Diagonal"
              v-model.number="selected.diagonalInch"
              @input="calcDiagonalInch"
              hide-details
            >
            </v-text-field-alt>
          </v-col>
        </v-row>
        <v-row class="my-0">
          <v-col cols="12" md="12" class="mt-5 mb-3">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" md="12" class="py-0">
            <h4>Reference Figure:</h4>
            <v-card
              hover
              class="mt-1"
              @click.stop
              style="width: 228px; height: 120px; cursor: pointer; overflow: hidden"
              v-viewer.static="options"
              @show="isImageViewerActive = true"
              @hidden="isImageViewerActive = false"
            >
              <img
                :key="commonStandardsImages.full"
                :src="commonStandardsImages.thumb"
                :data-source="commonStandardsImages.full"
                alt="Most Common Display Resolutions and Standards"
                height="100%"
                width="100%"
                class="pa-2"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      active: false,
      options: {
        toolbar: true,
        url: "data-source",
      },
      isImageViewerActive: false,
      commonStandardsImages: {
        thumb:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/Vector_Video_Standards8.svg/320px-Vector_Video_Standards8.svg.png",
        full: "https://upload.wikimedia.org/wikipedia/commons/0/0c/Vector_Video_Standards8.svg",
      },
      lastUnit: null,
      lastKey: null,
      selected: {
        //pixel
        widthPixel: 1920,
        heightPixel: 1080,
        //cm
        widthCM: 0,
        heightCM: 0,
        diagonalCM: 0,
        //inch
        widthInch: 0,
        heightInch: 0,
        diagonalInch: 0,
      },
      predefinedAspectRatios: [
        {
          type: "header",
          label: "Common",
        },
        {
          label: "4K Ultra HD",
          width: 3840,
          height: 2160,
        },
        {
          label: "Full HD",
          width: 1920,
          height: 1080,
        },
        {
          label: "HD-Ready",
          width: 1280,
          height: 720,
        },
        {
          label: "WUXGA",
          width: 1920,
          height: 1200,
        },
        {
          label: "WQXGA",
          width: 2560,
          height: 1600,
        },
        {
          type: "header",
          label: "Ultra-wide",
        },
        {
          label: "WFHD",
          width: 2560,
          height: 1080,
        },
        {
          label: "WQHD",
          width: 3440,
          height: 1440,
        },
        {
          label: "UW4K",
          width: 4320,
          height: 1800,
        },
        {
          label: "UW8K",
          width: 8640,
          height: 3600,
        },
        {
          type: "header",
          label: "Others",
        },
        {
          label: "FWXGA",
          width: 1366,
          height: 768,
        },
        {
          label: "NTSC",
          width: 720,
          height: 540,
        },
        {
          label: "PAL",
          width: 720,
          height: 576,
        },
      ],
    };
  },
  props: {},
  mounted() {},
  computed: {
    aspectRatio() {
      var ar = this.calcAspectRatio(this.selected.widthPixel, this.selected.heightPixel);
      if (ar.success) {
        return ar.message;
      } else return null;
    },
  },
  methods: {
    //cm
    calcWidthCM() {
      this.lastUnit = "cm";
      this.lastKey = "w";
      this.selected.widthInch = this.cmToInch(this.selected.widthCM).toFixed(1);
      this.calculateByWidth();
    },
    calcHeightCM() {
      this.lastUnit = "cm";
      this.lastKey = "h";
      this.selected.heightInch = this.cmToInch(this.selected.heightCM).toFixed(1);
      this.calculateByHeight();
    },
    calcDiagonalCM() {
      this.lastUnit = "cm";
      this.lastKey = "d";
      this.selected.diagonalInch = this.cmToInch(this.selected.diagonalCM).toFixed(1);
      this.calculateByDiagonal();
    },
    //inches
    calcWidthInch() {
      this.lastUnit = "inch";
      this.lastKey = "w";
      this.selected.widthCM = this.inchToCm(this.selected.widthInch).toFixed(1);
      this.calculateByWidth();
    },
    calcHeightInch() {
      this.lastUnit = "inch";
      this.lastKey = "h";
      this.selected.heightCM = this.inchToCm(this.selected.heightInch).toFixed(1);
      this.calculateByHeight();
    },
    calcDiagonalInch() {
      this.lastUnit = "inch";
      this.lastKey = "d";
      this.selected.diagonalCM = this.inchToCm(this.selected.diagonalInch).toFixed(1);
      this.calculateByDiagonal();
    },
    //convert
    cmToInch(value) {
      return value / 2.54;
    },
    inchToCm(value) {
      return value * 2.54;
    },
    //calc
    calculateByWidth() {
      var aspectRatioDiagonal = this.calculateTheDiagonal(
        this.selected.widthPixel,
        this.selected.heightPixel
      );
      //CM
      var widthCM = this.selected.widthCM;
      if (widthCM > 0) {
        var factor = widthCM / this.selected.widthPixel;
        var diagonal = aspectRatioDiagonal * factor;
        var height = this.selected.heightPixel * factor;

        this.selected.diagonalCM = diagonal.toFixed(1);
        this.selected.heightCM = height.toFixed(1);
      }
      //Inch
      var widthInch = this.selected.widthInch;
      if (widthInch > 0) {
        var factor = widthInch / this.selected.widthPixel;
        var diagonal = aspectRatioDiagonal * factor;
        var height = this.selected.heightPixel * factor;

        this.selected.diagonalInch = diagonal.toFixed(1);
        this.selected.heightInch = height.toFixed(1);
      }
    },
    calculateByHeight() {
      var aspectRatioDiagonal = this.calculateTheDiagonal(
        this.selected.widthPixel,
        this.selected.heightPixel
      );
      //CM
      var heightCM = this.selected.heightCM;
      if (heightCM > 0) {
        var factor = heightCM / this.selected.heightPixel;
        var diagonal = aspectRatioDiagonal * factor;
        var width = this.selected.widthPixel * factor;

        this.selected.diagonalCM = diagonal.toFixed(1);
        this.selected.widthCM = width.toFixed(1);
      }
      //Inch
      var heightInch = this.selected.heightInch;
      if (heightInch > 0) {
        var factor = heightInch / this.selected.heightPixel;
        var diagonal = aspectRatioDiagonal * factor;
        var width = this.selected.widthPixel * factor;

        this.selected.diagonalInch = diagonal.toFixed(1);
        this.selected.widthInch = width.toFixed(1);
      }
    },
    calculateByDiagonal() {
      var aspectRatioDiagonal = this.calculateTheDiagonal(
        this.selected.widthPixel,
        this.selected.heightPixel
      );

      //CM
      var diagonalCM = this.selected.diagonalCM;
      if (diagonalCM > 0) {
        var factor = diagonalCM / aspectRatioDiagonal;
        var width = this.selected.widthPixel * factor;
        var height = this.selected.heightPixel * factor;

        this.selected.widthCM = width.toFixed(1);
        this.selected.heightCM = height.toFixed(1);
      }
      //Inch
      var diagonalInch = this.selected.diagonalInch;
      if (diagonalInch > 0) {
        var factor = diagonalInch / aspectRatioDiagonal;
        var width = this.selected.widthPixel * factor;
        var height = this.selected.heightPixel * factor;

        this.selected.widthInch = width.toFixed(1);
        this.selected.heightInch = height.toFixed(1);
      }
    },
    calculateTheDiagonal(width, height) {
      return Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2));
    },
    //////////////////////////
    onPredefinedRatioSelected(ratio) {
      this.selected.widthPixel = ratio.width;
      this.selected.heightPixel = ratio.height;
      this.recallLastUsedKeyAndUnit();
    },
    calcAspectRatio(width, height) {
      if (!this.isNum(width) || !this.isNum(height)) {
        return {
          success: false,
          message: "Invalid width or height",
        };
      }

      var w = parseInt(width.toString());
      var h = parseInt(height.toString());
      var dividend,
        divisor,
        remainder,
        aspectRatio,
        mode = "portrait";

      if (h == 0 && w == 0) {
        return {
          success: false,
          message: "Invalid width or height",
        };
      }
      if (h == 0 && w != 0) {
        return {
          success: false,
          message: "Invalid height",
        };
      }
      if (h != 0 && w == 0) {
        return {
          success: false,
          message: "Invalid width",
        };
      }

      if (h == w) {
        aspectRatio = "1 : 1";
      } else {
        if (h > w) {
          dividend = h;
          divisor = w;
          mode = "portrait";
        }

        if (w > h) {
          dividend = w;
          divisor = h;
          mode = "landscape";
        }

        var gcd = -1;
        while (gcd == -1) {
          remainder = dividend % divisor;
          if (remainder == 0) {
            gcd = divisor;
          } else {
            dividend = divisor;
            divisor = remainder;
          }
        }

        var hr = w / gcd;
        var vr = h / gcd;

        // handle special cases
        if (hr === 8 && vr === 5) {
          hr = 16;
          vr = 10;
        } else if (hr === 5 && vr === 8) {
          hr = 10;
          vr = 16;
        }

        if ((hr === 683 && vr === 384) || (hr === 384 && vr === 683)) aspectRatio = "≈ 16 : 9";
        else aspectRatio = hr + " : " + vr;
      }

      return {
        success: true,
        message: aspectRatio,
        mode: mode,
      };
    },
    open() {
      this.active = true;
      setTimeout(() => {
        this.$refs.widthPixel.select();
      }, 300);
    },
    close() {
      this.active = false;
    },
    isNum(value) {
      return /^[0-9]+.[0-9]+|[0-9]+$/.test(value);
    },
    widthPixelChanged() {
      this.recallLastUsedKeyAndUnit();
    },
    heightPixelChanged() {
      this.recallLastUsedKeyAndUnit();
    },
    recallLastUsedKeyAndUnit() {
      if (!this.isNum(this.selected.widthPixel) || !this.isNum(this.selected.heightPixel)) return;

      if (this.lastKey == "w") this.calculateByWidth();
      else if (this.lastKey == "h") this.calculateByHeight();
      else if (this.lastKey == "d") this.calculateByDiagonal();
    },
  },
  watch: {},
};
</script>

<style lang="scss"></style>
