<template>
  <div class="spotlight-bar" :class="isFocused ? 'spotlight-on-focus' : ''">
    <v-btn :text="!mini" :icon="mini" class="spotlight-btn" @click="open">
      <i class="far fa-search"></i>
      <v-scroll-x-transition mode="out-in">
        <span v-if="!mini" class="ml-3" key="spotlight-btn-text">Search</span>
      </v-scroll-x-transition>
      <v-scroll-x-transition mode="out-in">
        <kbd
          class="light ml-3 fs-12px"
          v-if="!$vuetify.breakpoint.mobile && !mini"
          key="spotlight-btn-keyboard"
          >CTRL<span class="opacity-54">+</span>F</kbd
        >
      </v-scroll-x-transition>
    </v-btn>
    <v-dialog
      v-model="isOpen"
      width="575"
      retain-focus
      scrollable
      origin="top"
      content-class="spotlight-dialog"
    >
      <v-card>
        <div class="spotlight-header">
          <v-text-field-alt
            placeholder="Search Concordia"
            v-model="options.term"
            id="spotlightSearch"
            ref="spotlightSearch"
            hide-details
            width="100%"
            dense
            @focus="isFocused = true"
            @blur="isFocused = false"
            :transparent="!isFocused"
            :loading="isLoading"
            autocomplete="off"
          >
            <template v-slot:prepend-inner>
              <i class="far fa-search mr-2" :class="isFocused ? 'icon-focus' : ''"></i>
            </template>
          </v-text-field-alt>
          <v-divider v-if="isResultYield && !isResultYieldEmpty"></v-divider>
        </div>

        <div class="spotlight-result" v-if="isResultYield && isResultYieldEmpty">
          <div class="py-7 text-center">
            <!-- <i class="fad fa-empty-set" style="font-size: 32px"></i> -->
            <span class="fa-stack fa-2x">
              <i class="fad fa-rocket-launch fa-swap-opacity- fa-stack-1x"></i>
              <i class="fas fa-ban fa-stack-2x" style="opacity: 0.54"></i>
            </span>
            <p class="mb-0 mt-2">
              No results for <b>"{{ options.term }}"</b>
            </p>
          </div>
        </div>
        <div class="spotlight-result" v-else-if="isResultYield && !isResultYieldEmpty">
          <v-list class="pt-0" v-if="result.projects != null && result.projects.total > 0">
            <v-subheader>
              Projects <v-spacer></v-spacer>
              <v-btn
                v-if="result.projects.total > result.projects.items.length"
                color="info"
                text
                small
                @click="openProjectsSearch(options.term)"
              >
                <v-icon small class="mr-2">{{
                  ctrlKeyActive ? "mdi-arrow-left-bottom" : "mdi-open-in-new"
                }}</v-icon>
                Show more ({{ result.projects.total }} results)
              </v-btn></v-subheader
            >
            <v-list-item-group color="info" v-model="active.projects">
              <v-list-item
                dense
                v-for="(item, i) in result.projects.items"
                :key="i"
                @click="openProject(item.id)"
              >
                <v-list-item-icon>
                  <v-icon small>fal fa-folder</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <span
                    class="text-caption mb-0"
                    style="line-height: 1rem !important; font-size: 10.5px; font-weight: 400"
                  >
                    {{ item.client.name }}
                  </span>
                  <span style="font-size: 12.25px; font-weight: 600">{{ item.name }}</span>
                </v-list-item-content>
                <v-list-item-icon class="enter-icon">
                  <v-icon small>{{
                    ctrlKeyActive ? "mdi-arrow-left-bottom" : "mdi-open-in-new"
                  }}</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-list class="pt-0" v-if="result.leads != null && result.leads.total > 0">
            <v-subheader>
              Leads <v-spacer></v-spacer>
              <v-btn
                v-if="result.leads.total > result.leads.items.length"
                color="info"
                text
                small
                @click="openLeadsSearch(options.term)"
              >
                <v-icon small class="mr-2">{{
                  ctrlKeyActive ? "mdi-arrow-left-bottom" : "mdi-open-in-new"
                }}</v-icon>
                Show more ({{ result.leads.total }} results)
              </v-btn></v-subheader
            >
            <v-list-item-group color="info" v-model="active.leads">
              <v-list-item
                dense
                v-for="(item, i) in result.leads.items"
                :key="i"
                @click="openLead(item.id)"
              >
                <v-list-item-icon>
                  <v-icon small>fal fa-folder</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <span
                    class="text-caption mb-0"
                    style="line-height: 1rem !important; font-size: 10.5px; font-weight: 400"
                  >
                    {{ item.client.name }}
                  </span>
                  <span style="font-size: 12.25px; font-weight: 600">{{ item.name }}</span>
                </v-list-item-content>
                <v-list-item-icon class="enter-icon">
                  <v-icon small>{{
                    ctrlKeyActive ? "mdi-arrow-left-bottom" : "mdi-open-in-new"
                  }}</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-list class="pt-0" v-if="result.spaces != null && result.spaces.total > 0">
            <v-divider></v-divider>
            <v-subheader> Spaces <v-spacer></v-spacer> </v-subheader>
            <v-list-item-group color="info" v-model="active.spaces">
              <v-list-item
                dense
                v-for="(item, i) in result.spaces.items"
                :key="i"
                @click="openSpace(item.projectId, item.id)"
              >
                <v-list-item-icon>
                  <v-icon small>fal fa-draw-square</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <span
                    class="text-caption mb-0 d-flex align-center"
                    style="line-height: 1rem !important; font-size: 10.5px; font-weight: 400"
                  >
                    <v-icon x-small class="mr-1">fal fa-folder</v-icon>
                    {{ item.project != null ? item.project.name : "N/A" }}
                  </span>
                  <span style="font-size: 12.25px; font-weight: 600">
                    {{ item.name }}
                    <span class="ml-2">
                      <v-icon x-small class="mr-1">fal fa-plug</v-icon>
                      <span style="font-weight: 500">
                        {{ item.equipmentCount }}
                      </span>
                    </span>
                  </span>
                </v-list-item-content>
                <v-list-item-icon class="enter-icon">
                  <v-icon small>{{
                    ctrlKeyActive ? "mdi-arrow-left-bottom" : "mdi-open-in-new"
                  }}</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-list
            class="pt-0"
            v-if="result.spaceTemplates != null && result.spaceTemplates.total > 0"
          >
            <v-divider></v-divider>
            <v-subheader>
              Space Templates <v-spacer></v-spacer>
              <v-btn
                v-if="result.spaceTemplates.total > result.spaceTemplates.items.length"
                color="info"
                text
                small
                @click="openSpaceTemplatesSearch(options.term)"
              >
                <v-icon small class="mr-2">{{
                  ctrlKeyActive ? "mdi-arrow-left-bottom" : "mdi-open-in-new"
                }}</v-icon>
                Show more ({{ result.spaceTemplates.total }} results)
              </v-btn>
            </v-subheader>
            <v-list-item-group color="info" v-model="active.spaceTemplates">
              <v-list-item
                dense
                v-for="(item, i) in result.spaceTemplates.items"
                :key="i"
                @click="openSpaceTemplate(item.id)"
              >
                <v-list-item-icon>
                  <v-icon small>fal fa-code</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <span style="font-size: 12.25px; font-weight: 600">
                    {{ item.name }}
                    <span class="ml-2">
                      <v-icon x-small class="mr-1">fal fa-plug</v-icon>
                      <span style="font-weight: 500">
                        {{ item.equipmentCount }}
                      </span>
                    </span>
                    <span class="ml-2">
                      <v-icon x-small class="mr-1">fal fa-link</v-icon>
                      <span style="font-weight: 500">
                        {{ item.countTemplateUsed }}
                      </span>
                    </span>
                  </span>
                </v-list-item-content>
                <v-list-item-icon class="enter-icon">
                  <v-icon small>{{
                    ctrlKeyActive ? "mdi-arrow-left-bottom" : "mdi-open-in-new"
                  }}</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-list class="pt-0" v-if="result.equipments != null && result.equipments.total > 0">
            <v-divider></v-divider>
            <v-subheader>
              Equipment <v-spacer></v-spacer>
              <v-btn
                v-if="result.equipments.total > result.equipments.items.length"
                color="info"
                text
                small
                @click="openEquipmentsSearch(options.term)"
              >
                <v-icon small class="mr-2">{{
                  ctrlKeyActive ? "mdi-arrow-left-bottom" : "mdi-open-in-new"
                }}</v-icon>
                Show more ({{ result.equipments.total }} results)
              </v-btn>
            </v-subheader>
            <v-list-item-group color="info" v-model="active.equipment">
              <v-list-item
                dense
                v-for="(item, i) in result.equipments.items"
                :key="i"
                @click="openEquipment(item.id)"
              >
                <v-list-item-icon>
                  <v-icon small v-if="item.category != null">fal {{ item.category.icon }}</v-icon>
                  <v-icon small v-else>fal fa-plug</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <span
                    class="text-caption mb-0"
                    style="line-height: 1rem !important; font-size: 10.5px; font-weight: 400"
                  >
                    <span v-if="item.manufacture">{{ item.manufacture.name }}</span>
                    <span v-if="item.productFamily">- {{ item.productFamily.name }}</span>
                  </span>
                  <span>
                    <span style="font-size: 12.25px; font-weight: 600">{{ item.tag }}</span>
                    <span class="ml-1" style="font-size: 11px; font-weight: 400"
                      >⦁ {{ item.model }}</span
                    >
                  </span>
                </v-list-item-content>
                <v-list-item-icon class="enter-icon">
                  <v-icon small>{{
                    ctrlKeyActive ? "mdi-arrow-left-bottom" : "mdi-open-in-new"
                  }}</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
        <div class="spotlight-footer">
          <v-divider></v-divider>
          <v-card-actions>
            <span v-if="hasResults">
              <span v-if="$vuetify.breakpoint.mobile">
                <kbd class="mr-1">Tap</kbd> open in new tab
              </span>
              <span v-if="!$vuetify.breakpoint.mobile"> <kbd class="mr-1">TAB</kbd> navigate </span>
              <span class="ml-3" v-if="!$vuetify.breakpoint.mobile">
                <kbd class="mr-1">ENTER</kbd> open in new tab
              </span>
              <span class="ml-3" v-if="!$vuetify.breakpoint.mobile">
                <kbd class="mr-1">CTRL<span class="opacity-54">+</span>ENTER</kbd> open in same tab
              </span>
              <span class="ml-3" v-if="!$vuetify.breakpoint.mobile">
                <kbd class="mr-1">CTRL<span class="opacity-54">+</span>F</kbd> search
              </span>
              <span class="ml-3" v-if="!$vuetify.breakpoint.mobile">
                <kbd class="mr-1">ESC</kbd> close
              </span>
            </span>
            <span v-else>
              <span> <kbd class="mr-1">TYPE</kbd> search </span>
              <span class="ml-3"> <kbd class="mr-1">ESC</kbd> close </span>
            </span>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import spotlightService from "../services/spotlight-service.js";
export default {
  name: "spotlight",
  data() {
    return {
      spotlightTimerId: null,
      options: { term: "" },
      result: null,
      active: {
        projects: null,
        spaces: null,
        spaceTemplates: null,
        equipments: null,
        leads: null
      },
      items: [],
      isOpen: false,
      isFocused: false,
      isLoading: false,
      isResultYield: false,
      ctrlKeyActive: false,
    };
  },
  props: {
    mini: {
      type: Boolean,
      default: null,
    },
  },
  mounted() {
    document.addEventListener("keyup", (event) => {
      this.ctrlKeyActive = event.ctrlKey;
    });
    document.addEventListener("keydown", (event) => {
      this.ctrlKeyActive = event.ctrlKey;
      if (event.code == "KeyF" && event.ctrlKey) {
        event.preventDefault();
        this.open();
        // var _focused = document.activeElement;
        // if (_focused) {
        //   var _inputting =
        //     _focused.tagName.toLowerCase() === "textarea" ||
        //     _focused.tagName.toLowerCase() === "input";
        //   if (_inputting) {
        //     // event.preventDefault();
        //     _focused.blur();
        //   }
        // }
      } else if (event.code == "Escape") {
        this.close();
      }
    });
  },
  computed: {
    hasResults() {
      if (
        this.result != null &&
        (this.result.projects.total > 0 ||
          this.result.spaces.total > 0 ||
          this.result.spaceTemplates.total > 0 ||
          this.result.equipments.total > 0 || 
          this.result.leads.total > 0)
      ) {
        return true;
      }
      return false;
    },
    isResultYieldEmpty() {
      if (
        this.result != null &&
        this.result.projects.total == 0 &&
        this.result.spaces.total == 0 &&
        this.result.spaceTemplates.total == 0 &&
        this.result.equipments.total == 0 &&
        this.result.leads.total == 0
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    open() {
      if (this.isOpen) {
        setTimeout(() => {
          if (this.$refs.spotlightSearch) this.$refs.spotlightSearch.select();
        }, 150);
        return;
      }
      this.isOpen = true;
    },
    close() {
      if (!this.isOpen) return;
      this.isOpen = false;
    },
    getTermVal(url) {
      const params = new Proxy(new URLSearchParams(url), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      return params.term;
    },
    getData() {
      if (this.trim(this.options.term) == "") return;
      this.isLoading = true;
      this.isResultYield = false;
      spotlightService
        .query(this.$clean(this.options))
        .then((resp) => {
          var term = this.getTermVal(resp.config.url.replace("spotlight?", ""));
          // this.$log("spotlightService", term, resp);
          if (this.options.term != term) return;
          this.result = resp.data;
          this.isLoading = false;
          this.isResultYield = true;
        })
        .catch((err) => {
          this.isLoading = false;
          this.isResultYield = false;
          this.$handleError(err);
        });
    },
    getDataDebounced() {
      if (this.spotlightTimerId == null) {
        this.spotlightTimerId = -1;
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.spotlightTimerId);

      // delay new call 400ms
      this.spotlightTimerId = setTimeout(() => {
        this.getData();
      }, 400);
    },
    openLink(path) {
      if (this.ctrlKeyActive) {
        this.close();
        setTimeout(() => {
          if (this.$route.path !== path) this.$router.push(path);
        });
      } else {
        window.open(path, "_blank");
      }
      setTimeout(() => {
        this.active.projects = null;
        this.active.spaces = null;
        this.active.spaceTemplates = null;
        this.active.equipments = null;
      }, 50);
    },
    openProjectsSearch(term) {
      const path = `/active-projects?pq=${term}`;
      this.openLink(path);
    },
    openProject(id) {
      const path = `/projects/${id}`;
      this.openLink(path);
    },
    openLeadsSearch(term) {
      const path = `/leads?q=${term}`;
      this.openLink(path);
    },
    openLead(id) {
      const path = `/leads/${id}`;
      this.openLink(path);
    },
    openSpace(projectId, id) {
      const path = `/projects/${projectId}/spaces/${id}`;
      this.openLink(path);
    },
    openSpaceTemplatesSearch(term) {
      const path = `/space-templates?q=${term}`;
      this.openLink(path);
    },
    openSpaceTemplate(id) {
      const path = `/space-templates/${id}`;
      this.openLink(path);
    },
    openEquipmentsSearch(term) {
      const path = `/equipment?q=${term}`;
      this.openLink(path);
    },
    openEquipment(id) {
      const path = `/equipment/${id}`;
      this.openLink(path);
    },
  },
  watch: {
    options: {
      handler() {
        if (this.trim(this.options.term) == "") {
          this.result = null;
          this.isLoading = false;
          this.isResultYield = false;
        } else this.getDataDebounced();
      },
      deep: true,
    },
    isOpen: {
      handler() {
        if (this.isOpen === true) {
          setTimeout(() => {
            if (this.$refs.spotlightSearch) this.$refs.spotlightSearch.select();
          }, 150);
        } else {
          this.options.term = "";
          this.result = null;
          this.isLoading = false;
          this.isResultYield = false;
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
kbd.light {
  background-color: #eee !important;
  border-radius: 3px;
  border: 1px solid #b4b4b4 !important;
  box-shadow: 0 1px 1px rgb(0 0 0 / 20%), 0 2px 0 0 rgb(255 255 255 / 70%) inset;
  color: #333 !important;
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 4px;
  white-space: nowrap;
}

@-moz-document url-prefix() {
  kbd.light {
    padding: 2px 4px 3px 4px;
  }
}

.spotlight-dialog {
  align-self: flex-start;
  border-radius: 0.65rem !important;

  .v-card {
    border-radius: 0.65rem !important;
  }

  .v-text-field-alt .v-input .v-input__control > .v-input__slot {
    border-radius: 0;
  }

  .spotlight-header {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 10;
    // box-shadow: 0 3px 6px rgba($shades-black, 0.02), 0 3px 6px rgba($shades-black, 0.08);

    .v-input__prepend-inner {
      i {
        color: rgba($shades-black, 0.54);

        &.icon-focus {
          color: $shades-black;
        }
      }
    }
  }

  .spotlight-footer {
    position: sticky;
    bottom: 0;
    background: #fff;
    z-index: 10;
    // box-shadow: 0 -3px 6px rgba($shades-black, 0.02), 0 -3px 6px rgba($shades-black, 0.08);
  }

  .v-card__actions {
    font-size: 11px;

    kbd {
      font-size: 9px;
    }
  }
  .theme--light.v-subheader {
    height: auto;
    font-weight: 500;
    height: 28px;
  }
  .spotlight-result {
    overflow-y: auto;

    .v-list-item {
      .enter-icon {
        opacity: 0;
        transition: all 0.15s ease-out;
      }
      &:focus,
      &:hover {
        .enter-icon {
          opacity: 1;
        }
      }
    }
    .v-list-item__icon {
      margin-right: 0.5rem !important;
    }
  }
}

.spotlight-bar {
  .spotlight-btn {
    gap: 0.75rem;
    cursor: pointer;

    .v-btn__content > i {
      font-size: 12px !important;
    }

    .v-btn__content > span {
      font-size: 12px !important;
      font-weight: 600;
    }

    kbd {
      min-width: 24px;
    }
  }
}
</style>
