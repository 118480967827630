import perms from "../../../plugins/permissions";
export default [
  {
    path: "/manufacturers",
    name: "manufacturers",
    // TODO: update this line when permissions updated
    meta: { layout: "dashboard", title: "Manufacturers", permissions: [perms.Clients.View] },
    component: () => import("./views/Manufacturers.vue"),
  },
  {
    path: "/manufacturers/:id",
    name: "single-manufacturer",
    meta: { layout: "dashboard" },
    component: () => import("./views/Manufacturers.vue"),
  },
  {
    path: "/manufacturers/:id/product-families",
    name: "manufacturer-product-families",
    meta: { layout: "dashboard" },
    component: () => import("./views/Manufacturers.vue"),
  },
];
