<template>
  <div v-if="stage != null && stageObj != null">
    <v-chip
      class="project-stage px-2"
      :small="!small"
      :x-small="small"
      :color="stageObj.color"
      :text-color="stageObj.textColor"
    >
      <i class="fas mr-2" :class="stageObj.icon" style="line-height: 0"></i>
      <span class="font-weight-medium" style="line-height: 0">{{
        stageObj.textSingle.toUpperCase()
      }}</span>
    </v-chip>
  </div>
</template>

<script>
import Enums from "../../../plugins/enums";
export default {
  name: "project-stage",
  data() {
    return {
      stageObj: null,
    };
  },
  props: {
    stage: {
      type: Number,
      default: null,
    },
    small: {
      type: Boolean,
      default: null,
    },
  },
  mounted() {
    this.updateStageObj();
  },
  computed: {
    // allProjectStage() {
    //   return (
    //     Object.entries(Enums.PROJECT_STAGE).flatMap((EnumItem) => {
    //       if (EnumItem[1].SUB_STAGE != null) {
    //         let subs =
    //           Object.entries(EnumItem[1].SUB_STAGE).flatMap((subItem) => {
    //             return [subItem[1]];
    //           }) || [];
    //         return [EnumItem[1], ...subs];
    //       }
    //       return EnumItem[1];
    //     }) || []
    //   );
    // },
  },
  methods: {
    updateStageObj() {
      if (this.stage != null) {
        this.stageObj = this.getEnumMember(Enums.PROJECT_STAGE, this.stage);
      }
    },
  },
  watch: {
    stage: {
      handler() {
        this.updateStageObj();
      },
    },
  },
};
</script>

<style lang="scss"></style>
