import Api from "../../../Shared/services/api";
import ApiUploader from "../../../Shared/services/apiUploader";
import { buildParams } from "../../../../plugins/helpers";

const baseUrl = "Manufactures";
export default {
  get() {
    return Api().get(baseUrl);
  },
  typeHead(search, all) {
    const qParams = new URLSearchParams({ search, all });
    return Api().get(`${baseUrl}/typeHead?` + qParams.toString());
  },
  getAll() {
    return Api().get(`${baseUrl}/All`);
  },
  query(options) {
    // const qParams = new URLSearchParams(options);
    const qParams = buildParams(options);
    return Api().get(baseUrl + "?" + qParams.toString());
  },
  queryLite(options) {
    // const qParams = new URLSearchParams(options);
    const qParams = buildParams(options);
    return Api().get(baseUrl + "/Lite?" + qParams.toString());
  },
  queryLiteWithFamilies(options) {
    // const qParams = new URLSearchParams(options);
    const qParams = buildParams(options);
    return Api().get(baseUrl + "/LiteWithFamilies?" + qParams.toString());
  },
  update(entity) {
    if (entity.id) return Api().put(baseUrl + `/${entity.id}`, entity);
    else return this.add(entity);
  },
  add(entity) {
    return Api().post(baseUrl, entity);
  },
  uploadImage(imageData, config) {
    return ApiUploader().post(`${baseUrl}/Image`, imageData, config);
  },
  delete(id) {
    return Api().delete(baseUrl + `/${id}`);
  },
  getById(id) {
    return Api().get(baseUrl + `/${id}`);
  },
  getHubspotDetails(companyId) {
    return Api().get(`${baseUrl}/HubspotCompaniesById` + `/${companyId}`);
  },
  getManufacturerStats(id) {
    return Api().get(baseUrl + `/${id}/Stats`);
  },
  getManufacturerStatsItems(id, options, type) {
    const qParams = new URLSearchParams(options);
    return Api().get(`${baseUrl}/${id}/Stats/${type}?${qParams.toString()}`);
  },
  getRecent(mode, count) {
    return Api().get(`${baseUrl}/Recent?count=${count}&mode=${mode}`);
  },
  getAccessList(manufactureId) {
    return Api().get(`${baseUrl}/${manufactureId}/AccessList`);
  },
  saveAccessList(manufactureId, data) {
    return Api().put(`${baseUrl}/${manufactureId}/AccessList`, data);
  },
};
