var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-combobox-wrap",class:{ 'details-hidden': _vm.hideDetails }},[_c('label',{attrs:{"for":"equipmentHashtags"}},[_vm._v("Hashtags ")]),_c('v-combobox',{staticClass:"mt-1",attrs:{"rules":_vm.required ? [_vm.allRules.required] : [],"value":_vm.value,"search-input":_vm.options.search,"dense":"","filled":"","no-filter":"","hide-details":_vm.hideDetails,"item-text":"name","item-value":"id","loading":_vm.isHashtagsLoading,"readonly":_vm.readonly,"clearable":"","id":"equipmentHashtags","persistent-placeholder":true,"placeholder":"Equipment Hashtags","append-icon":"fas fa-caret-down","deletable-chips":"","items":_vm.hashtags,"return-object":"","multiple":"","chips":"","solo":"","flat":"","hide-selected":""},on:{"input":_vm.emitHashtag,"update:searchInput":function($event){return _vm.$set(_vm.options, "search", $event)},"update:search-input":function($event){return _vm.$set(_vm.options, "search", $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"color":"secondary","input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{class:!_vm.readonly ? 'pr-2' : ''},[_vm._v(" "+_vm._s(item.name || item)+" ")]),(!_vm.readonly)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v(" $delete ")]):_vm._e()],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-space-between align-center",staticStyle:{"width":"100%"}},[_c('span',[_vm._v(_vm._s(item.name))]),_c('v-tooltip',{attrs:{"top":"","nudge-top":"-5"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('span',[_c('span',{staticClass:"mono-font"},[_vm._v(_vm._s(item.hashtagUsageCount))]),_c('i',{staticClass:"fad fa-stopwatch ml-2"})])])]}}],null,true)},[_c('span',[_vm._v("Usage Count")])])],1)]}},{key:"append-item",fn:function(){return [_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.loadMore),expression:"loadMore"}]})]},proxy:true},{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"d-inline-flex justify-end align-center elevation-1 white mr-6",staticStyle:{"position":"sticky","top":"0px","z-index":"10","padding":"0.5rem","margin-top":"-0.5rem","border-radius":"0 0 0.5rem 0"}},[_c('span',{staticStyle:{"font-weight":"600","font-size":"12px","margin-right":"0.5rem","margin-left":"0.5rem"}},[_vm._v(" Sort By ")]),_c('v-btn-toggle',{attrs:{"mandatory":"","color":"info","dense":""},model:{value:(_vm.isPopular),callback:function ($$v) {_vm.isPopular=$$v},expression:"isPopular"}},[_c('v-btn',{attrs:{"value":true,"small":""}},[_c('i',{staticClass:"fas fa-arrow-trend-up mr-2"}),_c('span',[_vm._v("Popular")])]),_c('v-btn',{attrs:{"value":false,"small":""}},[_c('i',{staticClass:"fas fa-clock mr-2"}),_c('span',[_vm._v("Recent")])])],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }