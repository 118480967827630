import Vue from "vue";
import Enums from "./enums";
import moment from "moment";
let stripHtmlRegex = /(<([^>]+)>)/gi;

function getEnumMember(enumClass, enumValue) {
  for (var val in enumClass) {
    if (enumClass[val].value == enumValue) return enumClass[val];
  }
  return {};
}

function fixedFloat(val) {
  return parseFloat(val.toFixed(2));
}

Vue.filter("ToDate", function (date) {
  return moment(date).format("YYYY-MM-DD");
});

Vue.filter("stripHtml", stripHtml);

Vue.filter("EnumMember", function (enumValue, enumClass) {
  return getEnumMember(enumClass, enumValue);
});

let EnumToListFn = function (EnumObj, nested = false) {
  if (nested)
    return Object.entries(EnumObj).map((EnumItem) => ({
      text: EnumItem[1].text,
      value: EnumItem[1].value,
    }));
  else
    return Object.entries(EnumObj).map((EnumItem) => ({ text: EnumItem[0], value: EnumItem[1] }));
};

export function stripHtml(html) {
  return html.replace(stripHtmlRegex, "");
}

Vue.filter("EnumToList", EnumToListFn);

Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});
Vue.filter("numberWithCommas", function (value) {
  if (!value) return "";
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
});

Vue.filter("fixedFloat", function (val) {
  return fixedFloat(val);
});
Vue.filter("fixedFloatFormat", function (val) {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
  }).format(fixedFloat(val));
});
Vue.filter("fixedFloatFormatNoDecimalPoints", function (val) {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
  }).format(fixedFloat(val));
});
Vue.filter("usdFormat", function (price) {
  if (price == null) return "N/A";
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  }).format(price);
});
Vue.filter("fileSize", function (bytes) {
  var si = false;
  var thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }
  var units = si
    ? ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"]
    : ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  var u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + " " + units[u];
});

Vue.filter("percentage", function (val) {
  if (!val) return "0%";
  return Math.round(val) + "%";
});

Vue.filter("digit", function (val, num = 2) {
  if (!val || !isFinite(val)) return 0;
  return val.toFixed(num);
});
