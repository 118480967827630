import perms from "../../plugins/permissions";
export default [
  {
    path: "/users",
    name: "users",
    meta: { layout: "dashboard", title: "Users", permissions: [perms.DNAUsers.View] },
    component: () => import("./views/Users.vue"),
  },
  {
    path: "/users/:id",
    name: "single-user",
    meta: { layout: "dashboard", title: "Users", permissions: [perms.DNAUsers.View] },
    component: () => import("./views/Users.vue"),
  },
  {
    path: "/audit-logs",
    name: "audits",
    meta: { layout: "dashboard", title: "Audits Logs", permissions: [perms.AuditLog.View] },
    component: () => import("./views/AuditLogs.vue"),
  },
  {
    path: "/app-settings",
    name: "app-settings",
    meta: { layout: "dashboard", title: "App Settings", permissions: [perms.AppSettings.View] },
    component: () => import("./views/AppSettings.vue"),
  },
];
