import perms from "../../plugins/permissions";
export default [
  {
    path: "/shift-requests",
    name: "shift-requests",
    meta: {
      layout: "dashboard",
      title: "My Shift Requests",
      permissions: [perms.ResourceShiftRequests.View],
    },
    component: () => import("./views/MyShiftRequests.vue"),
  },
  {
    path: "/shift-requests/:id",
    name: "single-shift-request",
    meta: {
      layout: "dashboard",
      title: "Shift Requests",
      permissions: [perms.ResourceShiftRequests.View],
    },
    component: () => import("./views/MyShiftRequests.vue"),
  },
  {
    path: "/manage-shift-requests",
    name: "manage-shift-requests",
    meta: {
      layout: "dashboard",
      title: "Manage Shift Requests",
      permissions: [perms.ResourceShiftRequests.Manage],
    },
    component: () => import("./views/ManageShiftRequests.vue"),
  },
  {
    path: "/manage-shift-requests/:id",
    name: "manage-single-shift-request",
    meta: {
      layout: "dashboard",
      title: "Manage Shift Requests",
      permissions: [perms.ResourceShiftRequests.Manage],
    },
    component: () => import("./views/ManageShiftRequests.vue"),
  },
];
