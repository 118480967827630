<template>
  <slideout
    dock="right"
    :size="mainPanelSize"
    :min-size="600"
    :allow-resize="true"
    :append-to="'#app'"
    class="slideout-dark-striped-header"
    :visible.sync="slideouts.update.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Your Personal Settings...
      </h3>
      <h3 v-else class="font-weight-bold pa-1" small>
        <i class="fad fa-user-cog mr-2"></i> Personal Settings
      </h3>
      <div>
        <panel-size-control v-model="slideouts.update.fullWidth" dark></panel-size-control>
        <v-btn @click="closeUpdateSlideout()" icon dark><i class="far fa-times"></i></v-btn>
      </div>
    </template>

    <v-scroll-y-transition mode="out-in">
      <v-container key="settings-loading" fluid class="pa-0 text-center" v-if="isFetching">
        <video
          width="320"
          muted
          loop
          autoplay
          style="padding: 0.5rem; opacity: 0.64; margin: 0 auto"
        >
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Fetching Your Personal Settings...</p>
      </v-container>
      <v-container
        key="settings-error"
        fluid
        v-else-if="loadingError"
        class="d-flex flex-column align-center justify-center py-12"
      >
        <img width="500" style="max-width: 100%" src="/img/art/undraw_moonlight_-5-ksn.svg" />
        <h1 class="mt-3" style="font-size: 2.75rem; color: #e45d51">
          Error Loading Your Personal Settings!
        </h1>
        <h3 class="font-weight-bold text--disabled" style="font-size: 1.5rem">
          Uh-Oh! Something went wrong!
        </h3>
      </v-container>
      <div
        v-else
        class="pa-3"
        style="height: 100%; display: flex; flex-direction: column"
        key="settings-loaded"
      >
        <v-container
          fluid
          class="pa-3 inset-shadow"
          style="
            height: 100%;
            display: flex;
            flex-direction: column;
            background: #eceff1;
            border-radius: 1rem;
          "
        >
          <v-form v-model="slideouts.update.valid" :disabled="isSaving">
            <v-container fluid class="pb-6 pa-0">
              <h4 class="settings-group-title">Projects & Spaces</h4>
              <!-- <v-divider class="my-2"></v-divider> -->
              <v-card rounded="lg" class="settings-card pa-2 px-4 mb-2" outlined>
                <v-row dense>
                  <v-col cols="12" md="auto" class="d-flex align-center justify-center pl-3 pr-5">
                    <i class="fad fa-shapes fs-28px"></i>
                  </v-col>
                  <v-col cols="12" md>
                    <v-switch
                      class="ma-0 pa-0 v-switch-swap"
                      color="orange darken-1"
                      hide-details
                      dense
                      inset
                      v-model="selected.autoCreateDefaultAreaGroupInSpace"
                      :disabled="!allowedToUpdate"
                    >
                      <template v-slot:label>
                        <div class="d-flex flex-column pr-5">
                          <label class="font-weight-bold fs-15px secondary--text ma-0">
                            <!-- When creating a new space, a default area group will be auto created! -->
                            Auto Create A Default Area Group!
                          </label>
                          <p
                            class="font-weight-medium text--secondary ma-0 fs-12px"
                            style="line-height: normal"
                          >
                            When creating a new Space, Concordia will auto create a default Area
                            Group!
                          </p>
                        </div>
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
              </v-card>
              <!-- <v-divider class="my-2"></v-divider> -->
              <v-card rounded="lg" class="settings-card pa-2 px-4" outlined>
                <v-row dense>
                  <v-col cols="12" md="auto" class="d-flex align-center justify-center pl-3 pr-5">
                    <i class="fad fa-square-list fs-28px"></i>
                  </v-col>
                  <v-col cols="12" md>
                    <v-switch
                      class="ma-0 pa-0 v-switch-swap"
                      color="orange darken-1"
                      hide-details
                      dense
                      inset
                      v-model="selected.autoCollapseNavigationDrawerOnProjectOpen"
                      :disabled="!allowedToUpdate"
                    >
                      <template v-slot:label>
                        <div class="d-flex flex-column pr-5">
                          <label class="font-weight-bold fs-15px secondary--text ma-0">
                            Auto Collapse Navigation Drawer On Project Open
                          </label>
                          <p
                            class="font-weight-medium text--secondary ma-0 fs-12px"
                            style="line-height: normal"
                          >
                            When opening a project, Concordia left navigation drawer will auto
                            collapse to make more horizontal space
                          </p>
                        </div>
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
              </v-card>
            </v-container>
            <v-container
              fluid
              class="pb-6 pa-0"
              v-if="$has(perms.PersonalSettings.ShowManagementSettings)"
            >
              <h4 class="settings-group-title">Users</h4>
              <!-- <v-divider class="my-2"></v-divider> v-switch-align-top -->
              <v-card rounded="lg" class="settings-card pa-2 px-4" outlined>
                <v-row dense>
                  <v-col cols="12" md="auto" class="d-flex align-center justify-center pl-3 pr-5">
                    <i class="fad fa-face-dotted fs-28px"></i>
                  </v-col>
                  <v-col cols="12" md>
                    <v-switch
                      class="ma-0 pa-0 v-switch-swap"
                      color="orange darken-1"
                      hide-details
                      dense
                      inset
                      v-model="selected.maskSuspendedUsers"
                      :disabled="!allowedToUpdate"
                    >
                      <template v-slot:label>
                        <div class="d-flex flex-column pr-5">
                          <label class="font-weight-bold fs-15px secondary--text ma-0">
                            <!-- When creating a new space, a default area group will be auto created! -->
                            Mask Suspended Users
                          </label>
                          <p
                            class="font-weight-medium text--secondary ma-0 fs-12px"
                            style="line-height: normal"
                          >
                            When turned off, suspended users will appear as normal users all over
                            Concordia, i.e. selectable & searchable on any users list and without
                            the <u class="grey--text text--darken-1 mr-1">Previous User</u>mask!
                          </p>
                        </div>
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
              </v-card>
            </v-container>
          </v-form>
        </v-container>
      </div>
    </v-scroll-y-transition>
    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!isFetching && allowedToUpdate"
          :disabled="isSaving || !slideouts.update.hasChanges"
          @click="discardChanges()"
          color="secondary"
        >
          <i class="fal fa-clock-rotate-left mr-2"></i> Discard
        </v-btn>

        <v-btn
          v-if="!isFetching && !allowedToUpdate"
          @click="closeUpdateSlideout()"
          color="secondary"
        >
          <i class="fal fa-xmark mr-2"></i> Close
        </v-btn>

        <div class="d-flex ml-2" v-if="!isFetching && allowedToUpdate">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="info"
                :disabled="!slideouts.update.valid || isSaving || !slideouts.update.hasChanges"
                @click="saveSettings(false)"
                :loading="isSaving"
              >
                <i class="mr-2 fas fa-save"></i> Save
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >Save<kbd class="light ml-1 fs-12px">CTRL<span class="opacity-54">+</span>S</kbd>
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="!isFetching && allowedToUpdate">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="cyan white--text"
                :disabled="
                  !slideouts.update.valid ||
                  slideouts.update.isLoading ||
                  !slideouts.update.hasChanges
                "
                @click="saveSettings(true)"
                :loading="slideouts.update.isLoading"
              >
                <i class="fas fa-save mr-2"></i>
                Save & Close
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >Save & Close Panel<kbd class="light ml-2 fs-12px"
                >CTRL<span class="opacity-54">+</span>SHIFT<span class="opacity-54">+</span>S</kbd
              >
            </span>
          </v-tooltip>
        </div>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import ApiService from "../services/personal-settings-service";
import perms from "../../../plugins/permissions";
import PanelSizeControl from "../../Shared/components/PanelSizeControl.vue";

export default {
  components: { PanelSizeControl },
  data() {
    return {
      perms,
      selected: { autoCreateDefaultAreaGroupInSpace: false, maskSuspendedUsers: false },
      selectedCemented: null,
      isFetching: false,
      isSaving: false,
      loadingError: false,
      slideouts: {
        update: {
          active: false,
          fullWidth: false,
          valid: true,
          isClosing: false,
          hasChanges: false,
        },
      },
    };
  },
  watch: {
    selected: {
      handler() {
        this.checkForChanges();
      },
      deep: true,
    },
  },
  mounted() {
    document.addEventListener("keydown", this.documentKeyListener);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.documentKeyListener);
  },
  methods: {
    documentKeyListener(event) {
      this.ctrlKeyActive = event.ctrlKey;
      this.shiftKeyActive = event.shiftKey;
      if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.slideouts.update.valid &&
        !this.isSaving &&
        this.allowedToUpdate &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.saveSettings(false);
      } else if (
        this.ctrlKeyActive &&
        this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.slideouts.update.valid &&
        !this.isSaving &&
        this.allowedToUpdate &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.saveSettings(true);
      }
    },
    closeUpdateSlideout() {
      this.slideouts.update.active = false;
    },
    onSlideoutClosing(e) {
      this.$log("onSlideoutClosing, active:", this.slideouts.update.active);
      // prevent close and wait
      e.wait = true;

      if (this.slideouts.update.hasChanges && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }

      //reset the changes
      this.selected = {};
      this.cementSettings();
      this.$releaseChanges();

      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    open() {
      this.fetchPersonalSettings();
      this.slideouts.update.active = true;
    },
    fetchPersonalSettings() {
      this.isFetching = true;
      this.loadingError = false;
      ApiService.get()
        .then((res) => {
          this.selected = res.data;
          this.selectedCemented = this.cloneDeep(this.selected);
          this.isFetching = false;
        })
        .catch((err) => {
          this.isFetching = false;
          this.loadingError = true;
          this.$handleError(err);
        });
    },
    checkForChanges() {
      this.slideouts.update.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
      if (this.slideouts.update.hasChanges) {
        this.$log(
          "%c=>> deepDiff",
          "color: red",
          this.deepDiff(this.selectedCemented, this.selected)
        );
      }
      if (this.slideouts.update.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
    saveSettings(closeAfter) {
      this.isSaving = true;
      ApiService.update(this.selected)
        .then((resp) => {
          this.$store.dispatch("setPersonalSettings", this.cloneDeep(resp.data));
          this.isSaving = false;
          this.selected = resp.data;
          this.cementSettings();
          this.checkForChanges();
          if (closeAfter) {
            this.closeUpdateSlideout();
          }
        })
        .catch((err) => {
          this.isSaving = false;
          this.$handleError(err);
        });
    },
    cementSettings() {
      this.selectedCemented = this.cloneDeep(this.selected);
    },
    discardChanges() {
      this.selected = this.cloneDeep(this.selectedCemented);
    },
  },
  computed: {
    mainPanelSize() {
      return this.slideouts.update.fullWidth ? "100%" : "600px";
    },
    allowedToUpdate() {
      return this.$has(perms.PersonalSettings.Update);
    },
  },
};
</script>

<style lang="scss">
.settings-group-title {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.75px;
  margin-bottom: 0.35rem;
  color: $shades-black;
}

.settings-card {
  border: 1.5px solid rgba($shades-black, 0.16) !important;
  transition: all 0.25s ease-out;

  &:hover {
    box-shadow: 1px 1px 6px 0px rgba($shades-black, 0.16);
    // box-shadow: 0px 3px 1px -2px rgba(42, 54, 59, 0.16), 0px 2px 2px 0px rgba(42, 54, 59, 0.1),
    //   0px 1px 5px 0px rgba(42, 54, 59, 0.08) !important;
  }
}
</style>
