<template>
  <div
    class="v-text-field-alt"
    :class="{
      'v-text-field-alt-transparent': transparent,
      'v-text-field-alt-short': short,
      'v-text-field-alt-pos': numberSignColor && $attrs.value > 0,
      'v-text-field-alt-neg': numberSignColor && $attrs.value < 0
    }"
  >
    <!-- <label
      v-if="label != null && label != '' && transparent != true"
      :for="this.id"
      v-html="label + requiredIcon"
      @mouseenter="showCopyBtn()"
      @mouseleave="hideCopyBtn()"
    ></label> -->

    <div
      style="display: flex; align-items: center; justify-content: space-between"
    >
      <label
        v-if="label != null && label != '' && transparent != true"
        :for="this.id"
        v-html="label + requiredIcon"
      ></label>
      <slot name="label-append"></slot>
    </div>
    <!-- <v-tooltip top v-if="label != null && label != '' && transparent != true">
      <template v-slot:activator="{ on, attrs }">
        <div class="d-inline-block" v-bind="attrs" v-on="on">
          <transition name="slideCopyBtn">
            <v-btn
              v-if="showCopy"
              @focus="showCopyBtn()"
              @mouseenter="showCopyBtn()"
              @mouseleave="hideCopyBtn()"
              icon
              x-small
              color="secondary"
              class="ml-1"
              @click="copyToClipboard"
            >
              <i class="fad fa-copy"></i>
            </v-btn>
          </transition>
        </div>
      </template>
      <span>Copy to Clipboard</span>
    </v-tooltip>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar> -->
    <v-text-field
      :id="this.id"
      ref="vTextField"
      v-bind="{ ...$attrs, ...commonAttrs }"
      v-on="$listeners"
      @keydown="onKeyDown"
      @keypress="onKeyPress($event, $attrs.number)"
    >
      <template
        v-for="(_, scopedSlotName) in $scopedSlots"
        #[scopedSlotName]="slotData"
      >
        <slot :name="scopedSlotName" v-bind="slotData" />
      </template>
      <template v-for="(_, slotName) in $slots" #[slotName]>
        <slot :name="slotName" />
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: "v-text-field-alt",
  inheritAttrs: false,
  data() {
    return {
      showCopy: false,
      showCopyTimer: null,
      snackbar: false,
      snackbarText: "Copied to Clipboard!",
      snackbarTimeout: 2000
    };
  },
  props: {
    label: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      default: ""
    },
    transparent: {
      type: Boolean,
      default: null
    },
    short: {
      type: Boolean,
      default: null
    },
    hideInput: {
      type: Boolean,
      default: null
    },
    numberSignColor: {
      type: Boolean,
      default: null
    }
  },
  methods: {
    onKeyDown(e) {
      if (e.code == "Escape") {
        this.$refs.vTextField.blur();
        setTimeout(() => {
          let parent = this.$refs.vTextField.$el.closest("div.vue-slideout");
          if (parent) parent.focus();
        });
      }
    },
    onKeyPress(evt, number) {
      if (number !== undefined) {
        let ASCIICode = evt.which ? evt.which : evt.keyCode;
        if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
          evt.preventDefault();
      }
    },
    hideCopyBtn() {
      clearTimeout(this.showCopyTimer);
      this.showCopyTimer = setTimeout(() => {
        this.showCopy = false;
      }, 250);
    },
    showCopyBtn() {
      clearTimeout(this.showCopyTimer);
      this.showCopy = true;
    },
    copyToClipboard() {
      let val = this.$refs.vTextField.value;
      let valTitleCase = "";
      if (val != null)
        valTitleCase = val.replace(/\w\S*/g, function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });

      navigator.clipboard.writeText(valTitleCase);
      this.snackbar = true;
    },
    focus() {
      this.$refs.vTextField.focus();
    },
    select() {
      this.focus();
      this.$refs.vTextField.$el.querySelector("input").select();
    }
  },
  computed: {
    hasError() {
      return this.$refs.vTextField.hasError;
    },
    hasRequiredRule() {
      if (this.$attrs == null || this.$attrs.rules == null) return false;
      return this.$attrs.rules.some(
        r => r.name == "required" || r.name == "requiredNumber"
      );
    },
    requiredIcon() {
      return this.hasRequiredRule
        ? " <i class='fas fa-star-of-life pink--text label-icon'></i>"
        : "";
    },
    commonAttrs() {
      return {
        label: "",
        solo: true,
        dense: true,
        flat: true,
        // filled: true,
        class: {
          "mt-1": this.$props.label,
          "d-none": this.hideInput
        }
      };
    }
  }
};
</script>

<style lang="scss">
.v-text-field.v-input {
  .v-label {
    font-size: 14px !important;
    max-width: 100% !important;
  }
}

.v-text-field-alt {
  label {
    font-weight: 600 !important;
    font-size: 15px;
    display: flex;
    align-items: center;
  }

  .v-input {
    input {
      font-size: 14px !important;
    }

    input:-webkit-autofill {
      box-shadow: 0 0 0 1000px #f2f4f3 inset !important;
    }

    .v-input__control > .v-input__slot {
      background-color: rgba($shades-black, 0.06) !important;

      .v-input__icon .v-icon {
        font-size: 16px;
      }
    }

    .v-input__control > .v-input__slot:hover {
      background-color: rgba($shades-black, 0.1) !important;

      input:-webkit-autofill {
        box-shadow: 0 0 0 1000px #e9ebeb inset !important;
      }
    }

    &.v-input--is-focused {
      .v-input__control > .v-input__slot {
        background-color: rgba($shades-black, 0.16) !important;

        input:-webkit-autofill {
          box-shadow: 0 0 0 1000px #dddfde inset !important;
        }
      }
    }

    &.error--text {
      .v-input__control > .v-input__slot {
        background-color: #fce4ec !important;
        .v-input__icon .v-icon {
          color: var(--v-accent-darken2) !important;
        }

        input:-webkit-autofill {
          box-shadow: 0 0 0 1000px #fce4ec inset !important;
        }
      }
      .v-input__control > .v-input__slot:hover {
        background-color: #f8bbd0 !important;

        input:-webkit-autofill {
          box-shadow: 0 0 0 1000px #fce4ec inset !important;
        }
      }
      &.v-input--is-focused {
        .v-input__control > .v-input__slot {
          background-color: #f8bbd0 !important;
          input:-webkit-autofill {
            box-shadow: 0 0 0 1000px #f8bbd0 inset !important;
          }
        }
      }
    }

    &.v-input--is-readonly {
      .v-input__control > .v-input__slot {
        background-color: transparent !important;
        border: 1px dashed rgba($shades-black, 0.24) !important;
      }

      // .v-input__append-inner {
      //   display: none !important;
      // }
    }
  }

  &.calendar-input {
    .v-input.v-input--is-readonly {
      .v-input__control > .v-input__slot {
        background-color: rgba($shades-black, 0.06) !important;
        border: none !important;
        border: 1px dashed rgba($shades-black, 0.24) !important;
      }

      // .v-input__append-inner {
      //   display: none !important;
      // }

      .v-input__control > .v-input__slot:hover {
        background-color: rgba($shades-black, 0.1) !important;

        input:-webkit-autofill {
          box-shadow: 0 0 0 1000px #e9ebeb inset !important;
        }
      }
    }

    .v-input.error--text {
      .v-input__control > .v-input__slot {
        background-color: #fce4ec !important;
        border: 1px dashed #f1a0bb !important;

        .v-input__icon .v-icon {
          color: var(--v-accent-darken2) !important;
        }

        input:-webkit-autofill {
          box-shadow: 0 0 0 1000px #fce4ec inset !important;
        }
      }
      .v-input__control > .v-input__slot:hover {
        background-color: #f8bbd0 !important;

        input:-webkit-autofill {
          box-shadow: 0 0 0 1000px #fce4ec inset !important;
        }
      }
      &.v-input--is-focused {
        .v-input__control > .v-input__slot {
          background-color: #f8bbd0 !important;
          input:-webkit-autofill {
            box-shadow: 0 0 0 1000px #f8bbd0 inset !important;
          }
        }
      }
    }
  }

  &.v-text-field-alt-transparent {
    .v-input {
      input {
        font-size: 14px !important;
      }

      input:-webkit-autofill {
        box-shadow: 0 0 0 1000px #fff inset !important;
      }

      .v-input__control > .v-input__slot {
        background-color: rgba($shades-black, 0) !important;

        .v-input__icon .v-icon {
          font-size: 16px;
        }
      }
    }
  }
  &.v-text-field-alt-short {
    .v-input {
      input {
        max-height: 28px;
      }
      .v-input__control {
        min-height: 28px;

        & > .v-input__slot {
          min-height: 28px;
          padding-left: 0.25rem;
          padding-right: 0.25rem;
        }
      }
    }
  }
  &.v-text-field-alt-pos {
    .v-input {
      input {
        color: $info-base !important;
      }
    }
    .v-text-field__prefix,
    .v-text-field__suffix {
      color: $info-base !important;
    }

    .v-input__control > .v-input__slot {
      border: 1px solid rgba($info-base, 0.87) !important;
    }
  }
  &.v-text-field-alt-neg {
    .v-input {
      input {
        color: $accent-base !important;
      }
    }
    .v-text-field__prefix,
    .v-text-field__suffix {
      color: $accent-base !important;
    }

    .v-input__control > .v-input__slot {
      border: 1px solid rgba($accent-base, 0.87) !important;
    }
  }
}
</style>
