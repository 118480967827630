import perms from "../../plugins/permissions";
export default [
  {
    path: "/space-templates",
    name: "space-templates",
    meta: {
      layout: "dashboard",
      title: "Space Templates",
      permissions: [perms.SpaceTemplates.View],
    },
    component: () => import("./views/SpaceTemplates.vue"),
  },
  {
    path: "/space-templates/:id",
    name: "space-template",
    meta: { layout: "dashboard", permissions: [perms.SpaceTemplates.View] },
    component: () => import("./views/SpaceTemplates.vue"),
  },
  {
    path: "/restore-space-templates",
    name: "restore-space-templates",
    meta: {
      layout: "dashboard",
      title: " Restore Space Templates",
      permissions: [perms.SpaceTemplates.Restore],
    },
    component: () => import("./views/SpaceTemplatesRestore.vue"),
  },
];
