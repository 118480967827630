import perms from "../../plugins/permissions";
export default [
  {
    path: "/dna-defaults",
    name: "dna-defaults",
    // TODO: update this line when permissions updated
    meta: {
      layout: "dashboard",
      title: "DNA Defaults",
      permissions: [perms.DNADefaults.View],
    },
    component: () => import("./views/DNADefaults.vue"),
  },
];
