<template>
  <div>
    <v-tooltip bottom nudge-bottom="-5" z-index="100">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-btn
            :dark="dark && !loading"
            :height="small ? 28 : 32"
            :color="dark ? 'secondary' : 'green'"
            :disabled="loading"
            x-small
            @click="$emit('refresh')"
          >
            <!-- :small="!small" :x-small="small" -->
            <i
              class="far fa-refresh"
              :class="loading ? 'fa-spin' : ''"
              :style="`font-size: ${small ? '14px' : '16px'}`"
            ></i>
          </v-btn>
        </div>
      </template>
      <span>Refresh</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
