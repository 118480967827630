<template>
  <div class="project-card" v-if="project" @click="cardClicked">
    <div class="folder-top">Client</div>
    <div class="folder-content">
      <div class="project-header">
        <div class="project-client">
          <v-tooltip bottom nudge-top="20">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-img
                  v-if="
                    project.client != null &&
                    project.client.logoUrl != null &&
                    project.client.logoUrl != ''
                  "
                  :src="project.client.logoUrl"
                  height="32"
                  max-width="64"
                  position="left center"
                  contain
                >
                </v-img>
                <i v-else class="fad fa-user-tie fa-swap-opacity fa-2x" style="opacity: 0.24"></i>
              </div>
            </template>
            <span>{{ project.client.name }}</span>
          </v-tooltip>
        </div>
        <div class="project-total">
          <v-tooltip left z-index="999" nudge-left="-4px">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                class="d-inline-block"
                style="font-size: 14px; font-weight: 600 !important"
              >
                {{ totalMSRPWithInstallCost | usdFormat }}
              </div>
            </template>
            <span>{{ project.name }}</span>
            <v-divider class="my-1 d-block" style="width: 100%" dark></v-divider>
            <span class="d-flex flex-column align-end" style="gap: 0.25rem">
              <span style="font-size: 12px; font-weight: 500 !important">
                <b
                  v-if="installationFactor"
                  class="blue--text mr-1"
                  style="font-size: 12px; font-weight: 700 !important"
                >
                  {{ installationFactor }}x
                </b>
                {{ installationCost | usdFormat }}
                <i class="fad fa-wrench ml-1"></i>
              </span>
              <span>
                {{ project.totalMsrpPrice | usdFormat }}
                <i class="fad fa-usd-square ml-1"></i>
              </span>
              <v-divider
                class="my-1 d-block"
                style="width: 100%; border-style: dashed !important"
                dark
              ></v-divider>
              <span style="font-size: 16px; font-weight: 600 !important">
                {{ totalMSRPWithInstallCost | usdFormat }}
              </span>
            </span>
          </v-tooltip>
        </div>
      </div>
      <label>Project</label>
      <h3 class="project-name">{{ project.name }}</h3>
      <dater class="project-date" :date="project.createDate" dateonly></dater>
      <div class="project-footer">
        <div class="project-stats">
          <space-count :count="project.spaceCount"></space-count>
          <equipment-count :count="project.equipmentCount"></equipment-count>
        </div>
        <div class="project-actions" v-if="false">
          <v-btn icon elevation="0" @click="goToProject(project.id)">
            <i class="fal fa-pen"></i>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "project-card",
  props: {
    project: {
      type: Object,
      default: null,
    },
  },
  computed: {
    installationFactor() {
      return this.project.installationFactor || 0.25;
    },
    installationCost() {
      return this.installationFactor * this.project.totalMsrpPrice;
    },
    totalMSRPWithInstallCost() {
      return this.project.totalMsrpPrice + this.installationCost;
    },
  },
  methods: {
    cardClicked() {
      this.$emit("click", this.project.id);
      this.goToProject(this.project.id);
    },
    goToProject(id) {
      this.$router.push({
        name: `view-project`,
        params: { id: id },
      });
    },
  },
};
</script>

<style lang="scss">
.project-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  filter: drop-shadow(0px 3px 1px rgba(42, 54, 59, 0.08))
    drop-shadow(0px 2px 2px rgba(42, 54, 59, 0.05)) drop-shadow(0px 1px 5px rgba(42, 54, 59, 0.04)) !important;

  .folder-top {
    position: relative;
    display: flex;
    height: 32px;
    width: 120px;
    border-radius: 1.5rem 0 0 0;
    background: #fff;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
    padding: 0 1rem;
    color: rgba($shades-black, 0.4);

    &:after {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      right: -32px;
      border-bottom: 32px solid #fff;
      border-right: 32px solid transparent;
    }
  }

  .folder-content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
    border-radius: 0 1.5rem 1.5rem 1.5rem;
    background: #fff;
    padding: 1rem;

    label {
      margin-top: 1rem;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 12px;
      color: rgba($shades-black, 0.4);
    }

    .project-name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .project-date {
      font-size: 12px;
      font-weight: 700;
      color: rgba($shades-black, 0.4);
    }

    .project-header {
      display: inline-flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .client-logo {
        width: 100px;
        height: auto;
      }

      .project-total {
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        padding: 0.15rem 0.5rem;
        border-radius: 0.75rem;
        font-weight: 600;
        font-size: 14px;
        color: rgba($shades-black, 0.72);
        border: 1.5px dashed rgba($shades-black, 0.24);
        background: rgba($shades-black, 0.04);

        i {
          line-height: 0;
        }
      }
    }

    .project-footer {
      display: inline-flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem;

      .project-stats {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
      }
    }
  }
}
</style>
