export default [
  {
    path: "/login",
    name: "login",
    meta: { title: "Login" },
    component: () => import("./views/Login.vue"),
  },
  {
    path: "/account/profile",
    name: "profile",
    meta: { title: "Profile" },
    component: () => import("./views/Profile.vue"),
  },
  {
    path: "/account/reset-password",
    name: "resetPassword",
    meta: { title: "Reset Password" },
    component: () => import("./views/ResetPassword.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: { title: "Logout" },
    component: () => import("./views/Logout.vue"),
  },
  {
    path: "/invitations/accept/:id",
    name: "invitation-accept",
    meta: { title: "Invitation Request" },
    component: () => import("./views/InvitationAccept.vue"),
  },
];
