<template>
  <vue-pdf-app
    class="coco-pdf-viewer"
    v-if="value != null && value != ''"
    :pdf="value"
    :config="config"
    theme="light"
    :pageNumber="1"
  ></vue-pdf-app>
  <div v-else class="error--text">Invalid File Url</div>
</template>

<script>
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css"; // import this to use default icons for buttons

export default {
  components: { VuePdfApp },
  name: "pdf-viewer",
  data() {
    return {
      url: null,
    };
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    allowDownload: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {},
  computed: {
    config() {
      return {
        sidebar: {
          viewThumbnail: true,
          viewOutline: true,
          viewAttachments: true,
        },
        secondaryToolbar: {
          secondaryPresentationMode: true,
          secondaryOpenFile: true,
          secondaryPrint: true,
          secondaryDownload: true,
          secondaryViewBookmark: true,
          firstPage: true,
          lastPage: true,
          pageRotateCw: true,
          pageRotateCcw: true,
          cursorSelectTool: true,
          cursorHandTool: true,
          scrollVertical: true,
          scrollHorizontal: true,
          scrollWrapped: true,
          spreadNone: true,
          spreadOdd: true,
          spreadEven: true,
          documentProperties: true,
        },
        toolbar: {
          toolbarViewerLeft: {
            findbar: true,
            previous: true,
            next: true,
            pageNumber: true,
          },
          toolbarViewerRight: {
            presentationMode: true,
            openFile: false,
            print: true,
            download: this.allowDownload,
            viewBookmark: false,
          },
          toolbarViewerMiddle: {
            zoomOut: true,
            zoomIn: true,
            scaleSelectContainer: true,
          },
        },
        errorWrapper: true,
      };
    },
  },
  methods: {},
  watch: {},
};
</script>

<style lang="scss">
.coco-pdf-viewer.pdf-app {
  font-size: 14px !important;
  font-weight: 500;
  font-family: $body-font-family;

  button,
  input,
  select {
    font-size: 14px !important;
    font-weight: 500;
    font-family: $body-font-family;
  }

  .toolbarLabel {
    font-size: 14px !important;
    font-weight: 500;
  }

  .pdfViewer {
    // margin-bottom: 20px !important;

    .page {
      margin: 10px auto 20px auto !important;
      border-radius: 0.25rem;
      // height: 100% !important;
      box-shadow: 0px 2px 4px -1px rgba(42, 54, 59, 0.16), 0px 4px 5px 0px rgba(42, 54, 59, 0.1),
        0px 1px 10px 0px rgba(42, 54, 59, 0.08) !important;
      padding: 0;
      border: none !important;

      .textLayer {
        width: 100% !important;
        height: 100% !important;
      }

      .canvasWrapper {
        border-radius: 0.5rem;
        width: 100% !important;
        height: 100% !important;
      }
    }
  }

  #thumbnailView {
    width: 100% !important;
  }

  #outlineView {
    width: 100% !important;
    text-align: left;

    .outlineItem {
      margin-left: 0.25rem !important;
    }
  }

  .thumbnail {
    .thumbnailSelectionRing {
      padding: 5px 5px 0px 5px !important;
      width: fit-content !important;
      height: fit-content !important;
      border-radius: 0.25rem;

      img {
        border-radius: 0.25rem;
      }
    }

    &.selected {
      .thumbnailSelectionRing {
        background-color: rgba($info-base, 0.32);
      }
    }
  }

  #sidebarContainer {
    top: 32px !important;
  }

  #toolbarSidebar {
    box-shadow: 1px 0px 4px rgba($shades-black, 0.25);
    border-bottom: thin solid var(--pdf-horizontal-toolbar-separator-color);

    .splitToolbarButton {
      margin-left: 0.25rem;

      .toolbarButton {
        margin: 0;
        border-radius: 0.25rem;
      }
    }
  }

  #sidebarContent {
    // top: 28px !important;
    box-shadow: 1px 1px 4px rgba($shades-black, 0.25);
  }

  #sidebarResizer {
    display: none !important;
  }

  #toolbarViewerLeft {
    margin-left: 0.25rem;
    display: flex;
    align-items: center;

    input {
      border-radius: 0.25rem;
      border: none !important;
    }
  }

  #toolbarViewerMiddle {
    // background-color: var(--pdf-app-background-color);
    height: 24px;
    margin-top: 4px;
    display: flex;
    align-items: center;
    border-radius: 0.25rem;
    // border: thin solid var(--pdf-split-toolbar-button-separator-color) !important;

    #scaleSelectContainer {
      margin: 0;
      height: 32px;

      select {
        background: transparent;
        height: 100%;
        padding-right: 0;
        border-radius: 0.25rem;
      }

      &::after {
        margin-top: 3px;
        margin-right: 6px;
        pointer-events: none;
      }
    }
  }

  #toolbarViewerRight {
    margin-right: 0.25rem;
  }

  &.light {
    --pdf-app-background-color: #eceff1;
    --pdf-sidebar-content-color: #f1f3f5;
    --pdf-toolbar-sidebar-color: #fff;
    --pdf-toolbar-color: #fff;
    --pdf-loading-bar-color: #3f4b5b;
    --pdf-loading-bar-secondary-color: #8dd0ff;
    --pdf-find-results-count-color: #3f4b5b;
    --pdf-find-results-count-font-color: hsla(0, 0%, 100%, 0.87);
    --pdf-find-message-font-color: hsla(0, 0%, 100%, 0.87);
    --pdf-not-found-color: brown;
    --pdf-split-toolbar-button-separator-color: rgba(42, 54, 59, 0.24);
    --pdf-toolbar-font-color: rgba(42, 54, 59, 0.87);
    --pdf-button-hover-font-color: #0d47a1;
    --pdf-button-toggled-color: rgba(42, 54, 59, 0.2);
    --pdf-horizontal-toolbar-separator-color: rgba(42, 54, 59, 0.24);
    --pdf-input-color: #eceff1;
    --pdf-input-font-color: rgba(42, 54, 59, 0.87);
    --pdf-find-input-placeholder-font-color: rgba(42, 54, 59, 0.24);
    --pdf-thumbnail-selection-ring-color: rgba(108, 117, 125, 0.7);
    --pdf-thumbnail-selection-ring-selected-color: #3f4b5b;
    --pdf-error-wrapper-color: #f55;
    --pdf-error-more-info-color: #d9d9d9;
    --pdf-error-more-info-font-color: #000;
    --pdf-overlay-container-color: rgba(108, 117, 125, 0.7);
    --pdf-overlay-container-dialog-color: #6c757d;
    --pdf-overlay-container-dialog-font-color: #d9d9d9;
    --pdf-overlay-container-dialog-separator-color: #000;
    --pdf-dialog-button-font-color: #d9d9d9;
    --pdf-dialog-button-color: #3f4b5b;
  }

  .vue-pdf-app-icon::before,
  .vue-pdf-app-icon::after {
    font-family: "Font Awesome 6 Pro";
    font-weight: 500;
  }

  .vue-pdf-app-icon.sidebar-toggle::before {
    content: "\f0c9";
  }

  .vue-pdf-app-icon.dropdown-toolbar-button::after {
    content: "\f107";
  }

  .vue-pdf-app-icon.secondary-toolbar-toggle::before {
    content: "\f142";
  }

  .vue-pdf-app-icon.find-previous::before {
    content: "\f060";
  }

  .vue-pdf-app-icon.find-next::before {
    content: "\f061";
  }

  .vue-pdf-app-icon.page-up::before {
    content: "\f062";
  }

  .vue-pdf-app-icon.page-down::before {
    content: "\f063";
  }

  .vue-pdf-app-icon.zoom-out::before {
    content: "\f056";
  }

  .vue-pdf-app-icon.zoom-in::before {
    content: "\f055";
  }

  .vue-pdf-app-icon.presentation-mode::before {
    content: "\f685";
  }

  .vue-pdf-app-icon.print-button::before {
    content: "\f02f";
  }

  .vue-pdf-app-icon.open-file::before {
    content: "\f07c";
  }

  .vue-pdf-app-icon.download-button::before {
    content: "\f354";
    content: "\e4e7";
  }

  .vue-pdf-app-icon.bookmark-button::before {
    content: "\f02e";
  }

  .vue-pdf-app-icon.view-thumbnail::before {
    content: "\e196";
  }

  .vue-pdf-app-icon.view-outline::before {
    content: "\f0ca";
  }

  .vue-pdf-app-icon.view-attachments::before {
    content: "\f0c6";
  }

  .vue-pdf-app-icon.view-find::before {
    content: "\f002";
  }

  .vue-pdf-app-icon.first-page::before {
    content: "\f048";
  }

  .vue-pdf-app-icon.last-page::before {
    content: "\f051";
  }

  .vue-pdf-app-icon.rotate-clockwise::before {
    content: "\f01e";
  }

  .vue-pdf-app-icon.rotate-counter-clockwise::before {
    content: "\f0e2";
  }

  .vue-pdf-app-icon.select-tool::before {
    content: "\f246";
  }

  .vue-pdf-app-icon.hand-tool::before {
    content: "\f25a";
    content: "\f256";
  }

  .vue-pdf-app-icon.scroll-vertical::before {
    content: "\f07d";
  }

  .vue-pdf-app-icon.scroll-horizontal::before {
    content: "\f07e";
  }

  .vue-pdf-app-icon.scroll-wrapped::before {
    content: "\e816";
    content: "\f047";
  }

  .vue-pdf-app-icon.spread-none::before {
    content: "\f15b";
  }

  .vue-pdf-app-icon.spread-odd::before {
    content: "\e256";
  }

  .vue-pdf-app-icon.spread-even::before {
    content: "\e257";
  }

  .vue-pdf-app-icon.document-properties::before {
    content: "\e49a";
  }

  html[dir="rtl"] {
    .vue-pdf-app-icon.find-previous::before {
      content: "\f061";
    }

    .vue-pdf-app-icon.find-next::before {
      content: "\f060";
    }

    .vue-pdf-app-icon.first-page::before {
      content: "\f051";
    }

    .vue-pdf-app-icon.last-page::before {
      content: "\f048";
    }
  }
}
</style>
