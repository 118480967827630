<template>
  <div class="breadcrumbs-alt">
    <router-link
      v-if="arrowItem != null"
      :to="arrowItem.to"
      class="breadcrumbs-alt-arrow"
    >
      <i class="far fa-long-arrow-left"></i>
    </router-link>
    <v-breadcrumbs
      :id="this.id"
      v-bind="{ ...$attrs, ...commonAttrs }"
      v-on="$listeners"
    >
      <template
        v-for="(_, scopedSlotName) in $scopedSlots"
        #[scopedSlotName]="slotData"
      >
        <slot :name="scopedSlotName" v-bind="slotData" />
      </template>
      <template v-for="(_, slotName) in $slots" #[slotName]>
        <slot :name="slotName" />
      </template>
    </v-breadcrumbs>
  </div>
</template>

<script>
export default {
  name: "v-breadcrumbs-alt",
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default: ""
    },
    items: {
      type: [],
      default: null
    }
  },
  computed: {
    arrowItem() {
      if (this.items.length > 1) return this.items[this.items.length - 2];
      else return null;
    },
    commonAttrs() {
      return {
        label: "",
        solo: true,
        dense: true,
        flat: true,
        filled: true,
        items: this.items,
        class: {
          "mt-1": this.$props.label
        }
      };
    }
  }
};
</script>

<style lang="scss">
.breadcrumbs-alt {
  display: flex;
  flex-direction: row;
  align-items: center;
  // background: rgba($shades-black, 0.04);

  .breadcrumbs-alt-arrow {
    font-size: 16px;
    color: rgba($shades-black, 0.54);
    line-height: 0;
    padding: 0.5rem 0;
  }

  .v-breadcrumbs {
    padding: 0.5rem;
    font-weight: 600;

    .v-breadcrumbs__divider {
      padding: 0 8px;
    }
  }
}
</style>
