export default [
  {
    text: "Open",
    value: "actions",
    align: "left",
    sortable: false,
    width: "50px",
    order: 0,
    hidable: true,
    hidden: false,
  },
  { text: "Name", value: "name", order: 1, hidable: false, hidden: false },
  {
    text: "Description",
    value: "description",
    order: 2,
    hidable: true,
    hidden: false,
  },
];
