import perms from "../../../plugins/permissions";
export default [
  {
    path: "/external-users",
    name: "externalUsers",
    meta: { layout: "dashboard", title: "External Users", permissions: [perms.ExternalUsers.View] },
    component: () => import("./views/Users.vue"),
  },
  {
    path: "/external-users/:id",
    name: "single-external-user",
    meta: { layout: "dashboard", title: "External Users", permissions: [perms.ExternalUsers.View] },
    component: () => import("./views/Users.vue"),
  },
  {
    path: "/invitations/:id",
    name: "single-invitation",
    meta: { layout: "dashboard", permissions: [perms.Invitations.View] },
    component: () => import("./views/Invitations.vue"),
  },
  {
    path: "/invitations",
    name: "invitation",
    meta: { layout: "dashboard", permissions: [perms.Invitations.View] },
    component: () => import("./views/Invitations.vue"),
  },
];
