import perms from "../../../plugins/permissions";
export default [
  {
    path: "/sub-contractors",
    name: "sub-contractors",
    // TODO: update this line when permissions updated
    meta: { layout: "dashboard", title: "SubContractors", permissions: [perms.Clients.View] },
    component: () => import("./views/SubContractors.vue"),
  },
  {
    path: "/sub-contractors/:id",
    name: "single-sub-contractor",
    meta: { layout: "dashboard" },
    component: () => import("./views/SubContractors.vue"),
  },
];
