<template>
  <v-menu
    transition="slide-y-transition"
    bottom
    content-class="overflow-y-hidden white"
    :close-on-content-click="false"
    @input="setMenuStatus"
  >
    <template v-slot:activator="{ on: { click } }">
      <div @click="click" :class="{ 'disabled-event': disabled, 'filter-active-item': closable }">
        <slot name="selection" :item="selectedObject" :groups="groups">
          <v-btn
            class="text-capitalize body-1 d-flex align-center"
            :height="height != null ? height : 32"
            :class="selectedObject ? 'filter-active pr-8' : 'pr-3'"
            :color="selectedObject ? 'secondary' : 'white'"
            small
            :disabled="disabled"
          >
            <i class="fad fa-filter me-2 fs-12px"></i>{{ title }}
            <span v-if="selectedObject && selectedObject[itemText]"
              >: {{ selectedObject[itemText] }}</span
            >
            <v-chip
              x-small
              color="white font-weight-bold"
              class="ml-1"
              text-color="black"
              v-if="groups.length > 1"
              >+{{ groups.length - 1 }}</v-chip
            >
            <!-- <i class="far fa-chevron-down ms-1" style="font-size: 12px"></i> -->
          </v-btn>
        </slot>
        <span class="closable" v-if="closable && selectedObject" @click.stop="clearAndClose()">
          <i
            class="far fa-times"
            :class="[
              selectedObject && selectedObject[itemText] ? 'white--text' : 'grey--text lighten-2',
            ]"
          ></i>
        </span>
      </div>
    </template>
    <v-progress-linear
      indeterminate
      absolute
      top
      color="info"
      striped
      :active="loading || search.loading"
    ></v-progress-linear>
    <v-text-field
      hide-details
      :placeholder="'Search ' + title"
      class="rounded-0"
      flat
      solo
      clearable
      v-model="search.key"
      :loading="search.loading"
    >
      <template v-slot:prepend-inner> <i class="far fa-search mr-2"></i> </template>
    </v-text-field>
    <v-divider></v-divider>
    <v-sheet
      width="300px"
      v-if="entries.length == 0 && (loading || search.loading)"
      class="d-flex flex-column align-center justify-center font-weight-medium pa-6"
    >
      <v-progress-circular indeterminate :size="25" :width="2"></v-progress-circular>
      <span> Loading... </span>
    </v-sheet>
    <template v-else>
      <v-sheet
        width="300px"
        v-if="noMatch"
        class="d-flex flex-column align-center justify-center font-weight-medium pa-6"
      >
        <i class="fad fa-grin-beam-sweat mb-5" style="font-size: 48px"></i>
        <span>
          No matches found for
          <u>
            <b>{{ search.key }}</b>
          </u>
          !
        </span>
      </v-sheet>
      <v-sheet
        width="300px"
        v-else-if="!entries.length"
        class="d-flex flex-column align-center justify-center font-weight-medium pa-6"
      >
        <i class="fad fa-empty-set mb-5" style="font-size: 48px"></i>
        No data available!
      </v-sheet>
      <template v-else>
        <v-list
          class="overflow-y-auto py-2"
          :class="cssClass"
          max-height="300px"
          width="260px"
          dense
        >
          <v-list-item-group
            color="info"
            active-class="filter-selected-item"
            v-model="selectedItems"
            @change="emitValue"
            :multiple="multiple"
            ref="groupItems"
          >
            <v-list-item
              v-for="(item, i) in entries"
              :disabled="item.divider"
              :input-value="item"
              :key="'itemList' + i + '_' + id"
              dense
              :class="{
                'pa-0 ma-0 min-height-10': item.divider,
                'height-0': item.divider && entries.length < 3,
              }"
            >
              <template v-slot:default="{ active }">
                <v-list-item-action v-if="multiple && !item.divider">
                  <v-checkbox :input-value="active" color="info" dense></v-checkbox>
                </v-list-item-action>
                <v-divider v-if="item.divider" v-show="entries.length > 2"></v-divider>
                <slot v-else name="item" :item="item"></slot>
              </template>
            </v-list-item>
          </v-list-item-group>
          <v-btn
            @click="options.page++"
            v-if="showMore"
            :loading="loading"
            text
            block
            color="info"
            class="text-capitalize my-1"
            style="font-size: 14px"
          >
            Show More
          </v-btn>
        </v-list>
        <div>
          <v-divider></v-divider>
          <div class="pl-1 pr-3 py-1 d-flex align-center">
            <v-btn
              text
              @click="clearSelection"
              v-if="groups.length"
              color="info"
              class="text-capitalize clear-selection-link"
              style="font-size: 14px"
            >
              Clear selection
            </v-btn>
            <v-spacer></v-spacer>
            <div class="font-weight-medium grey--text text--darken-1 py-2" style="font-size: 14px">
              {{ `${entries.length} of ${search.key || !baseUrl ? entries.length : total}` }}
            </div>
          </div>
        </div>
      </template>
    </template>
  </v-menu>
</template>
<script>
import Api from "../../Shared/services/api";
export default {
  props: {
    value: {
      type: Array,
    },
    cssClass: {
      type: String,
      require: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "items",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    itemValue: {
      type: String,
      default: "id",
    },
    itemText: {
      type: String,
      default: "name",
    },
    baseUrl: {
      type: String,
      default: "",
    },
    searchUrl: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    unshiftList: {
      type: Array,
      default: () => [],
    },
    allowDivider: {
      type: Boolean,
      default: true,
    },
    closable: {
      type: Boolean,
      default: true,
    },
    isLazy: {
      type: Boolean,
      default: true,
    },
    height: {
      type: [Number, String],
      default: null,
    },
  },
  computed: {
    showMore() {
      return this.usedItems.length < this.total;
    },
    entries() {
      return this.cloneDeep(
        this.search.key && this.search.items.length ? this.search.items : this.usedItems
      );
    },
    usedItems() {
      return this.baseUrl
        ? this.serverItems
        : this.cloneDeep(this.items).sort(
            (a, b) =>
              a[this.itemText] ||
              "".trim().toLowerCase().localeCompare(b[this.itemText].trim().toLowerCase())
          );
    },
    noMatch() {
      return this.search.key && !this.search.items.length && !this.search.loading;
    },
    selectedObject() {
      return this.groups.length ? this.entries[this.groups[0]] : null;
    },
  },
  data() {
    return {
      selectedItems: null,
      groups: [],
      serverItems: [],
      total: 0,
      loading: false,
      itemsTimerId: null,
      search: {
        key: "",
        items: [],
        loading: false,
      },
      options: {
        itemsPerPage: 10,
        multiSort: false,
        mustSort: false,
        page: 1,
        sortBy: this.itemText,
        sortDesc: false,
      },
      searAbortController: null,
      menuStatus: false,
    };
  },
  methods: {
    setMenuStatus(val) {
      this.menuStatus = val;
    },
    typeHead(search, all) {
      if (this.searAbortController) {
        this.searAbortController.abort();
      }
      this.searAbortController = new AbortController();
      const qParams = new URLSearchParams({ search, all, itemsPerPage: this.total || 20 });
      const url = this.searchUrl || `${this.baseUrl}/typeHead`;
      return Api().get(`${url}?${qParams.toString()}`, {
        signal: this.searAbortController.signal,
      });
    },
    query(options) {
      const qParams = new URLSearchParams(options);
      return Api().get(this.baseUrl + "?" + qParams.toString());
    },
    setCurrentIndex() {
      if (this.value) {
        const items = this.value.map((id) =>
          this.entries.findIndex((elm) => elm[this.itemValue] == id)
        );
        if (this.multiple) {
          this.selectedItems = items;
        } else {
          this.selectedItems = items.length > 0 ? items[0] : null;
        }
      } else {
        this.selectedItems = null;
      }
      this.getGroups();
    },
    getData() {
      this.loading = true;
      this.query(this.$clean(this.options))
        .then((resp) => {
          this.$log("filter-menu resp.data", resp.data.total, resp.data);
          this.addUniqueArr(this.serverItems, resp.data.items, this.itemValue);
          if (this.unshiftList.length) {
            this.unshiftSelected(this.unshiftList);
          }
          this.total = resp.data.total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          // this.$handleError(err);
        });
    },
    getDataDebounced() {
      if (this.itemsTimerId == null) {
        this.itemsTimerId = -1;
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.itemsTimerId);
      // delay new call 400ms
      this.itemsTimerId = setTimeout(() => {
        this.getData();
      }, 400);
    },
    clearAndClose() {
      this.clearSelection();
      this.$emit("close");
    },
    clearSelection() {
      this.selectedItems = null;
      this.emitValue();
    },
    unshiftSelected(items) {
      return;
      const newItems = this.allowDivider
        ? items.length
          ? [{ divider: true }, ...items]
          : []
        : [...items];
      this.addUniqueArr(this.usedItems, newItems, this.itemValue, true);
    },
    returnSelectedItems(groups) {
      return groups.filter((elm) => elm !== -1).map((elm) => this.entries[elm][this.itemValue]);
    },
    getGroups() {
      const isExist = this.selectedItems || this.selectedItems == 0;
      if (this.multiple) {
        this.groups = isExist ? this.cloneDeep(this.selectedItems) : [];
      } else {
        this.groups = isExist ? [this.selectedItems] : [];
      }
      if (!this.groups.length) this.resetListGroup();
    },
    emitValue() {
      this.getGroups();
      this.$emit("input", this.returnSelectedItems(this.cloneDeep(this.groups)));
    },
    serverSearch(val) {
      if (val) {
        this.search.loading = true;
        this.typeHead(val, false)
          .then((res) => {
            this.search.items = res.data.items || res.data;
            this.search.loading = false;
            this.setCurrentIndex();
          })
          .catch((err) => {
            // if (err.message != "canceled") this.$handleError(err);
            this.search.loading = false;
          });
      } else {
        const selectedItem = [...this.search.items, ...this.usedItems].filter((elm) =>
          this.value.includes(elm[this.itemValue])
        );
        this.unshiftSelected(selectedItem.concat(this.unshiftList));
        this.search.items = [];
        this.setCurrentIndex();
      }
    },
    localSearch(val) {
      this.search.loading = true;
      if (val) {
        this.search.items = this.items.filter((elm) =>
          elm[this.itemText].toLowerCase().includes(val.toLowerCase())
        );
      } else {
        const selectedItem = this.items.filter((elm) => this.value.includes(elm[this.itemValue]));
        this.unshiftSelected(selectedItem.concat(this.unshiftList));
        this.search.items = [];
      }
      const timeout = setTimeout(() => {
        this.search.loading = false;
        this.setCurrentIndex();
        clearTimeout(timeout);
      }, 400);
    },
    resetListGroup() {
      if (this.$refs["groupItems"]) {
        this.$refs["groupItems"].$el.childNodes.forEach((elm) => {
          elm.classList.remove("filter-selected-item", "v-list-item--active");
          elm.setAttribute("aria-selected", false);
        });
      }
    },
  },
  watch: {
    options: {
      handler() {
        if (this.baseUrl) this.getDataDebounced();
      },
      deep: true,
    },
    menuStatus: {
      handler(val) {
        const fetch = !this.isLazy || (this.isLazy && val);
        if (fetch && this.baseUrl) this.getDataDebounced(); // && !this.entries.length
      },
      immediate: true,
    },
    "search.key"(val) {
      if (this.baseUrl) {
        this.serverSearch(val);
      } else {
        this.localSearch(val);
      }
    },
    value: {
      handler() {
        this.setCurrentIndex();
      },
      deep: true,
    },
    unshiftList(val, old) {
      if (val.length && !this.loading && !this.isEqual(val, old)) {
        this.unshiftSelected(val);
        if (!this.groups.length) this.resetListGroup();
      }
    },
  },
};
</script>
<style lang="scss">
.filter-selected-item {
  position: relative;

  &::after {
    content: "";
    width: 2px;
    height: 28px;
    min-height: unset !important;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: $info-base;
    border-radius: 0 0.25rem 0.25rem 0;
  }
}
.filter-active-item {
  position: relative;

  .v-btn {
    padding-right: 36px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .closable {
    position: absolute;
    right: 4px;
    padding: 0.5rem;
    width: 28px;
    top: calc(50%);
    justify-content: center;
    align-items: center;
    display: flex;
    transform: translateY(-50%);
    transition: all 0.3s ease-out;
    line-height: 0;
    border-radius: 1rem;

    i {
      transition: all 0.3s ease-out;
    }

    &:hover {
      background: rgba($shades-black, 0.08);
      cursor: pointer;
    }
    &:active {
      background: rgba($shades-black, 0.4);
      i {
        color: #fff !important;
      }
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss" scoped>
.disabled-event {
  pointer-events: none;
}
.min-height-10 {
  min-height: 10px !important;
}
.height-0 {
  height: 0;
  min-height: 0 !important;
}
</style>
