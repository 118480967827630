<template>
  <v-dialog v-model="active" width="800" scrollable retain-focus content-class="quick-tools-dialog">
    <v-card rounded="lg">
      <v-card-title
        class="font-weight-medium white--text grey darken-4"
        style="font-size: 16px; padding: 12px 16px 8px 20px; padding: 8px 8px 6px 18px"
      >
        <span class="d-flex align-center"
          ><i class="fad fa-bolt mr-3 fs-20px"></i>Power Calculator</span
        >
        <v-spacer></v-spacer>
        <v-btn dark @click="close" icon>
          <i class="far fa-times"></i>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="secondary--text">
        <v-row class="my-0 mt-4">
          <v-col cols="12" md="12" class="pt-0 d-flex align-start flex-column" style="gap: 0.25rem">
            <h3>
              <i class="fad fa-info-circle mr-2"></i>
              Fill 2 values only and click 'Calculate' to get the other 2, and 'Reset' for a new
              calculation
            </h3>
          </v-col>
          <v-col cols="12" md="12" class="pt-0">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" md="3" class="d-flex align-end">
            <v-text-field-alt
              :rules="[allRules.number]"
              suffix="volts"
              label="Voltage (V)"
              ref="toolVoltage"
              placeholder="Voltage"
              v-model.number="selected.volt"
              hide-details
              clearable
            >
            </v-text-field-alt>
            <i class="fad fa-right-left" style="bottom: 12px; left: 12px"></i>
          </v-col>
          <v-col cols="12" md="3" class="d-flex align-end">
            <v-text-field-alt
              :rules="[allRules.number]"
              suffix="amps"
              label="Current (I)"
              ref="toolCurrent"
              placeholder="Current"
              v-model.number="selected.amp"
              hide-details
              clearable
            >
            </v-text-field-alt>
            <i class="fad fa-right-left" style="bottom: 12px; left: 12px"></i>
          </v-col>
          <v-col cols="12" md="3" class="d-flex align-end">
            <v-text-field-alt
              :rules="[allRules.number]"
              suffix="watts"
              label="Power (P)"
              placeholder="Power"
              ref="toolWatts"
              v-model.number="selected.watt"
              hide-details
              clearable
            >
            </v-text-field-alt>
            <i class="fad fa-right-left" style="bottom: 12px; left: 12px"></i>
          </v-col>
          <v-col cols="12" md="3" class="d-flex align-end">
            <v-text-field-alt
              :rules="[allRules.number]"
              suffix="ohms"
              label="Resistance (R)"
              placeholder="Resistance"
              ref="toolOhms"
              v-model.number="selected.ohm"
              hide-details
              clearable
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12" md="3" class="d-flex align-end">
            <v-text-field-alt
              label="HeatLoad (BTU/Hr)"
              placeholder="BTU/Hr"
              v-model.number="generated.heatload"
              hide-details
              readonly
            >
            </v-text-field-alt>
            <i class="fad fa-link" style="bottom: 12px; left: 12px"></i>
          </v-col>
          <v-col cols="12" md="3" class="">
            <v-text-field-alt
              label="HVAC (Tonnage)"
              placeholder="Tonnage"
              v-model.number="generated.hvacTonnage"
              hide-details
              readonly
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12" md="6" class="pt-0 d-flex align-end">
            <v-btn color="secondary" :disabled="!validToCalc" @click="calc">
              <i class="far fa-check"></i><span class="ml-2">Calculate</span>
            </v-btn>
            <v-btn color="secondary" outlined @click="reset" class="ml-2">
              <i class="far fa-redo"></i><span class="ml-2">Reset</span>
            </v-btn>
          </v-col>
          <v-col cols="12" md="12" class="mt-0">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" md="12" class="py-0">
            <v-expansion-panels ref="Reference">
              <v-expansion-panel class="power-spec-ep">
                <v-expansion-panel-header class="px-4 py-0" :ripple="true">
                  <h4>Reference:</h4>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="12" md="12">
                      <ul class="mt-0">
                        <li>
                          <div class="d-flex align-center" style="gap: 0.5rem">
                            <b>Voltage:</b>
                            <div class="d-flex align-center" style="gap: 2rem">
                              <img src="https://latex.codecogs.com/svg.image?V=I\times R" />
                              <u>OR</u>
                              <img
                                src="https://latex.codecogs.com/svg.image?V=\frac{P}{I}"
                                alt=""
                              />
                              <u>OR</u>
                              <img
                                src="https://latex.codecogs.com/svg.image?V=\sqrt{P\times%20R}"
                                alt=""
                              />
                            </div>
                          </div>
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="12" md="12">
                      <ul class="mt-0">
                        <li>
                          <div class="d-flex align-center" style="gap: 0.5rem">
                            <b>Power:</b>
                            <div class="d-flex align-center" style="gap: 2rem">
                              <img src="https://latex.codecogs.com/svg.image?P=V\times T" />
                              <u>OR</u>
                              <img
                                src="https://latex.codecogs.com/svg.image?P=\frac{V^{2}}{R}"
                                alt=""
                              />
                              <u>OR</u>
                              <img src="https://latex.codecogs.com/svg.image?P=I^{2}\times R" />
                            </div>
                          </div>
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="12" md="12">
                      <ul class="mt-0">
                        <li>
                          <div class="d-flex align-center" style="gap: 0.5rem">
                            <b>Current:</b>
                            <div class="d-flex align-center" style="gap: 2rem">
                              <img
                                src="https://latex.codecogs.com/svg.image?I=\frac{V}{R}"
                                alt=""
                              />
                              <u>OR</u>
                              <img
                                src="https://latex.codecogs.com/svg.image?I=\frac{P}{V}"
                                alt=""
                              />
                              <u>OR</u>
                              <img
                                src="https://latex.codecogs.com/svg.image?I=\sqrt{\frac{P}{R}}"
                                alt=""
                              />
                            </div>
                          </div>
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="12" md="12">
                      <ul class="mt-0">
                        <li>
                          <div class="d-flex align-center" style="gap: 0.5rem">
                            <b>Resistance:</b>
                            <div class="d-flex align-center" style="gap: 2rem">
                              <img src="https://latex.codecogs.com/svg.image?R=\frac{V}{I}" />
                              <u>OR</u>
                              <img
                                src="https://latex.codecogs.com/svg.image?R=\frac{V^{2}}{P}"
                                alt=""
                              />
                              <u>OR</u>
                              <img
                                src="https://latex.codecogs.com/svg.image?R=\frac{P}{I^{2}}"
                                alt=""
                              />
                            </div>
                          </div>
                        </li>
                      </ul>
                    </v-col>
                    <v-col cols="12" md="12">
                      <ul class="mt-1">
                        <li>1 Watt = 3.412 BTU/hr</li>
                        <li>1 HVAC Tonnage = 12,000 BTU/hr</li>
                      </ul>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      active: false,
      constants: { wattToBTU: 3.412141633 },
      selected: {
        volt: null,
        amp: null,
        watt: null,
        ohm: null,
      },
      generated: {
        heatload: null,
        hvacTonnage: null,
      },
    };
  },
  props: {},
  mounted() {},
  computed: {
    validToCalc() {
      if (this.totalFilled == 2) return true;
      return false;
    },
    totalFilled() {
      var count = Object.values(this.selected).reduce((total, cur) => {
        var curCount = cur == null || cur == "" ? 0 : 1;
        return total + curCount;
      }, 0);
      return count;
    },
  },
  methods: {
    getValidVal(val) {
      return val != null && val != "" ? val : 0;
    },
    calc() {
      var r = this.getValidVal(this.selected.ohm);
      var i = this.getValidVal(this.selected.amp);
      var v = this.getValidVal(this.selected.volt);
      var p = this.getValidVal(this.selected.watt);

      if (r != 0 && v != 0) {
        i = v / r;
        p = v * i;
      } else if (r != 0 && i != 0) {
        v = i * r;
        p = v * i;
      } else if (r != 0 && p != 0) {
        v = Math.sqrt(p * r);
        i = p / v;
      } else if (i != 0 && v != 0) {
        r = v / i;
        p = v * i;
      } else if (i != 0 && p != 0) {
        v = p / i;
        r = v / i;
      } else if (v != 0 && p != 0) {
        i = p / v;
        r = v / i;
      }

      this.selected.ohm = r;
      this.selected.amp = i;
      this.selected.volt = v;
      this.selected.watt = p;
      this.refreshHeatAndHvac();
    },
    reset() {
      this.selected = {
        volt: null,
        amp: null,
        watt: null,
        ohm: null,
      };
      this.generated = {
        heatload: null,
        hvacTonnage: null,
      };
    },
    refreshHeatAndHvac() {
      if (this.selected == null || !this.isNum(this.selected.watt)) return;

      //Heat Load = POWER CONSUMPTION * 3.412
      //HVAC Tonnage = Heat Load / 12000

      let powerConsumption = parseFloat(this.selected.watt);
      this.generated.heatload = parseFloat(
        (powerConsumption * this.constants.wattToBTU).toFixed(2)
      );
      this.generated.hvacTonnage = parseFloat((this.generated.heatload / 12000).toFixed(5));
    },
    open() {
      this.active = true;
      setTimeout(() => {
        this.$refs.toolVoltage.select();
      }, 300);
    },
    close() {
      this.active = false;
    },
    isNum(value) {
      return /^[0-9]+.[0-9]+|[0-9]+$/.test(value);
    },
    voltageChanged() {
      if (this.isNum(this.selected.voltage)) {
        // Amps = Watts / Volts
        this.selected.amp = this.selected.watt / this.selected.volt;
      }
    },
  },
  watch: {
    "selected.watt": {
      handler() {
        // this.refreshHeatAndHvac();
      },
    },
  },
};
</script>

<style lang="scss"></style>
