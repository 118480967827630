<template>
  <span class="d-inline-flex ml-1">
    <v-row align-content="center" justify="start" no-gutters style="flex: none">
      <v-col sm="auto" class="d-flex align-center mr-1">
        <i :class="'equipment-icon fad ' + parsedValue.Icon"></i>
      </v-col>
      <v-col class="d-flex align-center col-auto">
        <div class="d-flex align-center">
          {{ parsedValue.Name }}
        </div>
      </v-col>
    </v-row>
  </span>
</template>

<script>
export default {
  components: {},
  name: "category-logged-prop",
  inheritAttrs: false,
  data() {
    return {};
  },
  props: {
    value: {
      type: String,
      default: null
    }
  },
  computed: {
    parsedValue() {
      var val = this.value == "" ? null : this.value;
      this.$log("AcPowerSpecLoggedProp", val);
      return JSON.parse(val) || null;
    }
  },
  methods: {}
};
</script>

<style lang="scss"></style>
