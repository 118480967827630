import store from "../store";

function perms() {
  if (
    store.getters.user &&
    store.getters.user.roles[0] &&
    store.getters.user.roles[0].permissions
  ) {
    return store.getters.user.roles[0].permissions.map((elm) => elm.name);
  }
  return [];
}

function has(value, any = false) {
  value = value && typeof value == "string" ? [value] : value;
  let permissions = perms();
  let pass = false;
  if (value && Array.isArray(value))
    value.every((element) => {
      pass = permissions.includes(element);
      if (any ? pass : !pass) return false;
      return true;
    });
  return pass;
}

function buildParams(dataObject) {
  const params = new URLSearchParams();
  for (const [key, value] of Object.entries(dataObject)) {
    if (Array.isArray(dataObject[key])) {
      dataObject[key].forEach((item) => {
        params.append(key, item);
      });
    } else {
      params.append(key, value);
    }
  }
  return params;
}

export { has, buildParams };
