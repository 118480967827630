<template>
  <span v-if="value == 'true' || value == 'True'">
    <!-- <i class="fad fa-square-check fs-18px green--text"></i> -->
    <v-switch v-model="checked" color="info" readonly></v-switch>
  </span>
  <span v-else-if="value == 'false' || value == 'False'">
    <!-- <i class="fad fa-square-xmark fs-18px red--text"></i> -->
    <v-switch v-model="unchecked" color="info" readonly></v-switch>
  </span>
  <span v-else-if="value == '[]' || value == ''">
    <i class="fad fa-empty-set fs-16px ml-2" style="opacity: 0.24"></i>
  </span>
  <span v-else style="line-break: anywhere">{{ value }}</span>
</template>

<script>
export default {
  name: "default-logged-prop",
  inheritAttrs: false,
  data() {
    return {
      checked: true,
      unchecked: false,
    };
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss"></style>
