<template>
  <v-container fluid class="pa-6" v-if="selected != null">
    <v-form v-model="valid" ref="updateForm2">
      <v-row
        class="my-0 d-flex flex-row justify-space-between flex-wrap"
        style="gap: 0.5rem; min-height: 36px"
      >
        <v-col cols="auto" class="py-0">
          <v-switch
            class="ma-0 pa-0"
            color="orange"
            hide-details
            v-model="selected.powerRequired"
            :readonly="readonly"
            :disabled="readonly"
          >
            <template v-slot:label>
              <label class="v-switch-label ma-0">Power Required</label>
            </template>
          </v-switch>
        </v-col>
        <v-col cols="auto" class="py-0">
          <v-menu offset-y left v-if="selected.powerRequired">
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="readonly"
                color="orange"
                class="action-btn"
                :key="'add-new-power-specs-btn'"
                style="margin-bottom: 4px"
              >
                <i class="fal fa-plus mr-2"></i>Add Power Spec
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                link
                style="min-height: 32px"
                :disabled="hasAcPowerSpec"
                @click="addNewAcPowerSpec"
              >
                <v-list-item-icon class="mr-2 justify-center align-center">
                  <span
                    class="fa-stack"
                    style="
                      font-size: 20px;
                      width: 20px;
                      height: 20px;
                      line-height: 20px;
                    "
                  >
                    <i class="fad fa-dash fa-stack-1x"></i>
                    <i
                      class="fas fa-wave-sine fa-stack-1x"
                      style="font-size: 14px"
                    ></i>
                  </span>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    style="font-size: 12px !important"
                    class="font-weight-medium"
                    :disabled="hasAcPowerSpec"
                  >
                    AC Power Spec
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                style="min-height: 32px"
                :disabled="hasDcPowerSpec"
                @click="addNewDcPowerSpec"
              >
                <v-list-item-icon class="mr-2 justify-center align-center">
                  <span
                    class="fa-stack"
                    style="
                      font-size: 20px;
                      width: 20px;
                      height: 20px;
                      line-height: 20px;
                    "
                  >
                    <i class="fad fa-grip-dots fa-stack-1x"></i>
                    <i
                      class="fas fa-minus fa-stack-1x"
                      style="font-size: 20px; margin-top: -3px"
                    ></i>
                  </span>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    style="font-size: 12px !important"
                    class="font-weight-medium"
                    :disabled="hasDcPowerSpec"
                  >
                    DC Power Spec
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                style="min-height: 32px"
                :disabled="hasPoePowerSpec"
                @click="addNewPoEPowerSpec"
              >
                <v-list-item-icon class="mr-2 justify-center align-center">
                  <span
                    class="fa-stack"
                    style="
                      font-size: 18px;
                      width: 18px;
                      height: 18px;
                      line-height: 18px;
                    "
                  >
                    <i class="fad fa-ethernet fa-stack-1x"></i>
                    <i
                      class="fas fa-bolt fa-stack-1x"
                      style="font-size: 8px; margin-top: -1px"
                    ></i>
                  </span>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    style="font-size: 12px !important"
                    class="font-weight-medium"
                    :disabled="hasPoePowerSpec"
                  >
                    PoE Power Spec
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <v-container
        fluid
        class="power-specs-content d-flex flex-column my-3"
        :class="{
          'inset-shadow power-specs-content-selected': selected.powerRequired,
        }"
      >
        <v-scroll-y-transition mode="out-in">
          <v-row
            class="my-12"
            key="equipment-no-power-required"
            v-if="!selected.powerRequired"
          >
            <v-col cols="12" md="12" sm="12">
              <p class="text-center mb-2">
                <span class="fa-stack" style="font-size: 40px">
                  <i
                    class="fad fa-plug-circle-bolt blue-grey--text fa-stack-2x"
                  ></i>
                  <i class="fas fa-slash fa-stack-2x" style="color: tomato"></i>
                </span>
              </p>
              <h3
                class="
                  text-center
                  blue-grey--text
                  text--lighten-2
                  mb-0
                  font-weight-medium
                "
              >
                No Power Required!
              </h3>
            </v-col>
          </v-row>
          <v-row v-else key="equipment-power-specs">
            <v-col
              cols="12"
              md="12"
              sm="12"
              class="text-center my-6"
              v-if="!hasAcPowerSpec && !hasDcPowerSpec && !hasPoePowerSpec"
            >
              <p class="text-center fs-28px">
                <span class="fa-stack mr-0">
                  <i
                    class="
                      fad
                      fa-plug-circle-bolt fa-stack-2x fa-beat-fade
                      orange--text
                    "
                    style="
                      --fa-animation-duration: 3s;
                      --fa-fade-opacity: 0.64;
                      --fa-beat-scale: 1;
                    "
                  ></i>
                </span>
              </p>
              <h3 class="text--disabled mb-3">No power specs are added yet!</h3>
              <v-menu offset-y left>
                <template v-slot:activator="{ attrs, on }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="orange"
                    min-width="28px"
                    min-height="28px"
                    class="action-btn px-0"
                    style="margin-bottom: 4px"
                  >
                    <i class="far fa-plus"></i>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item
                    link
                    style="min-height: 32px"
                    :disabled="hasAcPowerSpec"
                    @click="addNewAcPowerSpec"
                  >
                    <v-list-item-icon class="mr-2 justify-center align-center">
                      <span
                        class="fa-stack"
                        style="
                          font-size: 20px;
                          width: 20px;
                          height: 20px;
                          line-height: 20px;
                        "
                      >
                        <i class="fad fa-dash fa-stack-1x"></i>
                        <i
                          class="fas fa-wave-sine fa-stack-1x"
                          style="font-size: 14px"
                        ></i>
                      </span>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        style="font-size: 12px !important"
                        class="font-weight-medium"
                        :disabled="hasAcPowerSpec"
                      >
                        AC Power Spec
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    link
                    style="min-height: 32px"
                    :disabled="hasDcPowerSpec"
                    @click="addNewDcPowerSpec"
                  >
                    <v-list-item-icon class="mr-2 justify-center align-center">
                      <span
                        class="fa-stack"
                        style="
                          font-size: 20px;
                          width: 20px;
                          height: 20px;
                          line-height: 20px;
                        "
                      >
                        <i class="fad fa-grip-dots fa-stack-1x"></i>
                        <i
                          class="fas fa-minus fa-stack-1x"
                          style="font-size: 20px; margin-top: -3px"
                        ></i>
                      </span>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        style="font-size: 12px !important"
                        class="font-weight-medium"
                        :disabled="hasDcPowerSpec"
                      >
                        DC Power Spec
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    link
                    style="min-height: 32px"
                    :disabled="hasPoePowerSpec"
                    @click="addNewPoEPowerSpec"
                  >
                    <v-list-item-icon class="mr-2 justify-center align-center">
                      <span
                        class="fa-stack"
                        style="
                          font-size: 18px;
                          width: 18px;
                          height: 18px;
                          line-height: 18px;
                        "
                      >
                        <i class="fad fa-ethernet fa-stack-1x"></i>
                        <i
                          class="fas fa-bolt fa-stack-1x"
                          style="font-size: 8px; margin-top: -1px"
                        ></i>
                      </span>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        style="font-size: 12px !important"
                        class="font-weight-medium"
                        :disabled="hasPoePowerSpec"
                      >
                        PoE Power Spec
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="text-left px-3" v-else>
              <v-expansion-panels
                ref="powerSpecsEP"
                v-model="expandedPowerSpecsPanels"
                multiple
              >
                <ac-power-spec-ep
                  v-if="hasAcPowerSpec"
                  v-model="selected.powerSpecList.acPowerSpec"
                  @delete="onAcPowerSpecDelete()"
                  :readonly="readonly"
                  key="ac-power-spec"
                ></ac-power-spec-ep>
                <dc-power-spec-ep
                  v-if="hasDcPowerSpec"
                  v-model="selected.powerSpecList.dcPowerSpec"
                  @delete="onDcPowerSpecDelete()"
                  :readonly="readonly"
                  key="dc-power-spec"
                ></dc-power-spec-ep>
                <poe-power-spec-ep
                  v-if="hasPoePowerSpec"
                  v-model="selected.powerSpecList.poePowerSpec"
                  @delete="onPoePowerSpecDelete()"
                  :readonly="readonly"
                  key="poe-power-spec"
                ></poe-power-spec-ep>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-scroll-y-transition>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import AcPowerSpecEp from "./AcPowerSpecEp.vue";
import DcPowerSpecEp from "./DcPowerSpecEp.vue";
import PoePowerSpecEp from "./PoePowerSpecEp.vue";
import Enums from "../../../../plugins/enums";

export default {
  name: "equipment-power-specs",
  props: {
    value: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valid: false,
      PowerSpecType: Enums.PowerSpecType,
      PowerSpecAvailability: Enums.PowerSpecAvailability,
      expandedPowerSpecsPanels: [],
      selected: {},
    };
  },
  mounted() {
    this.selected = this.cloneDeep(this.value);
  },
  computed: {
    hasAcPowerSpec() {
      return this.selected.powerSpecList.acPowerSpec != null;
    },
    hasDcPowerSpec() {
      return this.selected.powerSpecList.dcPowerSpec != null;
    },
    hasPoePowerSpec() {
      return this.selected.powerSpecList.poePowerSpec != null;
    },
  },
  methods: {
    addNewAcPowerSpec() {
      this.selected.powerSpecList.acPowerSpec = {
        id: null,
        type: this.PowerSpecType.AC.value,
        availability: this.PowerSpecAvailability.Required.value,
        options: [],
      };
    },
    addNewDcPowerSpec() {
      this.selected.powerSpecList.dcPowerSpec = {
        id: null,
        type: this.PowerSpecType.DC.value,
        availability: this.PowerSpecAvailability.Required.value,
        powerSupplyIncluded: true,
        powerSupplyPowerOptions: [],
        dCPowerRequirement: null,
      };
    },
    addNewPoEPowerSpec() {
      this.selected.powerSpecList.poePowerSpec = {
        id: null,
        type: this.PowerSpecType.PoE.value,
        availability: this.PowerSpecAvailability.Required.value,
        poeTypes: [],
        maxWattDraw: 1,
        maxHeatLoad: 34.12,
        maxHvacTonnage: 0.00284,
      };
    },
    onAcPowerSpecDelete() {
      this.selected.powerSpecList.acPowerSpec = null;
    },
    onDcPowerSpecDelete() {
      this.selected.powerSpecList.dcPowerSpec = null;
    },
    onPoePowerSpecDelete() {
      this.selected.powerSpecList.poePowerSpec = null;
    },
  },
  watch: {
    valid() {
      this.$emit("valid-changes", this.valid);
    },
    selected: {
      handler() {
        if (!this.isEqual(this.selected, this.value)) {
          this.$emit("input", this.selected);
        }
      },
      deep: true,
    },
    value: {
      handler() {
        if (!this.isEqual(this.selected, this.value)) {
          this.selected = this.cloneDeep(this.value);
        }
      },
      deep: true,
    },
  },
  components: { AcPowerSpecEp, DcPowerSpecEp, PoePowerSpecEp },
};
</script>
<style lang="scss">
.power-specs-content {
  flex: 1 1 auto;
  border-radius: 0.35rem;
  overflow: hidden;
  transition: all 0.3s ease-out;

  &.power-specs-content-selected {
    background: #eceff1;
  }
}
</style>
