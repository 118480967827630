<template>
  <slideout
    dock="right"
    :size="nested ? '87%' : '600px'"
    :min-size="nested ? 400 : 600"
    :allow-resize="nested ? false : false"
    :append-to="nested ? '' : '#app'"
    :class="readonly ? 'slideout-readonly-content' : ''"
    :visible.sync="slideouts.update.active"
    v-on:close="onSlideoutClosing"
  >
    <template #header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Contact ...
      </h3>
      <h3 v-else-if="selected.id != null" class="font-weight-bold pa-1" small>
        <span v-if="readonly">
          <i class="fad fa-user-tie mr-2"></i> Product Family: '{{ selected.name }}'
        </span>
        <span v-else> <i class="fad fa-pen-square mr-2"></i> Update: '{{ selected.name }}' </span>
      </h3>
      <h3 v-else class="font-weight-bold pa-1" small>
        <i class="fad fa-plus-square mr-2"></i> Create a New Product Family
      </h3>
      <div>
        <code
          v-if="readonly"
          class="text-caption ml-2 white secondary--text mr-2"
          style="border: 1px dashed rgba(42, 54, 59, 0.28) !important"
        >
          <span class="fa-stack mr-0">
            <i class="fad fa-pencil fa-stack-1x"></i>
            <i class="fas fa-slash fa-stack-1x" style="color: Tomato"></i>
          </span>
          Read-Only
        </code>
        <v-btn @click="closeUpdateSlideout()" icon><i class="far fa-times"></i></v-btn>
      </div>
    </template>

    <v-container class="pa-0 text-center" v-if="isFetching">
      <video width="320" muted loop autoplay style="padding: 0.5rem; margin: 0 auto">
        <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
      </video>
      <p class="font-weight-bold">Fetching Contact ...</p>
    </v-container>
    <v-container fluid class="py-0" v-else>
      <v-form v-model="slideouts.update.valid" ref="updateForm">
        <v-row class="my-0" dense>
          <v-col cols="12">
            <v-text-field-alt
              :rules="[allRules.required, allRules.length(1), allRules.noWhiteSpaces]"
              label="Name"
              id="productFamilyName"
              ref="productFamilyName"
              placeholder="Name"
              v-model="selected.name"
              :readonly="readonly"
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12">
            <v-text-field-alt
              :rules="[allRules.url]"
              label="URL"
              id="familyURL"
              placeholder="https://www.manufacturer.com/family"
              v-model="selected.url"
              :readonly="readonly"
              :append-outer-icon="
                selected.url != null && allRules.isUrl(selected.url) == true
                  ? 'fal fa-external-link fs-18px'
                  : ''
              "
              class="shrink"
              @click:append-outer="openURL()"
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12">
            <v-textarea-alt
              :rules="[allRules.noWhiteSpaces]"
              label="Description"
              id="productFamilyDescription"
              ref="productFamilyDescription"
              placeholder="Description"
              rows="5"
              v-model="selected.description"
              :readonly="readonly"
            >
            </v-textarea-alt>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div class="d-flex ml-2" v-if="!isFetching && readonly && $has(perms.Manufacturers.Update)">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="switchToEditMode()"
                :disabled="isFetching"
                color="orange"
              >
                <i class="fas fa-pen mr-2" style="font-size: 10px"></i>Edit
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >Edit<kbd class="light ml-2 fs-12px">CTRL<span class="opacity-54">+</span>E</kbd>
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="slideouts.update.isLoading"
                @click="jumpToRequiredField()"
                color="error"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                outlined
              >
                <i class="fas fa-asterisk fs-14px"></i>
              </v-btn>
            </template>
            <span>Jump to Required Field</span>
          </v-tooltip>
        </div>

        <v-btn
          class="ml-2"
          v-if="!isFetching && !readonly && selected.id != null"
          :disabled="slideouts.update.isLoading || !slideouts.update.hasChanges"
          @click="discardChanges()"
          color="secondary"
        >
          <i class="fal fa-clock-rotate-left mr-2"></i> Discard
        </v-btn>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="info"
                :disabled="
                  !slideouts.update.valid ||
                  slideouts.update.isLoading ||
                  !slideouts.update.hasChanges
                "
                @click="updateConfirmed(false)"
                :loading="slideouts.update.isLoading"
              >
                <i class="mr-2" :class="selected.id ? 'fas fa-save' : 'fal fa-plus'"></i>
                {{ selected.id ? "Save" : "Create" }}
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >{{ selected.id ? "Save" : "Create"
              }}<kbd class="light ml-1 fs-12px">CTRL<span class="opacity-54">+</span>S</kbd>
            </span>
          </v-tooltip>
        </div>
        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="cyan white--text"
                :disabled="
                  !slideouts.update.valid ||
                  slideouts.update.isLoading ||
                  !slideouts.update.hasChanges
                "
                @click="updateConfirmed(true)"
                :loading="slideouts.update.isLoading"
              >
                <i class="fas fa-save mr-2"></i>
                {{ selected.id ? "Save & Close" : "Create & Close" }}
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >{{ selected.id ? "Save & Close" : "Create & Close" }} Panel<kbd
                class="light ml-2 fs-12px"
                >CTRL<span class="opacity-54">+</span>SHIFT<span class="opacity-54">+</span>S</kbd
              >
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="selected.id != null">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                :loading="isFetching"
                :disabled="slideouts.update.isLoading || isFetching || slideouts.update.hasChanges"
                @click="fetchProductFamily()"
              >
                <i class="fal fa-arrows-rotate" style="font-size: 16px"></i>
              </v-btn>
            </template>
            <span>Refetch Product Family</span>
          </v-tooltip>
        </div>
        <v-menu dense offset-y top left z-index="1000" v-if="selected.id != null">
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              :disabled="isFetching || slideouts.update.isLoading || slideouts.update.hasChanges"
              :elevation="2"
              width="32px"
              height="32px"
              min-width="32px"
              min-height="32px"
              class="ml-2"
              v-bind="attrs"
              v-on="on"
            >
              <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
            </v-btn>
          </template>

          <v-list class="more-options-menu">
            <v-list-item @click="del" v-if="$has(perms.Manufacturers.Delete)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-trash-alt red--text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="red--text">Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import perms from "../../../../plugins/permissions";
import Enums from "../../../../plugins/enums";
import productFamiliesService from "../services/productFamilies-service.js";

export default {
  components: {},
  name: "edit-contact",
  data() {
    return {
      perms: perms,
      productFamilyId: null,
      isFetching: false,
      readonly: false,
      cloneMode: false,
      phoneObj: null,
      selected: {},
      selectedCemented: {},
      slideouts: {
        update: {
          valid: false,
          active: false,
          isLoading: false,
          isClosing: false,
          hasChanges: false,
        },
      },
      search: null,
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
    manufacturerId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    imageApiUrl() {
      return `CompanyContacts/Avatar`;
    },
  },
  mounted() {
    document.addEventListener("keydown", this.documentKeyListener);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.documentKeyListener);
  },
  methods: {
    documentKeyListener(event) {
      this.ctrlKeyActive = event.ctrlKey;
      this.shiftKeyActive = event.shiftKey;
      if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.slideouts.update.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(false);
      } else if (
        this.ctrlKeyActive &&
        this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.slideouts.update.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(true);
      } else if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyE" &&
        !this.isFetching &&
        this.readonly &&
        this.$has(this.perms.Manufacturers.Update)
      ) {
        event.preventDefault();
        this.switchToEditMode(true);
      }
    },
    openURL() {
      window.open(this.selected.url);
    },
    savePhoneWithCountry(payload) {
      this.phoneObj = payload;
      this.$log("this.phoneObj", this.phoneObj);
    },
    switchToEditMode() {
      this.readonly = false;
    },
    onSlideoutClosing(e) {
      // prevent close and wait
      e.wait = true;

      if (this.slideouts.update.hasChanges && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }

      //reset the changes
      this.selected = {};
      this.cementProductFamily();
      this.$releaseChanges();

      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    announceChange(isNewFamily) {
      this.$emit("save", this.selected, isNewFamily);
    },
    updateConfirmed(closeAfter) {
      this.slideouts.update.isLoading = true;
      let toSend = this.cloneDeep(this.selected);
      productFamiliesService
        .update(toSend)
        .then((resp) => {
          this.slideouts.update.isLoading = false;
          var message = "Product Family updated successfully!";
          this.selected = this.cloneDeep(resp.data);
          this.cloneMode = false;
          this.cementProductFamily();
          var isNewFamily = false;
          if (!toSend.id) {
            this.productFamilyId = this.selected.id;
            isNewFamily = true;
            message = "Product Family added successfully!";
          }
          this.announceChange(isNewFamily);
          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });

          this.readonly = true;
          this.checkForChanges();
          if (closeAfter) {
            this.closeUpdateSlideout();
          }
        })
        .catch((err) => {
          this.slideouts.update.isLoading = false;
          this.$dialog.notify.error(err.request.response, {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
    closeUpdateSlideout() {
      this.cloneMode = false;
      this.slideouts.update.isLoading = false;
      this.slideouts.update.active = false;
    },
    open(id, editMode = false) {
      this.$log("open() id => ", id, ", manufacturerId => ", this.manufacturerId);
      if (id == null) {
        this.selected = { manufactureId: this.manufacturerId };
        this.readonly = false;
        this.isFetching = false;
        this.productFamilyId = null;
        this.cementProductFamily();
        setTimeout(() => {
          this.$refs.updateForm.resetValidation();
          this.$refs.productFamilyName.focus();
        }, 250);
      } else {
        this.readonly = !editMode;
        this.productFamilyId = id;
        this.selected = {};
        this.fetchProductFamily();
      }
      this.slideouts.update.active = true;
    },
    clone(id) {
      this.readonly = false;
      this.cloneMode = true;
      this.productFamilyId = id;
      this.selected = {};
      this.fetchProductFamily();
      this.slideouts.update.active = true;
    },
    fetchProductFamily() {
      this.isFetching = true;
      this.$log("fetchProductFamily() => ", this.productFamilyId);
      productFamiliesService
        .getById(this.productFamilyId)
        .then((resp) => {
          this.isFetching = false;
          this.slideouts.update.isLoading = false;
          this.selected = this.cloneDeep(resp.data);
          if (this.cloneMode) this.selected.id = null;
          this.$log("fetchProductFamily()", this.cloneDeep(resp.data), "clone?", this.cloneMode);
          this.cementProductFamily();
        })
        .catch((err) => {
          this.closeUpdateSlideout();
          this.$dialog.notify.error(err.request.response, {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
    cementProductFamily() {
      if (this.cloneMode) this.selectedCemented = this.cloneDeep({});
      else this.selectedCemented = this.cloneDeep(this.selected);
      this.checkForChanges();
    },
    checkForChanges() {
      if (!this.slideouts.update.active) return;
      this.slideouts.update.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
      if (this.slideouts.update.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
      if (this.slideouts.update.hasChanges) {
        this.$log("%c==>> DIFF", "color: red", this.deepDiff(this.selectedCemented, this.selected));
      }
    },
    discardChanges() {
      this.selected = this.cloneDeep(this.selectedCemented);
    },
    jumpToRequiredField() {
      if (
        this.findRequiredFieldInFrom(this.$refs.updateForm, () => {
          this.slideouts.update.tab = 0;
        })
      )
        return;

      this.$dialog.notify.info("All required fields are filled!", {
        position: "top-right",
        timeout: 3000,
      });
    },
    del() {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete "<b>${this.selected.name}</b>" from product families?`,
          title: `Delete a Product Family?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return productFamiliesService
                  .delete(this.selected.id)
                  .then((resp) => {
                    this.$emit("delete", this.selected.id);
                    this.closeUpdateSlideout();
                    this.$dialog.notify.success("Product Family deleted successfully!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((err) => {
                    this.$handleError(err);
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
  },
  watch: {
    selected: {
      handler() {
        this.checkForChanges();
      },
      deep: true,
    },
  },
};
</script>
<style scoped lang="scss">
.tree-wrapper {
  border: 1px dashed rgba($shades-black, 0.4);
}
</style>
