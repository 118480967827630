<template>
  <div class="entity-id">
    <v-tooltip :bottom="!tooltipOnTop" :top="tooltipOnTop" z-index="9999" v-if="!hideLabel">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <code :class="{ dark: dark }" @click="copyToClipboard" @click.middle="openLink"
            >#{{ label }}</code
          >
        </div>
      </template>
      <span>
        Click to copy <b>{{ title }}</b> link!
      </span>
    </v-tooltip>
    <v-tooltip :bottom="!tooltipOnTop" :top="tooltipOnTop" z-index="9999">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <code :class="{ dark: dark }" @click="openLink"
            ><i class="far fa-arrow-up-right-from-square"></i
          ></code>
        </div>
      </template>
      <span>
        Open <b>{{ title }}</b> in a new tab!
      </span>
    </v-tooltip>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" top color="secondary">
      {{ snackbarTextFull }}
      <template v-slot:action="{ attrs }">
        <v-btn color="info" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "entity-id",
  inheritAttrs: false,
  data() {
    return {
      snackbar: false,
      snackbarText: "Link is copied to clipboard!",
      snackbarTextAlt: "link is copied to clipboard!",
      snackbarTimeout: 2000,
    };
  },
  props: {
    label: {
      type: [Number, String],
      default: null,
    },
    path: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    tooltipOnTop: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    snackbarTextFull() {
      return this.title != null ? `${this.title} ${this.snackbarTextAlt}` : this.snackbarText;
    },
    fullURL() {
      var origin = location.origin;
      return `${origin}/${this.path}`;
    },
  },
  methods: {
    copyToClipboard() {
      this.$log("copyToClipboard() > this.snackbar", this.snackbar);
      this.snackbar = true;
      this.$log("copyToClipboard() >> this.snackbar", this.snackbar);
      let val = this.fullURL;
      navigator.clipboard.writeText(val);
    },
    openLink() {
      window.open(this.fullURL, "_blank");
    },
  },
};
</script>

<style lang="scss">
.entity-id {
  display: inline-flex;
  cursor: pointer;
  gap: 0.15rem;

  code {
    transition: all 0.15s ease-out;
    background: rgba($shades-black, 0.1) !important;
    color: rgba($shades-black, 0.87) !important;
    font-weight: 600 !important;
    letter-spacing: 0.5px;
    font-size: 12px;
    font-family: $body-font-family;

    &:hover {
      background: rgba($shades-black, 0.87) !important;
      color: #fff !important;
    }

    &.dark {
      background: rgba(#fff, 0.1) !important;
      color: rgba(#fff, 0.87) !important;

      &:hover {
        background: rgba(#fff, 0.87) !important;
        color: $shades-black !important;
      }
    }
  }
}
</style>
