import { HubConnectionBuilder, HttpTransportType, LogLevel } from "@microsoft/signalr";
const signalR = {
  install(Vue) {
    const connection = new HubConnectionBuilder()
      .withUrl(`${process.env.VUE_APP_API_BASEURL}hubs/notifications`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => localStorage.getItem("token").split(" ")[1],
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build();
    Vue.prototype.$connection = connection;
    async function start() {
      try {
        await Vue.prototype.$connection.start();
        console.log("SignalR Connected.");
      } catch (err) {
        console.log(err);
        setTimeout(start, 5000);
      }
    }

    Vue.prototype.$connection.onclose(async () => {
      await start();
    });
    connection.onclose(async () => {
      await start();
    });

    // Start the connection.
    start();
  },
};
export default signalR;
