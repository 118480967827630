<template>
  <div
    class="single-image-uploader-container"
    :class="fullHeight ? 'full-height-uploader' : ''"
    @paste="onPaste"
  >
    <div style="display: flex; align-items: center; justify-content: space-between">
      <label
        class="siu-label"
        v-if="label != null && label != ''"
        v-html="label + requiredIcon"
        tabindex="1"
      ></label>
      <slot name="actions"></slot>
    </div>

    <v-text-field-alt
      v-if="required"
      v-model="activeImageUrl"
      readonly
      :rules="[allRules.required]"
      class="d-none"
    >
    </v-text-field-alt>
    <div class="single-image-uploader" v-if="id != null && apiUrl != null">
      <input
        type="file"
        :id="inputId"
        accept=".png, .jpg, .jpeg, .svg"
        hidden
        :ref="inputId"
        @change="onFileInputChange"
        :disabled="disabled"
      />
      <div
        class="siu-dropzone"
        @dragenter="onDragEnter"
        :class="uploadingError || requiredError ? 'uploading-error' : ''"
      >
        <div
          class="siu-drop-catcher"
          @dragleave="onDragLeave"
          @dragover.prevent
          @drop="onDrop"
          v-if="uploadAllowed && dropZoneIsOnDrag && !uploadingActive"
        >
          <i class="fad fa-inbox-in fa-3x mb-3"></i>
          <span>Drop Image Here</span>
        </div>
        <div class="siu-upload-wrapper">
          <div class="siu-empty-placeholder" v-if="emptyMode">
            <p class="siu-icon"><i class="fad fa-image fa-4x"></i></p>
            <p class="siu-main-info m-0" v-if="!disabled">
              Drop your image here, or
              <label :for="inputId" v-show="false" :id="'BrowseLabel_' + inputId"> browse </label>
              <v-btn
                small
                text
                color="info"
                elevation="0"
                @click="clickOnLabel('BrowseLabel_' + inputId)"
              >
                <span class="fs-12px"><i class="fad fa-arrow-to-top mr-2"></i>browse</span>
              </v-btn>
              <br />
              or
              <v-btn
                small
                text
                elevation="0"
                color="info"
                @click="copyImageFromClipboard"
                v-if="!disabled"
              >
                <span class="fs-12px"><i class="fad fa-paste mr-2"></i>Paste From Clipboard</span>
              </v-btn>
            </p>
            <p class="siu-main-info mb-0" v-else>No Image Available!</p>
            <p class="siu-support" v-if="!disabled">Supports: JPG, PNG, SVG</p>
          </div>
          <div class="siu-queue" v-if="uploadAllowed && uploadingMode">
            <div class="image-uploader">
              <v-badge
                overlap
                :color="uploadingError || requiredError ? 'error' : 'blue'"
                dark
                left
                :icon="
                  uploadingError || requiredError ? 'mdi-exclamation' : 'mdi-autorenew fa-spin'
                "
              >
                <img class="image-thumb" :src="fakeImageUrl" />
              </v-badge>
              <div class="image-info">
                <div class="image-name" v-text="imageToUploadName"></div>
                <div class="image-size" v-text="imageToUploadSize"></div>
                <div class="image-actions">
                  <v-btn icon elevation="0" v-if="uploadingError" @click="retry()" color="error">
                    <i class="far fa-redo"></i>
                  </v-btn>
                  <v-btn
                    icon
                    elevation="0"
                    v-if="!uploadingActive"
                    @click="deleteNewImage()"
                    color="error"
                  >
                    <i class="far fa-trash-alt"></i>
                  </v-btn>
                  <v-btn icon elevation="0" v-if="uploadingActive" @click="cancelUpload()">
                    <i class="far fa-times"></i>
                  </v-btn>
                </div>
              </div>
            </div>
            <div class="uploading-progress">
              <div class="bar" v-show="!uploadingError" :style="{ width: uploadingPCT }"></div>
              <span class="percentage" v-show="!uploadingError" v-text="uploadingPCT"></span>
            </div>
          </div>
          <div class="siu-result" v-if="imageIsValid && !uploadingMode && !emptyMode">
            <div class="image-viewer">
              <v-badge overlap color="green" dark left icon="far fa-check">
                <v-tooltip right z-index="999" nudge-right="-4px">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <div v-viewer>
                        <img class="image-thumb" :key="activeImageUrl" :src="activeImageUrl" />
                      </div>
                    </div>
                  </template>
                  <span>
                    <v-img :src="activeImageUrl" height="250px" width="250px" contain></v-img>
                  </span>
                </v-tooltip>
              </v-badge>
              <div class="image-actions">
                <v-btn small text elevation="0" @click="deleteValidImage()" v-if="!disabled">
                  <span class="fs-12px"><i class="fad fa-trash-alt mr-2"></i>Remove</span>
                </v-btn>
                <label
                  v-if="!disabled"
                  class="v-btn v-btn--text theme--light elevation-0 v-size--small"
                  :for="inputId"
                  v-show="false"
                  :id="'ChangeLabel_' + inputId"
                >
                  <span class="fs-12px"><i class="fad fa-arrow-to-top mr-2"></i>Change</span>
                </label>
                <v-btn
                  small
                  text
                  elevation="0"
                  @click="clickOnLabel('ChangeLabel_' + inputId)"
                  v-if="!disabled"
                >
                  <span class="fs-12px"><i class="fad fa-arrow-to-top mr-2"></i>Change</span>
                </v-btn>
                <v-btn small text elevation="0" @click="copyImageFromClipboard" v-if="!disabled">
                  <span class="fs-12px"><i class="fad fa-paste mr-2"></i>Paste From Clipboard</span>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/plugins/axios.js";
import Api from "../../Shared/services/api";
export default {
  name: "single-image-uploader",
  data() {
    return {
      imageToUpload: null,
      imageIsValid: false,
      cancellation: null,
      uploadingMode: false,
      uploadingActive: false,
      uploadingError: false,
      uploadingPCT: "0%",
      emptyMode: true,
      fakeImageUrl: null,
      activeImageUrl: null,
      dropZoneIsOnDrag: false,
    };
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    apiUrl: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    method: {
      type: String,
      default: "post",
    },
  },
  beforeDestroy() {
    // this.$log("@@@@@@@@@@@@@@@@@@@@@@@@@ beforeDestroy");
  },
  mounted() {
    // this.$log("mounted image uploader", this.value);
    if (this.value != null && this.value != "") {
      this.emptyMode = false;
      this.imageIsValid = true;
      this.activeImageUrl = this.value;
    }
  },
  computed: {
    requiredIcon() {
      return this.required ? " <i class='fas fa-star-of-life pink--text label-icon'></i>" : "";
    },
    requiredError() {
      return (
        this.required &&
        (this.activeImageUrl == "" || this.activeImageUrl == null) &&
        !this.uploadingActive
      );
    },
    inputId() {
      return "siu-input-" + this.id;
    },
    uploadAllowed() {
      if (!this.disabled) return true;
      return false;
    },
  },
  watch: {
    value: {
      handler() {
        // this.$log("watch image uploader", this.value);
        this.activeImageUrl = this.value;
        if (this.value != null && this.value != "") {
          this.emptyMode = false;
          this.imageIsValid = true;
        } else {
          this.emptyMode = true;
          this.imageIsValid = false;
          this.imageToUpload = null;
        }
      },
      deep: true,
    },
  },
  methods: {
    async copyImageFromClipboard() {
      try {
        const permission = await navigator.permissions.query({
          name: "clipboard-read",
        });
        if (permission.state === "denied") {
          throw new Error("Not allowed to read clipboard.");
        }
        const clipboardContents = await navigator.clipboard.read();
        for (const item of clipboardContents) {
          this.$log(item);
          if (!item.types.some((i) => i.includes("image/png"))) {
            // throw new Error("Clipboard contains non-image data.");
            continue;
          }
          // Get the blob of image
          // const blob = item.getAsFile();
          const blob = await item.getType("image/png");
          this.pastedFromClipboard(blob);
          // this.$log(URL.createObjectURL(blob));
        }
      } catch (error) {
        console.error(error.message);
      }
    },
    clickOnLabel(labelId) {
      document.getElementById(labelId).click();
    },
    onPaste(evt) {
      this.$log(`Handle the paste event`, evt.clipboardData);

      // Get the data of clipboard
      const clipboardItems = evt.clipboardData.items;
      const items = [].slice.call(clipboardItems).filter(function (item) {
        // Filter the image items only
        return item.type.indexOf("image") !== -1;
      });
      if (items.length === 0) {
        return;
      }

      const item = items[0];
      // Get the blob of image
      const blob = item.getAsFile();
      this.pastedFromClipboard(blob);
    },
    pastedFromClipboard(imageBlob) {
      if (this.disabled || this.uploadingActive) return;
      this.processNewImage(imageBlob);
    },
    onDragEnter(event) {
      if (this.uploadingActive) return;
      this.dropZoneIsOnDrag = true;
    },
    onDragLeave(event) {
      if (this.uploadingActive) return;
      this.dropZoneIsOnDrag = false;
    },
    onDrop(event) {
      if (this.uploadingActive) return;
      this.dropZoneIsOnDrag = false;
      if (this.uploadingActive) {
        return; //don't allow adding new files if files already exist
      }
      event.preventDefault();
      event.stopPropagation();
      var images = event.target.files || event.dataTransfer.files;
      this.$log("droped:", images);
      if (images.length) {
        this.processDroppedImages(images);
      }
    },
    processDroppedImages(images) {
      this.processNewImage(images[0]);
    },
    cancelUpload() {
      this.cancellation.source.cancel(`Uploading was cancelled!`);
    },
    deleteValidImage() {
      this.emptyMode = true;

      this.uploadingMode = false;
      this.uploadingActive = false;
      this.uploadingError = false;

      this.imageToUpload = null;
      this.fakeImageUrl = null;

      this.imageIsValid = false;
      this.activeImageUrl = null;
      this.annouceImageUpdate();
    },
    annouceImageUpdate() {
      this.$emit("input", this.activeImageUrl);
    },
    deleteNewImage() {
      this._deleteNewRestoreOld();
    },
    _deleteNewRestoreOld() {
      if (!this.imageIsValid || this.activeImageUrl == null) {
        this.deleteValidImage();
      } else {
        this.emptyMode = false;

        this.uploadingMode = false;
        this.uploadingActive = false;
        this.uploadingError = false;

        this.imageToUpload = null;
        this.fakeImageUrl = null;
      }
    },
    retry() {
      this.upload();
    },
    humanFileSize(bytes, si = false) {
      var thresh = si ? 1000 : 1024;
      if (Math.abs(bytes) < thresh) {
        return bytes + " B";
      }
      var units = si
        ? ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"]
        : ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      var u = -1;
      do {
        bytes /= thresh;
        ++u;
      } while (Math.abs(bytes) >= thresh && u < units.length - 1);
      return bytes.toFixed(1) + " " + units[u];
    },
    acceptableImageFile(imageFile) {
      if (
        imageFile.type == "image/jpeg" ||
        imageFile.type == "image/svg+xml" ||
        imageFile.type == "image/png"
      ) {
        return true;
      } else return false;
    },
    onFileInputChange(event) {
      this.$log(">> imageToUpload", this.imageToUpload);
      if (this.imageToUpload) return; //don't allow adding new images if there's already one

      var images = Array.from(event.target.files);
      this.$refs[this.inputId].value = null;
      this.$log("onChange", event, images);
      this.processNewImage(images[0]);
    },
    processNewImage(image) {
      if (this.acceptableImageFile(image)) {
        this.$log("acceptableImageFile", image);
        this.imageToUpload = image;
        this.imageToUploadName = this.imageToUpload.name;
        this.imageToUploadSize = this.humanFileSize(this.imageToUpload.size);
        this.fakeImageUrl = URL.createObjectURL(this.imageToUpload);
        const _cancelToken = axios.CancelToken;
        this.cancellation = {
          cancelToken: _cancelToken,
          source: _cancelToken.source(),
        };
        this.upload();
      }
    },
    upload() {
      this.$log("upload()", this.imageToUpload.name);
      var formData = new FormData();
      formData.append("image", this.imageToUpload, this.imageToUpload.name);
      formData.append("cancellation", this.cancellation.source.token);
      this.emptyMode = false;
      this.uploadingMode = true;
      this.uploadingActive = true;
      this.uploadingError = false;
      this.uploadingPCT = "0%";
      this.uploadImage(formData, {
        cancelToken: this.cancellation.source.token,
        onUploadProgress: function (progressEvent) {
          this.uploadingPCT = Math.floor((progressEvent.loaded * 100) / progressEvent.total) + "%";
          this.$log(`${this.imageToUpload.name} => ${this.uploadingPCT}`);
        }.bind(this),
      })
        .then(
          function (response) {
            this.$log("Uploading Image success", response, response.data);
            this.emptyMode = false;

            this.uploadingMode = false;
            this.uploadingActive = false;
            this.uploadingError = false;

            this.imageToUpload = null;
            this.fakeImageUrl = null;

            this.imageIsValid = true;
            this.activeImageUrl = response.data.url;
            this.annouceImageUpdate();
          }.bind(this)
        )
        .catch(
          function (error) {
            this.$log("Uploading Image Error:", error);

            if (axios.isCancel(error)) {
              this.$log("Request canceled", error.message);
              this.deleteNewImage();
            } else {
              //handle error
              this.uploadingError = true;
              this.uploadingActive = false;
            }
          }.bind(this)
        );
    },
    uploadImage(imageData, config) {
      return Api()[this.method](this.apiUrl, imageData, config);
    },
  },
};
</script>

<style lang="scss">
.v-badge__badge .v-icon {
  font-size: 14px !important;
}
.siu-label {
  font-size: 15px !important;
  font-weight: 600 !important;
}
.single-image-uploader-container {
  .single-image-uploader {
    margin-top: 4px !important;
    display: flex;
    width: 100%;
    flex-direction: column;

    .siu-dropzone {
      position: relative;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='rgba(42, 54, 59, 0.4)' stroke-width='3' stroke-dasharray='6%2c 6' stroke-dashoffset='4' stroke-linecap='butt'/%3e%3c/svg%3e");
      border-radius: 8px;
      padding: 1rem 1rem;
      padding-bottom: calc(1rem + 1px);
      text-align: center;
      // background-color: rgba($shades-black, 0.04);

      &.uploading-error {
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='rgb(229, 76, 60, 1)' stroke-width='3' stroke-dasharray='6%2c 6' stroke-dashoffset='4' stroke-linecap='butt'/%3e%3c/svg%3e");
        background-color: var(--v-error-lighten5);
      }

      .siu-empty-placeholder {
        .siu-icon {
          color: rgba($shades-black, 0.87);
          margin-bottom: 0.25rem;
        }

        .siu-main-info {
          color: rgba($shades-black, 0.87);
          font-weight: 600;
          font-size: 14px;
          margin: 0;

          label {
            color: var(--v-info-base);
            font-weight: 700;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }

            &[disabled="disabled"] {
              color: var(--v-secondary-base);
              cursor: default;
              text-decoration: none !important;
            }
          }
        }
        .siu-support {
          color: rgba($shades-black, 0.4);
          font-weight: 700;
          font-size: 12px;
          margin: 0;
          margin-top: 0.25rem;
        }
      }

      .siu-drop-catcher {
        content: "Drop here";
        display: flex;
        -ms-flex-direction: column;
        -webkit-flex-direction: column;
        flex-direction: column;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-align-items: center;
        align-items: center;
        color: #2196f3;
        font-size: 14px;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 4;
        background-color: rgba($shades-black, 0.04);
        -webkit-backdrop-filter: saturate(180%) blur(20px);
        backdrop-filter: saturate(180%) blur(20px);
        overflow: hidden;
        opacity: 1;
        transition: all 0.3s ease-out;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='rgba(33, 150, 243, 1)' stroke-width='4' stroke-dasharray='6%2c 6' stroke-dashoffset='4' stroke-linecap='butt'/%3e%3c/svg%3e");
        border-radius: 8px;

        span {
          font-weight: 500;
        }

        * {
          user-select: none !important;
          pointer-events: none !important;
        }
      }
    }

    .siu-queue {
      position: relative;
      border-radius: 0.5rem;

      .image-uploader {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5rem;

        .image-info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          width: calc(100% - 100px - 0.5rem);

          .image-name {
            font-weight: 700;
            font-size: 16px;
            font-size: 16px;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            text-align: left;
          }
          .image-size {
            font-weight: 600;
            font-size: 12px;
            opacity: 0.54;
          }
        }

        .image-thumb {
          width: 100px;
          height: 100px;
          object-fit: contain;
          padding: 0.5rem;
        }

        .image-actions {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-top: 0.5rem;
        }
      }

      .uploading-progress {
        display: flex;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 1;
        pointer-events: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-user-select: none;
        user-select: none;
        border-radius: 0.5rem;
        overflow: hidden;

        .bar {
          position: absolute;
          height: 100%;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background: rgba($shades-black, 0.08);
        }

        .percentage {
          position: absolute;
          bottom: 0.5rem;
          right: 0.5rem;
          font-size: 32px;
          font-weight: 700;
          color: rgba($shades-black, 0.16);
        }
      }
    }

    .siu-result {
      position: relative;

      .image-viewer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5rem;

        .image-thumb {
          width: 100px;
          height: 100px;
          object-fit: contain;
          padding: 0.5rem;
          cursor: pointer !important;
        }
      }

      .image-actions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 0.5rem;

        label {
          cursor: pointer;
        }
      }
    }
  }

  &.full-height-uploader {
    height: 100%;
    display: flex;
    flex-direction: column;

    .single-image-uploader {
      height: 100%;
      flex: 1 1 auto;
      .siu-dropzone {
        height: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        display: flex;

        .siu-upload-wrapper {
          width: 100%;
        }
      }
    }
  }
}
</style>
