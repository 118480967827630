import perms from "../../plugins/permissions";
export default [
  {
    path: "/big-board",
    name: "big-board",
    // TODO: update this line when permissions updated
    meta: { layout: "dashboard", title: "Big Board", permissions: [perms.BigBoard.View] },
    component: () => import("./views/BigBoard.vue"),
  },
  {
    path: "/my-board",
    name: "my-board",
    // TODO: update this line when permissions updated
    meta: { layout: "dashboard", title: "My Board", permissions: [perms.MyBoard.View] },
    component: () => import("./views/MyBoard.vue"),
  },
];
