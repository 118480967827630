import perms from "../../plugins/permissions";
export default [
  {
    path: "/leads",
    name: "leads",
    meta: { layout: "dashboard", title: "Leads", permissions: [perms.Leads.View] },
    component: () => import("./views/Leads.vue"),
  },
  {
    path: "/leads/:id",
    name: "lead",
    meta: { layout: "dashboard", permissions: [perms.Leads.View] },
    component: () => import("./views/Leads.vue"),
  },
];
