import perms from "../../plugins/permissions";
export default [
  {
    path: "/equipment",
    name: "equipment",
    meta: { layout: "dashboard", title: "Equipment List", permissions: [perms.Equipments.View] },
    component: () => import("./views/EquipmentListView.vue"),
  },
  {
    path: "/restore-equipment",
    name: "restore-equipment",
    meta: {
      layout: "dashboard",
      title: "Restore Equipment",
      permissions: [perms.Equipments.Restore],
    },
    component: () => import("./views/EquipmentsRestore.vue"),
  },
  {
    path: "/equipment/:id",
    name: "single-equipment",
    meta: { layout: "dashboard", permissions: [perms.Equipments.View] },
    component: () => import("./views/EquipmentListView.vue"),
  },
  {
    path: "/equip-groups",
    name: "equipment-groups",
    meta: { layout: "dashboard", title: "Equipment Groups", permissions: [perms.Equipments.View] },
    component: () => import("./views/EquipmentGroupsView.vue"),
  },

  {
    path: "/equipment-categories",
    name: "equipment-categories",
    meta: {
      layout: "dashboard",
      title: "Category List",
      permissions: [perms.EquipmentCategories.View],
    },
    component: () => import("./views/EquipmentCategories.vue"),
  },
  {
    path: "/equipment-categories/:id",
    name: "single-equipment-category",
    meta: { layout: "dashboard", permissions: [perms.EquipmentCategories.View] },
    component: () => import("./views/EquipmentCategories.vue"),
  },
];
