import perms from "../../plugins/permissions";
export default [
  {
    path: "/monthly-plans",
    name: "monthly-plans",
    // TODO: update this line when permissions updated
    meta: { layout: "dashboard", title: "Monthly Plans", permissions: [perms.DNAUsers.View] },
    component: () => import("./views/MonthlyPlans.vue"),
  },
];
