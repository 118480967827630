export default [
  {
    text: "Open",
    value: "actions",
    width: "64px",
    align: "left",
    sortable: false,
  },
  { text: "Image", value: "name", width: "90px", sortable: false },
  { text: "Category", value: "category.name" },
  { text: "Tag", value: "tag" },
  { text: "Maker", value: "make" },
  { text: "Model", value: "model" },
  { text: "Provider", value: "providerId", width: "100px" },
  { text: "Installer", value: "installerId", width: "100px" },
];
