import perms from "../../../plugins/permissions";
export default [
  {
    path: "/external-roles",
    name: "externalRoles",
    meta: { layout: "dashboard", title: "External Roles", permissions: [perms.ExternalRoles.View] },
    component: () => import("./views/ExternalRoles.vue"),
  },
  {
    path: "/external-roles/:id",
    name: "single-external-role",
    meta: { layout: "dashboard", permissions: [perms.ExternalRoles.View] },
    component: () => import("./views/ExternalRoles.vue"),
  },
];
