<template>
  <div class="mb-0 page-title">
    <h2 class="d-flex align-center font-weight-black">
      <span class="text--primary" v-html="title"></span>
      <v-chip v-if="this.badge != null" class="ml-2 font-weight-bold" small label>{{
        badge
      }}</v-chip>
    </h2>
    <!-- <p
      v-if="this.subtitle != null"
      v-text="this.subtitle"
      class="mb-0 text--secondary font-weight-medium"
    ></p> -->
  </div>
</template>

<script>
export default {
  name: "PageTitle",
  props: {
    title: {
      title: String,
      default: "",
    },
    subtitle: {
      title: String,
      default: null,
    },
    badge: {
      type: Number,
      default: null,
    },
  },
};
</script>

<style lang="scss"></style>
