import perms from "../../plugins/permissions";
export default [
  {
    path: "/offers",
    name: "offers",
    meta: { layout: "dashboard", title: "My Offers", permissions: [perms.ResourceOffers.View] },
    component: () => import("./views/MyOffers.vue"),
  },
  {
    path: "/offers/:id",
    name: "single-offer",
    meta: { layout: "dashboard", title: "Offers", permissions: [perms.ResourceOffers.View] },
    component: () => import("./views/MyOffers.vue"),
  },
  {
    path: "/manage-offers",
    name: "manage-offers",
    meta: {
      layout: "dashboard",
      title: "Manage Offers",
      permissions: [perms.ResourceOffers.Manage],
    },
    component: () => import("./views/ManageOffers.vue"),
  },
  {
    path: "/manage-offers/:id",
    name: "manage-single-offer",
    meta: {
      layout: "dashboard",
      title: "Manage Offers",
      permissions: [perms.ResourceOffers.Manage],
    },
    component: () => import("./views/ManageOffers.vue"),
  },
];
