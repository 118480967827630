<template>
<v-footer app :padless="true">
 <v-card
        flat
        tile
        width="100%"
        class=" lighten-1 text-center"
        
      >

        <v-divider></v-divider>
        <v-card-text class="" style="padding:.2rem 0;">
         ©  {{ new Date().getFullYear() }} — <strong>All rights reserved </strong>
        </v-card-text>
      </v-card>
  </v-footer>

</template>

<script>
export default {

}
</script>

<style>

</style>