import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import axios from "../plugins/axios";
import shared from "./modules/sharedModules";

export default new Vuex.Store({
  state: {
    miniSideMenu: localStorage.getItem("miniSideMenu") == "true",
    showProjectOverview: localStorage.getItem("showProjectOverview") == "true",
    isBlocked: false,
    blockersCount: 0,
    user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
    personalSettings: localStorage.getItem("personalSettings")
      ? JSON.parse(localStorage.getItem("personalSettings"))
      : {},
    token: localStorage.getItem("token") || "",
  },
  getters: {
    isBlocked: (state) => state.isBlocked,
    isAuthenticated: (state) => !!state.token,
    user(state) {
      return state.user;
    },
    miniSideMenu(state) {
      return state.miniSideMenu;
    },
    showProjectOverview(state) {
      return state.showProjectOverview;
    },
    personalSettings(state) {
      return state.personalSettings;
    },
  },
  mutations: {
    setUser(state, resp) {
      state.user = resp.data.user;
      localStorage.setItem("token", `Bearer ${resp.data.token}`);
      localStorage.setItem("user", JSON.stringify(resp.data.user));
      // let permissions = [];
      // resp.data.user.permissions.forEach((element) => {
      //   element.permissions
      //     .forEach((element) => {
      //       permissions.push(element.nameEn);
      //     });
      // });
      // localStorage.setItem('permissions', JSON.stringify(permissions));
    },
    setPersonalSettings(state, personalSettings) {
      state.personalSettings = personalSettings;
      localStorage.setItem("personalSettings", JSON.stringify(personalSettings));
    },
    getPersonalSettings(state) {
      state.personalSettings = localStorage.getItem("personalSettings")
        ? JSON.parse(localStorage.getItem("personalSettings"))
        : {};
    },
    setMiniSideMenu(state, miniSideMenu) {
      state.miniSideMenu = miniSideMenu;
      localStorage.setItem("miniSideMenu", miniSideMenu.toString());
    },
    updateMiniSideMenuFromStorage(state) {
      state.miniSideMenu = localStorage.getItem("miniSideMenu") == "true";
    },
    setShowProjectOverview(state, showProjectOverview) {
      state.showProjectOverview = showProjectOverview;
      localStorage.setItem("showProjectOverview", showProjectOverview.toString());
    },
    updateShowProjectOverviewFromStorage(state) {
      state.showProjectOverview = localStorage.getItem("showProjectOverview") == "true";
    },
    logOut(state) {
      state.user = null;
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("permissions");
      localStorage.removeItem("personalSettings");
    },
    blockRouter(state) {
      state.isBlocked = true;
    },
    unblockRouter(state) {
      state.isBlocked = false;
    },
    updateRole(state, newRole) {
      const current = state.user.roles[0];
      if (current && current.id == newRole.id) {
        window.location.reload();
        return;
        state.user.roles.splice(0, 1, newRole);
        localStorage.setItem("user", JSON.stringify(state.user));
      }
    },
  },
  actions: {
    async login({ commit }, user) {
      await axios.post("account/login", user).then((resp) => {
        commit("setUser", resp);
      });
    },
    setUser({ commit }, user) {
      commit("setUser", user);
    },
    setPersonalSettings({ commit }, personalSettings) {
      commit("setPersonalSettings", personalSettings);
    },
    getPersonalSettings({ commit }) {
      commit("getPersonalSettings");
    },
    setMiniSideMenu({ commit }, miniSideMenu) {
      commit("setMiniSideMenu", miniSideMenu);
    },
    refreshMiniSideMenu({ commit }) {
      commit("updateMiniSideMenuFromStorage");
    },
    setShowProjectOverview({ commit }, showProjectOverview) {
      commit("setShowProjectOverview", showProjectOverview);
    },
    refreshShowProjectOverview({ commit }) {
      commit("updateShowProjectOverviewFromStorage");
    },
    logOut({ commit }) {
      commit("logOut");
    },
    blockRouter({ commit }) {
      commit("blockRouter");
    },
    unblockRouter({ commit }) {
      commit("unblockRouter");
    },
  },
  modules: { shared },
});
