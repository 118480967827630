<template>
  <div class="v-combobox-wrap" :class="{ 'details-hidden': hideDetails }">
    <label for="equipmentHashtags">Hashtags </label>
    <v-combobox
      :rules="required ? [allRules.required] : []"
      :value="value"
      @input="emitHashtag"
      :search-input.sync="options.search"
      dense
      filled
      no-filter
      :hide-details="hideDetails"
      item-text="name"
      item-value="id"
      :loading="isHashtagsLoading"
      :readonly="readonly"
      clearable
      id="equipmentHashtags"
      :persistent-placeholder="true"
      placeholder="Equipment Hashtags"
      append-icon="fas fa-caret-down"
      deletable-chips
      :items="hashtags"
      return-object
      multiple
      chips
      solo
      flat
      hide-selected
      class="mt-1"
    >
      <template v-slot:selection="{ attrs, item, parent, selected }">
        <v-chip v-bind="attrs" color="secondary" :input-value="selected" label small>
          <span :class="!readonly ? 'pr-2' : ''">
            {{ item.name || item }}
          </span>
          <v-icon v-if="!readonly" small @click="parent.selectItem(item)"> $delete </v-icon>
        </v-chip>
      </template>
      <template v-slot:item="{ item }">
        <div class="d-flex justify-space-between align-center" style="width: 100%">
          <span>{{ item.name }}</span>
          <v-tooltip top nudge-top="-5">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <span>
                  <span class="mono-font">{{ item.hashtagUsageCount }}</span>
                  <i class="fad fa-stopwatch ml-2"></i>
                </span>
              </div>
            </template>
            <span>Usage Count</span>
          </v-tooltip>
        </div>
      </template>
      <template #append-item>
        <div v-intersect="loadMore" />
      </template>
      <template #prepend-item>
        <div
          class="d-inline-flex justify-end align-center elevation-1 white mr-6"
          style="
            position: sticky;
            top: 0px;
            z-index: 10;
            padding: 0.5rem;
            margin-top: -0.5rem;
            border-radius: 0 0 0.5rem 0;
          "
        >
          <span
            style="font-weight: 600; font-size: 12px; margin-right: 0.5rem; margin-left: 0.5rem"
          >
            Sort By
          </span>
          <v-btn-toggle mandatory color="info" v-model="isPopular" dense>
            <v-btn :value="true" small>
              <i class="fas fa-arrow-trend-up mr-2"></i>
              <span>Popular</span>
            </v-btn>
            <v-btn :value="false" small>
              <i class="fas fa-clock mr-2"></i>
              <span>Recent</span>
            </v-btn>
          </v-btn-toggle>
        </div>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import hashtagsAPI from "@Equipments/services/hashtags-service";
export default {
  props: {
    value: {
      type: [Number, String, Array],
    },
    obj: {
      type: Array,
    },
    required: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hashtags: [],
      isHashtagsLoading: false,
      hashtagsTimerId: null,
      total: 0,
      mainSearchInFocus: false,
      isPopular: false,
      hashtagsReplace: false,
      options: {
        search: null,
        page: 1,
        itemsPerPage: 50,
        isPopular: false,
      },
      toSendOptions: {
        search: null,
        page: 1,
        itemsPerPage: 50,
        isPopular: false,
      },
    };
  },
  methods: {
    emitHashtag(id) {
      this.$log("emitHashtag", id);
      this.$emit("input", id);
      this.$emit("update:obj", id ? this.hashtags.find((elm) => elm.id == id) : null);
    },
    getData() {
      this.isHashtagsLoading = true;
      this.toSendOptions = this.cloneDeep(this.options);
      hashtagsAPI
        .recentPopular({
          ...this.options,
          itemsPerPage: this.options.search && this.total ? this.total : this.options.itemsPerPage,
          search: this.options.search || "",
        })
        .then((resp) => {
          this.$log(">>> Hastag Selector recentPopular: ", resp.data.items, resp.data.total);
          const isSearch = !!this.options.search;
          this.$log("hashtagsReplace: ", this.hashtagsReplace);
          if (this.hashtagsReplace) {
            this.hashtags = [];
            this.hashtagsReplace = false;
          }
          resp.data.items.forEach((ht) => {
            this.hashtags.push(ht);
          });
          // this.addUniqueArr(this.hashtags, resp.data.items, "id", isSearch);
          // if (!isSearch) this.total = resp.data.total;
          this.total = resp.data.total;
          this.refreshAutocomplete();
          this.isHashtagsLoading = false;
        })
        .catch(() => {
          this.isHashtagsLoading = false;
        });
    },
    hashtagsFilter(item, queryText, itemText) {
      const textOne = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    getDataDebounced() {
      if (this.hashtagsTimerId == null) {
        this.hashtagsTimerId = -1;
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.hashtagsTimerId);
      // delay new call 400ms
      this.hashtagsTimerId = setTimeout(() => {
        this.getData();
      }, 500);
    },
    refreshAutocomplete() {
      if (this.value && (!this.obj || this.value != this.obj.id)) {
        const index = this.hashtags.findIndex((elm) => elm.id == this.value);
        if (index == -1) {
          this.fetchObj();
        }
      } else {
        if (
          this.obj &&
          (this.hashtags.findIndex((elm) => elm.id == this.obj.id) == -1 ||
            this.value != this.obj.id)
        ) {
          this.addAndEmit(this.obj);
        }
      }
    },
    addAndEmit(item) {
      this.addUniqueArr(this.hashtags, [item], "id", true);
      this.emitHashtag(item.id);
    },
    loadMore(undefine, _, isIntersecting) {
      if (isIntersecting && this.total > this.hashtags.length && !this.isHashtagsLoading) {
        // this line to loadMore if there is no search
        if (!this.options.search) this.options.page++;
        // this line to clear search only if there is item selected
        // if (this.value) this.options.search = "";
      }
    },
    fetchObj() {
      hashtagsAPI.getHashtagById(this.value).then((res) => {
        this.addAndEmit(res.data);
      });
    },
  },
  created() {
    this.getDataDebounced();
  },
  watch: {
    options: {
      handler() {
        this.$log(
          "this.options.search: ",
          this.options.search,
          "this.toSendOptions.search: ",
          this.toSendOptions.search
        );
        this.$log(
          "this.options.isPopular: ",
          this.options.isPopular,
          "this.toSendOptions.isPopular: ",
          this.toSendOptions.isPopular
        );
        this.$log("options: ", this.cloneDeep(this.options));
        if (
          this.options.search != this.toSendOptions.search ||
          this.options.isPopular != this.toSendOptions.isPopular
        ) {
          this.hashtagsReplace = true;
        }
        this.getDataDebounced();
      },
      deep: true,
    },
    obj() {
      this.refreshAutocomplete();
    },
    isPopular(val) {
      this.$log("isPopular: ", this.isPopular, "val: ", val);
      this.$log("options: ", this.cloneDeep(this.options));
      this.options = {
        search: null,
        page: 1,
        itemsPerPage: 50,
        isPopular: val,
      };
    },
  },
};
</script>
