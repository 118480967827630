<template>
  <slideout
    dock="right"
    :size="nested ? '87%' : '1200px'"
    :min-size="nested ? 400 : 1200"
    :allow-resize="nested ? false : true"
    :append-to="nested ? '' : '#app'"
    :visible.sync="slideouts.stats.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 class="font-weight-bold pa-1" small>
        <span>
          <i class="fad fa-pen-square mr-2"></i>Equipment Stats{{
            equipment && equipment.description ? `: '${equipment.description}'` : null
          }}</span
        >
      </h3>
      <v-btn @click="slideouts.stats.active = false" icon><i class="far fa-times"></i></v-btn>
    </template>

    <div color="white" style="position: sticky; top: 0; z-index: 1">
      <v-tabs v-model="slideouts.tab" color="error">
        <v-tab :key="0">
          Projects
          <v-chip
            :color="slideouts.tab == 0 ? 'error' : 'grey lighten-4'"
            class="ml-2 font-weight-bold"
            small
            label
          >
            {{ projectCount }}
          </v-chip>
        </v-tab>
        <v-tab :key="1">
          Buildings
          <v-chip
            :color="slideouts.tab == 1 ? 'error' : 'grey lighten-4'"
            class="ml-2 font-weight-bold"
            small
            label
          >
            {{ buildingCount }}
          </v-chip>
        </v-tab>
        <v-tab :key="2">
          Spaces
          <v-chip
            :color="slideouts.tab == 2 ? 'error' : 'grey lighten-4'"
            class="ml-2 font-weight-bold"
            small
            label
          >
            {{ spacesCount }}
          </v-chip>
        </v-tab>
        <v-tab :key="3">
          Space Templates
          <v-chip
            :color="slideouts.tab == 3 ? 'error' : 'grey lighten-4'"
            class="ml-2 font-weight-bold"
            small
            label
          >
            {{ spaceTemplatesCount }}
          </v-chip>
        </v-tab>
        <v-tab :key="4">
          Accessories
          <v-chip
            :color="slideouts.tab == 4 ? 'error' : 'grey lighten-4'"
            class="ml-2 font-weight-bold"
            small
            label
          >
            {{ accessoriesCount }}
          </v-chip>
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>
    </div>
    <v-tabs-items v-model="slideouts.tab" class="equipment-stats-tabs">
      <v-tab-item :key="0">
        <equipment-has-projects
          :equipment="equipment"
          :active="slideouts.stats.active"
          :storageKey="storageKey"
        ></equipment-has-projects>
      </v-tab-item>
      <v-tab-item :key="1">
        <equipment-has-buildings
          :equipment="equipment"
          :active="slideouts.stats.active"
          :storageKey="storageKey"
        ></equipment-has-buildings>
      </v-tab-item>
      <v-tab-item :key="2">
        <equipment-has-spaces
          :equipment="equipment"
          :active="slideouts.stats.active"
          :storageKey="storageKey"
        ></equipment-has-spaces>
      </v-tab-item>
      <v-tab-item :key="3">
        <equipment-has-space-template
          :equipment="equipment"
          :active="slideouts.stats.active"
          :storageKey="storageKey"
        ></equipment-has-space-template>
      </v-tab-item>
      <v-tab-item :key="4">
        <equipment-has-accessories
          :equipment="equipment"
          :active="slideouts.stats.active"
          :storageKey="storageKey"
        ></equipment-has-accessories>
      </v-tab-item>
    </v-tabs-items>

    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="slideouts.stats.active = false">
          <i class="fal fa-times mr-2"></i>
          Close
        </v-btn>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import equipmentService from "../services/equipments-service";
import EquipmentHasAccessories from "./EquipmentHasAccessories.vue";
import EquipmentHasBuildings from "./EquipmentHasBuildings.vue";
import EquipmentHasProjects from "./EquipmentHasProjects.vue";
import EquipmentHasSpaces from "./EquipmentHasSpaces.vue";
import EquipmentHasSpaceTemplate from "./EquipmentHasSpaceTemplate.vue";

export default {
  components: {
    EquipmentHasSpaces,
    EquipmentHasProjects,
    EquipmentHasBuildings,
    EquipmentHasSpaceTemplate,
    EquipmentHasAccessories,
  },
  name: "equipment-stats",
  data() {
    return {
      storageKey: "EquipmentStats",
      equipment: null,
      projectCount: 0,
      buildingCount: 0,
      spacesCount: 0,
      spaceTemplatesCount: 0,
      accessoriesCount: 0,
      slideouts: {
        tab: 1,
        stats: {
          active: false,
          isLoading: false,
        },
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onSlideoutClosing(e) {
      this.$log("onSlideoutClosing, active:", this.slideouts.stats.active);
      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    getEquipmentStats() {
      if (this.equipment) {
        equipmentService.getEquipmentStats(this.equipment.id).then((resp) => {
          this.projectCount = resp.data.projectsCount;
          this.buildingCount = resp.data.buildingsCount;
          this.spacesCount = resp.data.spacesCount;
          this.spaceTemplatesCount = resp.data.spaceTemplatesCount;
          this.accessoriesCount = resp.data.accessoriesCount;
        });
      }
    },
    open(equipment) {
      this.equipment = equipment;
      this.getEquipmentStats();
      this.slideouts.stats.active = true;
      this.slideouts.tab = 0;
    },
  },
};
</script>

<style lang="scss">
.v-list-item__title {
  font-size: 14px !important;
}
.equipment-stats-tabs.v-window.v-item-group.v-tabs-items {
  overflow: auto;
}
</style>
