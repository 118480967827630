<template>
  <v-dialog v-model="active" width="575" retain-focus content-class="quick-tools-dialog">
    <v-card rounded="lg">
      <v-card-title
        class="font-weight-medium white--text grey darken-4"
        style="font-size: 16px; padding: 12px 16px 8px 20px; padding: 8px 8px 6px 18px"
      >
        <span class="d-flex align-center"
          ><i class="fad fa-temperature-low mr-3 fs-20px"></i>BTU Calculator</span
        >
        <v-spacer></v-spacer>
        <v-btn dark @click="close" icon>
          <i class="far fa-times"></i>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="secondary--text">
        <v-row class="my-0 mt-4">
          <v-col cols="12" md="4" class="py-0 d-flex align-end">
            <v-text-field-alt
              :rules="[allRules.requiredNumber, allRules.number]"
              label="Watts"
              ref="toolWatts"
              placeholder="Power Consumption"
              v-model.number="selected.watt"
              @input="wattChanged"
              hide-details
            >
            </v-text-field-alt>
            <i class="fad fa-right-left" style="bottom: 12px; left: 12px"></i>
          </v-col>
          <v-col cols="12" md="4" class="py-0 d-flex align-end">
            <v-text-field-alt
              label="HeatLoad (BTU/Hr)"
              placeholder="BTU/Hr"
              v-model.number="selected.heatload"
              hide-details
              @input="heatloadChanged"
            >
            </v-text-field-alt>
            <i class="fad fa-right-left" style="bottom: 12px; left: 12px"></i>
          </v-col>
          <v-col cols="12" md="4" class="py-0">
            <v-text-field-alt
              label="HVAC (Tonnage)"
              placeholder="Tonnage"
              v-model.number="selected.hvacTonnage"
              hide-details
              @input="hvacChanged"
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12" md="12" class="mt-2">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" md="12" class="py-0">
            <h4>Reference:</h4>
            <ul class="mt-1">
              <li>1 Watt = 3.412 BTU/hr</li>
              <li>1 HVAC Tonnage = 12,000 BTU/hr</li>
            </ul>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      active: false,
      constants: { wattToBTU: 3.412141633 },
      selected: {
        watt: 100,
        heatload: 341.21,
        hvacTonnage: 0.02843,
      },
    };
  },
  props: {},
  mounted() {},
  computed: {},
  methods: {
    open() {
      this.active = true;
      setTimeout(() => {
        this.$refs.toolWatts.select();
      }, 300);
    },
    close() {
      this.active = false;
    },
    isNum(value) {
      return /^[0-9]+.[0-9]+|[0-9]+$/.test(value);
    },
    wattChanged() {
      if (this.isNum(this.selected.watt)) {
        this.refreshHeatAndHvac();
      }
    },
    refreshHeatAndHvac() {
      if (this.selected == null || !this.isNum(this.selected.watt)) return;

      //Heat Load = POWER CONSUMPTION * 3.412
      //HVAC Tonnage = Heat Load / 12000

      let powerConsumption = parseFloat(this.selected.watt);
      this.selected.heatload = parseFloat((powerConsumption * this.constants.wattToBTU).toFixed(2));
      this.selected.hvacTonnage = parseFloat((this.selected.heatload / 12000).toFixed(5));
    },
    heatloadChanged() {
      if (this.isNum(this.selected.heatload)) {
        this.refreshWattAndHvac();
      }
    },
    refreshWattAndHvac() {
      if (this.selected == null || !this.isNum(this.selected.heatload)) return;

      //POWER CONSUMPTION = Heat Load / 3.412
      //HVAC Tonnage = Heat Load / 12000

      let heatload = parseFloat(this.selected.heatload);
      this.selected.watt = parseFloat((heatload / this.constants.wattToBTU).toFixed(2));
      this.selected.hvacTonnage = parseFloat((heatload / 12000).toFixed(5));
    },
    hvacChanged() {
      if (this.isNum(this.selected.hvacTonnage)) {
        this.refreshWattAndHeatload();
      }
    },
    refreshWattAndHeatload() {
      if (this.selected == null || !this.isNum(this.selected.hvacTonnage)) return;

      //Heat Load = HVAC Tonnage * 12000
      //POWER CONSUMPTION = Heat Load / 3.412

      let hvacTonnage = parseFloat(this.selected.hvacTonnage);
      this.selected.heatload = parseFloat((hvacTonnage * 12000).toFixed(2));
      this.selected.watt = parseFloat(
        (this.selected.heatload / this.constants.wattToBTU).toFixed(2)
      );
    },
  },
  watch: {},
};
</script>

<style lang="scss"></style>
