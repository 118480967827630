var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"bottom":"","nudge-bottom":"32"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var click = ref.on.click;
return [_c('v-tooltip',{attrs:{"bottom":"","nudge-bottom":"-5"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"height":"32","color":"white","x-small":""},on:{"click":click}},[_c('i',{staticClass:"fal fa-tasks",staticStyle:{"font-size":"16px"}})])],1)]}}],null,true)},[_c('span',[_vm._v("Change Columns Visibility")])])]}}])},[_c('v-list',{staticClass:"py-0"},[_c('div',{staticClass:"d-flex my-1 justify-space-between align-center"},[_c('h5',{staticClass:"pl-4"},[_vm._v("Columns Visibility")]),_c('v-btn',{staticClass:"text-capitalize mr-1",attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.reset()}}},[_vm._v("reset")])],1),_c('v-list-item-group',{staticStyle:{"overflow":"overlay","max-height":"260px"},attrs:{"multiple":""},on:{"change":_vm.emitHeaders},model:{value:(_vm.groups),callback:function ($$v) {_vm.groups=$$v},expression:"groups"}},_vm._l((_vm.value),function(column,k){return _c('v-list-item',{key:'columnList' + k,attrs:{"input-value":column,"active-class":column.hidable ? 'blue--text' : '',"dense":"","disabled":!column.hidable},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('span',{staticClass:"fs-12px font-weight-medium mr-1",staticStyle:{"width":"20px"}},[_vm._v(_vm._s(k + 1))]),_c('v-list-item-action',{staticClass:"mr-1 my-0"},[_c('v-checkbox',{attrs:{"disabled":!column.hidable,"input-value":active,"color":"info","dense":""}})],1),_c('v-list-item-content',{staticClass:"px-1"},[_c('v-list-item-title',{staticStyle:{"font-size":"12px !important"}},[_vm._v(" "+_vm._s(column.text)+" ")])],1)]}}],null,true)})}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }