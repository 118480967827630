<template>
  <v-btn
    v-if="value != null && value != ''"
    link
    small
    @click.stop
    text
    color="info"
    :href="'tel:' + value"
    class="px-2"
    ><i class="fas fa-phone mr-2"></i>{{ value }}
  </v-btn>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
  },
};
</script>

<style></style>
