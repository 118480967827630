<template>
  <span class="d-inline-flex flex-wrap" style="gap: 0.25rem">
    <v-chip v-for="(tag, index) in parsedValue" :key="index" color="secondary" label small>{{
      tag
    }}</v-chip>
  </span>
</template>

<script>
export default {
  components: {},
  name: "hashtag-logged-prop",
  inheritAttrs: false,
  data() {
    return {};
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    parsedValue() {
      return this.value == null || this.value == "" ? [] : this.value.split(",");
    },
  },
  methods: {},
};
</script>

<style lang="scss"></style>
