<template>
  <span class="d-inline-flex" style="gap: 0.25rem">
    <v-tooltip right z-index="999" nudge-right="-4px">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <div class="equipment-img" style="height: 40px; width: 72px">
            <div
              style="height: 100%; width: 100%"
              v-viewer
              @click.stop
              v-if="value != null && value != ''"
            >
              <img :key="value + '_img'" :src="value" height="100%" width="100%" />
            </div>
            <i v-else :class="'fad fa-plug'"></i>
          </div>
        </div>
      </template>
      <span>
        <v-img
          v-if="(value != null) & (value != '')"
          class="img"
          :src="value"
          width="250px"
          height="250px"
          contain
        ></v-img>
        <i v-else :class="'fad fa-plug'" style="font-size: 32px; padding: 2rem"></i>
      </span>
    </v-tooltip>
  </span>
</template>

<script>
export default {
  components: {},
  name: "image-url-logged-prop",
  inheritAttrs: false,
  data() {
    return {};
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss"></style>
