<template>
  <v-menu offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on: { click } }">
      <v-tooltip top nudge-top="-5">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-btn height="32" color="white" small @click="click">
              <i v-if="sortDesc" class="fal fa-sort-amount-up-alt" style="font-size: 16px"></i>
              <i v-else class="fal fa-sort-amount-down-alt" style="font-size: 16px"></i>
            </v-btn>
          </div>
        </template>
        <span
          >Sort By
          <span> - {{ sortByColumn }} - {{ sortDirection }}</span>
        </span>
      </v-tooltip>
    </template>
    <v-list>
      <v-list-item>
        <v-list-item-content class="d-flex align-center">
          <v-tooltip top nudge-top="-5">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="$emit('update:sortDesc', true)"
                height="30"
                width="30"
                :color="sortDesc ? 'primary' : 'transparent'"
                small
                class="me-3"
                :elevation="sortDesc ? 1 : 0"
              >
                <i class="fal fa-sort-amount-up-alt" style="font-size: 12px"></i
              ></v-btn>
            </template>
            <span>descending</span>
          </v-tooltip>
          <v-tooltip top nudge-top="-5">
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="$emit('update:sortDesc', false)"
                height="30"
                width="30"
                :color="sortDesc ? 'transparent' : 'primary'"
                small
                :elevation="sortDesc ? 0 : 1"
              >
                <i class="fal fa-sort-amount-down-alt" style="font-size: 16px"></i
              ></v-btn>
            </template>
            <span>ascending</span>
          </v-tooltip>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="my-1"></v-divider>
      <v-list-item-group :value="value" @change="(val) => $emit('input', val)">
        <v-list-item link v-for="(item, index) in columns" :key="index">
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
    },
    sortDesc: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    storageKey: {
      type: String,
      default: "",
    },
  },
  mounted() {
    if (this.storageKey) {
      const { sortBy, sortDesc } = this.$getFromLocal(this.storageKey, true, {
        sortBy: this.value,
        sortDesc: this.sortDesc,
      });
      this.$emit("input", sortBy);
      this.$emit("update:sortDesc", sortDesc);
    }
  },
  computed: {
    sortByColumn() {
      if (!this.value) return "";
      return this.value !== -1 ? this.columns[this.value].value : "";
    },
    sortDirection() {
      return this.sortDesc ? "desc" : "asc";
    },
  },
};
</script>
