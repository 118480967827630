<template>
  <v-container
    fluid
    class="power-option-wrapper pa-6 pb-2"
    v-if="selected != null"
  >
    <v-form v-model="valid">
      <v-row class="my-0">
        <v-col cols="auto" class="py-0" style="width: 110px">
          <v-text-field-alt
            :rules="[
              allRules.requiredNumber,
              allRules.number,
              allRules.numberMin(1),
              allRules.numberMax(100),
            ]"
            label="Volts <span class='text--secondary'>1:100</span>"
            placeholder="Voltage"
            v-model.number="selected.voltage"
            @input="voltageChanged"
            :readonly="readonly"
            hide-details
          >
          </v-text-field-alt>
        </v-col>
        <v-col cols="auto" class="py-0" style="width: 110px">
          <v-text-field-alt
            :rules="[allRules.requiredNumber, allRules.number]"
            label="Amps"
            placeholder="Current"
            v-model.number="selected.current"
            @input="currentChanged"
            :readonly="readonly"
            hide-details
          >
          </v-text-field-alt>
        </v-col>
        <v-col cols="auto" class="py-0" style="width: 110px">
          <v-text-field-alt
            :rules="[allRules.requiredNumber, allRules.number]"
            label="Watts"
            placeholder="Power Consumption"
            v-model.number="selected.watt"
            @input="wattChanged"
            :readonly="readonly"
            hide-details
          >
          </v-text-field-alt>
        </v-col>
        <v-col cols="auto" class="py-0">
          <label
            class="d-block mb-1"
            style="font-weight: 600 !important; font-size: 15px"
          >
            BTU & HVAC <span class="text--secondary">Calculation</span>
          </label>
          <v-btn-toggle
            v-model="selected.heatLoadAutoCalculate"
            mandatory
            rounded
            color="orange"
            style="margin-top: 2.5px"
          >
            <v-btn :value="true" :disabled="readonly">
              <i class="fad fa-lock-alt mr-2 secondary--text"></i>
              <span class="fs-13px">Auto</span>
            </v-btn>
            <v-btn :value="false" :disabled="readonly">
              <i class="fad fa-lock-open-alt mr-2 secondary--text"></i>
              <span class="fs-13px">Manual</span>
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols="auto" class="py-0" style="width: 124px">
          <v-text-field-alt
            :rules="[allRules.requiredNumber, allRules.number]"
            placeholder="HeatLoad"
            :label="
              selected.heatLoadAutoCalculate
                ? `BTU/Hr <i class='fad fa-lock-alt text--disabled mx-1'></i>`
                : `BTU/Hr`
            "
            v-model.number="selected.heatload"
            :readonly="readonly"
            :class="selected.heatLoadAutoCalculate ? 'font-italic' : ''"
            hide-details
          >
          </v-text-field-alt>
        </v-col>
        <v-col cols="auto" class="py-0" style="width: 124px">
          <v-text-field-alt
            :rules="[allRules.requiredNumber, allRules.number]"
            id="equipmentHvacTonnage"
            placeholder="Tonnage"
            :label="
              selected.heatLoadAutoCalculate
                ? `HVAC <i class='fad fa-lock-alt text--disabled mx-1'></i>`
                : `HVAC`
            "
            v-model.number="selected.hvacTonnage"
            :readonly="readonly"
            :class="selected.heatLoadAutoCalculate ? 'font-italic' : ''"
            hide-details
          >
          </v-text-field-alt>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
// Watts = Amps x Volts
export default {
  name: "dc-power-option",
  props: {
    value: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valid: false,
      selected: {},
    };
  },
  mounted() {
    this.selected = this.cloneDeep(this.value);
  },
  computed: {},
  methods: {
    isNum(value) {
      // return /^[0-9]+.[0-9]+$/.test(value);
      return /^[0-9]+.[0-9]+|[0-9]+$/.test(value);
    },
    refreshHeatAndHvac() {
      if (
        this.selected == null ||
        this.selected.heatLoadAutoCalculate != true ||
        !this.isNum(this.selected.watt)
      )
        return;

      //Heat Load = POWER CONSUMPTION * 3.412
      //HVAC Tonnage = (POWER CONSUMPTION * 3.412) / 12000
      let pc = parseFloat(this.selected.watt);
      this.selected.heatload = parseFloat((pc * 3.412).toFixed(2));
      this.selected.hvacTonnage = parseFloat(((pc * 3.412) / 12000).toFixed(5));
    },
    deletePowerOption() {
      this.$emit("delete");
    },
    voltageChanged(value) {
      if (value > 100 || value < 1) return;
      // Watts = Amps x Volts
      this.selected.watt = this.selected.current * this.selected.voltage;
      // this.selected.current = this.selected.watt / this.selected.voltage;
    },
    wattChanged(value) {
      if (this.selected.voltage > 100 || this.selected.voltage < 1) return;
      // Amps = Watts / Volts
      this.selected.current = this.selected.watt / this.selected.voltage;
    },
    currentChanged(value) {
      if (this.selected.voltage > 100 || this.selected.voltage < 1) return;
      // Watts = Amps x Volts
      this.selected.watt = this.selected.current * this.selected.voltage;
    },
  },
  watch: {
    "selected.watt": {
      handler() {
        if (this.isNum(this.selected.watt)) {
          this.refreshHeatAndHvac();
        }
      },
    },
    value: {
      handler() {
        if (this.isEqual(this.value, this.selected)) return;
        this.selected = this.cloneDeep(this.value);
      },
      deep: true,
    },
    selected: {
      handler() {
        if (this.isEqual(this.value, this.selected)) return;
        this.$emit("input", this.selected);
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
.power-option-wrapper {
  position: relative !important;
  border-radius: 0.5rem;
  border: 1px dashed rgba($shades-black, 0.4);
  transition: all 0.25s ease-out;

  &:hover {
    border-color: $orange-base;
    background: rgba($orange-base, 0.04);
  }
}

.delete-power-option-btn {
  position: absolute !important;
  top: -0.5rem;
  left: -0.5rem;
}
</style>
