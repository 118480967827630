export default [
  {
    path: "/",
    redirect: "dashboard",
    title: "Dashboard",
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: { layout: "dashboard", title: "Dashboard" },
    component: () => import("./views/Dashboard.vue"),
  },
];
