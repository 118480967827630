<template>
  <v-container fluid class="pa-6" v-if="selected != null">
    <v-form v-model="valid" ref="updateForm3">
      <v-row class="my-0 d-flex flex-row justify-space-between flex-wrap">
        <v-col cols="12" md="6">
          <v-switch
            class="ma-0 pa-0"
            color="info"
            hide-details
            v-model="selected.wired"
            :readonly="readonly"
            :disabled="readonly"
          >
            <template v-slot:label>
              <label class="v-switch-label ma-0">Wired</label>
            </template>
          </v-switch>
          <v-container fluid class="network-connection-type d-flex flex-column my-3">
            <v-scroll-y-transition mode="out-in">
              <div class="my-12" key="equipment-network-no-wirless" v-if="!selected.wired">
                <p class="text-center mb-2">
                  <span class="fa-stack" style="font-size: 28px">
                    <i class="fad fa-network-wired blue-grey--text text--lighten-2 fa-stack-2x"></i>
                    <i
                      class="fas fa-slash fa-stack-2x fa-flip-horizontal"
                      style="color: tomato"
                    ></i>
                  </span>
                </p>
                <h4
                  class="text-center blue-grey--text text--lighten-2 mb-0 mt-4 font-weight-medium"
                >
                  Wired Ports Are Not Supported!
                </h4>
              </div>
              <div v-else key="equipment-network-supported-wired">
                <v-row>
                  <v-col cols="12" md="12" class="d-flex align-center justify-space-between pb-0">
                    <label class="input-label" v-if="selected.wired">Supported Network Ports</label>
                    <v-btn text color="info" @click="openAddWiredPort(null)" :disabled="readonly">
                      <i class="far fa-plus me-2"></i>Add Port
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-container
                      fluid
                      class="network-connection-type d-flex flex-column pa-2"
                      style="gap: 0.5rem"
                      :class="{
                        'inset-shadow network-connection-type-selected': selected.wired,
                      }"
                    >
                      <div
                        v-if="selected.networkPorts.length == 0 || selected.networkPorts == null"
                        class="pa-2"
                      >
                        <p class="text-center fs-28px my-3">
                          <span class="fa-stack mr-0">
                            <i
                              class="fad fa-ethernet fa-stack-2x fa-beat-fade info--text"
                              style="
                                --fa-animation-duration: 3s;
                                --fa-fade-opacity: 0.64;
                                --fa-beat-scale: 1;
                              "
                            ></i>
                          </span>
                        </p>
                        <h3 class="text--disabled mb-3 text-center">No wired port added yet!</h3>
                      </div>
                      <div v-else class="d-flex flex-column" style="gap: 0.5rem">
                        <v-card v-for="(port, index) in selected.networkPorts" :key="index">
                          <div class="d-flex justify-space-between align-center" style="gap: 1rem">
                            <div>
                              <v-card-title>
                                <span class="fs-18px font-weight-medium">{{
                                  port.portInterface
                                }}</span>
                                <span class="ms-2 fs-14px text--secondary">
                                  ({{ port.name }})
                                </span>
                              </v-card-title>
                              <v-card-subtitle>
                                <span class="d-flex fs-14px font-weight-medium">
                                  <span class="mr-2">{{ port.speed | numberWithCommas }} Mb/s</span>
                                  <span class="d-flex align-center text--secondary"
                                    >≈ {{ (port.speed / 1000) | numberWithCommas }} Gb/s
                                  </span>
                                </span>
                              </v-card-subtitle>
                            </div>
                            <div class="d-flex align-center justify-end pe-2" style="gap: 0.5rem">
                              <v-btn
                                color="info"
                                icon
                                @click="openAddWiredPort(index)"
                                :disabled="readonly"
                              >
                                <i class="far fa-edit"></i>
                              </v-btn>
                              <v-btn
                                color="error"
                                icon
                                @click="deleteWiredPort(index)"
                                :disabled="readonly"
                              >
                                <i class="far fa-trash-alt"></i>
                              </v-btn>
                            </div>
                          </div>
                        </v-card>
                      </div>
                    </v-container>
                  </v-col>
                </v-row>
              </div>
            </v-scroll-y-transition>
          </v-container>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="12" md="6">
          <v-switch
            class="ma-0 pa-0"
            color="info"
            hide-details
            v-model="selected.wireless"
            :readonly="readonly"
            :disabled="readonly"
          >
            <template v-slot:label>
              <label class="v-switch-label ma-0">Wireless</label>
            </template>
          </v-switch>
          <v-container fluid class="network-connection-type d-flex flex-column my-3">
            <v-scroll-y-transition mode="out-in">
              <div class="my-12" key="equipment-network-no-wirless" v-if="!selected.wireless">
                <p class="text-center mb-2">
                  <span class="fa-stack" style="font-size: 28px">
                    <i class="fad fa-wifi blue-grey--text text--lighten-2 fa-stack-2x"></i>
                    <i class="fas fa-slash fa-stack-2x" style="color: tomato"></i>
                  </span>
                </p>
                <h4
                  class="text-center blue-grey--text text--lighten-2 mb-0 mt-4 font-weight-medium"
                >
                  Wireless Interface Is Not Supported!
                </h4>
              </div>
              <div v-else key="equipment-network-supported-wireless">
                <v-row>
                  <v-col cols="12" md="12" class="d-flex align-center justify-space-between pb-0">
                    <label class="input-label">Supported Wireless Interfaces</label>
                    <v-btn text color="info" @click="addWirelessInterface" :disabled="readonly">
                      <i class="far fa-plus me-2"></i>Add Interface
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-container
                      fluid
                      class="network-connection-type d-flex flex-column pa-2"
                      style="gap: 0.5rem"
                      :class="{
                        'inset-shadow network-connection-type-selected': selected.wireless,
                      }"
                    >
                      <div
                        v-if="
                          selected.wirelessInterfaces == null ||
                          selected.wirelessInterfaces.length == 0
                        "
                        class="pa-2"
                      >
                        <p class="text-center fs-28px my-3">
                          <span class="fa-stack mr-0">
                            <i
                              class="fad fa-wifi fa-stack-2x fa-beat-fade info--text"
                              style="
                                --fa-animation-duration: 3s;
                                --fa-fade-opacity: 0.64;
                                --fa-beat-scale: 1;
                              "
                            ></i>
                          </span>
                        </p>
                        <h3 class="text--disabled mb-3 text-center">
                          No wireless interfaces added yet!
                        </h3>
                      </div>
                      <div v-else class="d-flex flex-column" style="gap: 0.5rem">
                        <v-expansion-panels
                          key="equipment-network-supported-wirless"
                          ref="areaGroupsEP"
                          v-model="expandedWirelessInterfacesPanels"
                          multiple
                        >
                          <v-expansion-panel
                            v-for="(wirelessInterface, i) in orderedWirelessInterfaces"
                            :key="i"
                          >
                            <v-expansion-panel-header class="px-4 py-0" :ripple="true">
                              <v-row no-gutters justify="space-between">
                                <v-col cols="auto" class="d-flex align-center">
                                  <span class="font-weight-bold mr-2 mono-font">
                                    {{ wirelessInterface.order || 0 }}
                                  </span>
                                  <span class="font-weight-bold order-btns">
                                    <v-btn
                                      class="order-up"
                                      icon
                                      small
                                      @click.stop="wirelessInterfaceOrderUp(wirelessInterface)"
                                      :disabled="wirelessInterface.order == 1 || readonly"
                                    >
                                      <i class="fas fa-caret-up"></i
                                    ></v-btn>
                                    <v-btn
                                      class="order-down"
                                      icon
                                      small
                                      @click.stop="wirelessInterfaceOrderDown(wirelessInterface)"
                                      :disabled="
                                        i == orderedWirelessInterfaces.length - 1 || readonly
                                      "
                                    >
                                      <i class="fas fa-caret-down"></i
                                    ></v-btn>
                                  </span>
                                  <v-divider vertical class="mx-3"></v-divider>
                                  <span class="font-weight-bold">
                                    <i class="fad fa-wifi mr-2"></i> {{ wirelessInterface.name }}
                                  </span>
                                </v-col>
                                <v-col cols="auto" class="d-flex align-center">
                                  <v-tooltip top z-index="9999" v-if="!readonly">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        color="error"
                                        class="mr-2"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click.stop="deleteWirelessInterface(i)"
                                      >
                                        <i class="fal fa-trash-alt"> </i>
                                      </v-btn>
                                    </template>
                                    <span>Delete Interface</span>
                                  </v-tooltip>
                                </v-col>
                              </v-row>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-row class="mb-3">
                                <v-col cols="12" class="d-flex align-center pb-0">
                                  <v-text-field-alt
                                    :rules="[allRules.required, allRules.noWhiteSpaces]"
                                    label="Interface Name"
                                    placeholder="name"
                                    style="width: 100%"
                                    hide-details
                                    :disabled="readonly"
                                    v-model="wirelessInterface.name"
                                  >
                                  </v-text-field-alt>
                                </v-col>
                                <v-col cols="12" class="d-flex align-center pb-0">
                                  <label class="input-label mr-4">
                                    Supported Wireless Interface Protocols
                                  </label>
                                </v-col>
                                <v-col
                                  cols="12"
                                  class="d-flex align-start flex-column pb-0"
                                  style="gap: 1rem"
                                >
                                  <v-checkbox
                                    v-for="(item, index) in WirelessProtocolsList"
                                    :key="index"
                                    class="ma-0 pa-0"
                                    color="info"
                                    hide-details
                                    dense
                                    :readonly="readonly"
                                    :disabled="readonly"
                                    v-model="wirelessInterface.interfaceProtocoals"
                                    :value="item.value"
                                  >
                                    <template v-slot:label>
                                      <label
                                        class="v-switch-label ma-0"
                                        :class="{
                                          'info--text': isWirelessProtocolSelected(
                                            wirelessInterface,
                                            item
                                          ),
                                        }"
                                        >{{ item.text }}
                                      </label>
                                    </template>
                                  </v-checkbox>
                                </v-col>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </div>
                    </v-container>
                  </v-col>
                </v-row>
              </div>
            </v-scroll-y-transition>
          </v-container>
        </v-col>
      </v-row>
    </v-form>
    <!-- Add Or Edit Wired Network Port Modal -->
    <v-dialog v-model="modals.addWiredPort.active" max-width="400px" persistent scrollable>
      <v-card>
        <v-card-title class="font-weight-bold" small>
          <i class="fad fa-pen-square mr-2 mt-1"></i
          >{{ modals.addWiredPort.data.index == null ? "Add a" : "Update" }} Port
        </v-card-title>
        <v-divider></v-divider>
        <div class="pa-3">
          <v-form v-model="modals.addWiredPort.valid" ref="addWiredPortForm">
            <v-container>
              <v-row class="my-0" dense>
                <v-col sm="12">
                  <v-text-field-alt
                    :rules="[allRules.required, allRules.noWhiteSpaces]"
                    label="Port Name"
                    placeholder="ex: Lan 1"
                    v-model="modals.addWiredPort.data.name"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col sm="12">
                  <v-select-alt
                    :rules="[allRules.required]"
                    label="Port Interface"
                    placeholder="Select an Interface"
                    v-model="modals.addWiredPort.data.portInterface"
                    :items="NetworkPortsList"
                    dense
                    filled
                    item-text="interface"
                    item-value="interface"
                    :menu-props="{ maxHeight: 200, contentClass: 'port-interface-menu' }"
                    attach
                  >
                  </v-select-alt>
                </v-col>
                <v-col sm="12">
                  <v-select-alt
                    :rules="[allRules.required]"
                    label="Port Speed"
                    placeholder="Select a Port Speed"
                    v-model="modals.addWiredPort.data.speed"
                    :items="NetworkPortsList"
                    dense
                    filled
                    item-text="speedMb"
                    item-value="speedMb"
                  >
                    <template v-slot:selection="{ item }">
                      <span class="mr-2">{{ item.speedMb | numberWithCommas }} Mb/s</span>
                      <span style="font-weight: 600; opacity: 0.54" class="d-flex align-center"
                        >≈ {{ (item.speedMb / 1000) | numberWithCommas }} Gb/s
                      </span>
                    </template>
                    <template v-slot:item="{ item }">
                      <span class="mr-2">{{ item.speedMb | numberWithCommas }} Mb/s</span>
                      <span style="font-weight: 600; opacity: 0.54" class="d-flex align-center"
                        >≈ {{ (item.speedMb / 1000) | numberWithCommas }} Gb/s
                      </span>
                    </template>
                  </v-select-alt>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="discardAddWiredPort()"
            ><i class="fal fa-chevron-left mr-2"></i>Discard</v-btn
          >
          <v-btn :disabled="!modals.addWiredPort.valid" @click="confirmAddWiredPort()" color="info">
            <i class="fal fa-check mr-2"></i>
            {{ modals.addWiredPort.data.index == null ? "Add" : "Update" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Enums from "../../../../plugins/enums";

export default {
  name: "equipment-network-connectivity",
  data() {
    return {
      WirelessProtocols: Enums.WirelessProtocolType,
      WirelessProtocolsList: Object.values(Enums.WirelessProtocolType),
      NetworkPorts: Enums.NetworkPort,
      NetworkPortsList: Object.values(Enums.NetworkPort),
      valid: false,
      selected: {},
      expandedWirelessInterfacesPanels: [],
      tempWirelessInterfaceId: -1,
      modals: {
        addWiredPort: {
          active: false,
          valid: false,
          data: {
            index: null,
            name: null,
            portInterface: null,
            speed: null,
          },
        },
        addInterface: {
          active: false,
          valid: false,
        },
      },
    };
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.selected = this.cloneDeep(this.value);
  },
  computed: {
    networkPortObj() {
      if (this.selected.networkPorts == null) return null;
      return this.getEnumMember(this.NetworkPorts, this.selected.networkPorts);
    },
    orderedWirelessInterfaces() {
      if (this.selected.wirelessInterfaces == null) return this.selected.wirelessInterfaces;
      var ordered = this.selected.wirelessInterfaces.slice(0).sort(this.compareFunc);
      this.$log("orderedWirelessInterfaces", this.cloneDeep(ordered));
      return ordered;
    },
  },
  methods: {
    compareFunc(interfaceA, interFaceB) {
      if (interfaceA.order < interFaceB.order) {
        return -1;
      }
      if (interfaceA.order > interFaceB.order) {
        return 1;
      }
      // a must be equal to b
      return 0;
    },
    wirelessInterfaceOrderUp(wirelessInterface) {
      var startOrder = wirelessInterface.order;
      if (startOrder > 1) {
        var indexAbove = this.selected.wirelessInterfaces.findIndex(
          (i) => i.order == startOrder - 1
        );
        if (indexAbove != -1) {
          wirelessInterface.order = startOrder - 1;
          this.selected.wirelessInterfaces[indexAbove].order = startOrder;
        }
      }
    },
    wirelessInterfaceOrderDown(wirelessInterface) {
      var biggestOrder =
        this.orderedWirelessInterfaces[this.orderedWirelessInterfaces.length - 1].order;
      var startOrder = wirelessInterface.order;
      if (startOrder < biggestOrder) {
        var indexBelow = this.selected.wirelessInterfaces.findIndex(
          (i) => i.order == startOrder + 1
        );
        if (indexBelow != -1) {
          wirelessInterface.order = startOrder + 1;
          this.selected.wirelessInterfaces[indexBelow].order = startOrder;
        }
      }
    },
    addWirelessInterface() {
      var biggestOrder = 0;
      if (this.orderedWirelessInterfaces.length > 0) {
        biggestOrder =
          this.orderedWirelessInterfaces[this.orderedWirelessInterfaces.length - 1].order;
      }
      let newWirelessInterface = {
        id: null,
        order: biggestOrder + 1,
        tempId: this.getTempWirelessInterfaceId(),
        name: "New Interface",
        interfaceProtocoals: [],
      };
      this.selected.wirelessInterfaces.push(this.cloneDeep(newWirelessInterface));
      this.expandedWirelessInterfacesPanels = [0];
      // this.expandedWirelessInterfacesPanels = this.selected.wirelessInterfaces.map(
      //   (ag, index) => index
      // );
      // this.sortWirelessInterfaces();
    },
    getTempWirelessInterfaceId() {
      this.tempWirelessInterfaceId -= 1;
      return this.tempWirelessInterfaceId;
    },
    sortWirelessInterfaces() {
      this.selected.wirelessInterfaces.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );
      this.expandedWirelessInterfacesPanels = this.selected.wirelessInterfaces.map(
        (inter, index) => index
      );
    },
    deleteWirelessInterface(index) {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this interface?`,
          title: `Delete Wireless Interface?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                this.selected.wirelessInterfaces.splice(index, 1);
              },
            },
          },
        })
        .then((res) => {});
    },
    openAddWiredPort(index) {
      this.$log("index", index);
      if (index == null) {
        this.modals.addWiredPort.data = {
          index: null,
          name: null,
          portInterface: null,
          speed: null,
        };
      } else {
        this.modals.addWiredPort.data = {
          index: index,
          name: this.selected.networkPorts[index].name,
          portInterface: this.selected.networkPorts[index].portInterface,
          speed: this.selected.networkPorts[index].speed,
        };
      }
      this.modals.addWiredPort.active = true;
      setTimeout(() => {
        this.$refs.addWiredPortForm.resetValidation();
      });
    },
    discardAddWiredPort() {
      this.modals.addWiredPort.active = false;
      this.modals.addWiredPort.data = {
        index: null,
        name: null,
        portInterface: null,
        speed: 0,
      };
    },
    confirmAddWiredPort() {
      if (this.modals.addWiredPort.data.index == null) {
        var newPort = {
          name: this.modals.addWiredPort.data.name,
          portInterface: this.modals.addWiredPort.data.portInterface,
          speed: this.modals.addWiredPort.data.speed,
        };
        this.selected.networkPorts.push(newPort);
      } else {
        var index = this.modals.addWiredPort.data.index;
        this.selected.networkPorts[index].name = this.modals.addWiredPort.data.name;
        this.selected.networkPorts[index].portInterface =
          this.modals.addWiredPort.data.portInterface;
        this.selected.networkPorts[index].speed = this.modals.addWiredPort.data.speed;
      }
      this.discardAddWiredPort();
    },
    deleteWiredPort(index) {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this port?`,
          title: `Delete Port?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                this.selected.networkPorts.splice(index, 1);
              },
            },
          },
        })
        .then((res) => {});
    },
    isWirelessProtocolSelected(wirelessInterface, protocol) {
      if (wirelessInterface == null || wirelessInterface.interfaceProtocoals.length == 0)
        return false;
      return wirelessInterface.interfaceProtocoals.find((p) => p == protocol.value) != null;
    },
  },
  watch: {
    valid() {
      this.$emit("valid-changes", this.valid);
    },
    selected: {
      handler() {
        if (!this.isEqual(this.selected, this.value)) {
          this.$emit("input", this.selected);
        }
      },
      deep: true,
    },
    value: {
      handler() {
        if (!this.isEqual(this.selected, this.value)) {
          this.selected = this.cloneDeep(this.value);
        }
      },
      deep: true,
    },
  },
  components: {},
};
</script>
<style lang="scss">
.network-connection-type {
  flex: 1 1 auto;
  border-radius: 0.35rem;
  overflow: hidden;
  transition: all 0.3s ease-out;

  &.network-connection-type-selected {
    background: #eceff1;
  }
}
.port-interface-menu .v-menu__content {
  overflow: auto;
  background: #fff;
}

.order-btns {
  position: relative;
  width: 16px;
  height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .order-up,
  .order-down {
    padding: 0.25rem;
    border-radius: 1rem;
    width: 16px;
    height: 16px;
    line-height: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: rgba($shades-black, 0.04);
    }

    i {
      line-height: 0;
    }
  }
}
</style>
