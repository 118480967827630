/**
 * Shared Module
 */

const state = {};

const getters = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
