<template>
  <div class="container py-0">
    <div style="display: flex; gap: 2rem; align-items: center">
      <div><i class="fad fa-bell-on" style="font-size: 32px"></i></div>
      <div>
        <h3 style="margin-bottom: 0.35rem; font-weight: 700">
          Allow Concordia to notify you about your generated reports?
        </h3>
        <p style="margin-bottom: 0.5rem; font-weight: 400">
          Click '<u>Accept</u>' then Click '<u>Allow</u>' from the browser prompet that will appear.
        </p>
        <div style="display: flex; gap: 0.5rem">
          <v-btn color="#2196f3" style="margin-top: 0.5rem; color: #fff" @click="accept">
            <i class="fas fa-check" style="margin-right: 0.5rem"></i
            ><span style="font-weight: 600">Accept</span>
          </v-btn>
          <v-btn color="white" style="margin-top: 0.5rem" @click="dismiss">
            <span style="font-weight: 600">No, Thanks!</span>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Enums from "../../../plugins/enums";
export default {
  name: "toast-notification-permission",
  methods: {
    dismiss() {
      this.$toast.dismiss("COCO_NOTIFY_PERM");
      this.$setToLocal("COCO_NotificationPerm", "denied");
    },
    accept() {
      if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
      }
      // Otherwise, we need to ask the user for permission
      else if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
          this.$setToLocal("COCO_NotificationPerm", Notification.permission);

          if (Notification.permission === "granted") {
            this.dismiss();
          } else if (Notification.permission === "denied") {
            this.dismiss();
          }
        });
      }
    },
  },
  mounted() {
    this.$log("TTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTT");
  },
  computed: {},
};
</script>
