<template>
  <span>
    <equipment-provider :providerName="parsedValue.Name"></equipment-provider>
  </span>
</template>

<script>
import EquipmentProvider from "../EquipmentProvider.vue";

export default {
  components: { EquipmentProvider },
  name: "provider-logged-prop",
  inheritAttrs: false,
  data() {
    return {};
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    parsedValue() {
      return JSON.parse(this.value);
    },
  },
  methods: {},
};
</script>

<style lang="scss"></style>
