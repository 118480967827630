<template>
  <slideout
    dock="right"
    :size="mainPanelSize"
    :min-size="1100"
    :allow-resize="false"
    :append-to="'#app'"
    :class="'slideout-black-header coco-viewer'"
    :style="'z-index: 99999 !important'"
    :visible.sync="slideouts.viewer.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 class="font-weight-bold pa-1" small v-if="slideouts.viewer.active">
        <i class="fad fa-eye mr-2"></i> {{ fileName }}
      </h3>
      <div>
        <panel-size-control v-model="slideouts.viewer.fullWidth" dark></panel-size-control>
        <v-btn @click="closeSlideout()" icon dark>
          <i class="far fa-times"></i>
        </v-btn>
      </div>
    </template>

    <v-container
      class="pa-0"
      fluid
      style="height: 100%; overflow: hidden"
      v-if="slideouts.viewer.active"
    >
      <div v-if="slideouts.viewer.isLoading" class="text-center py-5">
        <video width="320" muted loop autoplay style="padding: 0.5rem">
          <source src="/img/art/astronaut-mines-coins-4979111-4153144.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Fetching File...</p>
      </div>
      <div
        class="no-data-available"
        :key="'no-data-available'"
        v-else-if="slideouts.viewer.hasError"
      >
        <img
          width="600"
          style="max-width: 100%; width: 500px !important"
          src="/img/art/undraw_into_the_night_vumi.svg"
        />
        <h2 class="mt-3 error--text" style="font-size: 2.75rem; color: #e45d51">
          Error Fetching File Content!
        </h2>
      </div>
      <div v-else-if="isCode" class="coco-viewer-code-section">
        <pre><code class="white pa-3">{{ fileContent }}</code></pre>
      </div>
      <div v-else-if="isPdf" class="coco-viewer-pdf-section">
        <pdf-viewer :allowDownload="allowDownload" v-model="url"></pdf-viewer>
      </div>
    </v-container>
  </slideout>
</template>

<script>
import PanelSizeControl from "../PanelSizeControl.vue";
import axios from "axios";
import hljs from "highlight.js/lib/common";
import PdfViewer from "./PdfViewer.vue";

export default {
  components: { PanelSizeControl, PdfViewer },
  name: "coco-viewer",
  data() {
    return {
      allowDownload: true,
      url: null,
      fileName: null,
      ext: null,
      fileContent: null,
      slideouts: {
        viewer: {
          active: false,
          fullWidth: false,
          isLoading: false,
          hasError: false,
        },
      },
      extensions: {
        image: ["png", "jpg", "jpeg", "gif", "bmp", "svg", "webp", "tiff"],
        code: ["json", "html", "xml", "log", "config"],
        pdf: ["pdf"],
      },
    };
  },
  props: {},
  mounted() {},
  computed: {
    mainPanelSize() {
      return this.slideouts.viewer.fullWidth ? "100%" : "1100px";
    },
    isImage() {
      if (this.ext == null) return false;
      return this.extensions.image.includes(this.ext);
    },
    isCode() {
      if (this.ext == null) return false;
      return this.extensions.code.includes(this.ext);
    },
    isPdf() {
      if (this.ext == null) return false;
      return this.extensions.pdf.includes(this.ext);
    },
  },
  methods: {
    view(fileName, url, allowDownload = true) {
      this.reset();
      this.allowDownload = allowDownload;

      this.url = url;
      this.fileName = fileName;
      this.ext = this.getFileExt(fileName);

      if (this.isImage) this.viewImage();
      else if (this.isCode) this.viewCode();
      else if (this.isPdf) this.viewPdf();
      else this.displayNotSupportedMessage();
    },
    viewImage() {
      this.$viewerApi({
        images: [this.url],
      });
    },
    viewCode() {
      this.openSlideout();
      this.slideouts.viewer.isLoading = true;
      // .get(this.url)
      axios({
        url: this.url,
        method: "GET",
        headers: [],
      })
        .then((resp) => {
          this.slideouts.viewer.isLoading = false;
          this.fileContent = this.cloneDeep(resp.data);
          setTimeout(() => {
            document
              .querySelectorAll(".coco-viewer .coco-viewer-code-section code")
              .forEach((elm) => {
                hljs.highlightElement(elm);
              });
          }, 0);
        })
        .catch((err) => {
          this.closeSlideout();
          this.$dialog.notify.error(err.request.response, {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
    viewPdf() {
      this.openSlideout();
    },
    getFileExt(fileName) {
      return fileName.substring(fileName.lastIndexOf(".") + 1);
    },
    displayNotSupportedMessage() {
      this.$dialog.notify.warning("No supported viewer for this file type!", {
        position: "top-right",
        timeout: 5000,
      });
    },
    openSlideout() {
      this.slideouts.viewer.active = true;
    },
    closeSlideout() {
      this.slideouts.viewer.active = false;
    },
    onSlideoutClosing(e) {
      this.$emit("close");
    },
    reset() {
      this.url = null;
      this.fileName = null;
      this.ext = null;
      this.fileContent = null;

      this.slideouts.viewer.active = false;
      this.slideouts.viewer.fullWidth = false;
      this.slideouts.viewer.isLoading = false;
      this.slideouts.viewer.hasError = false;
    },
  },
  watch: {},
};
</script>

<style lang="scss">
.coco-viewer {
  overflow-x: hidden;

  .coco-viewer-code-section {
    text-align: initial;
    height: 100%;
    overflow: hidden;

    pre {
      height: 100%;
      overflow: hidden;

      code {
        overflow: overlay;
        height: 100%;
      }
    }
  }
  .coco-viewer-pdf-section {
    height: 100%;
    // flex: 8 0 10px;
  }
}
</style>
