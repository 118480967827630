<template>
  <div class="d-flex flex-column" v-if="parsedValue != null && parsedValue.length > 0">
    <ul>
      <li v-for="(accessory, index) in parsedValue" :key="index" class="mb-1">
        <div class="d-flex">
          <div class="mr-1" style="min-width: 64px">
            <entity-id
              :label="accessory.Id"
              :path="equipmentUrl(accessory.Id)"
              title="Equipment"
            ></entity-id>
          </div>
          <b>Count: {{ accessory.Count }}</b>
        </div>
      </li>
    </ul>
  </div>
  <div v-else>
    <i class="fad fa-empty-set fs-16px ml-2" style="opacity: 0.24"></i>
  </div>
</template>

<script>
import Enums from "../../../../plugins/enums";
export default {
  components: {},
  name: "accessories-logged-prop",
  inheritAttrs: false,
  data() {
    return {};
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    parsedValue() {
      return JSON.parse(this.value) || null;
    },
  },
  methods: {
    equipmentUrl(id) {
      if (id == null) return "";
      return `equipment/${id}`;
    },
  },
};
</script>

<style lang="scss"></style>
