<template>
  <v-expansion-panel class="power-spec-ep" v-if="selected != null">
    <v-expansion-panel-header class="px-4 py-0" :ripple="true">
      <v-row no-gutters justify="space-between">
        <v-col
          cols="auto"
          class="d-flex align-center flex-wrap"
          style="gap: 0.5rem"
        >
          <span class="font-weight-bold d-flex align-center mr-4">
            <span
              class="fa-stack mr-3"
              style="
                font-size: 18px;
                width: 18px;
                height: 18px;
                line-height: 18px;
              "
            >
              <i class="fad fa-ethernet fa-stack-1x"></i>
              <i
                class="fas fa-bolt fa-stack-1x"
                style="font-size: 8px; margin-top: -1px"
              ></i>
            </span>
            <span class="fs-14px">PoE Power Spec</span>
          </span>
          <v-btn-toggle
            v-model="selected.availability"
            dense
            rounded
            color="orange"
            mandatory
          >
            <v-btn
              :value="PowerSpecAvailability.Required.value"
              class="fs-12px"
              max-height="28px"
              @click.stop
              :disabled="readonly"
              color="transparent"
            >
              <i
                class="fa-circle-check mr-2"
                :class="{
                  'secondary--text fas':
                    selected.availability ==
                    PowerSpecAvailability.Required.value,
                  'text--secondary fal':
                    selected.availability !=
                    PowerSpecAvailability.Required.value,
                }"
              ></i>
              <span
                class="font-weight-bold"
                :class="{
                  'text--secondary':
                    selected.availability !=
                    PowerSpecAvailability.Required.value,
                }"
                >Required
              </span>
            </v-btn>
            <v-btn
              :value="PowerSpecAvailability.Optional.value"
              class="fs-12px"
              max-height="28px"
              @click.stop
              :disabled="readonly"
              color="transparent"
            >
              <i
                class="fa-circle-half-stroke mr-2"
                :class="{
                  'secondary--text fad':
                    selected.availability ==
                    PowerSpecAvailability.Optional.value,
                  'text--secondary fal':
                    selected.availability !=
                    PowerSpecAvailability.Optional.value,
                }"
              ></i>
              <span
                class="font-weight-bold"
                :class="{
                  'text--secondary':
                    selected.availability !=
                    PowerSpecAvailability.Optional.value,
                }"
                >Optional
              </span>
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols="auto" class="d-flex align-center">
          <v-tooltip top z-index="9999" v-if="!readonly">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="error"
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click.stop="deletePowerSpec"
              >
                <i class="far fa-trash-alt"></i>
              </v-btn>
            </template>
            <span>Delete PoE Power Spec</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row
        style="
          margin-left: -1rem;
          margin-right: -1rem;
          width: calc(100% + 2rem);
          margin-top: 0;
        "
      >
        <v-col cols="12" md="12" class="pa-0">
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto" class="d-flex align-center pb-0">
          <label class="input-label mr-4">Supported PoE Types</label>
        </v-col>
        <v-col
          cols="auto"
          class="d-flex align-center flex-row pb-0"
          style="gap: 3rem"
        >
          <v-checkbox
            class="ma-0 pa-0"
            color="orange"
            hide-details
            dense
            :readonly="readonly"
            :disabled="readonly"
            v-model="selected.poeTypes"
            :value="PoEType.PoeType1.value"
          >
            <template v-slot:label>
              <label
                class="v-switch-label ma-0"
                :class="{ 'orange--text': hasPoeType1 }"
                >{{ PoEType.PoeType1.text }}</label
              >
            </template>
          </v-checkbox>
          <v-checkbox
            class="ma-0 pa-0"
            color="orange"
            hide-details
            dense
            :readonly="readonly"
            :disabled="readonly"
            v-model="selected.poeTypes"
            :value="PoEType.PoeType2.value"
          >
            <template v-slot:label>
              <label
                class="v-switch-label ma-0"
                :class="{ 'orange--text': hasPoeType2 }"
                >{{ PoEType.PoeType2.text }}</label
              >
            </template>
          </v-checkbox>
          <v-checkbox
            class="ma-0 pa-0"
            color="orange"
            hide-details
            dense
            :readonly="readonly"
            :disabled="readonly"
            v-model="selected.poeTypes"
            :value="PoEType.PoeType3.value"
          >
            <template v-slot:label>
              <label
                class="v-switch-label ma-0"
                :class="{ 'orange--text': hasPoeType3 }"
                >{{ PoEType.PoeType3.text }}</label
              >
            </template>
          </v-checkbox>
          <v-checkbox
            class="ma-0 pa-0"
            color="orange"
            hide-details
            dense
            :readonly="readonly"
            :disabled="readonly"
            v-model="selected.poeTypes"
            :value="PoEType.PoeType4.value"
          >
            <template v-slot:label>
              <label
                class="v-switch-label ma-0"
                :class="{ 'orange--text': hasPoeType4 }"
                >{{ PoEType.PoeType4.text }}</label
              >
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-container fluid class="power-option-wrapper pa-6 py-4 mt-6">
        <v-row class="my-0">
          <v-col cols="auto" class="py-0" style="width: 164px">
            <v-text-field-alt
              :rules="[allRules.requiredNumber, allRules.number]"
              label="Max Watts"
              placeholder="Power Consumption"
              v-model.number="selected.maxWattDraw"
              @input="maxWattDrawChanged"
              :readonly="readonly"
              hide-details
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="auto" class="py-0" style="width: 164px">
            <v-text-field-alt
              :rules="[allRules.requiredNumber, allRules.number]"
              placeholder="HeatLoad"
              label="Max BTU/Hr"
              v-model.number="selected.maxHeatLoad"
              :readonly="readonly"
              hide-details
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="auto" class="py-0" style="width: 164px">
            <v-text-field-alt
              :rules="[allRules.requiredNumber, allRules.number]"
              id="equipmentHvacTonnage"
              placeholder="Tonnage"
              label="Max HVAC"
              v-model.number="selected.maxHvacTonnage"
              :readonly="readonly"
              hide-details
            >
            </v-text-field-alt>
          </v-col>
        </v-row>
      </v-container>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import Enums from "../../../../plugins/enums";

export default {
  name: "poe-power-spec-ep",
  props: {
    value: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      PowerSpecAvailability: Enums.PowerSpecAvailability,
      PoEType: Enums.PoEType,
      valid: false,
      selected: {},
    };
  },
  computed: {
    hasPoeType1() {
      return this.selected.poeTypes
        ? this.selected.poeTypes.includes(this.PoEType.PoeType1.value)
          ? true
          : false
        : false;
    },
    hasPoeType2() {
      return this.selected.poeTypes
        ? this.selected.poeTypes.includes(this.PoEType.PoeType2.value)
          ? true
          : false
        : false;
    },
    hasPoeType3() {
      return this.selected.poeTypes
        ? this.selected.poeTypes.includes(this.PoEType.PoeType3.value)
          ? true
          : false
        : false;
    },
    hasPoeType4() {
      return this.selected.poeTypes
        ? this.selected.poeTypes.includes(this.PoEType.PoeType4.value)
          ? true
          : false
        : false;
    },
  },
  mounted() {
    this.selected = this.cloneDeep(this.value);
  },
  methods: {
    deletePowerSpec() {
      this.$emit("delete");
    },
    isNum(value) {
      // return /^[0-9]+.[0-9]+$/.test(value);
      return /^[0-9]+.[0-9]+|[0-9]+$/.test(value);
    },
    refreshHeatAndHvac() {
      if (this.selected == null || !this.isNum(this.selected.maxWattDraw))
        return;

      //Heat Load = POWER CONSUMPTION * 3.412
      //HVAC Tonnage = (POWER CONSUMPTION * 3.412) / 12000
      let pc = parseFloat(this.selected.maxWattDraw);
      this.selected.maxHeatLoad = (pc * 3.412).toFixed(2);
      this.selected.maxHvacTonnage = ((pc * 3.412) / 12000).toFixed(5);
    },
    deletePowerOption() {
      this.$emit("delete");
    },
    maxWattDrawChanged(value) {
      // Amps = Watts / Volts
      this.refreshHeatAndHvac();
    },
  },
  watch: {
    valid() {
      this.$emit("valid-changes", this.valid);
    },
    value: {
      handler() {
        if (this.isEqual(this.value, this.selected)) return;
        this.selected = this.cloneDeep(this.value);
      },
      deep: true,
    },
    selected: {
      handler() {
        if (this.isEqual(this.value, this.selected)) return;
        this.$emit("input", this.selected);
      },
      deep: true,
    },
  },
  components: {},
};
</script>
<style lang="scss">
.power-specs-content {
  flex: 1 1 auto;
  border-radius: 0.35rem;
  overflow: hidden;
  transition: all 0.3s ease-out;

  &.power-specs-content-selected {
    background: #eceff1;
  }
}
.power-spec-ep {
  .v-expansion-panel-content__wrap {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
</style>
