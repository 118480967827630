import perms from "../../plugins/permissions";
export default [
  {
    path: "/personal-settings",
    name: "personal-settings",
    meta: {
      layout: "dashboard",
      title: "Personal Settings",
      permissions: [perms.PersonalSettings.View],
    },
    component: () => import("./views/PersonalSettings.vue"),
  },
];
