<template>
  <div class="d-flex ml-1 flex-column" v-if="parsedValue != null && parsedValue.length != 0">
    <ol>
      <li v-for="(wirelessInterface, index) in orderedWirelessInterfaces" :key="index">
        <div class="d-flex flex-row flex-wrap" style="gap: 0.25rem 0.5rem">
          <span class="fs-14px font-weight-bold">{{ wirelessInterface.Name }}</span>
        </div>
        <h4 class="font-weight-bold fs-12px mt-1">
          <i class="fad fa-caret-right mr-1"></i>Supported Wireless Interface Protocols
        </h4>
        <div class="d-flex flex-row flex-wrap ml-3" style="gap: 0.35rem 0.35rem">
          <!-- <span v-for="(protocol, index) in wirelessInterface.InterfaceProtocoals" :key="index">{{
            getInterfaceProtocol(protocol).text
          }}</span> -->
          <v-chip
            v-for="(protocol, index) in wirelessInterface.InterfaceProtocoals"
            :key="index"
            small
            color="grey lighten-3"
            text-color="secondary"
            style="height: 18px"
            >{{ getInterfaceProtocol(protocol).text }}</v-chip
          >
          <span v-if="wirelessInterface.InterfaceProtocoals.length == 0">
            <i class="fad fa-empty-set fs-12px mt-1" style="opacity: 0.24"></i>
          </span>
        </div>
        <v-divider v-if="index != orderedWirelessInterfaces.length - 1" class="my-1"></v-divider>
      </li>
    </ol>
  </div>
  <div v-else>
    <i class="fad fa-empty-set fs-16px ml-2" style="opacity: 0.24"></i>
  </div>
</template>

<script>
import Enums from "../../../../plugins/enums";
export default {
  components: {},
  name: "wireless-interfaces-logged-prop",
  inheritAttrs: false,
  data() {
    return {};
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    parsedValue() {
      var val = this.value == "" || this.value == null ? "[]" : this.value;
      return JSON.parse(val) || null;
    },
    orderedWirelessInterfaces() {
      if (this.parsedValue == null) return this.parsedValue;
      var ordered = this.parsedValue.slice(0).sort(this.compareFunc);
      return ordered;
    },
  },
  methods: {
    compareFunc(interfaceA, interFaceB) {
      if (interfaceA.Order < interFaceB.Order) {
        return -1;
      }
      if (interfaceA.Order > interFaceB.Order) {
        return 1;
      }
      // a must be equal to b
      return 0;
    },
    getInterfaceProtocol(type) {
      return this.getEnumMember(Enums.WirelessProtocolType, type);
    },
  },
};
</script>

<style lang="scss"></style>
