<template>
  <div class="d-flex flex-column" style="gap: 0.25rem">
    <span v-if="hasValue(value.addressLine1)">{{ value.addressLine1 }}</span>
    <span v-if="hasValue(value.addressLine2)">{{ value.addressLine2 }}</span>
    <span>
      <span v-if="hasValue(value.city)">{{ value.city }}, </span>
      <span v-if="hasValue(value.state)">{{ value.state }}, </span>
      <span v-if="hasValue(value.postalCode)">{{ value.postalCode }}</span>
    </span>
    <span v-if="hasValue(value.country)">{{ value.country }}</span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  methods: {
    hasValue(dataStr) {
      if (dataStr != null && dataStr.trim() != "") return true;
      return false;
    },
  },
};
</script>

<style></style>
