<template>
  <v-autocomplete-alt
    auto-select-first
    :rules="required ? [allRules.required] : []"
    :label="hideLabel ? null : 'Category'"
    placeholder="Equipment Category"
    v-model="selected"
    @input="categoryChanged"
    :items="categories"
    :filter="categoriesFilter"
    dense
    filled
    item-value="id"
    item-text="name"
    :loading="isCategoriesLoading"
    :disabled="isCategoriesLoading"
    :readonly="readonly"
    :clearable="clearable"
    :hide-details="hideDetails"
    :menu-props="{ contentClass: 'equipment-category-selector-menu' }"
  >
    <template v-slot:selection="data">
      <v-row align-content="center" justify="start" no-gutters style="flex: none">
        <v-col sm="auto" class="d-flex align-center mr-2">
          <i class="equipment-icon fad" :class="data.item.icon"></i>
        </v-col>
        <v-col class="d-flex align-center col-auto">
          <div class="d-flex align-center">
            {{ data.item.name }}
            <span class="ml-2 text--disabled">
              <i class="fas fa-hashtag fs-12px mr-1"></i>{{ data.item.number }}
            </span>
          </div>
        </v-col>
      </v-row>
    </template>
    <template v-slot:item="data">
      <v-row align-content="center" justify="start" no-gutters style="flex: none">
        <v-col sm="auto" class="d-flex align-center mr-2">
          <i class="equipment-icon fad" :class="data.item.icon"></i>
        </v-col>
        <v-col class="d-flex align-center col-auto">
          <div class="d-flex align-center">
            {{ data.item.name }}
            <span class="ml-2 text--disabled">
              <i class="fas fa-hashtag fs-12px mr-1"></i>{{ data.item.number }}
            </span>
          </div>
        </v-col>
      </v-row>
    </template>
  </v-autocomplete-alt>
</template>

<script>
import categoriesAPI from "../../Equipments/services/categories-service";

export default {
  name: "equipment-category-selector",
  props: {
    value: {
      type: [Number, String],
    },
    obj: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      categories: [],
      selected: null,
      isCategoriesLoading: false,
    };
  },
  methods: {
    categoryChanged(id) {
      this.$emit("input", id);
      this.$emit("update:obj", id ? this.categories.find((elm) => elm.id == id) : null);
    },
    categoriesFilter(item, queryText, itemText) {
      const textOne = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },
    getCategories() {
      this.isCategoriesLoading = true;
      categoriesAPI
        .get()
        .then((resp) => {
          this.categories = resp.data.items;
          this.isCategoriesLoading = false;
        })
        .catch((err) => {
          this.isCategoriesLoading = false;
          this.$handleError(err);
        });
    },
  },
  mounted() {
    this.selected = this.value;
    this.getCategories();
  },
  watch: {
    value() {
      this.selected = this.value;
    },
  },
};
</script>
<style lang="scss">
.equipment-category-selector-menu {
  max-width: 500px;
}
</style>
