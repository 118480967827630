<template>
  <v-container fluid class="px-6 pt-2" style="background: #eceff1">
    <v-row class="my-0" justify="center" align="center">
      <v-col>
        <h3>Projects that contains this Equipment!</h3>
      </v-col>
      <v-col
        lg="5"
        md="2"
        sm="6"
        class="d-flex flex-row align-center justify-end flex-wrap"
        style="gap: 0.5rem"
      >
        <v-text-field
          v-model="options.search"
          label="Search"
          ref="mainSearch"
          class="table-search-field"
          @focus="mainSearchInFocus = true"
          @blur="mainSearchInFocus = false"
          :style="{
            'max-width':
              mainSearchInFocus || (options.search != '' && options.search != null)
                ? '200px'
                : '110px',
          }"
          dense
          solo
          clearable
          hide-details
          prepend-inner-icon="far fa-search"
          :loading="loadingStates.table"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      dense
      class="elevation-2"
      :headers="headers"
      :items="entities"
      :server-items-length="total"
      :items-per-page="15"
      :loading="loadingStates.table"
      :options.sync="options"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'far fa-arrow-to-left',
        lastIcon: 'far fa-arrow-to-right',
        prevIcon: 'far fa-angle-left',
        nextIcon: 'far fa-angle-right',
        itemsPerPageOptions: [15, 30, 50, 100],
      }"
    >
      <template v-slot:[`header.jiraCode`]="{ header }">
        <i :class="header.icon"></i>
        {{ header.text.toUpperCase() }}
      </template>

      <template v-slot:[`item.jiraCode`]="{ item }">
        <a
          v-if="item.jiraCode != null && item.jiraCode != ''"
          target="_blank"
          class="jira-external-link"
          @click.stop
          :href="'https://dangeloconsultants.atlassian.net/browse/' + item.jiraCode"
        >
          <span class="icon-wrpr mr-1">
            <i class="fab fa-jira org-icon"></i>
            <i class="far fa-external-link alt-icon"></i>
          </span>
          <span>{{ item.jiraCode }}</span>
        </a>
        <span v-else class="text--disabled">
          <i class="fad fa-unlink"></i>
        </span>
      </template>

      <template v-slot:[`item.client`]="{ item }">
        <v-tooltip top nudge-top="-16px">
          <template v-slot:activator="{ on, attrs }">
            <div class="pa-2" v-bind="attrs" v-on="on" @click.stop="viewClient(item.client.id)">
              <v-img
                v-if="item.client.logoUrl != null && item.client.logoUrl != ''"
                :src="item.client.logoUrl"
                max-height="28"
                max-width="54"
                position="left center"
                contain
              >
              </v-img>
              <v-img
                v-else
                src="/img/DNA_Symbol.png"
                max-height="28"
                max-width="54"
                position="left center"
                contain
              >
              </v-img>
            </div>
          </template>
          <span>{{ item.client.name }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.fullName`]="{ item }">
        <h4>{{ item.fullName }}</h4>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <project-status :status="item.status" small show-parent></project-status>
      </template>

      <template v-slot:[`item.spaceCount`]="{ item }">
        <space-count :count="item.spaceCount" />
      </template>

      <template v-slot:[`item.equipmentCount`]="{ item }">
        <equipment-count :count="item.equipmentCount" />
      </template>

      <template v-slot:[`item.totalMsrpPrice`]="{ item }">
        <div class="project-total">
          <v-tooltip left z-index="999" nudge-left="-4px">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                class="d-inline-block"
                style="font-size: 14px; font-weight: 600 !important"
              >
                {{ totalMSRPWithInstallCost(item) | usdFormat }}
              </div>
            </template>
            <span>{{ item.name }}</span>
            <v-divider class="my-1 d-block" style="width: 100%" dark></v-divider>
            <span class="d-flex flex-column align-end" style="gap: 0.25rem">
              <span style="font-size: 12px; font-weight: 500 !important">
                <b class="blue--text mr-1" style="font-size: 12px; font-weight: 700 !important">
                  {{ installationFactor(item) }}x
                </b>
                {{ installationCost(item) | usdFormat }}
                <i class="fad fa-wrench ml-1"></i>
              </span>
              <span>
                {{ item.totalMsrpPrice | usdFormat }}
                <i class="fad fa-usd-square ml-1"></i>
              </span>
              <v-divider
                class="my-1 d-block"
                style="width: 100%; border-style: dashed !important"
                dark
              ></v-divider>
              <span style="font-size: 16px; font-weight: 600 !important">
                {{ totalMSRPWithInstallCost(item) | usdFormat }}
              </span>
            </span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:[`item.stage`]="{ item }">
        <project-stage :stage="item.stage" small></project-stage>
      </template>

      <template v-slot:[`item.createDate`]="{ item }">
        <dater :date="item.createDate" dateonly></dater>
      </template>

      <template v-slot:[`item.updateDate`]="{ item }">
        <dater :date="item.updateDate" dateonly></dater>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          icon
          elevation="0"
          color="info"
          target="_blank"
          @click.stop
          :href="`/projects/${item.id}`"
        >
          <i class="fal fa-external-link"></i>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <img width="300" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>

      <template v-slot:loading>
        <video width="250" muted loop autoplay style="padding: 0.5rem">
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Searching the Cosmos...</p>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import ProjectStatus from "../../Projects/components/ProjectStatus.vue";
import ProjectStage from "../../Projects/components/ProjectStage.vue";
import equipmentService from "../services/equipments-service";
import associatedProject from "../../Projects/config/tables/associatedProject.header";

export default {
  components: { ProjectStatus, ProjectStage },
  name: "equipment-has-projects",
  props: {
    equipment: {
      type: Object,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    storageKey: {
      type: String,
    },
  },
  data() {
    return {
      entities: [],
      total: 0,
      clientsTimerId: null,
      mainSearchInFocus: false,
      options: {
        search: null,
        page: 1,
        itemsPerPage: 15,
      },
      loadingStates: {
        table: false,
      },
      headers: associatedProject,
    };
  },
  created() {
    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
  },
  methods: {
    installationFactor(project) {
      return project.installationFactor || 0.25;
    },
    installationCost(project) {
      return this.installationFactor(project) * project.totalMsrpPrice;
    },
    totalMSRPWithInstallCost(project) {
      return project.totalMsrpPrice + this.installationCost(project);
    },
    getEquipmentItems() {
      this.$backToTop(
        0,
        document.querySelector(".equipment-stats-tabs.v-window.v-item-group.v-tabs-items")
      );
      this.loadingStates.table = true;
      if (this.equipment) {
        equipmentService
          .getEquipmentItems(this.equipment.id, this.$clean(this.options, true), "Projects")
          .then((resp) => {
            this.entities = resp.data.items;
            this.total = resp.data.total;
            this.loadingStates.table = false;
          })
          .catch(() => {
            this.loadingStates.table = false;
          });
      }
    },
    getProjectDebounced() {
      if (this.clientsTimerId == null) {
        this.clientsTimerId = -1;
        this.getEquipmentItems();
        return;
      }
      // cancel pending call
      clearTimeout(this.clientsTimerId);

      // delay new call 400ms
      this.clientsTimerId = setTimeout(() => {
        this.getEquipmentItems();
      }, 400);
    },
  },
  watch: {
    options: {
      handler(val) {
        this.$setToLocal(`${this.storageKey}-itemsPerPage`, val.itemsPerPage);
        this.getProjectDebounced();
      },
      deep: true,
    },
    active(val) {
      if (val) {
        this.options.page = 1;
        this.getProjectDebounced();
      } else {
        this.entities = [];
        this.total = 0;
      }
    },
  },
};
</script>
