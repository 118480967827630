<template>
  <div class="quick-tools-bar">
    <v-speed-dial v-model="fab" direction="bottom" transition="slide-y-transition">
      <template v-slot:activator>
        <!-- <v-btn
          v-model="fab"
          text
          class="quick-tools-btn px-1"
          min-height="33.6px"
          height="33.6px"
          small
        >
          <i v-if="fab" class="far fa-times"></i>
          <i v-else class="fad fa-wand-magic-sparkles"></i>
          <i v-if="false" class="fad fa-wrench-simple"></i>
        </v-btn> -->
        <v-btn :text="!mini" :icon="mini" class="quick-tools-btn" v-model="fab" ref="quickToolsBtn">
          <i class="fad fa-wrench-simple"></i>
          <v-scroll-x-transition mode="out-in">
            <span v-if="!mini" class="ml-3" key="quick-tools-btn-text">Quick Tools</span>
          </v-scroll-x-transition>
          <v-scroll-x-transition mode="out-in">
            <kbd
              class="light ml-3 fs-12px"
              v-if="!$vuetify.breakpoint.mobile && !mini"
              key="quick-tools-btn-keyboard"
              >CTRL<span class="opacity-54">+</span>Q</kbd
            >
          </v-scroll-x-transition>
        </v-btn>
      </template>
      <v-tooltip
        color="white"
        right
        content-class="elevation-5 opacity-100"
        v-model="showTooltips"
        eager
        transition="slide-x-transition"
        nudge-bottom="15px"
        :open-on-click="false"
        :open-on-focus="false"
        :open-on-hover="false"
        z-index="1750"
        v-for="tool in tools"
        :key="tool.key"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            :color="tool.isBtnFocused ? 'info' : 'secondary'"
            v-bind="attrs"
            v-on="on"
            :elevation="tool.isBtnFocused ? 12 : 6"
            @click="openTool(tool)"
            @focus="($event) => (tool.isBtnFocused = true)"
            @blur="($event) => (tool.isBtnFocused = false)"
            :ref="tool.btnRef"
          >
            <i class="fs-18px" :class="[tool.icon, tool.isBtnFocused ? 'fas' : 'fad']"></i>
          </v-btn>
        </template>
        <span class="secondary--text font-weight-medium">{{ tool.title }}</span>
      </v-tooltip>
    </v-speed-dial>
    <btu-calculator ref="BtuCalculator"></btu-calculator>
    <project-cost-estimator ref="ProjectCostEstimator"></project-cost-estimator>
  </div>
</template>

<script>
import BtuCalculator from "./BtuCalculator.vue";
import ProjectCostEstimator from "./ProjectCostEstimator.vue";

export default {
  components: { BtuCalculator, ProjectCostEstimator },
  name: "quick-tools",
  data() {
    return {
      showTooltips: true,
      fab: false,
      ctrlKeyActive: false,
      tools: [
        {
          value: "BTU",
          title: "BTU Calculator",
          icon: "fa-temperature-low",
          key: "BtuCalculator",
          componentRef: "BtuCalculator",
          isBtnFocused: false,
          btnRef: "toolBtnBtuCalculator",
        },
        {
          value: "PCE",
          title: "Project Cost Estimator",
          icon: "fa-circle-dollar",
          key: "ProjectCostEstimator",
          componentRef: "ProjectCostEstimator",
          isBtnFocused: false,
          btnRef: "toolBtnProjectCostEstimator",
        },
      ],
    };
  },
  props: {
    mini: {
      type: Boolean,
      default: null,
    },
  },
  mounted() {
    document.addEventListener("keyup", (event) => {
      this.ctrlKeyActive = event.ctrlKey;
    });
    document.addEventListener("keydown", (event) => {
      this.ctrlKeyActive = event.ctrlKey;
      if (event.code == "KeyQ" && event.ctrlKey) {
        event.preventDefault();
        this.open();
      } else if (event.code == "Escape") {
        this.close();
      }
    });
    var parent = document.querySelector(".quick-tools-bar .v-speed-dial__list");
    parent.addEventListener("focusout", (event) => {
      if (!this.fab) return;
      event.stopPropagation();

      if (parent.contains(event.relatedTarget)) {
        // if focus moved to another parent descend
        return;
      }

      // parent.focus(); // otherwise focus on parent or change to another dom
      if (this.$refs[this.tools[0].btnRef].length > 0) {
        this.$refs[this.tools[0].btnRef][0].$el.focus();
      }
    });
  },
  computed: {},
  methods: {
    openTool(tool) {
      this.$refs[tool.componentRef].open();
    },
    open() {
      this.tools.forEach((tool) => {
        this.$refs[tool.componentRef].close();
      });
      this.fab = true;
    },
    close() {
      this.fab = false;
    },
  },
  watch: {
    fab: {
      handler() {
        if (this.fab) {
          document.getElementsByClassName("app-main-container")[0].classList.add("has-overlay");
          document.getElementsByClassName("toolbar-custom-card")[0].classList.add("show-on-top");
          setTimeout(() => {
            if (this.$refs[this.tools[0].btnRef].length > 0) {
              this.$refs[this.tools[0].btnRef][0].$el.focus();
            }
          }, 50);
        } else {
          document.getElementsByClassName("app-main-container")[0].classList.remove("has-overlay");
          document.getElementsByClassName("toolbar-custom-card")[0].classList.remove("show-on-top");
        }
      },
    },
  },
};
</script>

<style lang="scss">
.quick-tools-dialog {
  align-self: flex-start;
  margin-top: 4rem;
}
</style>

<style lang="scss">
@-moz-document url-prefix() {
  kbd.light {
    padding: 2px 4px 3px 4px;
  }
}

.quick-tools-bar {
  z-index: 1750;

  .v-speed-dial__list {
    align-items: flex-start !important;
  }
  .quick-tools-btn {
    color: rgba($shades-black, 0.87);
    cursor: pointer;

    .v-btn__content > i {
      font-size: 14px !important;
    }
    .v-btn__content > span {
      font-size: 12px !important;
      font-weight: 600;
    }

    kbd {
      min-width: 24px;
    }
  }
}
</style>
