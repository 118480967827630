var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.project)?_c('div',{staticClass:"project-card",on:{"click":_vm.cardClicked}},[_c('div',{staticClass:"folder-top"},[_vm._v("Client")]),_c('div',{staticClass:"folder-content"},[_c('div',{staticClass:"project-header"},[_c('div',{staticClass:"project-client"},[_c('v-tooltip',{attrs:{"bottom":"","nudge-top":"20"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(
                  _vm.project.client != null &&
                  _vm.project.client.logoUrl != null &&
                  _vm.project.client.logoUrl != ''
                )?_c('v-img',{attrs:{"src":_vm.project.client.logoUrl,"height":"32","max-width":"64","position":"left center","contain":""}}):_c('i',{staticClass:"fad fa-user-tie fa-swap-opacity fa-2x",staticStyle:{"opacity":"0.24"}})],1)]}}],null,false,863907485)},[_c('span',[_vm._v(_vm._s(_vm.project.client.name))])])],1),_c('div',{staticClass:"project-total"},[_c('v-tooltip',{attrs:{"left":"","z-index":"999","nudge-left":"-4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-inline-block",staticStyle:{"font-size":"14px","font-weight":"600 !important"}},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("usdFormat")(_vm.totalMSRPWithInstallCost))+" ")])]}}],null,false,2313749886)},[_c('span',[_vm._v(_vm._s(_vm.project.name))]),_c('v-divider',{staticClass:"my-1 d-block",staticStyle:{"width":"100%"},attrs:{"dark":""}}),_c('span',{staticClass:"d-flex flex-column align-end",staticStyle:{"gap":"0.25rem"}},[_c('span',{staticStyle:{"font-size":"12px","font-weight":"500 !important"}},[(_vm.installationFactor)?_c('b',{staticClass:"blue--text mr-1",staticStyle:{"font-size":"12px","font-weight":"700 !important"}},[_vm._v(" "+_vm._s(_vm.installationFactor)+"x ")]):_vm._e(),_vm._v(" "+_vm._s(_vm._f("usdFormat")(_vm.installationCost))+" "),_c('i',{staticClass:"fad fa-wrench ml-1"})]),_c('span',[_vm._v(" "+_vm._s(_vm._f("usdFormat")(_vm.project.totalMsrpPrice))+" "),_c('i',{staticClass:"fad fa-usd-square ml-1"})]),_c('v-divider',{staticClass:"my-1 d-block",staticStyle:{"width":"100%","border-style":"dashed !important"},attrs:{"dark":""}}),_c('span',{staticStyle:{"font-size":"16px","font-weight":"600 !important"}},[_vm._v(" "+_vm._s(_vm._f("usdFormat")(_vm.totalMSRPWithInstallCost))+" ")])],1)],1)],1)]),_c('label',[_vm._v("Project")]),_c('h3',{staticClass:"project-name"},[_vm._v(_vm._s(_vm.project.name))]),_c('dater',{staticClass:"project-date",attrs:{"date":_vm.project.createDate,"dateonly":""}}),_c('div',{staticClass:"project-footer"},[_c('div',{staticClass:"project-stats"},[_c('space-count',{attrs:{"count":_vm.project.spaceCount}}),_c('equipment-count',{attrs:{"count":_vm.project.equipmentCount}})],1),(false)?_c('div',{staticClass:"project-actions"},[_c('v-btn',{attrs:{"icon":"","elevation":"0"},on:{"click":function($event){return _vm.goToProject(_vm.project.id)}}},[_c('i',{staticClass:"fal fa-pen"})])],1):_vm._e()])],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }