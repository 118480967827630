<template>
  <div class="d-flex ml-1 flex-column" v-if="parsedValue != null">
    <span class="d-flex">
      <v-chip
        v-if="parsedValue.Availability == PowerSpecAvailability.Required.value"
        small
        outlined
        color="orange"
        text-color="orange"
        style="height: 22px"
      >
        <i class="fas fa-circle-check mr-2 secondary--text"></i>
        <span class="font-weight-bold text--orange"> Required </span>
      </v-chip>
      <v-chip v-else small outlined color="orange" text-color="orange" style="height: 22px">
        <i class="fas fa-circle-half-stroke mr-2 secondary--text"></i>
        <span class="font-weight-bold text--orange"> Optional </span>
      </v-chip>
    </span>
    <h4 class="font-weight-bold fs-12px mt-1"><i class="fad fa-caret-right mr-1"></i>Poe Types</h4>
    <div class="d-flex flex-row flex-wrap ml-3" style="gap: 0.25rem 0.5rem">
      <span v-for="(type, index) in parsedValue.PoeTypes" :key="index">{{
        getPoeType(type.SupportedPoEType).text
      }}</span>
    </div>
    <h4 class="font-weight-bold fs-12px mt-1"><i class="fad fa-caret-right mr-1"></i>Option</h4>
    <div class="d-flex flex-row flex-wrap ml-3" style="gap: 0.25rem 0.5rem">
      <span><b>Max Watt Draw:</b> {{ parsedValue.MaxWattDraw }}</span>
      <span><b>Max HeatLoad:</b> {{ parsedValue.MaxHeatLoad }}</span>
      <span><b>Max Hvac Tonnage:</b> {{ parsedValue.MaxHvacTonnage }}</span>
    </div>
  </div>
  <div v-else>
    <i class="fad fa-empty-set fs-16px ml-2" style="opacity: 0.24"></i>
  </div>
</template>

<script>
import Enums from "../../../../plugins/enums";
export default {
  components: {},
  name: "poe-power-spec-logged-prop",
  inheritAttrs: false,
  data() {
    return {
      PowerSpecAvailability: Enums.PowerSpecAvailability,
    };
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    parsedValue() {
      var val = this.value == "" ? null : this.value;
      return JSON.parse(val) || null;
    },
  },
  methods: {
    getPoeType(type) {
      return this.getEnumMember(Enums.PoEType, type);
    },
  },
};
</script>

<style lang="scss"></style>
