<template>
  <v-container fluid class="pa-6 pt-2" style="background: #eceff1">
    <v-row class="my-2" justify="space-between">
      <v-col>
        <v-btn
          v-if="!readonly"
          color="info"
          ref="addSuggestedEquipmentsBtn"
          @click="openSuggestedEquipmentsPanel()"
        >
          <i class="fal fa-plus mr-2"></i>Add Suggested Equipment
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      dense
      :headers="equipmentSuggestedEquipmentsHeaders"
      :items="value"
      :item-class="rowClass"
      :items-per-page="-1"
      class="elevation-2"
      hide-default-footer
    >
      <template v-slot:[`item.info`]="{ item }">
        <v-btn
          color="secondary"
          icon
          elevation="0"
          @click="viewEquipment(item.suggested.id)"
          small
        >
          <i class="far fa-info"></i>
        </v-btn>
      </template>

      <template v-slot:[`item.suggested.name`]="{ item }">
        <v-tooltip right z-index="999" nudge-right="-4px">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <div class="equipment-img">
                <div
                  style="height: 100%; width: 100%"
                  v-viewer
                  @click.stop
                  v-if="
                    item.suggested.imageUrl != null &&
                      item.suggested.imageUrl != ''
                  "
                >
                  <img
                    :key="item.suggested.id + '_img'"
                    :src="item.suggested.imageUrl"
                    height="100%"
                    width="100%"
                  />
                </div>
                <i
                  v-else-if="item.suggested.category != null"
                  :class="'fad ' + item.suggested.category.icon"
                ></i>
                <i v-else :class="'fad fa-plug'"></i>
              </div>
            </div>
          </template>
          <span>
            <v-img
              v-if="
                (item.suggested.imageUrl != null) &
                  (item.suggested.imageUrl != '')
              "
              class="img"
              :src="item.suggested.imageUrl"
              width="250px"
              height="250px"
              contain
            ></v-img>
            <i
              v-else-if="item.suggested.category != null"
              :class="'fad ' + item.suggested.category.icon"
              style="font-size: 32px; padding: 2rem"
            ></i>
            <i
              v-else
              :class="'fad fa-plug'"
              style="font-size: 32px; padding: 2rem"
            ></i>
          </span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.suggested.category.name`]="{ item }">
        <v-row no-gutters>
          <v-col
            sm="auto"
            class="d-flex justify-content-start align-items-center"
          >
            <i
              :class="'equipment-icon fad ' + item.suggested.category.icon"
              v-if="item.suggested.category"
            ></i>
          </v-col>
          <v-col class="d-flex align-center">
            <span class="category-name" v-if="item.suggested.category">{{
              item.suggested.category.name
            }}</span>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.suggested.tag`]="{ item }">
        <div class="equipment-tag font-weight-bold">
          {{ item.suggested.tag }}
        </div>
      </template>

      <template v-slot:[`item.suggested.model`]="{ item }">
        <div class="equipment-model">{{ item.suggested.model }}</div>
      </template>

      <template v-slot:[`item.suggested.providerId`]="{ item }">
        <equipment-provider
          v-if="item.suggested.provider"
          :provider-name="item.suggested.provider.name"
        ></equipment-provider>
        <i v-else :class="'far fa-times pl-2 text--disabled'"></i>
      </template>

      <template v-slot:[`item.suggested.installerId`]="{ item }">
        <equipment-installer
          v-if="item.suggested.installer"
          :installer-name="item.suggested.installer.name"
        ></equipment-installer>
        <i v-else :class="'far fa-times pl-2 text--disabled'"></i>
      </template>

      <template v-slot:[`item.count`]="{ item }">
        <counter
          :key="item.suggested.id"
          v-model="item.count"
          :min="0"
          :max="1000"
          @input="onSuggestedEquipmentsCountChange($event, item.suggested)"
          :readonly="readonly"
        />
      </template>

      <template v-slot:no-data>
        <img width="300" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>
    </v-data-table>

    <edit-equipment ref="editEquipment" nested></edit-equipment>

    <slideout
      dock="right"
      size="87%"
      v-if="!readonly"
      :visible.sync="slideouts.addSuggestedEquipments.active"
      :closeOnMaskClick="true"
    >
      <template v-slot:header>
        <h3 class="font-weight-bold pa-1" small>
          <i class="fad fa-plus-square mr-2"></i> Add Suggested Equipment
        </h3>
        <v-btn @click="discardAddSuggestedEquipments()" icon
          ><i class="far fa-times"></i
        ></v-btn>
      </template>
      <v-container fluid class="pa-6 pt-2" style="background: #eceff1">
        <add-accessories
          :value="value"
          :disabledAccessories="selectedId ? [selectedId] : null"
          ref="addSuggestedEquipmentsControl"
          @input="onAddSuggestedEquipmentsChange"
          v-if="slideouts.addSuggestedEquipments.active"
          type="suggested"
        ></add-accessories>
      </v-container>
      <template v-slot:footer>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="info"
            @click="slideouts.addSuggestedEquipments.active = false"
          >
            <i class="fal fa-check mr-2"></i> Done
          </v-btn>
        </v-card-actions>
      </template>
    </slideout>
  </v-container>
</template>

<script>
import AddAccessories from "./AddAccessories.vue";
import EquipmentProvider from "./EquipmentProvider.vue";
import EquipmentInstaller from "./EquipmentInstaller.vue";
import editEquipmentSuggested from "../config/tables/editEquipmentSuggested.header";

export default {
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => []
    },
    selectedId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      slideouts: {
        addSuggestedEquipments: {
          active: false,
          isLoading: false
        }
      },
      equipmentSuggestedEquipmentsHeaders: editEquipmentSuggested
    };
  },
  components: {
    AddAccessories,
    EquipmentProvider,
    EquipmentInstaller
  },
  methods: {
    viewEquipment(equipmentId) {
      this.$refs.editEquipment.open(equipmentId);
    },
    openSuggestedEquipmentsPanel() {
      this.slideouts.addSuggestedEquipments.active = true;
      setTimeout(() => {
        this.$refs.addSuggestedEquipmentsControl.$refs.mainSearch.focus();
      }, 350);
    },
    rowClass(item) {
      return item.count > 0 ? "row-not-empty" : "";
    },
    discardAddSuggestedEquipments() {
      this.slideouts.addSuggestedEquipments.active = false;
    },
    onAddSuggestedEquipmentsChange(val) {
      this.$emit("input", val);
    },
    onSuggestedEquipmentsCountChange(newVal, suggested) {
      let arr = [...this.value];
      let foundIndex = arr.findIndex(e => e.suggested.id === suggested.id);
      if (newVal == 0 && foundIndex != -1) {
        arr.splice(foundIndex, 1);
        this.$emit("input", arr);
      }
    }
  }
};
</script>

<style></style>
