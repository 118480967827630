<template>
  <div v-if="status != null && statusObj != null">
    <v-chip
      class="px-2"
      :small="!small"
      :x-small="small"
      :style="{ minWidth: width || 'auto', justifyContent: 'center' }"
      :color="'secondary' || statusObj.color"
      :text-color="'white' || statusObj.textColor"
    >
      <i v-if="!hideIcon" class="fas mr-2" :class="statusObj.icon" style="line-height: 0"></i>
      <span class="font-weight-medium" style="line-height: 0">{{
        statusObj.text.toUpperCase()
      }}</span>
    </v-chip>
  </div>
</template>

<script>
export default {
  name: "status-item",
  data() {
    return {
      statusObj: null,
    };
  },
  props: {
    status: {
      type: Number,
      default: null,
    },
    width: {
      type: String,
      default: null,
    },
    small: {
      type: Boolean,
      default: null,
    },
    hideIcon: {
      type: Boolean,
      default: null,
    },
    statusList: {
      type: Object,
    },
  },
  mounted() {
    this.updateStatusObj();
  },

  methods: {
    updateStatusObj() {
      if (this.status != null) {
        this.statusObj = this.getEnumMember(this.statusList, this.status);
      }
    },
  },
  watch: {
    status: {
      handler() {
        this.updateStatusObj();
      },
    },
  },
};
</script>
