<template>
  <v-app>
    <div id="app" :is="layout">
      <transition name="fade" mode="out-in" appear>
        <router-view></router-view>
      </transition>
    </div>
  </v-app>
</template>
<script>
const default_layout = "default";
export default {
  mounted() {},
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.apexcharts-toolbar {
  z-index: 8 !important;
}
</style>
