export default [
  {
    path: "/assets",
    name: "assets",
    meta: { layout: "dashboard", title: "Assets" },
    component: () => import("./views/Assets.vue"),
  },
  {
    path: "/not-authorizd",
    name: "error-not-authorizd",
    meta: { layout: "dashboard" },
    component: () => import("./views/ErrorNotAuthorized.vue"),
  },
  {
    path: "*",
    name: "error404",
    meta: { layout: "dashboard" },
    component: () => import("./views/Error404.vue"),
  },
];
