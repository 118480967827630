var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"entity-id"},[(!_vm.hideLabel)?_c('v-tooltip',{attrs:{"bottom":!_vm.tooltipOnTop,"top":_vm.tooltipOnTop,"z-index":"9999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('code',{class:{ dark: _vm.dark },on:{"click":_vm.copyToClipboard,"mouseup":function($event){if('button' in $event && $event.button !== 1){ return null; }return _vm.openLink.apply(null, arguments)}}},[_vm._v("#"+_vm._s(_vm.label))])])]}}],null,false,3011613219)},[_c('span',[_vm._v(" Click to copy "),_c('b',[_vm._v(_vm._s(_vm.title))]),_vm._v(" link! ")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":!_vm.tooltipOnTop,"top":_vm.tooltipOnTop,"z-index":"9999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('code',{class:{ dark: _vm.dark },on:{"click":_vm.openLink}},[_c('i',{staticClass:"far fa-arrow-up-right-from-square"})])])]}}])},[_c('span',[_vm._v(" Open "),_c('b',[_vm._v(_vm._s(_vm.title))]),_vm._v(" in a new tab! ")])]),_c('v-snackbar',{attrs:{"timeout":_vm.snackbarTimeout,"top":"","color":"secondary"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"info","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarTextFull)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }