import perms from "../../../plugins/permissions";
export default [
  {
    path: "/general-contractors",
    name: "general-contractors",
    // TODO: update this line when permissions updated
    meta: { layout: "dashboard", title: "General Contractors", permissions: [perms.Clients.View] },
    component: () => import("./views/GeneralContractors.vue"),
  },
  {
    path: "/general-contractors/:id",
    name: "single-general-contractor",
    meta: { layout: "dashboard" },
    component: () => import("./views/GeneralContractors.vue"),
  },
];
