export default [
  // { text: "Jira", icon: "fab fa-jira mr-1", value: "jiraCode", width: "110px" },
  { text: "Open", value: "actions", order: 0, width: "54px", sortable: false },
  { text: "Client", value: "client", width: "92px", order: 2, hidable: false, hidden: false },
  { text: "Full Name", value: "fullName", width: "175px" },
  { text: "Stage", value: "stage", sortable: false },
  { text: "Status", value: "status", sortable: false },
  { text: "Spaces", value: "spaceCount", width: "92px" },
  { text: "Equips", value: "equipmentCount", width: "92px" },
  { text: "Total Cost", value: "totalMsrpPrice", width: "120px", sortable: false },
  // { text: "Created", value: "createDate", width: "110px" },
  { text: "Last Updated", value: "updateDate", width: "130px" },
];
