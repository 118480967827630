import Vue from "vue";
import { has } from "./helpers";
function checkWidth(el, binding) {
  var mirror = document.querySelector(".vue-input-autowidth-mirror-".concat(el.dataset.uuid));
  var defaults = {
    maxWidth: "none",
    minWidth: "none",
    comfortZone: 0,
  };
  var options = Object.assign({}, defaults, binding.value);
  el.style.maxWidth = options.maxWidth;
  el.style.minWidth = options.minWidth;
  var val = el.value;

  if (!val) {
    val = el.placeholder || "";
  }

  while (mirror.childNodes.length) {
    mirror.removeChild(mirror.childNodes[0]);
  }

  mirror.appendChild(document.createTextNode(val));
  var newWidth = mirror.scrollWidth + options.comfortZone + 2;

  if (newWidth != el.scrollWidth) {
    el.style.width = "".concat(newWidth, "px");
  }
}
function getInputEl(el) {
  var nestedEl = el.querySelector("input");
  if (el.tagName.toLocaleUpperCase() == "INPUT") return el;
  else if (nestedEl != null) return nestedEl;
}
Vue.directive("hasRole", {
  bind: function (el, binding) {
    debugger;
    var role = binding.expression.replace(/'/g, "").replace(/"/g, "");

    if (role instanceof Array) {
      var d = 5;
    } else {
    }
    var user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      el.style.display = "none";
      return;
    }
    var userRole = user.roles[0].name;
    if (userRole != role) {
      el.style.display = "none";
    }
  },
});
Vue.directive("has", {
  inserted: function (_, binding, vnode) {
    let { value, modifiers } = binding;
    if (!has(value, modifiers.any ? true : false)) {
      vnode.elm.parentElement.removeChild(vnode.elm);
    }
  },
});
Vue.directive("autowidth", {
  bind: function bind(el) {
    el = getInputEl(el);
    if (el.tagName.toLocaleUpperCase() !== "INPUT") {
      throw new Error("v-input-autowidth can only be used on input elements.");
    }

    el.dataset.uuid = Math.random().toString(36).slice(-5);
    el.style.boxSizing = "content-box";
  },
  inserted: function inserted(el, binding) {
    el = getInputEl(el);
    var styles = window.getComputedStyle(el);
    el.mirror = document.createElement("span");
    Object.assign(el.mirror.style, {
      position: "absolute",
      top: "0",
      left: "0",
      visibility: "hidden",
      height: "0",
      overflow: "hidden",
      whiteSpace: "pre",
      fontSize: styles.fontSize,
      fontFamily: styles.fontFamily,
      fontWeight: styles.fontWeight,
      fontStyle: styles.fontStyle,
      letterSpacing: styles.letterSpacing,
      textTransform: styles.textTransform,
    });
    el.mirror.classList.add("vue-input-autowidth-mirror-".concat(el.dataset.uuid));
    el.mirror.setAttribute("aria-hidden", "true");
    document.body.appendChild(el.mirror);
    checkWidth(el, binding);
  },
  componentUpdated: function componentUpdated(el, binding) {
    el = getInputEl(el);
    checkWidth(el, binding);
  },
  unbind: function unbind(el) {
    el = getInputEl(el);
    document.body.removeChild(el.mirror);
  },
});
