<template>
  <v-card
    dense
    :class="$vuetify.breakpoint.mobile ? '' : 'rounded-bl-lg'"
    class="toolbar-custom-card elevation-2 rounded-0"
    :width="$vuetify.breakpoint.mobile ? '100vw' : 'auto'"
  >
    <v-img
      contain
      left
      v-if="$vuetify.breakpoint.mobile"
      height="36"
      width="120"
      class="ml-12"
      src="/img/logo.svg"
      position="center left"
    ></v-img>
    <div v-if="false && isTestEnv && !$vuetify.breakpoint.mobile">
      <v-tooltip bottom z-index="9999" color="pink darken-1">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-chip
              class="mr-2 py-0"
              small
              hide-details
              color="pink darken-1 white--text"
              style="line-height: 0"
              @click.stop="dialog = true"
              ><i class="fas fa-vial mr-2"></i><b>TEST SITE</b>
            </v-chip>
          </div>
        </template>
        <span>
          <b>You're now on the Testing Site!</b>
          <v-divider class="white my-1" dark style="opacity: 0.32"></v-divider>
          <!-- <i class="fas fa-caret-right mr-2 ml-1"></i>Click for more details! -->
          <span style="font-size: 13px">
            <i class="fad fa-swap-opacity fa-hand-pointer mr-2"></i>Click for more details!
          </span>
        </span>
      </v-tooltip>
    </div>
    <quick-tools v-if="false" :mini="miniMode"></quick-tools>
    <v-scroll-x-reverse-transition mode="out-in">
      <v-divider vertical inset class="mx-1" v-if="!miniMode && false"></v-divider>
    </v-scroll-x-reverse-transition>
    <spotlight
      v-if="loggedInUser != null && !loggedInUser.isExternalUser"
      :mini="miniMode"
    ></spotlight>
    <v-scroll-x-reverse-transition mode="out-in">
      <v-divider
        v-if="loggedInUser != null && !loggedInUser.isExternalUser && !miniMode"
        vertical
        inset
        class="mx-1"
      ></v-divider>
    </v-scroll-x-reverse-transition>
    <user-profile-card
      :user="userObj"
      :mini="miniMode"
      :class="{ 'ml-2': loggedInUser != null && loggedInUser.isExternalUser }"
    ></user-profile-card>
    <v-dialog v-model="dialog" max-width="500">
      <v-card style="">
        <v-card-title class="text-h5 pink--text">
          You're now on the Concordia <b class="ml-1">Testing Site</b>!
        </v-card-title>
        <v-card-text>
          <h3 class="mb-4 secondary--text" style="font-weight: 500">
            This site is for testing purposes only!
            <br />
            If you want to view the LIVE site, please click the link below.
          </h3>
          <h3>
            <span style="font-weight: 800" class="secondary--text">Concorida LIVE: </span>
            <br />
            <a href="https://concordia.dangeloconsultants.com">
              https://concordia.dangeloconsultants.com
            </a>
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="pink" outlined @click="dialog = false"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import UserProfileCard from "../../Shared/components/UserProfileCard.vue";
import QuickTools from "./QuickTools/QuickTools.vue";

export default {
  data() {
    return {
      fab: false,
      dialog: false,
      userObj: null,
      miniMode: false,
    };
  },
  mounted() {
    this.userObj = this.$store.getters.user;
    // document.addEventListener("scroll", (event) => {
    //   if (window.scrollY >= 87) this.miniMode = true;
    //   else this.miniMode = false;
    // });
  },
  computed: {},
  methods: {},
  components: { UserProfileCard, QuickTools },
};
</script>

<style lang="scss">
.toolbar-custom-card {
  position: fixed !important;
  right: 0;
  top: 0;
  display: inline-flex !important;
  padding: 0.25rem 0.75rem 0.25rem 0.25rem !important;
  justify-content: flex-end;
  height: 48px;
  height: 40px;
  align-items: center;
  z-index: 20;

  &.show-on-top {
    z-index: 2000;
  }
}
</style>
