import perms from "../../plugins/permissions";
export default [
  {
    path: "/opportunities",
    name: "opportunities",
    meta: { layout: "dashboard", title: "Opportunities", permissions: [perms.Projects.View] },
    component: () => import("./views/Opportunities.vue"),
  },
  {
    path: "/active-projects",
    name: "active-projects",
    meta: { layout: "dashboard", title: "Active Projects", permissions: [perms.Projects.View] },
    component: () => import("./views/ActiveProjects.vue"),
  },
  {
    path: "/closed-projects",
    name: "closed-projects",
    meta: { layout: "dashboard", title: "Closed Projects", permissions: [perms.Projects.View] },
    component: () => import("./views/ClosedProjects.vue"),
  },
  {
    path: "/archived-projects",
    name: "archived-projects",
    meta: { layout: "dashboard", title: "Archived Projects", permissions: [perms.Projects.View] },
    component: () => import("./views/ArchivedProjects.vue"),
  },
  {
    path: "/restore-projects",
    name: "restore-project",
    meta: { layout: "dashboard", title: "Restore Projects", permissions: [perms.Projects.Restore] },
    component: () => import("./views/ProjectsRestore.vue"),
  },
  {
    path: "/projects/create",
    name: "create-project",
    meta: { layout: "dashboard", title: "Create a Project", permissions: [perms.Projects.Create] },
    component: () => import("./views/EditProject.vue"),
  },
  {
    path: "/projects/:id",
    name: "view-project",
    meta: { layout: "dashboard", permissions: [perms.Projects.View] },
    component: () => import("./views/EditProject.vue"),
  },
  {
    path: "/projects/:id/spaces/:spaceId",
    name: "view-project-space",
    meta: { layout: "dashboard", permissions: [perms.Projects.View] },
    component: () => import("./views/EditProject.vue"),
  },
  {
    path: "/projects/:id/buildings/:buildId",
    name: "view-project-building",
    meta: { layout: "dashboard", permissions: [perms.Projects.View] },
    component: () => import("./views/EditProject.vue"),
  },
  // {
  //   path: "/projects/:id/phases/:phaseId",
  //   name: "view-project-phase",
  //   meta: { layout: "dashboard", permissions: [perms.Projects.View] },
  //   component: () => import("./views/EditProject.vue"),
  // },
  {
    path: "/projects/:id/contracts",
    name: "view-project-contracts",
    meta: { layout: "dashboard", permissions: [perms.ProjectContracts.View] },
    component: () => import("./views/EditProject.vue"),
  },
  {
    path: "/projects/:id/small-board",
    name: "view-project-small-board",
    meta: { layout: "dashboard", permissions: [perms.SmallBoard.View] },
    component: () => import("./views/EditProject.vue"),
  },
  {
    path: "/projects/:id/spaces",
    name: "view-project-spaces",
    meta: { layout: "dashboard", permissions: [perms.Projects.View] },
    component: () => import("./views/EditProject.vue"),
  },
  {
    path: "/projects/:id/equipment",
    name: "view-project-equipment",
    meta: { layout: "dashboard", permissions: [perms.ProjectEquipment.View] },
    component: () => import("./views/EditProject.vue"),
  },
  {
    path: "/projects/:id/notes",
    name: "view-project-notes",
    meta: { layout: "dashboard", permissions: [perms.ProjectNotes.View] },
    component: () => import("./views/EditProject.vue"),
  },
  {
    path: "/projects/:id/drawing-documents",
    name: "view-project-drawing-documents",
    meta: { layout: "dashboard", permissions: [perms.DrawingDocuments.View] },
    component: () => import("./views/EditProject.vue"),
  },
  {
    path: "/projects/:id/reports",
    name: "view-project-reports",
    meta: { layout: "dashboard", permissions: [perms.ProjectReports.Create] },
    component: () => import("./views/EditProject.vue"),
  },
  {
    path: "/projects/:id/contacts",
    name: "view-project-contacts",
    meta: { layout: "dashboard", permissions: [perms.ProjectContacts.View] },
    component: () => import("./views/EditProject.vue"),
  },
  // {
  //   path: "/projects/:id/assignees",
  //   name: "view-project-assignees",
  //   meta: { layout: "dashboard", permissions: [perms.ProjectAssignees.View] },
  //   component: () => import("./views/EditProject.vue"),
  // },
  {
    path: "/projects/:id/buildings",
    name: "view-project-buildings",
    meta: { layout: "dashboard", permissions: [perms.Projects.View] },
    component: () => import("./views/EditProject.vue"),
  },
  {
    path: "/projects/:id/budget",
    name: "view-project-budget",
    meta: { layout: "dashboard", permissions: [perms.ProjectBudget.View] },
    component: () => import("./views/EditProject.vue"),
  },
  {
    path: "/projects/:id/variables",
    name: "view-project-variables",
    meta: { layout: "dashboard", permissions: [perms.ProjectVariables.View] },
    component: () => import("./views/EditProject.vue"),
  },
  {
    path: "/projects/:id/timeline",
    name: "view-project-timeline",
    meta: { layout: "dashboard", permissions: [perms.ProjectTimeline.View] },
    component: () => import("./views/EditProject.vue"),
  },
  {
    path: "/projects/:id/access-list",
    name: "view-project-access-list",
    meta: { layout: "dashboard", permissions: [perms.ProjectAccessList.View] },
    component: () => import("./views/EditProject.vue"),
  },
  {
    path: "/projects/:id/external-access-list",
    name: "view-project-external-access-list",
    meta: { layout: "dashboard", permissions: [perms.ProjectExternalAccessList.View] },
    component: () => import("./views/EditProject.vue"),
  },
  {
    path: "/projects/:id/activity-logs",
    name: "view-project-activity-logs",
    meta: { layout: "dashboard", permissions: [perms.ProjectActivityLogs.View] },
    component: () => import("./views/EditProject.vue"),
  },
];
