<template>
  <h5 class="ma-0">{{ parsedValue.Name }}</h5>
</template>

<script>
export default {
  name: "product-family-logged-prop",
  inheritAttrs: false,
  data() {
    return {};
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    parsedValue() {
      return JSON.parse(this.value);
    },
  },
  methods: {},
};
</script>

<style lang="scss"></style>
