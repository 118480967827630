<template>
  <div v-if="status != null && statusObj != null">
    <v-chip
      class="project-status px-2"
      :small="!small"
      :x-small="small"
      :color="'secondary' || statusObj.color"
      :text-color="'white' || statusObj.textColor"
    >
      <i class="fas mr-2" :class="statusObj.icon" style="line-height: 0"></i>
      <span class="font-weight-medium" style="line-height: 0">{{
        statusObj.text.toUpperCase()
      }}</span>
    </v-chip>
  </div>
</template>

<script>
import Enums from "../../../plugins/enums";
export default {
  name: "project-status",
  data() {
    return {
      statusObj: null,
    };
  },
  props: {
    status: {
      type: Number,
      default: null,
    },
    small: {
      type: Boolean,
      default: null,
    },
  },
  mounted() {
    this.updateStatusObj();
  },
  computed: {
    // allProjectStatus() {
    //   return (
    //     Object.entries(Enums.PROJECT_STATUS).flatMap((EnumItem) => {
    //       if (EnumItem[1].SUB_STATUS != null) {
    //         let subs =
    //           Object.entries(EnumItem[1].SUB_STATUS).flatMap((subItem) => {
    //             return [subItem[1]];
    //           }) || [];
    //         return [EnumItem[1], ...subs];
    //       }
    //       return EnumItem[1];
    //     }) || []
    //   );
    // },
  },
  methods: {
    updateStatusObj() {
      if (this.status != null) {
        this.statusObj = this.getEnumMember(Enums.PROJECT_STATUS, this.status);
      }
    },
  },
  watch: {
    status: {
      handler() {
        this.updateStatusObj();
      },
    },
  },
};
</script>

<style lang="scss"></style>
