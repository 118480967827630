import perms from "../../../plugins/permissions";
export default [
  {
    path: "/external-access-groups",
    name: "external-access-groups",
    meta: {
      layout: "dashboard",
      title: "External Access Groups",
      permissions: [perms.ExternalAccessGroups.View],
    },
    component: () => import("./views/ExternalAccessGroups.vue"),
  },
  {
    path: "/external-access-groups/:id",
    name: "single-external-access-group",
    meta: { layout: "dashboard" },
    component: () => import("./views/ExternalAccessGroups.vue"),
  },
];
