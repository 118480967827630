<template>
  <v-tooltip
    top
    nudge-top="-12"
    :disabled="noTooltip || formattedFullDateTimeLocal == 'N/A'"
    z-index="9999"
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="pa-0" v-bind="attrs" v-on="on">
        <span
          v-if="alteredText != null && alteredText != ''"
          class="dater"
          v-text="alteredText"
        ></span>
        <span v-else class="dater" v-text="formattedLocal"></span>
      </div>
    </template>
    <span class="d-flex flex-column">
      <span
        >Local: {{ formattedFullDateTimeLocal }} (UTC{{
          parsedDateOffset > 0 ? `+${parsedDateOffset}` : `${parsedDateOffset}`
        }})</span
      >
      <span>UTC: {{ formattedFullDateTime }}</span>
    </span>
  </v-tooltip>
</template>

<script>
import moment from "moment";
export default {
  name: "dater",
  data() {
    return {
      //yyyy-mm-dd
      crntYearFormat: "YYYY/MM/DD, hh:mm A",
      otherYearFormat: "YYYY/MM/DD, hh:mm A",
      crntYearDateOnlyFormat: "YYYY/MM/DD",
      otherYearDateOnlyFormat: "YYYY/MM/DD",
      old_crntYearFormat: "D MMM, hh:mm A",
      old_otherYearFormat: "D MMM YYYY, hh:mm A",
      old_crntYearDateOnlyFormat: "D MMM",
      old_otherYearDateOnlyFormat: "D MMM YYYY",
    };
  },
  props: {
    date: {
      type: [Date, Number, String],
      default: null,
    },
    dateonly: {
      type: Boolean,
      default: false,
    },
    noTooltip: {
      type: Boolean,
      default: false,
    },
    alteredText: {
      type: String,
      default: null,
    },
  },
  mounted() {},
  computed: {
    today() {
      return moment(Date.now());
    },
    parsedDateLocal() {
      if (!this.date) {
        return null;
      }
      return moment.utc(this.date).local();
    },
    parsedDateOffset() {
      if (!this.date) {
        return null;
      }
      return moment(this.date).utcOffset() / 60;
    },
    parsedDate() {
      if (!this.date) {
        return null;
      }
      return moment(this.date);
    },
    formattedFullDateTime() {
      if (!this.date) {
        return "N/A";
      } else if (!this.parsedDate) {
        return "N/A'";
      }
      let isCurrentYear = this.parsedDate.year() == this.today.year();
      return this.parsedDate.format(isCurrentYear ? this.crntYearFormat : this.otherYearFormat);
    },
    formattedFullDateTimeLocal() {
      if (!this.date) {
        return "N/A";
      } else if (!this.parsedDateLocal) {
        return "N/A'";
      }
      let isCurrentYear = this.parsedDateLocal.year() == this.today.year();
      return this.parsedDateLocal.format(
        isCurrentYear ? this.crntYearFormat : this.otherYearFormat
      );
    },
    formatted() {
      if (!this.date) {
        return "N/A";
      } else if (!this.parsedDate) {
        return "N/A'";
      }
      let isCurrentYear = this.parsedDate.year() == this.today.year();
      if (this.dateonly)
        return this.parsedDate.format(
          isCurrentYear ? this.crntYearDateOnlyFormat : this.otherYearDateOnlyFormat
        );
      else
        return this.parsedDate.format(isCurrentYear ? this.crntYearFormat : this.otherYearFormat);
    },
    formattedLocal() {
      if (!this.date) {
        return "N/A";
      } else if (!this.parsedDateLocal) {
        return "N/A'";
      }
      let isCurrentYear = this.parsedDateLocal.year() == this.today.year();
      if (this.dateonly)
        return this.parsedDateLocal.format(
          isCurrentYear ? this.crntYearDateOnlyFormat : this.otherYearDateOnlyFormat
        );
      else
        return this.parsedDateLocal.format(
          isCurrentYear ? this.crntYearFormat : this.otherYearFormat
        );
    },
  },
  methods: {},
};
</script>

<style lang="scss"></style>
