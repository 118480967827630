<template>
  <div
    class="space-count"
    :class="transparent ? 'bg-transparent' : '' + count > 0 ? '' : ' space-count-empty'"
    v-if="count != null"
  >
    <i class="far fa-door-open"></i>
    <span>{{ count }}</span>
  </div>
</template>

<script>
export default {
  name: "space-count",
  props: {
    count: {
      type: Number,
      default: null,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.space-count {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0.15rem 0.5rem;
  border-radius: 0.75rem;
  font-weight: 600;
  font-size: 12px;
  color: rgba($shades-black, 0.72);
  border: 1.5px solid rgba($shades-black, 0.24);
  background: rgba($shades-black, 0.04);
  cursor: inherit;

  &.bg-transparent {
    background: transparent;
  }

  &.space-count-empty {
    color: rgba($shades-black, 0.48);
    border: 1.5px solid rgba($shades-black, 0.16);
    background: rgba($shades-black, 0.01);
  }
}
</style>
