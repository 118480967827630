<template>
  <div class="rich-text-editor-wrpr">
    <div class="d-flex flex-row align-center justify-space-between mb-2">
      <span class="d-flex flex-row flex-wrap align-center" style="gap: 0.5rem">
        <label class="input-label" v-if="showLabel" v-html="title"></label>
        <span v-if="subtitle != null && subtitle.trim() != ''" v-html="subtitle"></span>
        <slot v-else name="subtitle"></slot>
        <slot name="label-append"></slot>
      </span>
      <v-btn
        v-if="allowExpand && !readonly"
        @click="modals.expandEditor.active = true"
        outlined
        :small="!smallExpandButton"
        :x-small="smallExpandButton"
        color="secondary"
      >
        <i class="fal fa-expand-arrows me-2 fs-12px"></i>
        <span class="fs-12px"> Expand Editor </span>
      </v-btn>
    </div>
    <tiptap-vuetify
      v-if="!readonly"
      v-model="selected"
      :toolbar-attributes="{
        color: $vuetify.theme.dark ? 'secondary' : 'blue-grey lighten-5',
        dark: $vuetify.theme.dark,
      }"
      :extensions="extensions"
      ref="tiptapVuetify"
      minHeight="125px"
      :maxHeight="maxHeight || ''"
      placeholder="Write something ..."
    />
    <div
      v-else
      class="tiptap-vuetify-editor__content tiptap-vuetify-editor__preview__readonly"
      v-html="
        selected != null && $stripHtml(selected) != ''
          ? selected
          : `<i class='fad fa-empty-set opacity-40'></i>`
      "
    />

    <v-dialog
      v-model="modals.expandEditor.active"
      max-width="64vw"
      scrollable
      style="z-index: 99999999"
    >
      <v-card>
        <v-card-title class="font-weight-bold py-2 px-4" small>
          <i class="fad fa-pen-square mr-2"></i> Update {{ title }}
          <v-spacer></v-spacer>
          <v-btn icon @click="modals.expandEditor.active = false">
            <i class="far fa-xmark fs-16px"></i>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-0 pb-0">
          <v-container fluid class="pa-5 inset-shadow" style="background: #eceff1">
            <v-form v-model="modals.expandEditor.valid" ref="expandSysDescEditorForm">
              <v-row class="my-0" dense>
                <v-col sm="12">
                  <tiptap-vuetify
                    v-if="!readonly"
                    v-model="selected"
                    :toolbar-attributes="{
                      color: $vuetify.theme.dark ? 'secondary' : 'blue-grey lighten-5',
                      dark: $vuetify.theme.dark,
                    }"
                    :extensions="extensions"
                    minHeight="54vh"
                    placeholder="Write something ..."
                  />
                  <div
                    v-else
                    class="tiptap-vuetify-editor__content tiptap-vuetify-editor__preview__readonly pa-0"
                    v-html="
                      selected != null && selected != ''
                        ? selected
                        : `<i class='fad fa-empty-set opacity-40'></i>`
                    "
                  />
                  <input type="text" disabled readonly style="display: none" />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="modals.expandEditor.active = false">
            <i class="fad fa-compress-arrows-alt mr-2"></i> Collapse Editor
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  // component
  TiptapVuetify,
  // extensions
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
  Image,
  TodoList,
  TodoItem,
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from "tiptap-vuetify";

export default {
  name: "rich-text-editor",
  components: { TiptapVuetify },
  props: {
    value: {
      type: String,
    },
    allowExpand: {
      type: Boolean,
      default: false,
    },
    smallExpandButton: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    maxHeight: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selected: null,
      selectedCemented: null,
      hasChanges: false,
      extensions: [
        Paragraph,
        [
          Heading,
          {
            // Options that fall into the tiptap's extension
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Italic,
        Underline,
        Strike,
        Link,
        Blockquote,
        ListItem, // if you need to use a list (BulletList, OrderedList)
        BulletList,
        OrderedList,
        Table,
        TableCell,
        TableHeader,
        TableRow,
        // Image,
        Link,
        Code,
        HorizontalRule,
        // TodoList,
        // [
        //   TodoItem,
        //   {
        //     options: { nested: true },
        //   },
        // ],
        History,
        HardBreak, // line break on Shift + Ctrl + Enter
      ],
      modals: {
        expandEditor: { active: false },
      },
    };
  },
  mounted() {
    this.selected = this.value;
  },
  methods: {
    cementSpace() {
      this.selectedCemented = this.selected;
      this.checkForChanges();
    },
    checkForChanges() {
      this.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
    },
    focus() {
      this.$refs.tiptapVuetify.$el
        .querySelector(".tiptap-vuetify-editor__content .ProseMirror")
        .focus();
    },
  },
  watch: {
    value: {
      handler() {
        if (!this.isEqual(this.selected, this.value)) {
          this.selected = this.value;
        }
      },
      deep: true,
    },
    selected: {
      handler() {
        if (!this.isEqual(this.selected, this.value)) {
          this.$emit("input", this.selected);
        }
      },
      deep: true,
    },
  },
};
</script>
