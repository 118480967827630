<template>
  <slideout
    dock="right"
    :size="mainPanelSize"
    :min-size="nested ? 400 : 975"
    :append-to="nested ? '' : '#app'"
    :visible.sync="slideouts.update.active"
    v-on:close="onSlideoutClosing"
    :class="readonly ? 'slideout-readonly-content' : ''"
  >
    <!-- :allow-resize="nested ? false : false" -->
    <template v-slot:header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Equipment ...
      </h3>
      <h3 v-else-if="selected.id != null" class="font-weight-bold pa-1" small>
        <span v-if="readonly">
          <i :class="'mr-2 fad ' + selected.category.icon"></i>Equipment:
          {{ selected.tag }}
        </span>
        <span v-else> <i class="fad fa-pen-square mr-2"></i> Update: '{{ selected.tag }}' </span>
        <entity-id
          class="ml-2"
          :label="selected.id"
          :path="equipmentUrl"
          title="Equipment"
        ></entity-id>
      </h3>
      <h3 v-else class="font-weight-bold pa-1" small>
        <i class="fad fa-plus-square mr-2"></i> Add Equipment
      </h3>
      <div>
        <code
          v-if="readonly"
          class="text-caption ml-2 white secondary--text mr-2"
          style="border: 1px dashed rgba(42, 54, 59, 0.28) !important"
        >
          <span class="fa-stack mr-0">
            <i class="fad fa-pencil fa-stack-1x"></i>
            <i class="fas fa-slash fa-stack-1x" style="color: Tomato"></i>
          </span>
          Read-Only
        </code>
        <panel-size-control v-model="slideouts.update.fullWidth"></panel-size-control>
        <v-btn @click="closeUpdateSlideout()" icon><i class="far fa-times fs-16px"></i></v-btn>
      </div>
    </template>
    <div
      color="white"
      style="position: sticky; top: 0; z-index: 2"
      @wheel="wheelIt"
      ref="tabsContainer"
    >
      <v-tabs
        v-model="slideouts.update.tab"
        color="error"
        v-if="slideouts.update.active && !isFetching"
        show-arrows
      >
        <v-tab :key="0">
          <v-fab-transition mode="out-in">
            <span class="tab-required-icon" v-if="!basicInfoTabIsValid">
              <i class="fas fa-star-of-life pink--text"></i>
            </span>
          </v-fab-transition>
          Basic Information
        </v-tab>
        <v-tab :key="1">
          <v-fab-transition mode="out-in">
            <span class="tab-required-icon" v-if="!techDetailsTabIsValid">
              <i class="fas fa-star-of-life pink--text"></i>
            </span>
          </v-fab-transition>
          Technical Details
        </v-tab>
        <v-tab :key="2">
          <v-fab-transition mode="out-in">
            <span class="tab-required-icon" v-if="!pricingTabIsValid">
              <i class="fas fa-star-of-life pink--text"></i>
            </span>
          </v-fab-transition>
          Pricing
        </v-tab>
        <v-tab :key="3">
          Included Accessories
          <v-chip
            :color="slideouts.update.tab == 3 ? 'error' : 'grey lighten-4'"
            class="ml-2 font-weight-bold"
            small
            label
          >
            {{ equipmentAccessoriesCount }}
          </v-chip>
        </v-tab>
        <v-tab :key="4">
          Suggested Equipment
          <v-chip
            :color="slideouts.update.tab == 4 ? 'error' : 'grey lighten-4'"
            class="ml-2 font-weight-bold"
            small
            label
          >
            {{ suggestedEquipmentsCount }}
          </v-chip>
        </v-tab>
        <v-tab :key="5">
          <v-fab-transition mode="out-in">
            <span class="tab-required-icon" v-if="!powerTabIsValid">
              <i class="fas fa-star-of-life pink--text"></i>
            </span>
          </v-fab-transition>
          <i class="fas fa-bolt me-2"></i> Power & Heat Load
        </v-tab>
        <v-tab :key="6">
          <v-fab-transition mode="out-in">
            <span class="tab-required-icon" v-if="!networkTabIsValid">
              <i class="fas fa-star-of-life pink--text"></i>
            </span>
          </v-fab-transition>
          <i class="fad fa-circle-nodes me-2"></i> Network Connectivity
        </v-tab>
        <!-- <v-tab :key="7"> <i class="fad fa-file-invoice me-2"></i> Files </v-tab> -->
      </v-tabs>
      <v-divider></v-divider>
    </div>
    <v-container class="pa-0 text-center" v-if="isFetching">
      <video width="320" muted loop autoplay style="padding: 0.5rem; opacity: 0.64; margin: 0 auto">
        <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
      </video>
      <p class="font-weight-bold">Fetching Equipment ...</p>
    </v-container>
    <v-container fluid class="pa-0" v-else>
      <v-tabs-items v-model="slideouts.update.tab">
        <v-tab-item :key="0" eager>
          <v-container fluid class="pa-6">
            <v-form v-model="forms.tab0" ref="updateForm0" :disabled="slideouts.update.isLoading">
              <v-row class="my-0">
                <v-col cols="12" md="4" sm="12" class="py-0" v-if="false">
                  <v-autocomplete-alt
                    auto-select-first
                    :rules="[allRules.required]"
                    label="Category"
                    v-model="selected.categoryId"
                    id="equipmentCategory"
                    ref="equipmentCategory"
                    placeholder="Equipment Category"
                    :items="categories"
                    :filter="categoriesFilter"
                    dense
                    filled
                    item-text="id"
                    item-value="name"
                    :loading="slideouts.update.categoryLoading"
                    :disabled="slideouts.update.categoryLoading"
                    :readonly="readonly"
                  >
                    <template v-slot:selection="data">
                      <v-row align-content="center" justify="start" no-gutters style="flex: none">
                        <v-col sm="auto" class="d-flex align-center mr-2">
                          <i :class="'equipment-icon fad ' + data.item.icon"></i>
                        </v-col>
                        <v-col class="d-flex align-center col-auto">
                          <div class="d-flex align-center">
                            {{ data.item.name }}
                          </div>
                        </v-col>
                      </v-row>
                    </template>
                    <template v-slot:item="data">
                      <v-row align-content="center" justify="start" no-gutters style="flex: none">
                        <v-col sm="auto" class="d-flex align-center mr-2">
                          <i :class="'equipment-icon fad ' + data.item.icon"></i>
                        </v-col>
                        <v-col class="d-flex align-center col-auto">
                          <div class="d-flex align-center">
                            {{ data.item.name }}
                          </div>
                        </v-col>
                      </v-row>
                    </template>
                  </v-autocomplete-alt>
                </v-col>
                <v-col cols="12" md="4" sm="12" class="py-0">
                  <equipment-category-selector
                    required
                    v-model="selected.categoryId"
                    :obj.sync="selected.category"
                    :readonly="readonly"
                  ></equipment-category-selector>
                </v-col>
                <v-col cols="12" md="4" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.required, allRules.lengthMax(12), allRules.noWhiteSpaces]"
                    label="Tag"
                    id="equipmentTag"
                    ref="equipmentTag"
                    class="mono-font-text-field"
                    placeholder="XYZ-123"
                    v-model="selected.tag"
                    :readonly="readonly"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col cols="12" md="4" sm="12" class="py-0">
                  <v-select-alt
                    :rules="[allRules.required]"
                    label="Availability"
                    id="equipmentAvailability"
                    placeholder="Availability State"
                    v-model="selected.availability"
                    :items="EquipmentAvailabilityList"
                    :readonly="readonly"
                    itemValue="value"
                    itemText="text"
                  >
                    <template v-slot:selection="{ item }">
                      <equipment-availability :availability="item.value"></equipment-availability>
                    </template>
                    <template v-slot:item="{ item }">
                      <equipment-availability :availability="item.value"></equipment-availability>
                    </template>
                  </v-select-alt>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="py-0">
                  <v-text-field-alt
                    label="Make <i class='fs-12px opacity-54 ml-2 mb-0' style='line-height: 0;'>Obsolete [To Be Removed]</i>"
                    id="equipmentMake"
                    placeholder="XYZ-123"
                    v-model="selected.make"
                    readonly
                  >
                  </v-text-field-alt>
                </v-col>
              </v-row>
              <manufacturer-product-family-editor
                ref="manufacturerProductFamilyEditor"
                :readonly="readonly"
                :manufacturerId.sync="selected.manufactureId"
                :productFamilyId.sync="selected.productFamilyId"
              ></manufacturer-product-family-editor>
              <v-row class="my-0">
                <!-- <v-col cols="12" md="4" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.required]"
                    label="Manufacturer"
                    id="equipmentManufactureId"
                    placeholder="20044"
                    v-model="selected.manufactureId"
                    :readonly="readonly"
                  >
                  </v-text-field-alt>
                </v-col> -->
                <v-col cols="12" md="6" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.required, allRules.noWhiteSpaces]"
                    label="Model"
                    id="equipmentModel"
                    placeholder="XYZ-123"
                    class="mono-font-text-field"
                    v-model="selected.model"
                    @input="updatePartNumber"
                    :readonly="readonly"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="py-0">
                  <v-text-field-alt
                    label="Part Number"
                    id="equipmentPartNumber"
                    placeholder="Part Number"
                    :rules="[allRules.noWhiteSpaces]"
                    v-model="selected.clientPartNumber"
                    class="mono-font-text-field"
                    :readonly="readonly"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="py-0 comp-wrpr">
                  <v-autocomplete-alt
                    auto-select-first
                    :rules="[allRules.required]"
                    label="Provider"
                    id="equipmentProvider"
                    placeholder="Provider"
                    v-model="selected.provider"
                    :items="providers"
                    :filter="providersFilter"
                    dense
                    filled
                    return-object
                    item-text="name"
                    item-value="id"
                    :loading="slideouts.update.providerLoading"
                    :disabled="slideouts.update.providerLoading"
                    :readonly="readonly"
                  >
                    <template v-slot:selection="data">
                      <equipment-provider :provider-name="data.item.name"></equipment-provider>
                    </template>
                    <template v-slot:item="data">
                      <equipment-provider :provider-name="data.item.name"></equipment-provider>
                      <p
                        class="ma-0 ml-2 text--secondary"
                        v-if="data.item.description != null && data.item.description != ''"
                      >
                        {{ data.item.description }}
                      </p>
                    </template>
                  </v-autocomplete-alt>
                  <!-- <v-btn
                    min-width="36px !important"
                    width="36px !important"
                    height="36px !important"
                    color="secondary"
                    class="pa-0"
                    rounded
                    @click="addNewProvider()"
                    :disabled="slideouts.update.providerLoading"
                    v-if="!readonly"
                  >
                    <i class="fal fa-plus"></i>
                  </v-btn> -->
                </v-col>
                <v-col cols="12" md="6" sm="12" class="py-0 comp-wrpr">
                  <v-autocomplete-alt
                    auto-select-first
                    :rules="[allRules.required]"
                    label="Installer"
                    id="equipmentInstaller"
                    placeholder="Installer"
                    v-model="selected.installer"
                    :items="installers"
                    :filter="installersFilter"
                    dense
                    filled
                    return-object
                    item-text="name"
                    item-value="id"
                    :loading="slideouts.update.installerLoading"
                    :disabled="slideouts.update.installerLoading"
                    :readonly="readonly"
                  >
                    <template v-slot:selection="data">
                      <div style="font-size: 14px" class="d-block">
                        <equipment-installer :installer-name="data.item.name"></equipment-installer>
                      </div>
                    </template>
                    <template v-slot:item="data">
                      <equipment-installer :installer-name="data.item.name"></equipment-installer>
                      <p
                        class="ma-0 ml-2 text--secondary"
                        v-if="data.item.description != null && data.item.description != ''"
                      >
                        {{ data.item.description }}
                      </p>
                    </template>
                  </v-autocomplete-alt>
                  <!-- <v-btn
                    min-width="36px !important"
                    width="36px !important"
                    height="36px !important"
                    color="secondary"
                    class="pa-0"
                    rounded
                    @click="addNewInstaller()"
                    :disabled="slideouts.update.installerLoading"
                    v-if="!readonly"
                  >
                    <i class="fal fa-plus"></i>
                  </v-btn> -->
                </v-col>
                <v-col cols="12" md="6" sm="12" class="py-0">
                  <hashtags-selector
                    :readonly="readonly"
                    v-model="selected.equipmentHashtags"
                  ></hashtags-selector>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.required, allRules.url]"
                    label="URL"
                    id="equipmentHvacURL"
                    placeholder="https://www.example.com/Equipment"
                    v-model="selected.url"
                    :readonly="readonly"
                    :append-outer-icon="
                      selected.url != null && allRules.isUrl(selected.url) == true
                        ? 'fal fa-external-link fs-18px'
                        : ''
                    "
                    class="shrink"
                    @click:append-outer="openURL()"
                  >
                  </v-text-field-alt>
                </v-col>

                <v-col cols="12" md="6" sm="12" class="py-0 mb-3">
                  <single-image-uploader
                    id="add-client-uploader"
                    :apiUrl="imageApiUrl"
                    v-model="selected.imageUrl"
                    label="Equipment Image"
                    :disabled="readonly"
                    :required="isImageRequired"
                  >
                    <template v-slot:actions>
                      <v-btn
                        small
                        text
                        elevation="0"
                        @click="copyManufacturerImage"
                        :disabled="readonly"
                      >
                        <span class="fs-12px"
                          ><i class="fad fa-copy mr-2"></i>Copy Manufacturer Image</span
                        >
                      </v-btn>
                    </template>
                  </single-image-uploader>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="py-0 mb-3">
                  <v-textarea-alt
                    :rules="[allRules.required, allRules.noWhiteSpaces]"
                    label="Description"
                    id="equipmentDescription"
                    placeholder="Equipment Description"
                    v-model="selected.description"
                    no-resize
                    height="165px"
                    hide-details="auto"
                    :readonly="readonly"
                  >
                    <template #label-append>
                      <div style="height: 24px">
                        <v-btn
                          v-if="allRules.noWhiteSpaces(selected.description) != true"
                          :disabled="slideouts.update.isLoading || readonly"
                          color="green darken-1"
                          dark
                          x-small
                          @click="cleanDescription()"
                        >
                          <i class="far fa-broom-wide mr-2"></i
                          ><span class="fs-10px">Clean Text</span>
                        </v-btn>
                      </div>
                    </template>
                  </v-textarea-alt>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-tab-item>
        <v-tab-item :key="1" eager>
          <v-container fluid class="pa-6">
            <v-form v-model="forms.tab1" ref="updateForm1">
              <v-row class="my-0">
                <v-col cols="12" md="3" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.requiredNumber, allRules.number]"
                    label="Weight (Lbs.)"
                    id="equipmentWeight"
                    ref="equipmentWeight"
                    placeholder="Weight (Lbs.)"
                    v-model="selected.weight"
                    :readonly="readonly"
                    type="number"
                    hide-spin-buttons
                  >
                  </v-text-field-alt>
                </v-col>
                <v-divider vertical class="mx-2"></v-divider>
                <v-col cols="auto" class="py-0 pr-0 d-flex align-center">
                  <h4 style="margin-top: 1px">
                    <i class="fad fa-container-storage fa-rotate-90 mr-2"></i>Equipment is a
                  </h4>
                </v-col>
                <v-col cols="auto" class="py-0 d-flex align-center">
                  <v-checkbox
                    color="info"
                    v-model="selected.isRack"
                    :readonly="readonly"
                    :disabled="readonly"
                    class="mt-0"
                    hide-details
                    @change="
                      (v) => {
                        if (v) selected.isRackMountable = false;
                      }
                    "
                  >
                    <template v-slot:label>
                      <label
                        class="v-switch-label"
                        :class="{
                          'info--text': selected.isRack,
                          'secondary--text': !selected.isRack,
                        }"
                        >Rack</label
                      >
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="auto" class="py-0 d-flex align-center">
                  <v-checkbox
                    color="info"
                    v-model="selected.isRackMountable"
                    :readonly="readonly"
                    :disabled="readonly"
                    class="mt-0"
                    hide-details
                    @change="
                      (v) => {
                        if (v) selected.isRack = false;
                      }
                    "
                  >
                    <template v-slot:label>
                      <label
                        class="v-switch-label"
                        :class="{
                          'info--text': selected.isRackMountable,
                          'secondary--text': !selected.isRackMountable,
                        }"
                        >Rack Mountable</label
                      >
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="auto" class="py-0">
                  <v-text-field-alt
                    :label="
                      selected.isRackMountable
                        ? 'Rack Units (RU)'
                        : selected.isRack
                        ? 'Rack Height'
                        : ''
                    "
                    placeholder="Rack Units (RU)"
                    v-model="selected.rackUnits"
                    :readonly="readonly"
                    v-if="selected.isRackMountable || selected.isRack"
                    :rules="[allRules.number, allRules.numberMin(0.01)]"
                    type="number"
                    hide-spin-buttons
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="py-0">
                  <v-divider class="mb-5"></v-divider>
                </v-col>
                <v-col cols="12" md="3" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.requiredNumber, allRules.number]"
                    label="Power Consumption (Watts)"
                    id="equipmentPowerConsumption"
                    placeholder="PowerConsumption (Watts)"
                    v-model="selected.powerConsumption"
                    :readonly="readonly"
                    @input="onPowerConsumptionChange"
                    type="number"
                    hide-spin-buttons
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col cols="12" md="3" sm="12" class="py-0">
                  <label class="d-block mb-1" style="font-weight: 600 !important; font-size: 15px">
                    HeatLoad & HVAC
                    <span class="text--secondary">Calculation</span>
                  </label>
                  <v-btn-toggle
                    v-model="selected.powerAutoCalculate"
                    mandatory
                    color="info"
                    @change="onPowerAutoCalculateChange"
                  >
                    <v-btn small :value="false" :disabled="readonly">
                      <!-- <v-icon left> fal fa-lock-open-alt </v-icon> -->
                      <i class="fad fa-lock-open-alt mr-2 secondary--text"></i>
                      <span>Manual</span>
                    </v-btn>
                    <v-btn small :value="true" :disabled="readonly">
                      <!-- <v-icon left> fal fa-lock-alt </v-icon> -->
                      <i class="fad fa-lock-alt mr-2 secondary--text"></i>
                      <span>Auto Derived</span>
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col cols="12" md="3" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.requiredNumber, allRules.number]"
                    id="equipmentHeatLoad"
                    placeholder="HeatLoad (BTU/Hr)"
                    :label="
                      selected.powerAutoCalculate
                        ? `HeatLoad (BTU/Hr) <i class='fad fa-lock-alt text--disabled mx-1'></i>`
                        : `HeatLoad (BTU/Hr)`
                    "
                    v-model="selected.heatload"
                    :readonly="readonly"
                    :class="selected.powerAutoCalculate ? '-font-italic' : ''"
                    type="number"
                    hide-spin-buttons
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col cols="12" md="3" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.requiredNumber, allRules.number]"
                    id="equipmentHvacTonnage"
                    placeholder="HVAC Tonnage"
                    :label="
                      selected.powerAutoCalculate
                        ? `HVAC Tonnage <i class='fad fa-lock-alt text--disabled mx-1'></i>`
                        : `HVAC Tonnage`
                    "
                    v-model="selected.hvacTonnage"
                    :readonly="readonly"
                    :class="selected.powerAutoCalculate ? 'font-italic' : ''"
                    type="number"
                    hide-spin-buttons
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="py-0">
                  <v-divider class="mb-5"></v-divider>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="py-0">
                  <v-row>
                    <v-col cols="12" md="12" class="d-flex align-center justify-space-between pb-0">
                      <label class="input-label">Technical Documents External Links</label>
                      <v-btn
                        text
                        color="info"
                        @click="addNewTechnicalDocument()"
                        :disabled="readonly"
                      >
                        <i class="far fa-plus me-2"></i>Add Technical Document Link
                      </v-btn>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-container
                        fluid
                        class="equipment-technical-documents d-flex flex-column pa-2 inset-shadow"
                        style="gap: 0.5rem"
                      >
                        <div
                          v-if="
                            selected.technicalDocuments == null ||
                            selected.technicalDocuments.length == 0
                          "
                          class="pa-2"
                        >
                          <p class="text-center fs-28px my-3">
                            <span class="fa-stack mr-0">
                              <i
                                class="fad fa-memo-circle-info fa-stack-2x info--text"
                                style="
                                  --fa-animation-duration: 3s;
                                  --fa-fade-opacity: 0.64;
                                  --fa-beat-scale: 1;
                                "
                              ></i>
                            </span>
                          </p>
                          <h3 class="text--disabled mb-3 text-center">
                            No technical documents added yet!
                          </h3>
                        </div>
                        <div v-else class="d-flex flex-column" style="gap: 0.5rem">
                          <v-card v-for="(doc, index) in selected.technicalDocuments" :key="index">
                            <div
                              class="d-flex justify-space-between align-center"
                              style="gap: 1rem"
                              :key="'techDoc_' + index"
                            >
                              <v-container fluid style="width: 100%" class="pt-3">
                                <v-row>
                                  <v-col cols="12" md="4" sm="12">
                                    <v-select-alt
                                      :rules="[allRules.required]"
                                      label="Technical Document Type"
                                      :id="'technicalDocumentType_' + index"
                                      v-model="doc.technicalDocumentType"
                                      :items="TechnicalDocumentType"
                                      :readonly="readonly"
                                      itemValue="value"
                                      itemText="text"
                                      hide-details
                                      small
                                      dense
                                    ></v-select-alt>
                                  </v-col>
                                  <v-col cols="12" md="8" sm="12">
                                    <v-text-field-alt
                                      :rules="[allRules.url]"
                                      label="Technical Document URL"
                                      :id="'technicalDocumentURL_' + index"
                                      placeholder="https://www.example.com/Equipment"
                                      v-model="doc.technicalDocumentURL"
                                      :readonly="readonly"
                                      hide-details
                                      small
                                      dense
                                      :append-outer-icon="
                                        doc.technicalDocumentURL != null &&
                                        allRules.isUrl(doc.technicalDocumentURL) == true
                                          ? 'fal fa-external-link fs-18px'
                                          : ''
                                      "
                                      class="shrink"
                                      @click:append-outer="openTechDocURL(doc.technicalDocumentURL)"
                                    >
                                    </v-text-field-alt>
                                  </v-col>
                                </v-row>
                              </v-container>
                              <div class="d-flex align-center justify-end pe-2" style="gap: 0.5rem">
                                <v-btn
                                  color="error"
                                  icon
                                  @click="deleteTechnicalDocument(index)"
                                  :disabled="readonly"
                                >
                                  <i class="far fa-trash-alt"></i>
                                </v-btn>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-tab-item>
        <v-tab-item :key="2" eager>
          <v-container fluid class="pa-6">
            <v-form v-model="forms.tab4" ref="updateForm4">
              <v-row class="my-0">
                <v-col cols="12" md="4" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.number, allRules.requiredNumber]"
                    label="MSRP"
                    id="equipmentMSRP"
                    ref="equipmentMSRP"
                    placeholder="MSRP"
                    v-model="selected.msrp"
                    @input="updateStreetPrice"
                    prefix="$"
                    :readonly="readonly"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col cols="12" md="4" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.number]"
                    label="Dealer Price"
                    id="equipmentDealerPrice"
                    placeholder="Dealer Price"
                    v-model="selected.dealerPrice"
                    prefix="$"
                    :readonly="readonly"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col cols="12" md="4" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.number, allRules.requiredNumber]"
                    label="Street Price"
                    id="equipmentStreetPrice"
                    placeholder="Street Price"
                    v-model="selected.streetPrice"
                    prefix="$"
                    :readonly="readonly"
                  >
                  </v-text-field-alt>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-tab-item>
        <v-tab-item :key="3">
          <accessories
            :readonly="readonly"
            v-model="selected.accessories"
            :selectedId="selected.id ? selected.id : 0"
          ></accessories>
        </v-tab-item>
        <v-tab-item :key="4">
          <suggested-equipments
            :readonly="readonly"
            v-model="selected.suggestedEquipments"
            :selectedId="selected.id ? selected.id : 0"
          ></suggested-equipments>
        </v-tab-item>
        <v-tab-item :key="5" eager>
          <equipment-power-specs
            ref="updateForm2Comp"
            v-model="selected.equipmentPower"
            :readonly="readonly"
            @valid-changes="forms.tab2 = $event"
          />
        </v-tab-item>
        <v-tab-item :key="6" eager>
          <equipment-network-connectivity
            ref="updateForm3Comp"
            v-model="selected.networkConnectivity"
            :readonly="readonly"
            @valid-changes="forms.tab3 = $event"
          />
        </v-tab-item>
        <!-- <v-tab-item :key="7" eager> File Manager </v-tab-item> -->
      </v-tabs-items>
      <!-- <edit-installer @save="onInstallerAdded" ref="addInstaller"></edit-installer> -->
      <!-- <edit-provider @save="onProviderAdded" ref="addProvider"></edit-provider> -->
    </v-container>
    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn @click="closeUpdateSlideout()"> <i class="fal fa-times mr-2"></i>Close</v-btn>
        <v-divider vertical class="mx-3"></v-divider> -->
        <div
          v-if="
            !isFetching &&
            readonly &&
            (isMainRoute || forceAllowEdit) &&
            $has(perms.Equipments.Update)
          "
        >
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="switchToEditMode()"
                :disabled="isFetching"
                color="orange"
              >
                <i class="fas fa-pen mr-2" style="font-size: 10px"></i>Edit
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >Edit<kbd class="light ml-2 fs-12px">CTRL<span class="opacity-54">+</span>E</kbd>
            </span>
          </v-tooltip>
        </div>
        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="slideouts.update.isLoading"
                @click="jumpToRequiredField()"
                color="error"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                class="ml-2"
                outlined
              >
                <i class="fas fa-asterisk fs-14px"></i>
              </v-btn>
            </template>
            <span>Jump to Required Field</span>
          </v-tooltip>
        </div>
        <v-btn
          class="ml-2"
          v-if="!isFetching && !readonly && selected.id != null"
          :disabled="slideouts.update.isLoading || !slideouts.update.hasChanges"
          @click="discardChanges()"
          color="secondary"
        >
          <i class="fal fa-clock-rotate-left mr-2"></i> Discard
        </v-btn>
        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="info"
                :disabled="!valid || slideouts.update.isLoading || !slideouts.update.hasChanges"
                @click="updateConfirmed(false)"
                :loading="slideouts.update.isLoading"
              >
                <i class="mr-2" :class="selected.id ? 'fas fa-save' : 'fal fa-plus'"></i>
                {{ selected.id ? "Save" : "Create" }}
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >{{ selected.id ? "Save" : "Create"
              }}<kbd class="light ml-1 fs-12px">CTRL<span class="opacity-54">+</span>S</kbd>
            </span>
          </v-tooltip>
        </div>
        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="cyan white--text"
                :disabled="!valid || slideouts.update.isLoading || !slideouts.update.hasChanges"
                @click="updateConfirmed(true)"
                :loading="slideouts.update.isLoading"
              >
                <i class="fas fa-save mr-2"></i>
                {{ selected.id ? "Save & Close" : "Create & Close" }}
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >{{ selected.id ? "Save & Close" : "Create & Close" }} Panel<kbd
                class="light ml-2 fs-12px"
                >CTRL<span class="opacity-54">+</span>SHIFT<span class="opacity-54">+</span>S</kbd
              >
            </span>
          </v-tooltip>
        </div>
        <div class="d-flex ml-2" v-if="selected.id != null">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                :loading="isFetching"
                :disabled="slideouts.update.isLoading || isFetching || slideouts.update.hasChanges"
                @click="fetchEquipment()"
              >
                <i class="fal fa-arrows-rotate" style="font-size: 16px"></i>
              </v-btn>
            </template>
            <span>Refetch Equipment</span>
          </v-tooltip>
        </div>
        <equipment-menu
          class="ml-2"
          key="menu-btn"
          v-if="isMainRoute && selected.id != null"
          :disabled="isFetching || slideouts.update.isLoading"
          :equipment="selected"
          :hasChanges="slideouts.update.hasChanges"
          hideUpdate
          @replaceEquipmentSuccess="onReplaceEquipmentSuccess"
          @cloneEquipmentSuccess="onCloneEquipmentSuccess"
          @deleteEquipmentSuccess="onDeleteEquipmentSuccess"
        ></equipment-menu>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import perms from "../../../plugins/permissions";
import Enums from "../../../plugins/enums";
import installersAPI from "../services/installers-service";
import providersAPI from "../services/providers-service";
import EquipmentPowerSpecs from "../components/power/EquipmentPowerSpecs.vue";
import EquipmentNetworkConnectivity from "../components/network/EquipmentNetworkConnectivity.vue";

import ApiService from "../services/equipments-service.js";
import Accessories from "./Accessories.vue";
import SuggestedEquipments from "./SuggestedEquipments.vue";
// import EditInstaller from "./EditInstaller.vue";
// import EditProvider from "./EditProvider.vue";
import EquipmentInstaller from "./EquipmentInstaller.vue";
import EquipmentProvider from "./EquipmentProvider.vue";
import HashtagsSelector from "@Shared/components/HashtagsSelector.vue";
import ManufacturerProductFamilyEditor from "./ManufacturerProductFamilyEditor.vue";
import EquipmentAvailability from "./EquipmentAvailability.vue";
import EquipmentMenu from "./EquipmentMenu.vue";
import PanelSizeControl from "@Shared/components/PanelSizeControl.vue";
import EquipmentCategorySelector from "./EquipmentCategorySelector.vue";

export default {
  name: "edit-equipment",
  data() {
    return {
      perms: perms,
      equipmentId: null,
      isFetching: false,
      readonly: false,
      imageApiUrl: `equipments/Image`,
      EquipmentAvailabilityList: Object.values(Enums.EquipmentAvailability),
      TechnicalDocumentType: Enums.TechnicalDocumentType,
      forms: {
        tab0: true,
        tab1: true,
        tab2: true,
        tab3: true,
        tab4: true,
      },
      categories: [],
      providers: [],
      installers: [],
      isLoading: false,
      selected: {},
      selectedCemented: {},
      slideouts: {
        update: {
          active: false,
          fullWidth: false,
          hasChanges: false,
          tab: null,
          isLoading: false,
          categoryLoading: false,
          providerLoading: false,
          installerLoading: false,
        },
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
    forceAllowEdit: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.getProviders();
    this.getInstallers();
    document.addEventListener("keydown", this.documentKeyListener);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.documentKeyListener);
  },
  computed: {
    isImageRequired() {
      if (
        this.selected != null &&
        this.selected.category != null &&
        this.selected.category.name == "Software"
      ) {
        return false;
      }
      return true;
    },
    mainPanelSize() {
      return this.slideouts.update.fullWidth ? "100%" : this.nested ? "87%" : "68%";
    },
    equipmentUrl() {
      if (this.selected == null) return "";
      return `equipment/${this.selected.id}`;
    },
    equipmentAccessoriesCount() {
      if (this.selected != null && this.selected.accessories != null)
        return this.selected.accessories.reduce((total, cur) => {
          return total + cur.count;
        }, 0);
      else return 0;
    },
    suggestedEquipmentsCount() {
      if (this.selected?.suggestedEquipments)
        return this.selected.suggestedEquipments.reduce((total, cur) => {
          return total + cur.count;
        }, 0);
      else return 0;
    },
    valid() {
      if (
        this.forms.tab0 &&
        this.forms.tab1 &&
        this.forms.tab2 &&
        this.forms.tab3 &&
        this.forms.tab4
      )
        return true;
      return false;
    },
    isMainRoute() {
      if (this.$route.name == "equipment" || this.$route.name == "single-equipment") return true;
      else return false;
    },
    basicInfoTabIsValid() {
      return this.forms.tab0;
    },
    techDetailsTabIsValid() {
      return this.forms.tab1;
    },
    powerTabIsValid() {
      return this.forms.tab2;
    },
    networkTabIsValid() {
      return this.forms.tab3;
    },
    pricingTabIsValid() {
      return this.forms.tab4;
    },
  },
  methods: {
    cleanDescription() {
      this.selected.description = this.selected.description.replace(/\s+/g, " ").trim();
    },
    documentKeyListener(event) {
      this.ctrlKeyActive = event.ctrlKey;
      this.shiftKeyActive = event.shiftKey;
      if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(false);
      } else if (
        this.ctrlKeyActive &&
        this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(true);
      } else if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyE" &&
        !this.isFetching &&
        this.readonly &&
        (this.isMainRoute || this.forceAllowEdit) &&
        this.$has(this.perms.Equipments.Update)
      ) {
        event.preventDefault();
        this.switchToEditMode(true);
      }
    },
    copyManufacturerImage() {
      var manf = this.$refs.manufacturerProductFamilyEditor.manufacturer;
      this.$log("manf", manf);
      if (manf != null && manf.logoUrl != null && manf.logoUrl != "") {
        this.selected.imageUrl = manf.logoUrl;
      } else if (manf != null && (manf.logoUrl == null || manf.logoUrl == "")) {
        this.$dialog.notify.warning("Selected manufacturer don't have an image to use!", {
          position: "top-right",
          timeout: 3000,
        });
        // /img/DNA_Symbol.png
      } else {
        this.$dialog.notify.warning("No Manufacturer Selected!", {
          position: "top-right",
          timeout: 3000,
        });
      }
    },
    discardChanges() {
      this.selected = this.cloneDeep(this.selectedCemented);
    },
    wheelIt(evt) {
      evt.preventDefault();
      // this.$log("wheel", evt);
      if (evt.deltaY > 0) {
        // this.$log("<<<< left");
        if (this.$refs.tabsContainer.querySelector(".v-slide-group__prev"))
          this.$refs.tabsContainer.querySelector(".v-slide-group__prev").click();
      } else {
        // this.$log(">>>> right");
        if (this.$refs.tabsContainer.querySelector(".v-slide-group__next"))
          this.$refs.tabsContainer.querySelector(".v-slide-group__next").click();
      }
      // this.$log("|||| scrollLeft", this.$refs.tabsContainer.scrollLeft);
      evt.stopPropagation();
    },
    onSlideoutClosing(e) {
      this.$console().log("onSlideoutClosing, active:", this.slideouts.update.active);
      // prevent close and wait
      e.wait = true;

      if (this.slideouts.update.hasChanges && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }

      //reset the changes
      this.selected = {};
      this.cementEquipment();
      this.$releaseChanges();

      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    fetchEquipment() {
      this.isFetching = true;
      ApiService.getEquipmentById(this.equipmentId)
        .then((resp) => {
          this.$log("getEquipmentById >> success", resp.data);
          this.isFetching = false;
          this.slideouts.update.isLoading = false;
          // resp.data.url = resp.data.url.replace("\r\n", "");
          resp.data.url = encodeURI(resp.data.url);
          this.prepareNewEquipmentData(this.cloneDeep(resp.data));
        })
        .catch((err) => {
          this.$log("getEquipmentById >> error", err);
          this.closeUpdateSlideout();
          this.$handleError(err);
        });
    },
    prepareNewEquipmentData(data) {
      this.selected = data;
      if (this.selected.technicalDocuments == null) this.selected.technicalDocuments = [];
      else {
        this.selected.technicalDocuments = JSON.parse(this.selected.technicalDocuments);
      }

      if (this.selected.equipmentPower == null)
        this.selected.equipmentPower = {
          powerRequired: false,
          powerSpecList: {
            acPowerSpec: null,
            dcPowerSpec: null,
            poePowerSpec: null,
          },
        };
      if (this.selected.networkConnectivity == null) {
        this.selected.networkConnectivity = {
          wired: false,
          networkPorts: [],
          wireless: false,
          wirelessInterfaces: [],
        };
      }
      // else if (this.selected.networkConnectivity.wirelessInterfaces.length > 0) {
      //   this.selected.networkConnectivity.wirelessInterfaces =
      //     this.selected.networkConnectivity.wirelessInterfaces.map((item) => {
      //       return item.type;
      //     });
      // }

      if (
        this.selected.equipmentPower != null &&
        this.selected.equipmentPower.powerSpecList != null &&
        this.selected.equipmentPower.powerSpecList.poePowerSpec != null &&
        this.selected.equipmentPower.powerSpecList.poePowerSpec.poeTypes != null &&
        this.selected.equipmentPower.powerSpecList.poePowerSpec.poeTypes.length > 0
      ) {
        this.selected.equipmentPower.powerSpecList.poePowerSpec.poeTypes =
          this.selected.equipmentPower.powerSpecList.poePowerSpec.poeTypes.map((item) => {
            return item.supportedPoEType;
          });
      }
      this.cementEquipment();
      if (this.isMainRoute) document.title = this.selected.tag + " | Concordia";
      // this.handleFocus();
    },
    switchToEditMode() {
      this.readonly = false;
    },
    announceChange(isNewEquipment) {
      this.$emit("save", this.cloneDeep(this.selected), isNewEquipment);
    },
    categoriesFilter(item, queryText, itemText) {
      const textOne = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },
    addNewProvider() {
      this.$refs.addProvider.open();
    },
    onProviderAdded(providerData, isNewProvider) {
      this.$log("onProviderAdded", providerData, isNewProvider);
      if (isNewProvider) {
        this.addToArr(this.providers, providerData);
        this.selected.providerId = providerData.id;
        this.refreshProvidersAutocomplete();
      }
    },
    getProviders() {
      this.slideouts.update.providerLoading = true;
      providersAPI
        .typeHead(null, true)
        .then((resp) => {
          this.providers = resp.data;
          this.refreshProvidersAutocomplete();
          this.checkProvidersHasSelected();
          this.slideouts.update.providerLoading = false;
        })
        .catch((err) => {
          this.slideouts.update.providerLoading = false;
          this.$handleError(err);
        });
    },
    providersFilter(item, queryText, itemText) {
      const textOne = item.name.toLowerCase();
      const textTwo = (item.description || "").toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
    },
    refreshProvidersAutocomplete() {
      if (this.selected.providerId != null && this.providers.length > 0)
        this.selected.provider =
          this.providers.find((c) => c.id == this.selected.providerId) || null;
    },
    checkProvidersHasSelected() {
      if (
        this.selected.provider != null &&
        !this.providers.some((t) => t.id == this.selected.provider.id)
      ) {
        this.providers.unshift(this.cloneDeep(this.selected.provider));
      }
    },
    addNewInstaller() {
      this.$refs.addInstaller.open();
    },
    onInstallerAdded(installerData, isNewInstaller) {
      this.$log("onInstallerAdded", installerData, isNewInstaller);
      if (isNewInstaller) {
        this.addToArr(this.installers, installerData);
        this.selected.installerId = installerData.id;
        this.refreshInstallersAutocomplete();
      }
    },
    getInstallers() {
      this.slideouts.update.installerLoading = true;
      installersAPI
        .typeHead(null, true)
        .then((resp) => {
          this.installers = resp.data;
          this.refreshInstallersAutocomplete();
          this.checkInstallersHasSelected();
          this.slideouts.update.installerLoading = false;
        })
        .catch((err) => {
          this.slideouts.update.installerLoading = false;
          this.$handleError(err);
        });
    },
    installersFilter(item, queryText, itemText) {
      const textOne = item.name.toLowerCase();
      const textTwo = (item.description || "").toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
    },
    refreshInstallersAutocomplete() {
      if (this.selected.installerId != null && this.installers.length > 0)
        this.selected.installer =
          this.installers.find((c) => c.id == this.selected.installerId) || null;
    },
    checkInstallersHasSelected() {
      if (
        this.selected.installer != null &&
        !this.installers.some((t) => t.id == this.selected.installer.id)
      ) {
        this.installers.unshift(this.cloneDeep(this.selected.installer));
      }
    },
    updateConfirmed(closeAfter) {
      this.slideouts.update.isLoading = true;
      let toSend = this.cloneDeep(this.selected);
      toSend.providerId = toSend.provider.id;
      toSend.installerId = toSend.installer.id;
      if (toSend.equipmentHashtags != null && toSend.equipmentHashtags.length > 0) {
        toSend.newHashtags = toSend.equipmentHashtags.filter((hashtag) => hashtag.id == null);
        toSend.equipmentHashtags = toSend.equipmentHashtags.filter((hashtag) => hashtag.id != null);
        toSend.equipmentHashtags = toSend.equipmentHashtags.map((hashtag) => hashtag.id);
      }
      if (toSend.accessories != null && toSend.accessories.length > 0) {
        toSend.accessories = toSend.accessories.map((item) => {
          return {
            accessoryId: item.accessory.id,
            count: item.count,
          };
        });
      }
      toSend.technicalDocuments = JSON.stringify(toSend.technicalDocuments);
      if (toSend.suggestedEquipments != null && toSend.suggestedEquipments.length > 0) {
        this.$log(toSend.suggestedEquipments);
        toSend.suggestedEquipments = toSend.suggestedEquipments
          .filter((elm) => elm.suggested)
          .map((item) => {
            return {
              suggestedId: item.suggested.id,
              count: item.count,
            };
          });
      }
      if (toSend.accessories == null || toSend.accessories.length == 0)
        toSend.clearAllAccessories = true;

      if (
        toSend.equipmentPower != null &&
        toSend.equipmentPower.powerSpecList != null &&
        toSend.equipmentPower.powerSpecList.poePowerSpec != null &&
        toSend.equipmentPower.powerSpecList.poePowerSpec.poeTypes != null &&
        toSend.equipmentPower.powerSpecList.poePowerSpec.poeTypes.length > 0
      ) {
        toSend.equipmentPower.powerSpecList.poePowerSpec.poeTypes =
          toSend.equipmentPower.powerSpecList.poePowerSpec.poeTypes.map((item) => {
            return {
              id: null,
              supportedPoEType: item,
            };
          });
      }

      this.$log("update >> toSend", toSend);
      ApiService.update(toSend)
        .then((resp) => {
          this.slideouts.update.isLoading = false;
          var message = "Equipment updated successfully!";
          this.$log(">>> updated", resp.data);
          this.prepareNewEquipmentData(this.cloneDeep(resp.data));
          var isNewEquipment = false;
          if (!toSend.id) {
            isNewEquipment = true;
            this.equipmentId = this.selected.id;
            message = "Equipment created successfully!";
          }
          this.announceChange(isNewEquipment);

          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
          this.slideouts.update.isLoading = false;
          this.readonly = true;
          this.checkForChanges();
          if (closeAfter) {
            this.closeUpdateSlideout();
          }
        })
        .catch((err) => {
          this.slideouts.update.isLoading = false;
          this.$handleError(err);
        });
    },
    closeUpdateSlideout() {
      this.slideouts.update.isLoading = false;
      this.slideouts.update.active = false;
    },
    isNum(value) {
      // return /^[0-9]+.[0-9]+$/.test(value);
      return /^[0-9]+.[0-9]+|[0-9]+$/.test(value);
    },
    refreshHeatAndHvac() {
      if (
        this.selected == null ||
        this.selected.powerAutoCalculate != true ||
        !this.isNum(this.selected.powerConsumption)
      )
        return;

      //Heat Load = POWER CONSUMPTION * 3.412
      //HVAC Tonnage = (POWER CONSUMPTION * 3.412) / 12000
      let pc = parseFloat(this.selected.powerConsumption);
      this.selected.heatload = (pc * 3.412).toFixed(2);
      this.selected.hvacTonnage = ((pc * 3.412) / 12000).toFixed(5);
    },
    open(id, editMode = false) {
      this.$log("################### equipment.open", id, editMode);
      this.slideouts.update.tab = 0;
      if (id == null) {
        this.selected = {
          imageUrl: null,
          isRack: false,
          isRackMountable: false,
          rackUnits: 0,
          powerConsumption: null,
          powerAutoCalculate: true,
          accessories: [],
          technicalDocuments: [],
          equipmentPower: {
            powerRequired: false,
            powerSpecList: {
              acPowerSpec: null,
              dcPowerSpec: null,
              poePowerSpec: null,
            },
          },
          networkConnectivity: {
            wired: false,
            networkPorts: [],
            wireless: false,
            wirelessInterfaces: [],
          },
        };
        this.readonly = false;
        this.isFetching = false;
        this.equipmentId = null;
        this.cementEquipment();
      } else {
        this.readonly = !editMode;
        this.equipmentId = id;
        this.fetchEquipment();
      }
      this.slideouts.update.fullWidth = false;
      this.slideouts.update.active = true;
    },
    handleFocus() {
      if (this.slideouts.update.tab == 0) {
        setTimeout(() => {
          // this.$refs.equipmentTag.focus();
        }, 350);
      } else if (this.slideouts.update.tab == 1) {
        setTimeout(() => {
          this.$refs.equipmentWeight.focus();
        }, 350);
      } else if (this.slideouts.update.tab == 2) {
        setTimeout(() => {
          // this.$refs.equipmentWeight.focus();
        }, 350);
      } else if (this.slideouts.update.tab == 3) {
        setTimeout(() => {
          this.$refs.equipmentMSRP.focus();
        }, 350);
      }
    },
    updatePartNumber() {
      this.selected.clientPartNumber = this.selected.model;
    },
    updateStreetPrice() {
      this.selected.streetPrice = this.selected.msrp;
    },
    cementEquipment() {
      this.selectedCemented = this.cloneDeep(this.selected);
      this.checkForChanges();
    },
    checkForChanges() {
      if (!this.slideouts.update.active) return;

      this.slideouts.update.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
      this.$console().log("#### checkForChanges:", this.slideouts.update.hasChanges);
      if (this.slideouts.update.hasChanges) {
        this.$log("%c==>> DIFF", "color: red", this.deepDiff(this.selectedCemented, this.selected));
      }
      if (this.slideouts.update.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
    openURL() {
      window.open(this.selected.url);
    },
    openTechDocURL(url) {
      window.open(url);
    },
    addNewTechnicalDocument() {
      this.selected.technicalDocuments.push({
        technicalDocumentType: 1,
        technicalDocumentURL: "",
      });
    },
    deleteTechnicalDocument(idx) {
      this.$dialog.warning({
        text: `Are you sure you want to delete this technical document?`,
        title: `Delete Technical Document?`,
        color: "error",
        persistent: true,
        actions: {
          false: {
            text: "Cancel",
          },
          true: {
            text: "Confirm",
            color: "error",
            handle: () => {
              this.selected.technicalDocuments.splice(idx, 1);
              this.$dialog.notify.warning(
                "The Technical Document will be deleted after you save changes!",
                {
                  position: "top-right",
                  timeout: 3000,
                }
              );
            },
          },
        },
      });
    },
    onDeleteEquipmentSuccess(id) {
      this.$emit("delete", id);
      this.closeUpdateSlideout();
    },
    onCloneEquipmentSuccess(clonedEquipment) {
      this.closeUpdateSlideout();
      this.$emit("clone", clonedEquipment);
    },
    onReplaceEquipmentSuccess() {
      this.closeUpdateSlideout();
    },
    jumpToRequiredField() {
      if (
        this.findRequiredFieldInFrom(this.$refs.updateForm0, () => {
          this.slideouts.update.tab = 0;
        })
      )
        return;
      if (
        this.findRequiredFieldInFrom(this.$refs.updateForm1, () => {
          this.slideouts.update.tab = 1;
        })
      )
        return;
      if (
        this.findRequiredFieldInFrom(this.$refs.updateForm2Comp.$refs.updateForm2, () => {
          this.slideouts.update.tab = 2;
        })
      )
        return;
      if (
        this.findRequiredFieldInFrom(this.$refs.updateForm3Comp.$refs.updateForm3, () => {
          this.slideouts.update.tab = 3;
        })
      )
        return;
      if (
        this.findRequiredFieldInFrom(this.$refs.updateForm4, () => {
          this.slideouts.update.tab = 4;
        })
      )
        return;

      this.$dialog.notify.info("All required fields are filled!", {
        position: "top-right",
        timeout: 3000,
      });
    },
    onPowerAutoCalculateChange() {
      if (this.selected.powerAutoCalculate) {
        this.refreshHeatAndHvac();
      }
    },
    onPowerConsumptionChange() {
      if (this.isNum(this.selected.powerConsumption)) {
        this.refreshHeatAndHvac();
      }
    },
  },
  watch: {
    equipment: {
      handler() {
        this.selected = this.cloneDeep(this.equipment || {});
        this.cementEquipment();
      },
      deep: true,
    },
    selected: {
      handler() {
        this.checkProvidersHasSelected();
        this.checkInstallersHasSelected();

        this.checkForChanges();
      },
      deep: true,
    },
    "selected.tag": {
      handler(newValue) {
        if (this.selected != null && this.selected.tag != null)
          this.selected.tag = this.selected.tag.toUpperCase();
      },
    },
    "slideouts.update.tab": {
      handler() {
        this.handleFocus();
      },
      deep: true,
    },
  },
  components: {
    EquipmentPowerSpecs,
    EquipmentNetworkConnectivity,
    EquipmentPowerSpecs,
    SuggestedEquipments,
    Accessories,
    EquipmentInstaller,
    EquipmentProvider,
    HashtagsSelector,
    ManufacturerProductFamilyEditor,
    EquipmentAvailability,
    EquipmentMenu,
    PanelSizeControl,
    EquipmentCategorySelector,
  },
};
</script>
<style lang="scss">
.equipment-technical-documents {
  flex: 1 1 auto;
  border-radius: 0.35rem;
  overflow: hidden;
  transition: all 0.3s ease-out;
  background: #eceff1;
}
</style>
