<template>
  <div class="d-flex align-center" style="gap: 0.5rem">
    <v-tooltip right z-index="999" nudge-right="-4px">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <div class="company-logo-mini">
            <div
              style="height: 100%; width: 100%"
              v-viewer
              @click.stop
              v-if="parsedValue.LogoUrl != null && parsedValue.LogoUrl != ''"
            >
              <img
                :key="parsedValue.Id + '_img'"
                :src="parsedValue.LogoUrl"
                height="100%"
                width="100%"
                style="object-fit: contain"
              />
            </div>
            <div style="height: 100%; width: 100%" v-viewer @click.stop v-else>
              <img
                :key="parsedValue.Id + '_img'"
                src="/img/DNA_Symbol.png"
                height="100%"
                width="100%"
                style="object-fit: contain"
              />
            </div>
          </div>
        </div>
      </template>
      <span>
        <v-img
          v-if="parsedValue.LogoUrl != null && parsedValue.LogoUrl != ''"
          :src="parsedValue.LogoUrl"
          height="250px"
          width="250px"
          contain
        ></v-img>
        <v-img v-else src="/img/DNA_Symbol.png" height="250px" width="250px" contain></v-img>
      </span>
    </v-tooltip>
    <h5 class="ma-0">{{ parsedValue.Name }}</h5>
  </div>
</template>

<script>
export default {
  name: "manufacturer-logged-prop",
  inheritAttrs: false,
  data() {
    return {};
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    parsedValue() {
      return JSON.parse(this.value);
    },
  },
  methods: {},
};
</script>

<style lang="scss"></style>
