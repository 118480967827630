import perms from "../../plugins/permissions";
export default [
  {
    path: "/teams",
    name: "teams",
    // TODO: update this line when permissions updated
    meta: { layout: "dashboard", title: "Teams", permissions: [perms.DNAUsers.View] },
    component: () => import("./views/Teams.vue"),
  },
  {
    path: "/teams/:id",
    name: "single-team",
    meta: { layout: "dashboard" },
    component: () => import("./views/Teams.vue"),
  },
];
