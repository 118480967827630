import Api from "../../Shared/services/api";

const baseUrl = "PersonalSettings";
export default {
  get() {
    return Api().get(`${baseUrl}`);
  },
  update(entity) {
    return Api().put(baseUrl, entity);
  },
};
