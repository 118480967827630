<template>
  <div class="d-flex ml-1 flex-column" v-if="parsedValue != null && parsedValue.length != 0">
    <ol>
      <li v-for="(port, index) in parsedValue" :key="index">
        <div class="d-flex flex-row flex-wrap" style="gap: 0.25rem 0.5rem">
          <span class="fs-14px font-weight-bold">{{ port.PortInterface }}</span>
          <span class="ms-1 fs-14px text--secondary">({{ port.Name }})</span>
          <span class="d-flex fs-14px font-weight-medium">
            <span class="mr-2">{{ port.Speed | numberWithCommas }} Mb/s</span>
            <span class="d-flex align-center text--secondary"
              >≈ {{ (port.Speed / 1000) | numberWithCommas }} Gb/s
            </span>
          </span>
        </div>
        <v-divider v-if="index != parsedValue.length - 1" class="my-1"></v-divider>
      </li>
    </ol>
  </div>
  <div v-else>
    <i class="fad fa-empty-set fs-16px ml-2" style="opacity: 0.24"></i>
  </div>
</template>

<script>
import Enums from "../../../../plugins/enums";
export default {
  components: {},
  name: "network-ports-logged-prop",
  inheritAttrs: false,
  data() {
    return {};
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    parsedValue() {
      var val = this.value == "" || this.value == null ? "[]" : this.value;
      return JSON.parse(val) || null;
    },
  },
  methods: {},
};
</script>

<style lang="scss"></style>
