import perms from "../../../plugins/permissions";
export default [
  {
    path: "/external-projects",
    name: "external-projects",
    meta: {
      layout: "dashboard",
      title: "External Projects",
      permissions: [perms.ExternalProjects.View],
    },
    component: () => import("./views/Projects.vue"),
  },
];
