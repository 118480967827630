import perms from "../../../plugins/permissions";
export default [
  {
    path: "/systems-integrators",
    name: "systems-integrators",
    // TODO: update this line when permissions updated
    meta: {
      layout: "dashboard",
      title: "Systems Integrators",
      permissions: [perms.SystemsIntegrators.View],
    },
    component: () => import("./views/SystemsIntegrators.vue"),
  },
  {
    path: "/systems-integrators/:id",
    name: "single-systems-integrator",
    meta: { layout: "dashboard", permissions: [perms.SystemsIntegrators.View] },
    component: () => import("./views/SystemsIntegrators.vue"),
  },
];
