import perms from "../../plugins/permissions";

export default [
  {
    path: "/roles",
    name: "roles",
    meta: { layout: "dashboard", title: "Roles", permissions: [perms.Roles.View] },
    component: () => import("./views/Roles.vue"),
  },
  {
    path: "/roles/:id",
    name: "single-role",
    meta: { layout: "dashboard", permissions: [perms.Roles.View] },
    component: () => import("./views/Roles.vue"),
  },
];
