<template>
  <div>
    <div class="project-card" @click="cardClicked">
      <div class="folder-top"></div>
      <div class="folder-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "project-card-empty",
  methods: {
    cardClicked() {
      this.$emit("click", this.project.id);
    },
  },
};
</script>

<style lang="scss">
.project-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  filter: drop-shadow(0px 3px 1px rgba(42, 54, 59, 0.08))
    drop-shadow(0px 2px 2px rgba(42, 54, 59, 0.05)) drop-shadow(0px 1px 5px rgba(42, 54, 59, 0.04)) !important;

  .folder-top {
    position: relative;
    display: flex;
    height: 32px;
    width: 120px;
    border-radius: 1.5rem 0 0 0;
    background: #fff;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
    padding: 0 1rem;
    color: rgba($shades-black, 0.4);

    &:after {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      right: -32px;
      border-bottom: 32px solid #fff;
      border-right: 32px solid transparent;
    }
  }

  .folder-content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
    border-radius: 0 1.5rem 1.5rem 1.5rem;
    background: #fff;
    padding: 1rem;

    label {
      margin-top: 1rem;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 12px;
      color: rgba($shades-black, 0.4);
    }

    .project-name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .project-date {
      font-size: 12px;
      font-weight: 700;
      color: rgba($shades-black, 0.4);
    }

    .project-header {
      display: inline-flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .client-logo {
        width: 100px;
        height: auto;
      }

      .project-total {
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        padding: 0.15rem 0.5rem;
        border-radius: 0.75rem;
        font-weight: 600;
        font-size: 14px;
        color: rgba($shades-black, 0.72);
        border: 1.5px dashed rgba($shades-black, 0.24);
        background: rgba($shades-black, 0.04);

        i {
          line-height: 0;
        }
      }
    }

    .project-footer {
      display: inline-flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem;

      .project-stats {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
      }
    }
  }
}
</style>
