var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mt-0 mb-1",attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"d-flex justify-start align-center"},[_c('v-switch',{staticClass:"ma-0",staticStyle:{"font-size":"14px !important"},attrs:{"label":"Show Selected Only","hide-details":""},model:{value:(_vm.selectedOnly),callback:function ($$v) {_vm.selectedOnly=$$v},expression:"selectedOnly"}})],1),_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"lg":"5","md":"5","sm":"12"}},[_c('v-text-field',{ref:"mainSearch",staticClass:"table-search-field",style:({
          'max-width':
            _vm.mainSearchInFocus || (_vm.options.search != '' && _vm.options.search != null)
              ? '200px'
              : '110px',
        }),attrs:{"label":"Search","hide-details":"","dense":"","solo":"","clearable":"","prepend-inner-icon":"far fa-search"},on:{"focus":function($event){_vm.mainSearchInFocus = true},"blur":function($event){_vm.mainSearchInFocus = false}},model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}})],1)],1),(_vm.selectedOnly)?_c('v-data-table',{staticClass:"elevation-2",attrs:{"dense":"","headers":_vm.type == 'suggested' ? _vm.suggested : _vm.headers,"items":_vm.selectedAccessories,"item-class":_vm.rowClass,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.info",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"color":"secondary","icon":"","elevation":"0","small":""},on:{"click":function($event){return _vm.viewEquipment(item[_vm.type].id)}}},[_c('i',{staticClass:"far fa-info"})])]}},{key:("item." + _vm.type + ".name"),fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":"","z-index":"999","nudge-left":"-4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"equipment-img"},[(item[_vm.type].imageUrl != null && item[_vm.type].imageUrl != '')?_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}],staticStyle:{"height":"100%","width":"100%"},on:{"click":function($event){$event.stopPropagation();}}},[_c('img',{key:item[_vm.type].id + '_img',attrs:{"src":item[_vm.type].imageUrl,"height":"100%","width":"100%"}})]):(item[_vm.type].category != null)?_c('i',{class:'fad ' + item[_vm.type].category.icon}):_c('i',{class:'fad fa-plug'})])])]}}],null,true)},[_c('span',[((item[_vm.type].imageUrl != null) & (item[_vm.type].imageUrl != ''))?_c('v-img',{staticClass:"img",attrs:{"src":item[_vm.type].imageUrl,"width":"250px","height":"250px","contain":""}}):_c('i',{class:'fad ' + item[_vm.type].category.icon,staticStyle:{"font-size":"32px","padding":"2rem"}})],1)])]}},{key:("item." + _vm.type + ".category.name"),fn:function(ref){
        var item = ref.item;
return [_c('v-row',{attrs:{"align-content":"center","justify":"start","no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center justify-start",attrs:{"sm":"auto"}},[(item[_vm.type].category)?_c('i',{class:'equipment-icon fad ' + item[_vm.type].category.icon}):_vm._e()]),_c('v-col',{staticClass:"d-flex align-center justify-start"},[(item[_vm.type].category)?_c('span',{staticClass:"category-name"},[_vm._v(_vm._s(item[_vm.type].category.name))]):_vm._e()])],1)]}},{key:("item." + _vm.type + ".tag"),fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"equipment-tag"},[_vm._v(_vm._s(item[_vm.type].tag))])]}},{key:("item." + _vm.type + ".model"),fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"equipment-model"},[_vm._v(_vm._s(item[_vm.type].model))])]}},{key:("item." + _vm.type + ".providerId"),fn:function(ref){
        var item = ref.item;
return [(item[_vm.type].provider)?_c('equipment-provider',{attrs:{"provider-name":item[_vm.type].provider.name}}):_c('i',{class:'far fa-times pl-2 text--disabled'})]}},{key:("item." + _vm.type + ".installerId"),fn:function(ref){
        var item = ref.item;
return [(item[_vm.type].installer)?_c('equipment-installer',{attrs:{"installer-name":item[_vm.type].installer.name}}):_c('i',{class:'far fa-times pl-2 text--disabled'})]}},{key:"item.count",fn:function(ref){
        var item = ref.item;
return [_c('counter',{key:item[_vm.type].id,attrs:{"min":0,"max":1000},on:{"input":function($event){return _vm.onCountChangeFromSelectedOnly($event, item[_vm.type])}},model:{value:(item.count),callback:function ($$v) {_vm.$set(item, "count", $$v)},expression:"item.count"}})]}},{key:"no-data",fn:function(){return [_c('img',{attrs:{"width":"300","src":"/img/art/fogg-no-connection-2.png"}}),_c('p',{staticClass:"font-weight-bold"},[_vm._v("No Data Available!")])]},proxy:true}],null,true)}):_vm._e(),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.selectedOnly),expression:"!selectedOnly"}],staticClass:"elevation-2",attrs:{"dense":"","headers":_vm.headers,"items":_vm.entities,"item-class":_vm.rowClass,"options":_vm.options,"server-items-length":_vm.total,"items-per-page":15,"loading":_vm.loadingStates.table,"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'far fa-arrow-to-left',
      lastIcon: 'far fa-arrow-to-right',
      prevIcon: 'far fa-angle-left',
      nextIcon: 'far fa-angle-right',
      itemsPerPageOptions: [15, 30, 50, 100],
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.info",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"color":"secondary","icon":"","elevation":"0","small":""},on:{"click":function($event){return _vm.viewEquipment(item.accessory.id)}}},[_c('i',{staticClass:"far fa-info"})])]}},{key:"item.accessory.name",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":"","z-index":"999","nudge-left":"-4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"equipment-img"},[(item.accessory.imageUrl != null && item.accessory.imageUrl != '')?_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}],staticStyle:{"height":"100%","width":"100%"},on:{"click":function($event){$event.stopPropagation();}}},[_c('img',{key:item.accessory.id + '_img',attrs:{"src":item.accessory.imageUrl,"height":"100%","width":"100%"}})]):(item.accessory.category != null)?_c('i',{class:'fad ' + item.accessory.category.icon}):_c('i',{class:'fad fa-plug'})])])]}}],null,true)},[_c('span',[((item.accessory.imageUrl != null) & (item.accessory.imageUrl != ''))?_c('v-img',{staticClass:"img",attrs:{"src":item.accessory.imageUrl,"width":"250px","height":"250px","contain":""}}):_c('i',{class:'fad ' + item.accessory.category.icon,staticStyle:{"font-size":"32px","padding":"2rem"}})],1)])]}},{key:"item.accessory.category.name",fn:function(ref){
    var item = ref.item;
return [_c('v-row',{attrs:{"align-content":"center","justify":"start","no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center justify-start",attrs:{"sm":"auto"}},[(item.accessory.category)?_c('i',{class:'equipment-icon fad ' + item.accessory.category.icon}):_vm._e()]),_c('v-col',{staticClass:"d-flex align-center justify-start"},[(item.accessory.category)?_c('span',{staticClass:"category-name"},[_vm._v(_vm._s(item.accessory.category.name))]):_vm._e()])],1)]}},{key:"item.accessory.tag",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"equipment-tag"},[_vm._v(_vm._s(item.accessory.tag))])]}},{key:"item.accessory.model",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"equipment-model"},[_vm._v(_vm._s(item.accessory.model))])]}},{key:"item.accessory.providerId",fn:function(ref){
    var item = ref.item;
return [(item.accessory.provider)?_c('equipment-provider',{attrs:{"provider-name":item.accessory.provider.name}}):_c('i',{class:'far fa-times pl-2 text--disabled'})]}},{key:"item.accessory.installerId",fn:function(ref){
    var item = ref.item;
return [(item.accessory.installer)?_c('equipment-installer',{attrs:{"installer-name":item.accessory.installer.name}}):_c('i',{class:'far fa-times pl-2 text--disabled'})]}},{key:"item.count",fn:function(ref){
    var item = ref.item;
return [(_vm.disabledAccessories == null || !_vm.disabledAccessories.includes(item.accessory.id))?_c('counter',{attrs:{"min":0,"max":1000},on:{"input":function($event){return _vm.onCountChange($event, item.accessory)}},model:{value:(item.count),callback:function ($$v) {_vm.$set(item, "count", $$v)},expression:"item.count"}}):_vm._e()]}},{key:"no-data",fn:function(){return [_c('img',{attrs:{"width":"300","src":"/img/art/fogg-no-connection-2.png"}}),_c('p',{staticClass:"font-weight-bold"},[_vm._v("No Data Available!")])]},proxy:true}],null,true)}),_c('edit-equipment',{ref:"editEquipment",attrs:{"nested":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }