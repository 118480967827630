<template>
  <div>
    <v-btn
      fixed
      small
      icon
      class="mt-2"
      style="z-index: 30"
      left
      @click.stop="drawer = !drawer"
      v-show="$vuetify.breakpoint.mobile"
    >
      <v-icon v-show="!drawer" small>far fa-bars</v-icon>
      <v-icon v-show="drawer" small>far fa-times</v-icon>
    </v-btn>
    <v-btn
      min-width="20px"
      width="20px"
      height="20px"
      color="white"
      class="side-menu-mini-btn"
      :style="{ left: mini ? '45px' : '208px' }"
      small
      @click.stop="toggleMini"
      v-show="!$vuetify.breakpoint.mobile"
    >
      <i class="fas fa-angle-right" v-if="mini" small></i>
      <i class="fas fa-angle-left" v-else small></i>
    </v-btn>
    <v-navigation-drawer
      v-model="drawer"
      app
      class="elevation-2 side-nav-drawer"
      width="220"
      :fixed="$vuetify.breakpoint.mobile"
      :temporary="$vuetify.breakpoint.mobile"
      :mini-variant="mini"
    >
      <div
        class="logo-wrapper"
        :class="{
          'localhost-env': isLocalhostEnv,
          'dev-env': isDevEnv,
          'test-env': isTestEnv,
          'demo-env': isDemoEnv,
        }"
      >
        <img height="36" src="/img/DNA_Symbol.png" />
        <img height="36" src="/img/logo.svg" style="object-fit: contain" class="ml-3" />
        <div class="app-env">
          <v-chip
            class="elevation-2 ml-2 py-0 font-weight-bold"
            x-small
            hide-details
            color="pink darken-1 white--text"
            v-if="isTestEnv"
            style="line-height: 0"
            ><i class="fas fa-vial mr-1"></i>TEST
          </v-chip>
          <v-chip
            class="elevation-2 ml-2 py-0 font-weight-bold"
            x-small
            hide-details
            color="green darken-1"
            dark
            v-else-if="isDemoEnv"
            style="line-height: 0"
            ><i class="fas fa-play mr-1"></i>DEMO
          </v-chip>
          <v-chip
            class="elevation-2 ml-2 py-0 font-weight-bold"
            x-small
            hide-details
            color="orange"
            v-else-if="isDevEnv"
            style="line-height: 0"
            ><i class="fad fa-code mr-1"></i>DEV
          </v-chip>
          <v-chip
            class="elevation-2 ml-2 py-0 font-weight-bold d-flex align-center"
            x-small
            hide-details
            color="purple"
            dark
            v-else-if="isLocalhostEnv"
            style="line-height: 0"
            ><i class="fad fa-binary mr-1"></i>LOCAL
          </v-chip>
          <v-chip
            class="elevation-2 ml-2 py-0 font-weight-bold"
            x-small
            hide-details
            color="cyan"
            dark
            v-else-if="isLiveEnv"
            style="line-height: 0"
            ><i class="fad fa-dot-circle mr-1"></i>LIVE
          </v-chip>
        </div>
      </div>
      <v-divider></v-divider>
      <v-list class="main-nav-list">
        <v-list-item-group active-class="active-list-item" mandatory v-model="selectedRoute">
          <div v-for="(item, i) in menuForUser" :key="i">
            <!-- :value="checkActiveChildren(item.children) || $route.path.startsWith(item.altPath)" -->
            <v-list-group
              v-if="hasChildren(item) && hasAuthorizedChildren(item.children)"
              no-action
              @mouseenter="($event) => openMenuItem(item, $event)"
              :value="checkActiveChildren(item.children)"
              :ripple="false"
              :class="checkActiveChildren(item.children) ? 'has-active-child-item' : ''"
            >
              <template v-slot:activator>
                <v-tooltip right z-index="999" nudge-left="-4px" :disabled="!mini">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-icon class="mr-3 justify-center" v-bind="attrs" v-on="on">
                      <!-- v-show="checkActiveChildren(item.children) || $route.path.startsWith(item.altPath)" -->
                      <v-icon
                        v-show="
                          checkActiveChildren(item.children) || $route.path.startsWith(item.altPath)
                        "
                        small
                        >{{ "fas " + item.icon }}</v-icon
                      >
                      <v-icon
                        v-show="
                          !checkActiveChildren(item.children) &&
                          !$route.path.startsWith(item.altPath)
                        "
                        small
                        >{{ "fal " + item.icon }}</v-icon
                      >
                    </v-list-item-icon>
                  </template>
                  <span>
                    {{ item.title }}
                  </span>
                </v-tooltip>
                <v-list-item-content>
                  <v-list-item-title class="custom-list-item-title">{{
                    item.title
                  }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <div class="v-list-item-wrapper" v-for="(child, j) in item.children" :key="j">
                <v-list-item v-if="isPathAuthorized(child.path)" :ripple="false" :to="child.path">
                  <!-- <v-list-item-icon class="mr-3 justify-center">
                    <v-icon v-show="$route.path.startsWith(child.path)" small>{{
                      "fas " + child.icon
                    }}</v-icon>
                    <v-icon
                      v-show="!$route.path.startsWith(child.path)"
                      small
                      >{{ "fal " + child.icon }}</v-icon
                    >
                  </v-list-item-icon> -->
                  <v-tooltip right z-index="999" nudge-left="-4px" :disabled="!mini">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-icon class="mr-3 justify-center" v-bind="attrs" v-on="on">
                        <v-icon v-show="$route.path == child.path" small>{{
                          "fas " + child.icon
                        }}</v-icon>
                        <v-icon v-show="$route.path != child.path" small>{{
                          "fal " + child.icon
                        }}</v-icon>
                      </v-list-item-icon>
                    </template>
                    <span>
                      {{ child.title }}
                    </span>
                  </v-tooltip>
                  <v-tooltip
                    top
                    z-index="999"
                    nudge-top="-16px"
                    :disabled="!isTextOverflowing('link_' + i + '_' + j)"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-content v-bind="attrs" v-on="on">
                        <v-list-item-title
                          class="custom-list-item-title"
                          :ref="'link_' + i + '_' + j"
                          >{{ child.title }}</v-list-item-title
                        >
                      </v-list-item-content>
                    </template>
                    <span>{{ child.title }}</span>
                  </v-tooltip>
                </v-list-item>
              </div>
            </v-list-group>
            <v-list-item
              v-else-if="!hasChildren(item) && isPathAuthorized(item.path)"
              :ripple="false"
              :to="item.path"
            >
              <v-tooltip right z-index="999" nudge-left="-4px" :disabled="!mini">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-icon class="mr-3 justify-center" v-bind="attrs" v-on="on">
                    <v-icon v-show="$route.path.startsWith(item.path)" small>{{
                      "fas " + item.icon
                    }}</v-icon>
                    <v-icon v-show="!$route.path.startsWith(item.path)" small>{{
                      "fal " + item.icon
                    }}</v-icon>
                  </v-list-item-icon>
                </template>
                <span>
                  {{ item.title }}
                </span>
              </v-tooltip>
              <v-list-item-content>
                <v-list-item-title class="custom-list-item-title">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <v-list class="pb-0">
          <v-list-item-group active-class="active-list-item" mandatory>
            <v-list-item :ripple="false" :to="'/assets'" v-if="false">
              <v-list-item-icon class="mr-3 justify-center">
                <v-icon v-show="'/assets' === $route.path" small>fas fa-box-full</v-icon>
                <v-icon v-show="'/assets' !== $route.path" small>fal fa-box-full</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="custom-list-item-title">Assets</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <div
              class="py-1 text--secondary grey lighten-4 d-flex flex-row justify-space-between align-center"
              :class="mini ? 'px-1' : 'px-4'"
              style="font-size: 12px; font-weight: 600"
            >
              <span><i class="fad fa-box-check mr-3 ml-1"></i>Build v{{ appVersion }}</span>
              <div class="app-env d-flex">
                <!-- <v-chip
                  class="elevation-2 ml-2 py-0 font-weight-bold"
                  x-small
                  hide-details
                  color="pink darken-1 white--text"
                  v-if="false && isTestEnv"
                  style="line-height: 0"
                  ><i class="fas fa-vial mr-1"></i>TEST
                </v-chip>
                <v-chip
                  class="elevation-2 ml-2 py-0 font-weight-bold"
                  x-small
                  hide-details
                  color="orange"
                  v-else-if="false && isDevEnv"
                  style="line-height: 0"
                  ><i class="fad fa-code mr-1"></i>DEV
                </v-chip>
                <v-chip
                  class="elevation-2 ml-2 py-0 font-weight-bold"
                  x-small
                  hide-details
                  outlined
                  color="cyan"
                  v-if="isLiveEnv"
                  style="line-height: 0"
                  ><i class="fad fa-dot-circle mr-1"></i>LIVE
                </v-chip> -->
              </div>
            </div>
          </v-list-item-group>
        </v-list>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import PKG from "../../../../package.json";
export default {
  data() {
    return {
      selectedRoute: null,
      appVersion: PKG.version,
      mini: false,
      drawer: true,
      menu: [
        {
          path: "/dashboard",
          title: "Dashboard",
          icon: "fa-home-lg",
          roles: ["All"],
        },
        {
          title: "Projects",
          icon: "fa-folders",
          roles: ["All"],
          altPath: "/projects",
          children: [
            {
              path: "/leads",
              title: "Leads",
              icon: "fa-pennant",
              roles: ["All"],
            },
            {
              path: "/opportunities",
              title: "Opportunities",
              icon: "fa-lightbulb-on",
              roles: ["All"],
            },
            {
              path: "/active-projects",
              title: "Active",
              icon: "fa-play",
              roles: ["All"],
            },
            {
              path: "/closed-projects",
              title: "Closed",
              icon: "fa-lock-alt",
              roles: ["All"],
            },
            {
              path: "/archived-projects",
              title: "Archived",
              icon: "fa-archive",
              roles: ["All"],
            },
          ],
        },
        {
          title: "Projects",
          path: "/external-projects",
          icon: "fa-folder-bookmark",
          roles: ["All"],
        },
        {
          title: "Equipments",
          path: "/external-equipments",
          icon: "fa-th-list",
          roles: ["All"],
        },
        {
          title: "Templates",
          icon: "fa-sitemap",
          roles: ["All"],
          children: [
            {
              path: "/space-templates",
              title: "Space Templates",
              icon: "fa-code",
              roles: ["All"],
            },
            {
              path: "/naming-templates",
              title: "Naming Templates",
              icon: "fa-pen-field",
              roles: ["All"],
            },
            {
              path: "/layout-templates",
              title: "Layout Templates",
              icon: "fa-drafting-compass",
              roles: ["All"],
            },
            {
              path: "/layout-views",
              title: "Layout Views",
              icon: "fa-street-view",
              roles: ["All"],
            },
          ],
        },
        {
          title: "Equipment",
          icon: "fa-plug",
          roles: ["All"],
          children: [
            {
              path: "/equipment",
              title: "Items",
              icon: "fa-plug",
              roles: ["All"],
            },
            {
              path: "/inventory",
              title: "Table",
              icon: "fa-th-list",
              roles: ["All"],
            },
            {
              path: "/equip-groups",
              title: "Groups",
              icon: "fa-layer-group",
              roles: ["All"],
            },
            {
              path: "/equipment-categories",
              title: "Categories",
              icon: "fa-shapes",
              roles: ["All"],
            },
          ],
        },
        {
          title: "Resource Tracker",
          icon: "fa-stopwatch",
          roles: ["All"],
          children: [
            {
              path: "/big-board",
              title: "Big Board",
              icon: "fa-dove",
              roles: ["All"],
            },
            {
              path: "/my-board",
              title: "My Board",
              icon: "fa-bird",
              roles: ["All"],
            },
            {
              path: "/offers",
              title: "My Offers",
              icon: "fa-envelope",
              roles: ["All"],
            },
            {
              path: "/shift-requests",
              title: "My Shift Requests",
              icon: "fa-arrows-turn-right",
              roles: ["All"],
            },
          ],
        },
        {
          title: "Manage",
          // icon: "fa-gavel",
          icon: "fa-clipboard-check",
          roles: ["All"],
          children: [
            {
              path: "/manage-contracts",
              title: "Contracts",
              icon: "fa-file-signature",
              roles: ["All"],
            },
            {
              path: "/manage-offers",
              title: "Offers",
              icon: "fa-envelope",
              roles: ["All"],
            },
            {
              path: "/manage-shift-requests",
              title: "Shift Requests",
              // icon: "fa-clock-rotate-left fa-flip-horizontal",
              icon: "fa-arrows-turn-right",
              roles: ["All"],
            },
          ],
        },
        {
          title: "Companies",
          icon: "fa-building",
          roles: ["All"],
          children: [
            {
              path: "/clients",
              title: "Clients",
              icon: "fa-user-tie",
              roles: ["All"],
            },
            {
              path: "/manufacturers",
              title: "Manufacturers",
              icon: " fa-industry-windows",
              roles: ["All"],
            },
            {
              path: "/representatives",
              title: "Representatives",
              icon: " fa-user-group-crown",
              roles: ["All"],
            },
            {
              path: "/systems-integrators",
              title: "Systems Integrators",
              icon: " fa-user-gear",
              roles: ["All"],
            },
            {
              path: "/general-contractors",
              title: "General Contractors",
              icon: " fa-user-helmet-safety",
              roles: ["All"],
            },
            {
              path: "/sub-contractors",
              title: "Sub Contractors",
              icon: " fa-user-tag",
              roles: ["All"],
            },
            {
              path: "/architects",
              title: "Architects",
              icon: " fa-drafting-compass",
              roles: ["All"],
            },
            {
              path: "/engineering-firms",
              title: "Engineering Firms",
              icon: " fa-building",
              roles: ["All"],
            },
          ],
        },
        {
          title: "DNA Users",
          icon: "fa-user-group",
          roles: ["All"],
          children: [
            {
              path: "/users",
              title: "Users List",
              icon: "fa-users",
              roles: ["All"],
            },
            {
              path: "/roles",
              title: "Roles",
              icon: "fa-crown",
              roles: ["All"],
            },
            {
              path: "/access-groups",
              title: "Access Groups",
              icon: "fa-screen-users",
              roles: ["All"],
            },
            // {
            //   path: "/monthly-plans",
            //   title: "Monthly Plans",
            //   icon: "fa-calendar-check",
            //   roles: ["All"],
            // },
            // {
            //   path: "/teams",
            //   title: "Teams",
            //   icon: "fa-screen-users",
            //   roles: ["All"],
            // },
            // {
            //   path: "/workload",
            //   title: "Workload",
            //   icon: "fa-user-clock",
            //   roles: ["All"],
            // },
          ],
        },
        {
          title: "External Users",
          icon: "fa-user-group-crown",
          roles: ["All"],
          children: [
            {
              path: "/external-users",
              title: "Users List",
              icon: "fa-users",
              roles: ["All"],
            },
            {
              path: "/external-roles",
              title: "Roles",
              icon: "fa-crown",
              roles: ["All"],
            },
            {
              path: "/external-access-groups",
              title: "Access Groups",
              icon: "fa-screen-users",
              roles: ["All"],
            },
            {
              path: "/invitations",
              title: "Invitations",
              icon: "fa-paper-plane",
              roles: ["All"],
            },
          ],
        },
        {
          title: "Restore",
          icon: "fa-trash-arrow-up",
          roles: ["All"],
          children: [
            {
              path: "/restore-projects",
              title: "Projects",
              icon: "fa-folders",
              roles: ["All"],
            },
            {
              path: "/restore-space-templates",
              title: "Space Templates",
              icon: "fa-code",
              roles: ["All"],
            },
            {
              path: "/restore-equipment",
              title: "Equipment",
              icon: "fa-plug",
              roles: ["All"],
            },
            {
              path: "/restore-clients",
              title: "Clients",
              icon: "fa-user-tie",
              roles: ["All"],
            },
          ],
        },
        {
          title: "Settings",
          // icon: "fa-cog",
          icon: "fa-sliders-up",
          roles: ["All"],
          children: [
            {
              path: "/dna-defaults",
              title: "DNA Defaults",
              icon: "fa-flag",
              roles: ["All"],
            },
            {
              path: "/audit-logs",
              title: "Audits",
              icon: "fa-album-collection",
              roles: ["All"],
            },
          ],
        },
      ],
    };
  },
  created() {
    this.mini = this.$store.getters.miniSideMenu;
    window.addEventListener("storage", this.onStorageUpdate);
  },
  beforeDestroy() {
    window.removeEventListener("storage", this.onStorageUpdate);
  },
  methods: {
    onStorageUpdate(event) {
      if (event.key === "miniSideMenu") {
        this.$store.dispatch("refreshMiniSideMenu");
        this.mini = this.$store.getters.miniSideMenu;
      }
    },
    toggleMini() {
      this.mini = !this.mini;
    },
    viewMini() {
      this.mini = true;
    },
    viewFull() {
      this.mini = false;
    },
    openMenuItem(item, event) {
      return;
      this.$log("openMenuItem", item, event);
      if (item && event && !event.srcElement.classList.contains("v-list-item--active")) {
        event.srcElement.click();
      }
    },
    isTextOverflowing(ref) {
      let linkEl = this.$refs[ref] != null ? this.$refs[ref][0] : null;
      if (linkEl == null) return false;
      let result = linkEl.offsetWidth < linkEl.scrollWidth;
      return result;
    },
    getRoute(path) {
      return this.$router.options.routes.find((r) => r.path == path);
    },
    isPathAuthorized(path) {
      const route = this.getRoute(path);
      if (route == null) return true;
      return (
        route.meta == null ||
        route.meta.permissions == null ||
        (route.meta.permissions != null && this.$has(route.meta.permissions))
      );
    },
    hasChildren(item) {
      return item.children != null && item.children.length > 0;
    },
    hasAuthorizedChildren(children) {
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        var isAuthorized = this.isPathAuthorized(child.path);
        if (isAuthorized) return true;
      }
      return false;
    },
    checkActiveChildren(children) {
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        if (this.$route.path.startsWith(child.path)) {
          return true;
        }
      }
      return false;
    },
  },
  computed: {
    menuForUser() {
      return this.menu.filter((e) => this.$hasRoles(e.roles));
    },
  },
  watch: {
    mini: {
      handler() {
        this.$store.dispatch("setMiniSideMenu", this.mini);
      },
    },
  },
};
</script>

<style lang="scss">
$navigation-drawer-border-width: 0px !important;

.side-nav-drawer {
  z-index: 40;

  .v-navigation-drawer__content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .main-nav-list {
    overflow: overlay;
    height: 100%;
    flex: 1 1 auto;
  }
  .v-list-item {
    min-height: 40px;

    .v-list-item__icon {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      margin-left: 0 !important;
      align-self: center;

      &.v-list-group__header__append-icon {
        min-width: auto;
      }
    }
    .v-list-item__content {
      padding: 10px 0;
    }
  }
}

.logo-wrapper {
  background: rgba(#090d0f, 0.04);
  padding: 1.5rem 0.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  &.dev-env {
    background: rgba($orange-base, 0.16);
  }

  &.localhost-env {
    background: rgba($purple-base, 0.16);
  }

  &.test-env {
    background: rgba($accent-base, 0.16);
  }

  &.demo-env {
    background: rgba($success-base, 0.16);
  }

  .app-env {
    position: absolute;
    bottom: -9px;
    right: 4px;
  }
}

.custom-list-item-title {
  font-weight: 500;
}

.active-list-item:not(.v-list-group__header):not(.v-list-group) {
  background-image: linear-gradient(
    to right,
    rgba($error-base, 0.06),
    rgba($error-base, 0.04),
    rgba($error-base, 0.02),
    rgba($error-base, 0.01),
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff
  );

  & > .v-list-item__icon > .v-icon {
    color: #090d0f !important;
  }

  & > .v-list-item__content > .custom-list-item-title {
    font-weight: 600;
    color: #090d0f;
  }

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    left: -3px;
    top: 4px;
    height: calc(100% - 8px);
    min-height: unset !important;
    width: 6px;
    border-radius: 0 6px 6px 0;
    background: #e54c3c;
    opacity: 1;
  }

  &::before {
    display: none;
  }
}

.v-list-group.has-active-child-item .v-list-group__header {
  background-image: linear-gradient(
    to right,
    rgba($error-base, 0.06),
    rgba($error-base, 0.04),
    rgba($error-base, 0.02),
    rgba($error-base, 0.01),
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff
  );
  .v-icon {
    color: #090d0f !important;
  }

  .custom-list-item-title {
    font-weight: 600;
    color: #090d0f;
  }

  &[aria-expanded="false"]::after {
    content: "";
    display: inline-block;
    position: absolute;
    left: -3px;
    top: 4px;
    height: calc(100% - 8px);
    min-height: unset !important;
    width: 6px;
    border-radius: 0 6px 6px 0;
    background: #e54c3c;
    opacity: 1;
  }

  &::before {
    display: none;
  }
}

.v-list-group .v-list-group__header[aria-expanded="true"] {
  .v-icon {
    color: #090d0f !important;
  }

  .custom-list-item-title {
    font-weight: 600;
    color: #090d0f;
  }
  &::before {
    display: none;
  }
}

// .v-application--is-ltr .v-list-group--no-action > .v-list-group__items > .v-list-item {
//   padding-left: 48px !important;
// }

.v-application--is-ltr
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item-wrapper
  > .v-list-item {
  padding-left: 48px !important;
}

.v-navigation-drawer--mini-variant .v-list-group--no-action .v-list-group__items,
.v-navigation-drawer--mini-variant .v-list-group--sub-group {
  display: block;
  background: rgba($shades-black, 0.05) !important;
  box-shadow: inset 0px -3px 1px -2px rgb(42 54 59 / 16%), inset 0px 2px 2px 0px rgb(42 54 59 / 10%),
    inset 0px 1px 5px 0px rgb(42 54 59 / 8%) !important;

  .v-list-item--active {
    // background: transparent !important;
    background: rgba($error-base, 0.05) !important;
  }

  .v-list-item-wrapper > .v-list-item {
    padding-left: 16px !important;
  }
}

.side-menu-mini-btn {
  position: fixed;
  top: 18px;
  padding: 0 !important;
  z-index: 99;
  transition: all 0.15s ease-out;
}
</style>
