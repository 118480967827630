<template>
  <v-expansion-panel class="power-spec-ep">
    <v-expansion-panel-header class="px-4 py-0" :ripple="true">
      <v-row no-gutters justify="space-between">
        <v-col cols="auto" class="d-flex align-center flex-wrap" style="gap: 0.5rem">
          <span class="font-weight-bold d-flex align-center mr-4">
            <span
              class="fa-stack mr-3"
              style="font-size: 20px; width: 20px; height: 20px; line-height: 20px"
            >
              <i class="fad fa-grip-dots fa-stack-1x"></i>
              <i class="fas fa-minus fa-stack-1x" style="font-size: 20px; margin-top: -3px"></i>
            </span>
            <span class="fs-14px">DC Power Spec</span>
          </span>
          <v-btn-toggle v-model="selected.availability" dense rounded color="orange" mandatory>
            <v-btn
              :value="PowerSpecAvailability.Required.value"
              class="fs-12px"
              max-height="28px"
              @click.stop
              :disabled="readonly"
              color="transparent"
            >
              <i
                class="fa-circle-check mr-2"
                :class="{
                  'secondary--text fas':
                    selected.availability == PowerSpecAvailability.Required.value,
                  'text--secondary fal':
                    selected.availability != PowerSpecAvailability.Required.value,
                }"
              ></i>
              <span
                class="font-weight-bold"
                :class="{
                  'text--secondary': selected.availability != PowerSpecAvailability.Required.value,
                }"
                >Required
              </span>
            </v-btn>
            <v-btn
              :value="PowerSpecAvailability.Optional.value"
              class="fs-12px"
              max-height="28px"
              @click.stop
              :disabled="readonly"
              color="transparent"
            >
              <i
                class="fa-circle-half-stroke mr-2"
                :class="{
                  'secondary--text fad':
                    selected.availability == PowerSpecAvailability.Optional.value,
                  'text--secondary fal':
                    selected.availability != PowerSpecAvailability.Optional.value,
                }"
              ></i>
              <span
                class="font-weight-bold"
                :class="{
                  'text--secondary': selected.availability != PowerSpecAvailability.Optional.value,
                }"
                >Optional
              </span>
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols="auto" class="d-flex align-center">
          <v-tooltip top z-index="9999" v-if="!readonly">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="error"
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click.stop="deletePowerSpec"
              >
                <i class="far fa-trash-alt"></i>
              </v-btn>
            </template>
            <span>Delete AC Power Spec</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row
        style="margin-left: -1rem; margin-right: -1rem; width: calc(100% + 2rem); margin-top: 0"
      >
        <v-col cols="12" md="12" class="pa-0">
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <div class="mt-5">
        <v-switch
          class="ma-0 pa-0"
          color="orange"
          hide-details
          v-model="selected.powerSupplyIncluded"
          :readonly="readonly"
          :disabled="readonly"
        >
          <template v-slot:label>
            <label class="v-switch-label ma-0">Power Supply Included</label>
          </template>
        </v-switch>
      </div>
      <div class="mt-3" v-if="selected.powerSupplyIncluded">
        <v-row justify="space-between">
          <v-col cols="auto" class="d-flex align-center pb-0">
            <label class="input-label mr-4">Power Options</label>
            <v-menu offset-y left z-index="999">
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  color="orange"
                  small
                  outlined
                  max-height="24px"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="readonly"
                >
                  <i class="fas fa-plus mr-2"></i>
                  <span class="fs-12px font-weight-medium">Add</span>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  link
                  style="min-height: 32px"
                  :disabled="has110Voltage"
                  @click="addNewPowerOption(110)"
                >
                  <v-list-item-icon class="mr-2 justify-center align-center">
                    <span style="font-size: 16px; height: 16px; line-height: 16px">
                      <i class="fad fa-bolt fs-12px mr-1"></i>
                      <span
                        class="mono-font"
                        :class="{
                          'blue-gery--text text--lighten-2': !has110Voltage,
                        }"
                        >110
                      </span>
                    </span>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      style="font-size: 12px !important"
                      class="font-weight-medium"
                      :disabled="has110Voltage"
                    >
                      <span :class="{ 'text--secondary': !has110Voltage }">Voltage Option</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  link
                  style="min-height: 32px"
                  :disabled="has208Voltage"
                  @click="addNewPowerOption(208)"
                >
                  <v-list-item-icon class="mr-2 justify-center align-center">
                    <span style="font-size: 16px; height: 16px; line-height: 16px">
                      <i class="fad fa-bolt fs-12px mr-1"></i>
                      <span
                        class="mono-font"
                        :class="{
                          'blue-gery--text text--lighten-2': !has208Voltage,
                        }"
                        >208
                      </span>
                    </span>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      style="font-size: 12px !important"
                      class="font-weight-medium"
                      :disabled="has208Voltage"
                    >
                      <span :class="{ 'text--secondary': !has208Voltage }">Voltage Option</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  link
                  style="min-height: 32px"
                  :disabled="has220Voltage"
                  @click="addNewPowerOption(220)"
                >
                  <v-list-item-icon class="mr-2 justify-center align-center">
                    <span style="font-size: 16px; height: 16px; line-height: 16px">
                      <i class="fad fa-bolt fs-12px mr-1"></i>
                      <span
                        class="mono-font"
                        :class="{
                          'blue-gery--text text--lighten-2': !has220Voltage,
                        }"
                        >220
                      </span>
                    </span>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      style="font-size: 12px !important"
                      class="font-weight-medium"
                      :disabled="has220Voltage"
                    >
                      <span :class="{ 'text--secondary': !has220Voltage }">Voltage Option</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-col cols="auto" class="d-flex align-center pb-0"> </v-col>
        </v-row>
        <v-row justify="space-between">
          <v-col
            cols="12"
            md="12"
            class="d-flex align-center flex-column justify-center"
            v-if="!selected.powerSupplyPowerOptions || selected.powerSupplyPowerOptions.length == 0"
          >
            <p class="text-center">
              <i
                class="fad fa-bolt-slash blue-grey--text"
                style="font-size: 48px; margin-top: 2px"
              ></i>
            </p>
            <h4 class="text-center blue-grey--text text--lighten-2 mb-0 font-weight-medium">
              No power options added!
            </h4>
          </v-col>
          <v-col cols="12" md="12" class="d-flex flex-column" style="gap: 1rem" v-else>
            <power-option
              v-for="(option, i) in selected.powerSupplyPowerOptions"
              v-model="selected.powerSupplyPowerOptions[i]"
              :key="i"
              :readonly="readonly"
              @delete="onPowerOptionDelete(i)"
            ></power-option>
          </v-col>
        </v-row>
      </div>
      <div class="mt-3" v-else>
        <v-row justify="space-between">
          <v-col cols="12" md="12" class="d-flex flex-column" style="gap: 1rem">
            <dc-power-option
              v-model="selected.dCPowerRequirement"
              :readonly="readonly"
            ></dc-power-option>
          </v-col>
        </v-row>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import PowerOption from "./PowerOption.vue";
import DcPowerOption from "./DcPowerOption.vue";
import Enums from "../../../../plugins/enums";

export default {
  name: "ac-power-spec-ep",
  props: {
    value: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      PowerSpecAvailability: Enums.PowerSpecAvailability,
      valid: false,
      selected: {},
    };
  },
  computed: {
    has110Voltage() {
      return this.selected.powerSupplyPowerOptions
        ? this.selected.powerSupplyPowerOptions.find((o) => o.voltage == 110)
          ? true
          : false
        : false;
    },
    has208Voltage() {
      return this.selected.powerSupplyPowerOptions
        ? this.selected.powerSupplyPowerOptions.find((o) => o.voltage == 208)
          ? true
          : false
        : false;
    },
    has220Voltage() {
      return this.selected.powerSupplyPowerOptions
        ? this.selected.powerSupplyPowerOptions.find((o) => o.voltage == 220)
          ? true
          : false
        : false;
    },
  },
  mounted() {
    this.selected = this.cloneDeep(this.value);
  },
  methods: {
    addNewPowerOption(voltage) {
      this.selected.powerSupplyPowerOptions.push({
        id: null,
        voltage: voltage,
        current: 1,
        watt: voltage,
        heatLoadAutoCalculate: true,
        heatload: 34.12,
        hvacTonnage: 0.00284,
      });
      this.selected.options = this.selected.options.sort((a, b) => {
        if (a.voltage < b.voltage) return -1;
        if (a.voltage > b.voltage) return 1;
        return 0;
      });
    },
    onPowerOptionDelete(index) {
      this.selected.powerSupplyPowerOptions.splice(index, 1);
    },
    deletePowerSpec() {
      this.$emit("delete");
    },
  },
  watch: {
    valid() {
      this.$emit("valid-changes", this.valid);
    },
    value: {
      handler() {
        if (this.isEqual(this.value, this.selected)) return;
        this.selected = this.cloneDeep(this.value);
      },
      deep: true,
    },
    selected: {
      handler() {
        if (this.isEqual(this.value, this.selected)) return;
        this.$emit("input", this.selected);
      },
      deep: true,
    },
    "selected.powerSupplyIncluded": {
      handler() {
        if (this.selected.powerSupplyIncluded || this.selected.dCPowerRequirement != null) return;
        this.selected.dCPowerRequirement = {
          id: null,
          voltage: 1,
          current: 235,
          watt: 1,
          heatLoadAutoCalculate: true,
          heatload: 34.12,
          hvacTonnage: 0.00284,
        };
      },
      deep: true,
    },
  },
  components: { PowerOption, DcPowerOption },
};
</script>
<style lang="scss">
.power-specs-content {
  flex: 1 1 auto;
  border-radius: 0.35rem;
  overflow: hidden;
  transition: all 0.3s ease-out;

  &.power-specs-content-selected {
    background: #eceff1;
  }
}
.power-spec-ep {
  .v-expansion-panel-content__wrap {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
</style>
