<template>
  <span class="secondary--text fs-14px" v-if="parsedValue.Action == 'Cloned To'">
    <v-alert color="blue-grey" text class="my-1 pa-1">
      <span class="d-flex align-center py-1 fs-15px" style="gap: 0.5rem">
        <i class="fad fa-clone fa-swap-opacity mx-1"></i> This equipment was
        <u><b>cloned to</b></u> a new equipment =>
        <entity-id
          :label="parsedValue.Data || parsedValue.data"
          :path="equipmentUrl"
          title="Equipment"
        ></entity-id>
      </span>
    </v-alert>
  </span>
  <span class="secondary--text fs-14px" v-else-if="parsedValue.Action == 'Cloned From'">
    <v-alert color="blue-grey" text class="my-1 pa-1">
      <span class="d-flex align-center py-1 fs-15px" style="gap: 0.5rem">
        <i class="fad fa-clone mx-1"></i> This equipment was <u><b>cloned from</b></u> another
        equipment =>
        <entity-id
          :label="parsedValue.Data || parsedValue.data"
          :path="equipmentUrl"
          title="Equipment"
        ></entity-id>
      </span>
    </v-alert>
  </span>
</template>

<script>
export default {
  components: {},
  name: "related-actions-logs",
  inheritAttrs: false,
  data() {
    return {};
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    parsedValue() {
      return JSON.parse(this.value);
    },
    equipmentUrl() {
      if (this.parsedValue.Data == null && this.parsedValue.data == null) return "";
      return `equipment/${this.parsedValue.Data || this.parsedValue.data}`;
    },
  },
  methods: {},
};
</script>

<style lang="scss"></style>
