import perms from "../../../plugins/permissions";
export default [
  {
    path: "/external-equipments",
    name: "external-equipments",
    meta: {
      layout: "dashboard",
      title: "External Equipments",
      permissions: [perms.ExternalEquipment.View],
    },
    component: () => import("./views/Equipments.vue"),
  },
];
