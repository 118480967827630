import perms from "../../plugins/permissions";
export default [
  {
    path: "/access-groups",
    name: "access-groups",
    // TODO: update this line when permissions updated
    meta: {
      layout: "dashboard",
      title: "Access Groups",
      permissions: [perms.AccessGroups.View],
    },
    component: () => import("./views/AccessGroups.vue"),
  },
  {
    path: "/access-groups/:id",
    name: "single-access-group",
    meta: { layout: "dashboard" },
    component: () => import("./views/AccessGroups.vue"),
  },
];
