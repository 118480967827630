var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',{staticClass:"power-spec-ep"},[_c('v-expansion-panel-header',{staticClass:"px-4 py-0",attrs:{"ripple":true}},[_c('v-row',{attrs:{"no-gutters":"","justify":"space-between"}},[_c('v-col',{staticClass:"d-flex align-center flex-wrap",staticStyle:{"gap":"0.5rem"},attrs:{"cols":"auto"}},[_c('span',{staticClass:"font-weight-bold d-flex align-center mr-4"},[_c('span',{staticClass:"fa-stack mr-3",staticStyle:{"font-size":"20px","width":"20px","height":"20px","line-height":"20px"}},[_c('i',{staticClass:"fad fa-dash fa-stack-1x"}),_c('i',{staticClass:"fas fa-wave-sine fa-stack-1x",staticStyle:{"font-size":"14px"}})]),_c('span',{staticClass:"fs-14px"},[_vm._v("AC Power Spec")])]),_c('v-btn-toggle',{attrs:{"dense":"","rounded":"","color":"orange","mandatory":""},model:{value:(_vm.selected.availability),callback:function ($$v) {_vm.$set(_vm.selected, "availability", $$v)},expression:"selected.availability"}},[_c('v-btn',{staticClass:"fs-12px",attrs:{"value":_vm.PowerSpecAvailability.Required.value,"max-height":"28px","disabled":_vm.readonly,"color":"transparent"},on:{"click":function($event){$event.stopPropagation();}}},[_c('i',{staticClass:"fa-circle-check mr-2",class:{
                'secondary--text fas':
                  _vm.selected.availability == _vm.PowerSpecAvailability.Required.value,
                'text--secondary fal':
                  _vm.selected.availability != _vm.PowerSpecAvailability.Required.value,
              }}),_c('span',{staticClass:"font-weight-bold",class:{
                'text--secondary': _vm.selected.availability != _vm.PowerSpecAvailability.Required.value,
              }},[_vm._v("Required ")])]),_c('v-btn',{staticClass:"fs-12px",attrs:{"value":_vm.PowerSpecAvailability.Optional.value,"max-height":"28px","disabled":_vm.readonly,"color":"transparent"},on:{"click":function($event){$event.stopPropagation();}}},[_c('i',{staticClass:"fa-circle-half-stroke mr-2",class:{
                'secondary--text fad':
                  _vm.selected.availability == _vm.PowerSpecAvailability.Optional.value,
                'text--secondary fal':
                  _vm.selected.availability != _vm.PowerSpecAvailability.Optional.value,
              }}),_c('span',{staticClass:"font-weight-bold",class:{
                'text--secondary': _vm.selected.availability != _vm.PowerSpecAvailability.Optional.value,
              }},[_vm._v("Optional ")])])],1)],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"auto"}},[(!_vm.readonly)?_c('v-tooltip',{attrs:{"top":"","z-index":"9999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.deletePowerSpec.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('i',{staticClass:"far fa-trash-alt"})])]}}],null,false,1087540163)},[_c('span',[_vm._v("Delete AC Power Spec")])]):_vm._e()],1)],1)],1),_c('v-expansion-panel-content',[_c('v-row',{staticStyle:{"margin-left":"-1rem","margin-right":"-1rem","width":"calc(100% + 2rem)","margin-top":"0"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"12"}},[_c('v-divider')],1)],1),_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"d-flex align-center pb-0",attrs:{"cols":"auto"}},[_c('label',{staticClass:"input-label mr-4"},[_vm._v("Power Options")]),_c('v-menu',{attrs:{"offset-y":"","left":"","z-index":"999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var attrs = ref.attrs;
              var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"orange","small":"","outlined":"","max-height":"24px","disabled":_vm.readonly}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fas fa-plus mr-2"}),_c('span',{staticClass:"fs-12px font-weight-medium"},[_vm._v("Add")])])]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticStyle:{"min-height":"32px"},attrs:{"link":"","disabled":_vm.has110Voltage},on:{"click":function($event){return _vm.addNewPowerOption(110)}}},[_c('v-list-item-icon',{staticClass:"mr-2 justify-center align-center"},[_c('span',{staticStyle:{"font-size":"16px","height":"16px","line-height":"16px"}},[_c('i',{staticClass:"fad fa-bolt fs-12px mr-1"}),_c('span',{staticClass:"mono-font",class:{
                      'blue-gery--text text--lighten-2': !_vm.has110Voltage,
                    }},[_vm._v("110 ")])])]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-medium",staticStyle:{"font-size":"12px !important"},attrs:{"disabled":_vm.has110Voltage}},[_c('span',{class:{ 'text--secondary': !_vm.has110Voltage }},[_vm._v("Voltage Option")])])],1)],1),_c('v-list-item',{staticStyle:{"min-height":"32px"},attrs:{"link":"","disabled":_vm.has208Voltage},on:{"click":function($event){return _vm.addNewPowerOption(208)}}},[_c('v-list-item-icon',{staticClass:"mr-2 justify-center align-center"},[_c('span',{staticStyle:{"font-size":"16px","height":"16px","line-height":"16px"}},[_c('i',{staticClass:"fad fa-bolt fs-12px mr-1"}),_c('span',{staticClass:"mono-font",class:{
                      'blue-gery--text text--lighten-2': !_vm.has208Voltage,
                    }},[_vm._v("208 ")])])]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-medium",staticStyle:{"font-size":"12px !important"},attrs:{"disabled":_vm.has208Voltage}},[_c('span',{class:{ 'text--secondary': !_vm.has208Voltage }},[_vm._v("Voltage Option")])])],1)],1),_c('v-list-item',{staticStyle:{"min-height":"32px"},attrs:{"link":"","disabled":_vm.has220Voltage},on:{"click":function($event){return _vm.addNewPowerOption(220)}}},[_c('v-list-item-icon',{staticClass:"mr-2 justify-center align-center"},[_c('span',{staticStyle:{"font-size":"16px","height":"16px","line-height":"16px"}},[_c('i',{staticClass:"fad fa-bolt fs-12px mr-1"}),_c('span',{staticClass:"mono-font",class:{
                      'blue-gery--text text--lighten-2': !_vm.has220Voltage,
                    }},[_vm._v("220 ")])])]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-medium",staticStyle:{"font-size":"12px !important"},attrs:{"disabled":_vm.has220Voltage}},[_c('span',{class:{ 'text--secondary': !_vm.has220Voltage }},[_vm._v("Voltage Option")])])],1)],1)],1)],1)],1),_c('v-col',{staticClass:"d-flex align-center pb-0",attrs:{"cols":"auto"}})],1),_c('v-row',{attrs:{"justify":"space-between"}},[(!_vm.selected.options || _vm.selected.options.length == 0)?_c('v-col',{staticClass:"d-flex align-center flex-column justify-center",attrs:{"cols":"12","md":"12"}},[_c('p',{staticClass:"text-center"},[_c('i',{staticClass:"fad fa-bolt-slash blue-grey--text",staticStyle:{"font-size":"48px","margin-top":"2px"}})]),_c('h4',{staticClass:"text-center blue-grey--text text--lighten-2 mb-0 font-weight-medium"},[_vm._v(" No power options added! ")])]):_c('v-col',{staticClass:"d-flex flex-column",staticStyle:{"gap":"1rem"},attrs:{"cols":"12","md":"12"}},_vm._l((_vm.selected.options),function(option,i){return _c('power-option',{key:i,attrs:{"readonly":_vm.readonly},on:{"delete":function($event){return _vm.onPowerOptionDelete(i)}},model:{value:(_vm.selected.options[i]),callback:function ($$v) {_vm.$set(_vm.selected.options, i, $$v)},expression:"selected.options[i]"}})}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }