<template>
  <div
    class="counter"
    :class="{
      positive: this.count > 0,
      negative: this.count < 0,
      'counter-is-readonly': readonly,
    }"
    @click.stop
  >
    <v-btn
      class="btn-action btn-inc"
      icon
      elevation="0"
      @click.stop="inc()"
      outlined
      ref="counterIncBtn"
      v-if="!readonly"
      @keydown="onKeyDown"
    >
      <i class="far fa-plus"></i>
    </v-btn>
    <input
      @click.stop
      v-model="count"
      :min="min"
      @input="onInput"
      ref="counterInput"
      type="number"
      step="1"
      :disabled="readonly"
      @keydown="onKeyDown"
    />
    <v-btn
      class="btn-action btn-dec"
      icon
      elevation="0"
      @click.stop="dec()"
      outlined
      ref="counterDecBtn"
      v-if="!readonly"
      @keydown="onKeyDown"
    >
      <i class="far fa-minus"></i>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "counter",
  data() {
    return {
      count: 0,
    };
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    value: {
      type: Number,
      default: null,
    },
    step: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    isFloat: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isInt() {
      return Number(this.step) === this.step && this.step % 1 === 0;
    },
  },
  watch: {
    value: {
      handler() {
        this.count = this.value;
      },
      deep: true,
    },
  },
  mounted() {
    this.count = this.value;
  },
  methods: {
    onKeyDown(e) {
      if (e.code == "Escape") {
        setTimeout(() => {
          let parent = this.$refs.counterDecBtn.$el.closest("div.vue-slideout");
          if (parent) parent.focus();
        });
      }
    },
    announceChange() {
      if (this.count != this.value) {
        this.$emit("input", this.count);
      }
    },
    inc() {
      if (this.max != null && this.count + this.step <= this.max) {
        this.count = this.count + this.step;
        this.count = Math.round((this.count + Number.EPSILON) * 100) / 100;
        this.announceChange();
      } else if (this.max != null && this.count + this.step >= this.max) {
        this.count = this.max;
        this.announceChange();
      }
    },
    dec() {
      if (this.min != null && this.count - this.step >= this.min) {
        //parseFloat(parseFloat((this.count - this.step).toString()).toFixed(2))
        this.count -= this.step;
        this.count = Math.round((this.count + Number.EPSILON) * 100) / 100;
        this.announceChange();
      } else if (this.min != null && this.count - this.step <= this.min) {
        this.count = this.min;
        this.announceChange();
      }
    },
    onInput(e) {
      e.stopPropagation();
      if (e.target.value == null || e.target.value.trim() === "") {
        e.target.value = 0;
      } else if (this.max != null && e.target.value > this.max) {
        e.target.value = this.max;
      } else if (this.min != null && e.target.value < this.min) {
        e.target.value = this.min;
      }

      this.count =
        this.isInt && !this.isFloat
          ? parseInt(e.target.value)
          : parseFloat(parseFloat(e.target.value).toFixed(2));

      this.announceChange();
    },
  },
};
</script>

<style lang="scss">
.counter {
  position: relative;
  display: inline-flex;
  border-radius: 3rem;
  background: rgba($shades-black, 0.04);
  border: 1px solid rgba($shades-black, 0.2);
  border-right: none;
  border-left: none;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  input {
    height: 28px;
    width: 100px;
    -moz-appearance: none;
    -webkit-appearance: none;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    line-height: 40px;
    border-radius: 3rem;
    outline: none;
    border: none;
    color: rgba($shades-black, 0.4);
  }

  .btn-action {
    position: absolute;
    width: 28px;
    height: 28px;
    top: calc((40px - 28px) / 2);
    top: 0;
    border: 1px solid rgba($shades-black, 0.2);
    background: #fff;
  }

  .btn-inc {
    right: 0;
  }

  .btn-dec {
    left: 0;
  }

  &:focus-within {
    background: rgba($shades-black, 0.08);
    border: 1px solid rgba($shades-black, 0.32);
    border-right: none;
    border-left: none;
  }

  &.counter-is-readonly {
    background: rgba(#fff, 0.54) !important;
    border: 1px dashed rgba($shades-black, 0.4) !important;
  }

  &.positive {
    background: rgba($primary-base, 0.08);
    // border: 1px solid var(--v-primary-base);
    border-right: none;
    border-left: none;

    &:focus-within {
      background: rgba($primary-base, 0.16);
      // border: 1px solid var(--v-primary-darken1);
      border-right: none;
      border-left: none;
    }

    input {
      color: $primary-base;
    }
  }

  &.negative {
    background: rgba($accent-base, 0.08) !important;
    // border: 1px solid var(--v-primary-base);
    border-right: none;
    border-left: none;

    &:focus-within {
      background: rgba($accent-base, 0.16) !important;
      // border: 1px solid var(--v-primary-darken1);
      border-right: none;
      border-left: none;
    }

    input {
      color: $accent-base !important;
    }
  }
}
</style>
