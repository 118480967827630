<template>
  <div class="user-profile" v-if="user !== null">
    <v-btn icon small elevation="0" v-if="false">
      <v-badge v-if="!hideMessages" bordered color="success" dot>
        <i class="fal fa-envelope"></i>
      </v-badge>
    </v-btn>
    <v-menu
      v-model="notificationPopover"
      :close-on-content-click="false"
      :nudge-width="300"
      offset-y
      bottom
      left
      max-height="400px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="!hideNotifications" icon small elevation="0" v-bind="attrs" v-on="on">
          <v-badge bordered color="info" dot :value="notificationBadge">
            <i v-if="notificationBadge" class="fad fa-bell-on info--text"></i>
            <i v-else class="far fa-bell"></i>
          </v-badge>
        </v-btn>
      </template>
      <v-card>
        <v-card-title
          class="fs-14px pa-0 white flex-column justify-space-between"
          style="position: sticky; top: 0; z-index: 10000"
        >
          <div class="d-flex flex-row px-2 py-1" style="width: 100%">
            <span><i class="far fa-bell mr-2"></i>Notifications</span>
            <v-spacer></v-spacer>
            <!-- <v-btn v-if="!hideNotifications" icon small>
              <i class="far fa-ellipsis-v fs-14px"></i>
            </v-btn> -->
            <v-progress-linear
              color="blue"
              absolute
              indeterminate
              bottom
              :active="isNotificationsLoading"
            ></v-progress-linear>
          </div>
          <v-divider style="width: 100%"></v-divider>
        </v-card-title>
        <v-card-text class="pa-0">
          <div
            v-if="notifications.length == 0"
            class="py-6 d-flex flex-column align-center justify-center"
          >
            <img width="250" src="/img/art/fogg-206.png" />
            <!-- <i class="fa-duotone fa-face-sunglasses fs-28px mb-3"></i> -->
            <h3>You're all caught up!</h3>
          </div>
          <div v-else>
            <div
              class="notification-card"
              v-for="(notification, index) in notifications"
              :key="index"
            >
              <h5 class="d-flex justify-space-between pr-1" style="width: 100%">
                <span>
                  <i class="fad mr-1" :class="getTemplateType(notification).icon"></i
                  >{{ getTemplateType(notification).text }}</span
                >
                <dater :date="notification.createDate" no-tooltip></dater>
              </h5>
              <h4 class="secondary--text">
                {{ notification.fileName }}
              </h4>
              <h4>
                Status:
                <i
                  class="fad mr-1"
                  :class="[
                    getDownloadStatus(notification).icon,
                    `${getDownloadStatus(notification).color}--text`,
                  ]"
                ></i
                ><u :class="[`${getDownloadStatus(notification).color}--text`]">{{
                  getDownloadStatus(notification).text
                }}</u>
              </h4>
              <v-btn
                x-small
                color="#4caf50"
                style="margin-top: 0.5rem; margin-bottom: 0.5rem; color: #fff"
                :href="notification.url"
                :download="notification.fileName"
                v-if="notification.url != null && notification.url != ''"
              >
                <span class="fs-10px">
                  <i class="fas fa-arrow-down-to-line mr-2"></i>
                  <span style="font-weight: 600">Download</span>
                </span>
              </v-btn>
            </div>
            <div v-if="!isEndReached">
              <v-card-title v-intersect="onIntersect" class="fs-14px py-1 px-0">
                Fetching more notifications ...
              </v-card-title>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
    <user-avatar
      :user="user"
      :icon="$vuetify.breakpoint.mobile || mini"
      disable-open-link
    ></user-avatar>
    <v-menu dense offset-y>
      <template v-slot:activator="{ attrs, on }">
        <v-btn small icon elevation="0" class="mr-1" v-bind="attrs" v-on="on">
          <i class="far fa-angle-down"></i>
        </v-btn>
      </template>
      <v-list class="user-profile-menu-list">
        <v-list-item link href="/profile" v-if="false">
          <v-list-item-icon class="mr-2 justify-center">
            <v-icon small color="secondary">fal fa-user</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title color="secondary">Profile</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="toggleDarkMode" v-if="false">
          <v-list-item-icon class="mr-2 justify-center">
            <v-icon small color="secondary">fal {{ darkMode ? "fa-moon-stars" : "fa-sun" }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title color="secondary"> {{ switchLabel }} Mode </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          v-if="isLocalhostEnv || isDevEnv || isTestEnv"
          @click="permsDialog = true"
        >
          <v-list-item-icon class="mr-2 justify-center">
            <v-icon small color="secondary">fal fa-lock-alt</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title color="secondary"> List Permissions </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="$has(allPerms.PersonalSettings.View)"
          @click="openPersonalSettingsSlideout"
        >
          <v-list-item-icon class="mr-2 justify-center">
            <v-icon small color="secondary">fal fa-user-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title color="secondary"> Personal Settings </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link href="/logout">
          <v-list-item-icon class="mr-2 justify-center">
            <v-icon small color="secondary">fal fa-sign-out</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title color="secondary">Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- update view Modal -->
    <v-dialog v-model="permsDialog" max-width="420px" scrollable style="z-index: 99999999">
      <v-card>
        <v-card-title class="font-weight-bold" small>
          {{ user.firstName }} {{ user.lastName }}'s Permissions "{{ perms.length }}"!
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container class="py-0">
            <v-list dense>
              <v-list-item-group color="primary">
                <v-list-item v-for="(perm, i) in perms" :key="i" :ripple="false">
                  <v-list-item-content>
                    <v-list-item-title>{{ perm.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="permsDialog = false" text> <i class="fal fa-xmark mr-2"></i> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <personal-settings ref="personalSettingsSlideout"></personal-settings>
  </div>
</template>

<script>
import UserAvatar from "../../Shared/components/UserAvatar.vue";
import store from "../../../store";
import Enums from "../../../plugins/enums";
import NotificationsApi from "../../Shared/services/notification-service";
import { eventBus } from "../../../main";
import ToastNotificationPermission from "./ToastNotificationPermission.vue";
import allPerms from "../../../plugins/permissions";
import personalSettingsService from "../../PersonalSettings/services/personal-settings-service";
import PersonalSettings from "../../PersonalSettings/components/PersonalSettings.vue";

export default {
  components: { UserAvatar, PersonalSettings },
  data() {
    return {
      allPerms: allPerms,
      darkMode: false,
      permsDialog: false,
      notifications: [],
      notificationPopover: false,
      notificationBadge: false,
      isNotificationsInitiallyLoaded: false,
      isNotificationsLoading: false,
      isIntersecting: false,
      isEndReached: false,
      options: {
        search: null,
        page: 0,
        itemsPerPage: 5,
        sortBy: "createDate",
        sortDesc: true,
      },
      total: 0,
      storedSearch: null,
      toastOptions: {
        id: "COCO_NOTIFY_PERM",
        position: "top-center",
        timeout: false,
        closeOnClick: false,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: false,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: false,
        icon: false,
        rtl: false,
        toastClassName: "notification-permission",
      },
    };
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    role: {
      type: String,
      default: null,
    },
    mini: {
      type: Boolean,
      default: null,
    },
    hideNotifications: {
      type: Boolean,
      default: false,
    },
    messages: {
      type: Array,
      default: null,
    },
    hideMessages: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    switchLabel: function () {
      return this.darkMode ? "Dark" : "Light";
    },
    perms() {
      if (
        store.getters.user &&
        store.getters.user.roles[0] &&
        store.getters.user.roles[0].permissions
      ) {
        return this.cloneDeep(store.getters.user.roles[0].permissions).sort((a, b) => {
          const nameA = a.name.toUpperCase(); // ignore upper and lowercase
          const nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
      }
      return [];
    },
  },
  created() {
    eventBus.$on("add-to-notification-center", (obj) => {
      this.$log(">>>>>>>>>>>>> openPopup", obj);
      var findNotificationIndex = this.notifications.findIndex((notif) => notif.id == obj.id);
      if (findNotificationIndex === -1) {
        this.notifications.unshift(obj);
      } else {
        this.notifications.splice(findNotificationIndex, 1);
        this.notifications.unshift(obj);
      }
    });
  },
  mounted() {
    this.$connection.on("SendTask", (obj) => {
      this.$log("SendTask", obj);
      this.notificationBadge = true;
      var findNotificationIndex = this.notifications.findIndex((notif) => notif.id == obj.id);
      if (findNotificationIndex === -1) {
        this.notifications.unshift(obj);
      } else {
        this.notifications.splice(findNotificationIndex, 1);
        this.notifications.unshift(obj);
      }
      this.$notify(obj);
      this.browserNotify(
        this.generateNotificationTitle(obj),
        this.generateNotificationText(obj),
        obj
      );
    });
    this.getData();
    this.checkNotificationPermission();
    this.loadPersonalSettings();
    window.addEventListener("storage", this.onStorageUpdate);
  },
  beforeDestroy() {
    window.removeEventListener("storage", this.onStorageUpdate);
  },
  methods: {
    onStorageUpdate(event) {
      if (event.key === "personalSettings") {
        this.$log("onStorageUpdate PersonalSettings");
        this.$store.dispatch("getPersonalSettings");
      }
    },
    openPersonalSettingsSlideout() {
      this.$refs.personalSettingsSlideout.open();
    },
    loadPersonalSettings() {
      this.personalSettingsLoading = true;
      personalSettingsService
        .get()
        .then((resp) => {
          this.$log("set PersonalSettings", resp.data);
          this.$store.dispatch("setPersonalSettings", this.cloneDeep(resp.data));
          this.$log("get PersonalSettings", this.$store.getters.personalSettings);
          this.personalSettingsLoading = false;
        })
        .catch((e) => {
          this.$log(e);
          this.personalSettingsLoading = false;
        });
    },
    toggleDarkMode: function () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.darkMode = !this.darkMode;
    },
    checkNotificationPermission() {
      var notification_perm = this.$getFromLocal("COCO_NotificationPerm", false, "default");
      if (Notification.permission == "default" && notification_perm != "denied") {
        this.$toast.info({ component: ToastNotificationPermission }, this.toastOptions);
      }
    },
    generateNotificationTitle(notification) {
      var type = this.getEnumMember(Enums.TemplateType, notification.templateType);
      var status = this.getEnumMember(Enums.DownloadStatus, notification.status);
      // return `Concordia - ${type.text}'s ${status.text.toLowerCase()}`;
      return `[${status.text}] ${type.text} - Concordia`;
    },
    generateNotificationText(notification) {
      var status = this.getEnumMember(Enums.DownloadStatus, notification.status);
      return `${notification.fileName}`;
    },
    browserNotify(notificationTitle, notificationText, data) {
      // Let's check if the browser supports notifications
      if (!("Notification" in window)) {
        this.$log("This browser does not support desktop notification");
      }

      // Let's check whether notification permissions have already been granted
      else if (Notification.permission === "granted") {
        var iconImg = "/img/DNA_Symbol.png";
        if (data.status == Enums.DownloadStatus.Succeeded.value)
          iconImg = "/img/DNA_Symbol_R_Success.png";
        else if (data.status == Enums.DownloadStatus.Inprogress.value)
          iconImg = "/img/DNA_Symbol_R_InProgress.png";
        else if (data.status == Enums.DownloadStatus.Failed.value)
          iconImg = "/img/DNA_Symbol_R_Failed.png";

        var iconImgUrl = window.location.origin + iconImg;
        // If it's okay let's create a notification
        var notif = new Notification(notificationTitle, {
          body: notificationText,
          data: data,
          icon: iconImgUrl,
        });
        notif.onclick = function (evt) {
          window.this.$log("data", evt.target.data);
          var notifObj = evt.target.data;
          if (notifObj != null && notifObj.status == Enums.DownloadStatus.Succeeded.value) {
            window.open(notifObj.url, "Download");
            // var downloadFrame = document.createElement("iframe");
            // downloadFrame.setAttribute("src", notifObj.url);
            // downloadFrame.setAttribute("class", "screenReaderText");
            // document.body.appendChild(downloadFrame);
          }
          window.focus();
          this.close();
        };
      }
      // At last, if the user has denied notifications, and you
      // want to be respectful there is no need to bother them any more.
    },
    getTemplateType(notification) {
      if (notification != null) {
        return this.getEnumMember(Enums.TemplateType, notification.templateType);
      } else return null;
    },
    getDownloadStatus(notification) {
      if (notification != null) {
        return this.getEnumMember(Enums.DownloadStatus, notification.status);
      } else return null;
    },
    getData() {
      this.$log(">>>> getData - this.isEndReached", this.isEndReached);
      if (this.isEndReached || this.isNotificationsLoading) return;
      this.options.page++;
      let optionsToSend = this.cloneDeep(this.options);
      if (this.storedSearch != optionsToSend.search) optionsToSend.page = 0;
      this.storedSearch = optionsToSend.search;

      this.isNotificationsLoading = true;
      NotificationsApi.query(this.$clean(optionsToSend, true))
        .then((resp) => {
          if (!this.isNotificationsInitiallyLoaded) {
            this.isNotificationsInitiallyLoaded = true;
          }
          if (resp.data.items.length < this.options.itemsPerPage) {
            this.isEndReached = true;
          }
          this.$log("resp.data", resp.data, "isEndReached ", this.isEndReached);
          resp.data.items.forEach((element) => {
            if (this.notifications.findIndex((notif) => notif.id == element.id) === -1)
              this.notifications.push(element);
          });
          this.total = resp.data.total;
          this.isNotificationsLoading = false;

          setTimeout(() => {
            this.$log(">>>> setTimeout - this.isIntersecting:", this.isIntersecting);
            if (this.isIntersecting && !this.isEndReached) {
              this.getData();
            }
          }, 500);
        })
        .catch((err) => {
          this.isNotificationsLoading = false;
          // this.$handleError(err);
        });
    },
    onIntersect(entries, observer) {
      // More information about these options
      // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      this.isIntersecting = entries[0].isIntersecting;
      this.$log(">>>> onIntersect - this.isIntersecting:", this.isIntersecting);
      if (this.isIntersecting) {
        this.getData();
      }
    },
  },
  watch: {
    notificationPopover() {
      if (this.notificationPopover) {
        this.notificationBadge = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user-profile {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: default;

  .v-btn.v-btn--icon {
    font-size: 16px;
  }
}
.user-profile-menu-list {
  .v-list-item {
    height: 32px;
    min-height: 32px;
  }
  .v-list-item__icon {
    align-self: center;
  }
}

.notification-card {
  display: flex;
  flex-direction: column;
  padding: 0.65rem 1rem;
  transition: 0.2s all ease-out;
  border-bottom: 1px solid rgba($shades-black, 0.16);
  align-items: flex-start;

  &:hover {
    background: rgba($shades-black, 0.06);
  }
}
</style>
