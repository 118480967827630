<template>
  <v-container fluid class="px-6 pt-2" style="background: #eceff1">
    <v-row class="my-0" justify="center" align="center">
      <v-col>
        <h3>Buildings that contains this Equipment!</h3>
      </v-col>
      <v-col
        lg="5"
        md="2"
        sm="6"
        class="d-flex flex-row align-center justify-end flex-wrap"
        style="gap: 0.5rem"
      >
        <v-text-field
          v-model="options.search"
          label="Search"
          ref="mainSearch"
          class="table-search-field"
          @focus="mainSearchInFocus = true"
          @blur="mainSearchInFocus = false"
          :style="{
            'max-width':
              mainSearchInFocus || (options.search != '' && options.search != null)
                ? '200px'
                : '110px',
          }"
          dense
          solo
          clearable
          hide-details
          prepend-inner-icon="far fa-search"
          :loading="loadingStates.table"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      dense
      class="elevation-2"
      :headers="headers"
      :items="entities"
      :server-items-length="total"
      :items-per-page="15"
      :loading="loadingStates.table"
      :options.sync="options"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'far fa-arrow-to-left',
        lastIcon: 'far fa-arrow-to-right',
        prevIcon: 'far fa-angle-left',
        nextIcon: 'far fa-angle-right',
        itemsPerPageOptions: [15, 30, 50, 100],
      }"
    >
      <template v-slot:[`item.name`]="{ item }">
        <h4><i class="far fa-building mr-1"></i> {{ item.name }}</h4>
      </template>

      <template v-slot:[`item.createDate`]="{ item }">
        <dater :date="item.createDate" dateonly></dater>
      </template>

      <template v-slot:[`item.updateDate`]="{ item }">
        <dater :date="item.updateDate" dateonly></dater>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          icon
          elevation="0"
          color="info"
          target="_blank"
          @click.stop
          :href="`/projects/${item.projectId}/buildings/${item.id}`"
        >
          <i class="fal fa-external-link"></i>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <img width="300" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>

      <template v-slot:loading>
        <video width="250" muted loop autoplay style="padding: 0.5rem">
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Searching the Cosmos...</p>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import equipmentService from "../services/equipments-service";
import buildingHeader from "../../Projects/config/tables/building.header";
export default {
  name: "equipment-has-buildings",
  props: {
    equipment: {
      type: Object,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    storageKey: {
      type: String,
    },
  },
  data() {
    return {
      entities: [],
      total: 0,
      clientsTimerId: null,
      mainSearchInFocus: false,
      options: {
        search: null,
        page: 1,
        itemsPerPage: 15,
      },
      loadingStates: {
        table: false,
      },
      headers: buildingHeader,
    };
  },
  created() {
    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
  },
  methods: {
    getEquipmentItems() {
      this.$backToTop(
        0,
        document.querySelector(".equipment-stats-tabs.v-window.v-item-group.v-tabs-items")
      );
      this.loadingStates.table = true;
      if (this.equipment) {
        equipmentService
          .getEquipmentItems(this.equipment.id, this.$clean(this.options, true), "buildings")
          .then((resp) => {
            this.entities = resp.data.items;
            this.total = resp.data.total;
            this.loadingStates.table = false;
          })
          .catch(() => {
            this.loadingStates.table = false;
          });
      }
    },
    getProjectDebounced() {
      if (this.clientsTimerId == null) {
        this.clientsTimerId = -1;
        this.getEquipmentItems();
        return;
      }
      // cancel pending call
      clearTimeout(this.clientsTimerId);

      // delay new call 400ms
      this.clientsTimerId = setTimeout(() => {
        this.getEquipmentItems();
      }, 400);
    },
  },
  watch: {
    options: {
      handler(val) {
        this.$setToLocal(`${this.storageKey}-itemsPerPage`, val.itemsPerPage);
        this.getProjectDebounced();
      },
      deep: true,
    },
    active(val) {
      if (val) {
        this.options.page = 1;
        this.getProjectDebounced();
      } else {
        this.entities = [];
        this.total = 0;
      }
    },
  },
};
</script>
