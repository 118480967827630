<template>
  <span>
    <equipment-installer :installerName="parsedValue.Name"></equipment-installer>
  </span>
</template>

<script>
import EquipmentInstaller from "../EquipmentInstaller.vue";

export default {
  components: { EquipmentInstaller },
  name: "installer-logged-prop",
  inheritAttrs: false,
  data() {
    return {};
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    parsedValue() {
      return JSON.parse(this.value);
    },
  },
  methods: {},
};
</script>

<style lang="scss"></style>
