import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#2196f3", //#3B96D2
        secondary: "#2a363b",
        accent: "#e91e63",
        success: "#4EB96F",
        error: "#E54C3C",
        chart: {
          orange: colors.orange.base,
          amber: colors.amber.darken1,
          green: colors.green.base,
          pink: colors.pink.base,
          brown: colors.brown.base,
        },
      },
    },
  },
});
