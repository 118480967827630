export default [
  { text: "Count", value: "count", align: "left", sortable: false, width: "100px" },
  { text: "Info", value: "info", align: "left", sortable: false, width: "40px" },
  { text: "Image", value: "suggested.name", width: "90px", sortable: false },
  { text: "Category", value: "suggested.category.name" },
  { text: "Tag", value: "suggested.tag" },
  { text: "Model", value: "suggested.model" },
  { text: "Provider", value: "suggested.providerId", width: "100px" },
  { text: "Installer", value: "suggested.installerId", width: "100px" },
];
