import Vue from "vue";
import VueRouter from "vue-router";
import adminRoutes from "../features/Admin/admin-routes";
import mainRoutes from "../features/Main/main-routes";
import dashboardRoutes from "../features/Main/dashboard-routes";
import externalDashboardRoutes from "../features/External/Main/external-dashboard-routes";
import accountsRoutes from "../features/Accounts/accounts-routes";
import projectsRoutes from "../features/Projects/projects-routes";
import clientsRoutes from "../features/Companies/Clients/clients-routes";
import spacesRoutes from "../features/Spaces/spaces-routes";
import equipmentRoutes from "../features/Equipments/equipment-routes";
import inventoryRoutes from "../features/Inventory/inventory-routes";
import ddmRoutes from "../features/DrawingDocuments/ddm-routes";
import store from "../store/index";
import leadsRoutes from "../features/Leads/leads-routes";
import rolesRoutes from "../features/Roles/roles-routes";
import { has } from "../plugins/helpers";
import manufacturesRoutes from "../features/Companies/Manufacturers/manufactures-routes";
import representativesRoutes from "../features/Companies/Representatives/representatives-routes";
import systemsIntegratorsRoutes from "../features/Companies/SystemsIntegrators/systemsIntegrators-routes";
import generalContractorsRoutes from "../features/Companies/GeneralContractors/generalContractors-routes";
import subContractorsRoutes from "../features/Companies/SubContractors/subContractors-routes";
import architectsRoutes from "../features/Companies/Architects/architects-routes";
import engineeringFirmsRoutes from "../features/Companies/EngineeringFirms/engineeringFirms-routes";
import teamRoutes from "../features/Teams/team-routes";
import accessGroupsRoutes from "../features/AccessGroups/access-group-routes";
import workloadRoutes from "../features/Workload/workload-routes";
import monthlyPlansRoutes from "../features/MonthlyPlan/monthlyPlans-routes";
import bigBoardRoutes from "../features/BigBoard/big-board-routes";
import dnaDefaultsRoutes from "../features/DNADefaults/dna-defaults-routes";
import externalUsersRoutes from "../features/External/Users/external-users-routes";
import externalProjectsRoutes from "../features/External/Projects/projects-routes";
import externalAccessGroupRoutes from "../features/External/AccessGroups/access-group-routes";
import externalEquipmentsRoutes from "../features/External/Equipments/equipments-routes";
import personalSettingsRoutes from "../features/PersonalSettings/personal-settings-routes";
import externalRoles from "../features/External/Roles/external-roles-routes";
import resourceOffers from "../features/ResourceOffers/offer-routes";
import resourceShiftRequests from "../features/ResourceShiftRequests/shift-request-routes";
import contractsRoutes from "../features/Contracts/contracts-routes";
Vue.use(VueRouter);

var targetDashboard =
  store.getters.user != null && store.getters.user.isExternalUser
    ? externalDashboardRoutes
    : dashboardRoutes;

const routes = [
  ...targetDashboard,
  ...mainRoutes,
  ...adminRoutes,
  ...accountsRoutes,
  ...projectsRoutes,
  ...clientsRoutes,
  ...spacesRoutes,
  ...equipmentRoutes,
  ...inventoryRoutes,
  ...leadsRoutes,
  ...ddmRoutes,
  ...rolesRoutes,
  ...manufacturesRoutes,
  ...representativesRoutes,
  ...systemsIntegratorsRoutes,
  ...generalContractorsRoutes,
  ...subContractorsRoutes,
  ...architectsRoutes,
  ...engineeringFirmsRoutes,
  ...teamRoutes,
  ...workloadRoutes,
  ...monthlyPlansRoutes,
  ...bigBoardRoutes,
  ...dnaDefaultsRoutes,
  ...accessGroupsRoutes,
  ...externalUsersRoutes,
  ...externalProjectsRoutes,
  ...externalAccessGroupRoutes,
  ...externalEquipmentsRoutes,
  // ...personalSettingsRoutes,
  ...externalRoles,
  ...resourceOffers,
  ...resourceShiftRequests,
  ...contractsRoutes,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

window.addEventListener(
  "beforeunload",
  function (e) {
    if (!store.getters.isBlocked) return undefined;
    var confirmationMessage = "You have unsaved changes on this page! leave anyway?";

    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
  }.bind(store)
);

router.beforeEach((to, from, next) => {
  if (store.getters.isBlocked) {
    const answer = window.confirm("You have unsaved changes on this page! leave anyway?");
    if (!answer) {
      return false;
    } else {
      store.dispatch("unblockRouter");
    }
  }

  if (to.meta != null && to.meta.title != null) document.title = to.meta.title + " | Concordia";
  // else {
  //   this.$log(">>>>>>>> ROUTER", { ...to });
  //   document.title = "Concordia";
  // }

  if (to.name === "login") {
    next(); // login route is always  okay (we could use the requires auth flag below). prevent a redirect loop
  } else if (
    store.getters.isAuthenticated &&
    to.meta != null &&
    to.meta.permissions != null &&
    !has(to.meta.permissions)
  ) {
    next({ name: "error-not-authorizd", query: { redirected: to.path } }); // i'm logged in. but not authorizd
  } else if (store.getters.isAuthenticated) {
    next(); // i'm logged in. carry on
  } else {
    next({ name: "login" }); // always put your redirect as the default case
  }
});

export default router;
