<script>
import Header from "@/features/Shared/components/Header.vue";
import Footer from "@/features/Shared/components/Footer.vue";
export default {
  components: {},
};
</script>

<template>
  <div>
    <v-toolbar color="transparent" flat>
      <a href="/" class="logo-wrapper-alt toolbar-logo">
        <img height="36" src="/img/DNA_Symbol.png" />
        <v-img contain height="36" src="/img/logo.svg"></v-img>
      </a>
    </v-toolbar>
    <v-main>
      <v-container fluid class="flex-">
        <slot />
      </v-container>
    </v-main>
  </div>
</template>
<style lang="scss">
.logo-wrapper-alt {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}
.v-application--wrap {
  justify-content: flex-start;
  & > div {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
  .v-toolbar {
    flex: none;
  }
}
.v-application .v-input__control .error--text {
  color: var(--v-accent-base) !important;
  caret-color: var(--v-accent-base) !important;
}
</style>
