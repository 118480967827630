<template>
  <v-chip
    v-on:click="clicked"
    :color="count > 0 ? 'info' : 'grey lighten-1'"
    :textColor="count > 0 ? 'info' : ''"
    class="count-tag"
    small
    outlined
    v-if="count != null"
  >
    <i class="fal mr-2" :class="icon ? icon : 'fa-tag'"></i>
    <span>{{ count }}</span></v-chip
  >
</template>

<script>
export default {
  name: "count-tag",
  props: {
    count: {
      type: Number,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
  },
  methods: {
    clicked(e) {
      this.$emit("click", e);
    },
  },
};
</script>

<style lang="scss">
.count-tag {
  padding: 0 0.75rem;
  min-width: 48px;
  border: 1.5px solid rgba($shades-black, 0.24) !important;

  span {
    font-weight: 600;
    font-size: 12px;
  }
}
</style>
