var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-6 pt-2",staticStyle:{"background":"#eceff1"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"my-2",attrs:{"justify":"space-between"}},[_c('v-col',[(!_vm.readonly)?_c('v-btn',{ref:"addAccessoriesBtn",attrs:{"color":"info"},on:{"click":function($event){return _vm.openAccessoriesPanel()}}},[_c('i',{staticClass:"fal fa-plus mr-2"}),_vm._v("Add Accessories ")]):_vm._e()],1)],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"dense":"","headers":_vm.equipmentAccessoriesHeaders,"items":_vm.value,"item-class":_vm.rowClass,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.info",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"secondary","icon":"","elevation":"0","small":""},on:{"click":function($event){return _vm.viewEquipment(item.accessory.id)}}},[_c('i',{staticClass:"far fa-info"})])]}},{key:"item.accessory.name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":"","z-index":"999","nudge-right":"-4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"equipment-img"},[(
                  item.accessory.imageUrl != null &&
                    item.accessory.imageUrl != ''
                )?_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}],staticStyle:{"height":"100%","width":"100%"},on:{"click":function($event){$event.stopPropagation();}}},[_c('img',{key:item.accessory.id + '_img',attrs:{"src":item.accessory.imageUrl,"height":"100%","width":"100%"}})]):(item.accessory.category != null)?_c('i',{class:'fad ' + item.accessory.category.icon}):_c('i',{class:'fad fa-plug'})])])]}}],null,true)},[_c('span',[(
              (item.accessory.imageUrl != null) &
                (item.accessory.imageUrl != '')
            )?_c('v-img',{staticClass:"img",attrs:{"src":item.accessory.imageUrl,"width":"250px","height":"250px","contain":""}}):(item.accessory.category != null)?_c('i',{class:'fad ' + item.accessory.category.icon,staticStyle:{"font-size":"32px","padding":"2rem"}}):_c('i',{class:'fad fa-plug',staticStyle:{"font-size":"32px","padding":"2rem"}})],1)])]}},{key:"item.accessory.category.name",fn:function(ref){
            var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-content-start align-items-center",attrs:{"sm":"auto"}},[(item.accessory.category)?_c('i',{class:'equipment-icon fad ' + item.accessory.category.icon}):_vm._e()]),_c('v-col',{staticClass:"d-flex align-center"},[(item.accessory.category)?_c('span',{staticClass:"category-name"},[_vm._v(_vm._s(item.accessory.category.name))]):_vm._e()])],1)]}},{key:"item.accessory.tag",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"equipment-tag font-weight-bold"},[_vm._v(" "+_vm._s(item.accessory.tag)+" ")])]}},{key:"item.accessory.model",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"equipment-model"},[_vm._v(_vm._s(item.accessory.model))])]}},{key:"item.accessory.providerId",fn:function(ref){
            var item = ref.item;
return [(item.accessory.provider)?_c('equipment-provider',{attrs:{"provider-name":item.accessory.provider.name}}):_c('i',{class:'far fa-times pl-2 text--disabled'})]}},{key:"item.accessory.installerId",fn:function(ref){
            var item = ref.item;
return [(item.accessory.installer)?_c('equipment-installer',{attrs:{"installer-name":item.accessory.installer.name}}):_c('i',{class:'far fa-times pl-2 text--disabled'})]}},{key:"item.count",fn:function(ref){
            var item = ref.item;
return [_c('counter',{key:item.accessory.id,attrs:{"min":0,"max":1000,"readonly":_vm.readonly},on:{"input":function($event){return _vm.onAccessoriesCountChange($event, item.accessory)}},model:{value:(item.count),callback:function ($$v) {_vm.$set(item, "count", $$v)},expression:"item.count"}})]}},{key:"no-data",fn:function(){return [_c('img',{attrs:{"width":"300","src":"/img/art/fogg-no-connection-2.png"}}),_c('p',{staticClass:"font-weight-bold"},[_vm._v("No Data Available!")])]},proxy:true}],null,true)}),_c('edit-equipment',{ref:"editEquipment",attrs:{"nested":""}}),(!_vm.readonly)?_c('slideout',{attrs:{"dock":"right","size":"87%","visible":_vm.slideouts.addAccessories.active,"closeOnMaskClick":true},on:{"update:visible":function($event){return _vm.$set(_vm.slideouts.addAccessories, "active", $event)}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h3',{staticClass:"font-weight-bold pa-1",attrs:{"small":""}},[_c('i',{staticClass:"fad fa-plus-square mr-2"}),_vm._v(" Add Accessories ")]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.discardAddAccessories()}}},[_c('i',{staticClass:"far fa-times"})])]},proxy:true},{key:"footer",fn:function(){return [_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"info"},on:{"click":function($event){_vm.slideouts.addAccessories.active = false}}},[_c('i',{staticClass:"fal fa-check mr-2"}),_vm._v(" Done ")])],1)]},proxy:true}],null,false,2612837353)},[_c('v-container',{staticClass:"pa-6 pt-2",staticStyle:{"background":"#eceff1"}},[(_vm.slideouts.addAccessories.active)?_c('add-accessories',{ref:"addAccessoriesControl",attrs:{"value":_vm.value,"disabledAccessories":_vm.selectedId ? [_vm.selectedId] : null},on:{"input":_vm.onAddAccessoriesChange}}):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }