<template>
  <v-tooltip
    top
    z-index="999"
    nudge-top="-4px"
    :disabled="!mini"
    v-if="availability != null && availabilityObj != null"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" class="d-inline-block">
        <v-chip
          class="equipment-availability font-weight-medium"
          :x-small="mini"
          :small="!mini"
          outlined
          :color="availabilityObj.color"
        >
          <span class="d-flex align-center">
            <i
              v-if="mini"
              class="fas text-center"
              :class="availabilityObj.icon"
              style="width: 16px; line-height: 0"
            ></i>
            <i
              v-else
              class="fas mr-1 text-center"
              :class="availabilityObj.icon"
              style="width: 16px; line-height: 0"
            ></i>
            <span v-if="!mini">{{ availabilityObj.text }}</span>
          </span>
        </v-chip>
      </div>
    </template>
    <span>{{ availabilityObj.text }}</span>
  </v-tooltip>
</template>

<script>
import Enums from "../../../plugins/enums";
export default {
  name: "equipment-availability",
  data() {
    return {
      equipmentAvailability: Enums.EquipmentAvailability,
      availabilityObj: null,
    };
  },
  props: {
    availability: {
      type: Number,
      default: null,
    },
    mini: {
      type: Boolean,
      default: null,
    },
  },
  mounted() {
    this.updateAvailabilityObj();
  },
  computed: {},
  methods: {
    updateAvailabilityObj() {
      if (this.availability != null)
        this.availabilityObj = this.getEnumMember(this.equipmentAvailability, this.availability);
    },
  },
  watch: {
    availability: {
      handler() {
        this.updateAvailabilityObj();
      },
    },
  },
};
</script>

<style lang="scss"></style>
