<template>
  <v-dialog v-model="active" width="600" retain-focus content-class="quick-tools-dialog">
    <v-card rounded="lg">
      <v-card-title
        class="font-weight-medium white--text grey darken-4"
        style="font-size: 16px; padding: 12px 16px 8px 20px; padding: 8px 8px 6px 18px"
      >
        <span class="d-flex align-center">
          <i class="fad fa-circle-dollar mr-3 fs-20px"></i>Project Cost Estimator
        </span>
        <v-spacer></v-spacer>
        <v-btn dark @click="close" icon>
          <i class="far fa-times"></i>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="secondary--text">
        <v-row class="my-0 mt-4">
          <v-col cols="12" md="8" class="pt-0">
            <v-text-field-alt
              prefix="$"
              :rules="[allRules.number]"
              label="Hardware Cost"
              ref="hardwareCost"
              placeholder="Hardware Cost"
              v-model.number="selected.hardwareCost"
              @input="hardwareCostChanged"
              hide-details
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12" md="4" class="pt-0">
            <v-text-field-alt
              suffix="Sqft"
              :rules="[allRules.number]"
              label="Total Area"
              ref="totalArea"
              placeholder="Total Area"
              v-model.number="selected.totalArea"
              hide-details
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12" md="12" class="pt-0">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" md="4" class="py-0">
            <v-text-field-alt
              :rules="[allRules.number]"
              label="Install Factor"
              placeholder="Install Factor"
              v-model.number="selected.installFactor"
              @input="installFactorChanged"
              hide-details
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12" md="4" class="py-0">
            <v-text-field-alt
              prefix="$"
              label="Install Cost"
              v-model.number="selected.installCost"
              hide-details
              readonly
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12" md="4" class="py-0">
            <v-text-field-alt
              suffix="$/Sqft"
              label="Install Cost <u class='ml-1 font-weight-bold opacity-54'>Per Sqft</u>"
              v-model.number="installCostPerSqft"
              hide-details
              readonly
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12" md="4" class="pb-0">
            <v-text-field-alt
              :rules="[allRules.number]"
              label="Labor Factor"
              placeholder="Labor Factor"
              v-model.number="selected.laborFactor"
              @input="laborFactorChanged"
              hide-details
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12" md="4" class="pb-0">
            <v-text-field-alt
              prefix="$"
              label="Labor Cost"
              placeholder="Labor Cost"
              v-model.number="selected.laborCost"
              hide-details
              readonly
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12" md="4" class="pb-0">
            <v-text-field-alt
              suffix="$/Sqft"
              label="Labor Cost <u class='ml-1 font-weight-bold opacity-54'>Per Sqft</u>"
              v-model.number="laborCostPerSqft"
              hide-details
              readonly
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12" md="12" class="mt-2">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" md="8" class="py-0">
            <v-text-field-alt
              prefix="$"
              label="Total Cost"
              v-model.number="totalCost"
              hide-details
              readonly
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12" md="4" class="py-0">
            <v-text-field-alt
              prefix="$"
              label="Total Cost <u class='ml-1 font-weight-bold opacity-54'>Per Sqft</u>"
              v-model.number="totalCostPerSqft"
              hide-details
              readonly
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12" md="12" class="mt-2">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" md="12" class="py-0">
            <h4>Reference:</h4>
            <ul class="mt-1">
              <li>Total Cost = Hardware Cost + Install Cost + Labor Cost</li>
              <li>Total Cost Per Sqft = Total Cost / Total Area</li>
            </ul>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      active: false,
      selected: {
        hardwareCost: 1000,
        totalArea: 0,
        installFactor: 0.25,
        installCost: 250,
        laborFactor: 0.25,
        laborCost: 250,
      },
    };
  },
  props: {},
  mounted() {},
  computed: {
    totalCost() {
      if (
        this.isNum(this.selected.hardwareCost) &&
        this.isNum(this.selected.installCost) &&
        this.isNum(this.selected.laborCost)
      ) {
        return (
          parseFloat(this.selected.hardwareCost) +
          parseFloat(this.selected.installCost) +
          parseFloat(this.selected.laborCost)
        );
      } else return 0;
    },
    totalCostPerSqft() {
      return this.selected.totalArea > 0
        ? (this.totalCost / this.selected.totalArea).toFixed(2)
        : 0;
    },
    installCostPerSqft() {
      return this.selected.totalArea > 0
        ? (this.selected.installCost / this.selected.totalArea).toFixed(2)
        : 0;
    },
    laborCostPerSqft() {
      return this.selected.totalArea > 0
        ? (this.selected.laborCost / this.selected.totalArea).toFixed(2)
        : 0;
    },
  },
  methods: {
    open() {
      this.active = true;
      setTimeout(() => {
        this.$refs.hardwareCost.select();
      }, 300);
    },
    close() {
      this.active = false;
    },
    isNum(value) {
      return /^[0-9]+.[0-9]+|[0-9]+$/.test(value);
    },
    hardwareCostChanged() {
      this.refreshInstallCost();
      this.refreshLaborCost();
    },
    installFactorChanged() {
      if (this.isNum(this.selected.installFactor)) {
        this.refreshInstallCost();
      }
    },
    laborFactorChanged() {
      if (this.isNum(this.selected.laborFactor)) {
        this.refreshLaborCost();
      } else {
        this.selected.laborCost = 0;
      }
    },
    refreshInstallCost() {
      if (
        this.selected == null ||
        !this.isNum(this.selected.hardwareCost) ||
        !this.isNum(this.selected.installFactor)
      )
        this.selected.installCost = 0;
      else
        this.selected.installCost = parseFloat(
          (this.selected.hardwareCost * this.selected.installFactor).toFixed(2)
        );
    },
    refreshLaborCost() {
      if (
        this.selected == null ||
        !this.isNum(this.selected.hardwareCost) ||
        !this.isNum(this.selected.laborFactor)
      )
        this.selected.laborCost = 0;
      else
        this.selected.laborCost = parseFloat(
          (this.selected.hardwareCost * this.selected.laborFactor).toFixed(2)
        );
    },
  },
  watch: {},
};
</script>

<style lang="scss"></style>
