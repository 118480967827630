import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "./plugins/axios";
import vuetify from "./plugins/vuetify";
import "./plugins/apexcharts";
import Filters from "./plugins/filters";
import Directives from "./plugins/directives";
import Mixins from "./plugins/mixins";
import signalR from "@/plugins/signalR";
import "./plugins/components";

Vue.config.productionTip = false;
Vue.mixin(Mixins);

import VuetifyDialog from "vuetify-dialog";
import "vuetify-dialog/dist/vuetify-dialog.css";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";
import "@hyjiacan/vue-slideout/lib/slideout.css";
Vue.use(VuetifyDialog, {
  context: {
    vuetify,
  },
});

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const toastOptions = {
  transition: "Vue-Toastification__fade",
  maxToasts: 20,
  newestOnTop: true,
};
Vue.use(Toast, toastOptions);

import "highlight.js/styles/xcode.css";
import hljs from "highlight.js/lib/common";
import xml from "highlight.js/lib/languages/xml";
import json from "highlight.js/lib/languages/json";
hljs.registerLanguage("xml", xml);
hljs.registerLanguage("json", json);

import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
Vue.use(VueViewer, {
  defaultOptions: {
    zIndex: 99999999,
  },
});
Vue.use(signalR);
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: "fa",
});

export const eventBus = new Vue(); // creating an event bus.

new Vue({
  router,
  store,
  vuetify,
  created: function () {},

  render: (h) => h(App),
}).$mount("#app");
