<template>
  <v-tooltip v-if="fullWidth" bottom z-index="99999" color="secondary">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        @click="fullWidth = false"
        icon
        v-bind="attrs"
        v-on="on"
        :dark="dark"
      >
        <i class="far fa-compress fs-16px"></i>
      </v-btn>
    </template>
    <span style="font-size: 14px"> Minimize Window </span>
  </v-tooltip>
  <v-tooltip v-else bottom z-index="99999" color="secondary">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        @click="fullWidth = true"
        icon
        v-bind="attrs"
        v-on="on"
        :dark="dark"
      >
        <i class="far fa-expand-wide fs-16px"></i>
      </v-btn>
    </template>
    <span style="font-size: 14px"> Maxmize Window </span>
  </v-tooltip>
</template>

<script>
export default {
  name: "panel-size-control",
  data() {
    return {
      fullWidth: false
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.fullWidth = this.value;
  },
  watch: {
    value() {
      if (this.value != this.fullWidth) {
        this.fullWidth = this.value;
      }
    },
    fullWidth() {
      this.$emit("input", this.fullWidth);
    }
  },
  methods: {}
};
</script>

<style lang="scss"></style>
